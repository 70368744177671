import fp from "lodash/fp";
import { camelCase, kebabCase, snakeCase } from "lodash";

function createMapper(changeCase) {
  // eslint-disable-next-line no-use-before-define
  const mapArray = fp.map(mapKeysDeep);
  const mapObject = fp.flow(
    fp.mapKeys(changeCase),
    // eslint-disable-next-line no-use-before-define
    fp.mapValues(mapKeysDeep),
  );

  // eslint-disable-next-line no-use-before-define
  return mapKeysDeep;

  function mapKeysDeep(value) {
    if (fp.isArray(value)) {
      return mapArray(value);
    }

    if (fp.isPlainObject(value)) {
      return mapObject(value);
    }

    return value;
  }
}

export function toCamelCase(value) {
  return createMapper(camelCase)(value);
}

export function toSnakeCase(value) {
  return createMapper(snakeCase)(value);
}

export function toKebabCase(value) {
  return createMapper(kebabCase)(value);
}
