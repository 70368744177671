import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { Form, Input, SubmitButton } from "formik-antd";

import { AppRoutes } from "../../constants/Routes";
import { Formik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  password: yup.string().min(8).required("Password is required"),
});

interface Props {
  readonly loading: boolean;
  readonly onSubmit: (value: { password: string }) => void;
}

export function MemberPasswordForm({ onSubmit, loading }: Props) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ password: "" }}
      validationSchema={validationSchema}
    >
      <Form>
        <Form.Item name="password" label="Password">
          <Input.Password name="password" />
        </Form.Item>

        <SubmitButton className="mb-1" type="primary" block={true} loading={loading}>
          SIGN-IN
        </SubmitButton>

        <Button type="link" block={true}>
          <Link to={AppRoutes.AuthMemberForgotPassword}>forgot password</Link>
        </Button>
      </Form>
    </Formik>
  );
}
