import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { Input } from "../ui/Input";

interface Props {
  values: any;
  active: boolean;
  valueName:
    | "linkedinOverrideUrl"
    | "facebookOverrideUrl"
    | "pinterestOverrideUrl"
    | "twitterOverrideUrl";
}

const InputUrlField = ({ values, active, valueName }: Props) => {
  const [overrideUrlMask, setOverrideUrlMask] = useState({
    prefix: "https://",
    adds: "",
  });
  const { Option } = Select;

  useEffect(() => {
    if (
      values?.promotionSocial?.[valueName] &&
      values?.promotionSocial?.[valueName].startsWith("http://")
    ) {
      setOverrideUrlMask({
        ...overrideUrlMask,
        adds: values.promotionSocial[valueName].slice(7),
      });
    }

    if (
      values?.promotionSocial?.[valueName] &&
      values?.promotionSocial?.[valueName].startsWith("https://")
    ) {
      setOverrideUrlMask({
        prefix: "https://",
        adds: values.promotionSocial[valueName].slice(8),
      });
    }
  }, []);

  const onChange = (e) => {
    setOverrideUrlMask({
      ...overrideUrlMask,
      adds: e.target.value,
    });
    if (e.target.value.length === 0) {
      // values.promotionSocial[valueName] = "";
      delete values.promotionSocial[valueName];
    } else {
      values.promotionSocial[valueName] = overrideUrlMask.prefix + e.target.value;
    }
  };

  const selectBefore = (
    <Select
      // defaultValue="http://"
      style={{ width: 90 }}
      value={overrideUrlMask.prefix}
      onChange={(e) => {
        setOverrideUrlMask({ ...overrideUrlMask, prefix: e });
        values.promotionSocial[valueName] = e + overrideUrlMask.adds;
      }}
    >
      <Option value="https://">https://</Option>
      <Option value="http://">http://</Option>
    </Select>
  );

  return (
    <>
      <label
        style={{
          fontSize: "10px",
          color: "#4a4a4a",
        }}
        className="field-label"
      >
        Override Target Url
      </label>

      <Input
        disabled={!active}
        // label="Override Target Url"
        className="messaging-form-item"
        value={overrideUrlMask.adds}
        addonBefore={selectBefore}
        onChange={(e) => {
          onChange(e);
        }}
      />
    </>
  );
};

export default InputUrlField;
