import React from "react";
import { Button } from "antd";
import { Formik } from "formik";
import PropTypes from "prop-types";
import "./assets/settings-users-invite.scss";

import { InputField } from "../form/InputField";
import { settingsInviteUsersValidationSchema } from "../../helpers/SettingsHelpers";
import { PhoneField } from "../form/PhoneField";

SettingsInviteUserForm.propTypes = {
  onSubmit: PropTypes.func,
};

export function SettingsInviteUserForm({ onSubmit }) {
  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={settingsInviteUsersValidationSchema}
      initialValues={{
        email: "",
        phone: "",
        lastName: "",
        password: "",
        firstName: "",
        confirmPassword: "",
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form className="d-flex flex-column p-2" onSubmit={handleSubmit}>
          <div className="d-flex flex-column flex-sm-row">
            <InputField
              name="firstName"
              label="First Name"
              transparent={false}
              className="flex-1 m-2"
            />
            <InputField
              name="lastName"
              label="Last Name"
              transparent={false}
              className="flex-1 m-2"
            />
          </div>
          <div className="d-flex flex-column flex-sm-row">
            <PhoneField
              name="phone"
              label="Phone"
              placeholder="Enter phone"
              labelClassName="ml-2"
              className="flex-1 m-2"
            />
            <InputField transparent={false} className="flex-1 m-2" name="email" label="Email" />
          </div>
          <div className="d-flex flex-column flex-sm-row">
            <InputField
              type="password"
              name="password"
              label="Password"
              transparent={false}
              className="flex-1 m-2"
            />
            <InputField
              type="password"
              transparent={false}
              className="flex-1 m-2"
              name="confirmPassword"
              label="Confirm password"
            />
          </div>

          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            className="align-self-sm-center mx-2 my-3"
          >
            Invite
          </Button>
        </form>
      )}
    </Formik>
  );
}
