import React, { useState } from "react";

import { TeamMembersTable } from "./TeamMembersTable";
import { DepartmentsTable } from "../departments/DepartmentsTable";
import { Tabs } from "antd";
import { ClientAppLayout } from "../../../components/app/ClientAppLayout";

const { TabPane } = Tabs;

export function TeamMembers() {
  const [tab, setTab] = useState("members");

  return (
    <ClientAppLayout>
      <Tabs animated={false} onChange={setTab} activeKey={tab}>
        <TabPane tab="Members" key="members">
          {tab === "members" && <TeamMembersTable />}
        </TabPane>
        <TabPane tab="Groups" key="groups">
          {tab === "groups" && <DepartmentsTable />}
        </TabPane>
      </Tabs>
    </ClientAppLayout>
  );
}
