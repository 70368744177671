import React from "react";

export function ConfirmedIcon({ size = 24, ...svgProps }: any) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4 12.6111L8.92308 17.5L20 6.5"
        stroke="green"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
