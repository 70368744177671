import { uid } from "react-uid";
import { DateTime } from "luxon";
import { isUndefined } from "lodash";
import React, { useMemo } from "react";

import { Loader } from "../ui/Loader";
import { SettingsBillingHistoryListItem } from "./SettingsBillingHistoryListItem";
import { propOr } from "ramda";

interface Props {
  readonly card: any;
  readonly list: any[];
  readonly loading: boolean;
  readonly subscription: any;
  readonly onEditClick: (id: any) => void;
}

export function SettingsBillingHistoryList({
  card,
  list,
  loading,
  onEditClick,
  subscription,
}: Props) {
  const status = useMemo(() => subscription?.status, [subscription]);
  const trialPassDays = useMemo(() => {
    if (!subscription?.trialStart) {
      return;
    }

    const trialStart = propOr(0, "trialStart", subscription);
    const { days } = DateTime.fromSeconds(trialStart).diffNow("days");

    return Math.floor(Math.abs(days));
  }, [subscription]);

  const expiryTitle = useMemo(() => {
    if (status === "extra_days_expired" || status === "extra_days_notstarted") {
      return "Expired";
    }

    if ((status === "extra_days_inprogress" || "in_trial") && !isUndefined(trialPassDays)) {
      return `No billing history available`;
    }

    if (card?.expiryMonth && card?.expiryYear)
      return `Expires ${card.expiryMonth}/${card.expiryYear}`;

    return "00/0000";
  }, [card, status, trialPassDays]);
  const cardInfoTitle = useMemo(() => {
    if (status === "extra_days_expired" || status === "extra_days_notstarted") {
      return "Your trial is over";
    }
    if (status === "in_trial" || status === "extra_days_inprogress") {
      return "We hope you are enjoying your trial";
    }
  }, [status]);

  if (cardInfoTitle) {
    return (
      <div className="d-flex flex-column align-items-center mt-4">
        <span className="fl-5 font-weight-light text-primary mb-1">{expiryTitle}</span>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column">
      {loading && <Loader />}

      {list.map((item: any) => (
        <SettingsBillingHistoryListItem
          invoice={item}
          key={uid(item)}
          onEditClick={() => onEditClick(item.id)}
        />
      ))}
    </div>
  );
}
