import React, { useCallback, useState } from "react";
import { AppRoutes } from "../../constants/Routes";
import { useQuery } from "../../hooks/useQuery";
import { Loader } from "../../components/ui/Loader";
import { toCamelCase } from "../../helpers/CaseUtils";
import { useAuthContext } from "../../api/auth/AuthContext";
import { SignInForm } from "../../components/auth/SignInForm";
import { SignInTitle } from "../../components/auth/SignInTitle";
import { SignInFooter } from "../../components/auth/SignInFooter";
import { PublicLayout } from "../../core/layout/public/PublicLayout";
import { useSettingsContext } from "../../api/settings/SettingsContext";
import { useNotification } from "../../hooks/useNotification";
import { useSettings, useToken, useUser } from "../../context/auth/hooks";
import { MemberCompaniesForm } from "../../components/auth/MemberCompaniesForm";
import { MemberPasswordForm } from "../../components/auth/MemberPasswordForm";
import useRouter from "../../hooks/useRouter";

interface Query {
  readonly redirect?: string;
}

enum SignInSteps {
  SIGN = "sign",
  CHOOSE_COMPANY = "choose-company",
  PASSWORD = "password",
}

enum SignInType {
  EMAIL = "email",
  PHONE = "phone",
}

export function SignInContainer() {
  const router = useRouter();
  const query = useQuery<Query>();
  const { setUser } = useUser();
  const { setToken } = useToken();
  const { setSettings } = useSettings();
  const { AuthApi } = useAuthContext();
  const { SettingsApi } = useSettingsContext();
  const { showError } = useNotification();

  const [cred, setCred] = useState("");
  const [step, setStep] = useState(SignInSteps.SIGN);
  const [type, setType] = useState("" as SignInType);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleClear = useCallback(() => {
    setType("" as SignInType);
    setCompanies([]);
    setSelectedCompany(0);
    setStep(SignInSteps.SIGN);
  }, []);

  const submitHandlerPhone = useCallback(
    (data) => {
      const { phone } = data;
      return AuthApi.checkMemberPhone(phone)
        .then((companies) => {
          if (companies.length > 1) {
            setCompanies(companies);
            setStep(SignInSteps.CHOOSE_COMPANY);
          } else {
            setSelectedCompany(companies[0].id);
            setStep(SignInSteps.PASSWORD);
          }
          setCred(phone);
          setType(SignInType.PHONE);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showError(error);
        });
    },
    [showError, AuthApi],
  );

  const onMemberEmailLogin = useCallback(
    ({ email }) => {
      return AuthApi.checkMember(email)
        .then((companies) => {
          if (companies.length > 1) {
            setCompanies(companies);
            setStep(SignInSteps.CHOOSE_COMPANY);
          } else {
            setSelectedCompany(companies[0].id);
            setStep(SignInSteps.PASSWORD);
          }
          setCred(email);
          setType(SignInType.EMAIL);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showError(error);
        });
    },
    [AuthApi, showError],
  );

  const onAdminEmailLogin = useCallback(
    (values) => {
      return AuthApi.loginAdmin(values)
        .then((res) => {
          setToken(res.sessionToken);
          setUser({ isAdministrator: true } as any);
          if (
            res.companyStatus === "extra_days_expired" ||
            res.companyStatus === "extra_days_notstarted"
          ) {
            router.push(AppRoutes.AuthExpired);
            setLoading(false);
          } else {
            setUser({ ...res, isAdministrator: true });
            return SettingsApi.getCompany({
              headers: [["vsbl-user-session-token", res.sessionToken]],
            }).then((companyRes) => {
              setSettings(toCamelCase(companyRes));
              if (query.redirect) {
                router.push(query.redirect);
              } else {
                router.push(AppRoutes.AdminPromotions);
              }
              setLoading(false);
            });
          }
        })
        .catch((error) => {
          const isNotVerified = error.data[0].userMsg.indexOf("has not been verified");
          setLoading(false);
          showError(error);
          if (isNotVerified >= 0) {
            router.push(AppRoutes.AuthResend);
          }
        });
    },
    [setSettings, setToken, setUser, AuthApi, SettingsApi, router, query, showError],
  );

  const handleSubmitEmail = useCallback(
    (values) => {
      setLoading(true);
      const { isAdministrator } = values;
      if (isAdministrator) {
        return onAdminEmailLogin(values);
      } else {
        return onMemberEmailLogin(values);
      }
    },
    [onMemberEmailLogin, onAdminEmailLogin],
  );

  const handleChooseCompany = useCallback((values) => {
    setSelectedCompany(values.company);
    setStep(SignInSteps.PASSWORD);
  }, []);

  const handleGetCompanySetting = useCallback(
    (values) => {
      const { sessionToken, ...user } = values;
      SettingsApi.getCompany({
        headers: [["vsbl-member-session-token", sessionToken]],
      }).then((response) => {
        setSettings(toCamelCase(response));
        setUser({ ...user, isAdministrator: false });
        setToken(sessionToken);
        setLoading(false);
        if (query.redirect) {
          router.push(query.redirect);
        } else {
          router.push(AppRoutes.TeamPortalPromotions);
        }
      });
    },
    [router, setToken, setUser, query.redirect, SettingsApi, setSettings],
  );

  const handlePasswordEnter = useCallback(
    (values) => {
      setLoading(true);
      const data = {
        password: values.password,
        clientId: selectedCompany,
      };
      if (type === SignInType.PHONE) {
        const body = { ...data, phone: cred };
        AuthApi.authorizeMemberPhone(body)
          .then((res) => handleGetCompanySetting(res))
          .catch((error) => {
            const isNotVerified = error.data[0].userMsg.indexOf("User is not found as a team");
            setLoading(false);
            showError(error);
            if (isNotVerified >= 0) {
              router.push(AppRoutes.AuthResend);
            }
            handleClear();
          });
      }
      if (type === SignInType.EMAIL) {
        const body = { ...data, email: cred };
        AuthApi.authorizeMember(body)
          .then((res) => handleGetCompanySetting(res))
          .catch((error) => {
            const isNotVerified = error.data[0].userMsg.indexOf("User is not found as a team");
            setLoading(false);
            showError(error);
            if (isNotVerified >= 0) {
              router.push(AppRoutes.AuthResend);
            }
            handleClear();
          });
      }
    },
    [router, AuthApi, cred, type, handleGetCompanySetting, handleClear, showError, selectedCompany],
  );

  return (
    <PublicLayout title={<SignInTitle />} HTMLHeadTitle="Sign In" footer={<SignInFooter />}>
      {loading && <Loader className="mb-2" />}

      {step === SignInSteps.SIGN && (
        <SignInForm onSubmit={handleSubmitEmail} submitHandlerPhone={submitHandlerPhone} />
      )}

      {step === SignInSteps.CHOOSE_COMPANY && (
        <MemberCompaniesForm companies={companies} onSubmit={handleChooseCompany} />
      )}

      {step === SignInSteps.PASSWORD && (
        <MemberPasswordForm onSubmit={handlePasswordEnter} loading={loading} />
      )}
    </PublicLayout>
  );
}
