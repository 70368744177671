import "./assets/slider.scss";

import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Slider as AntdSlider } from "antd";

Slider.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  showValue: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

export function Slider({ label, showValue = true, wrapperClassName, ...sliderProps }) {
  return (
    <div className={cx("slider", wrapperClassName)}>
      {Boolean(label) && <div className="panel-controls-item-label">{label}</div>}

      <AntdSlider {...sliderProps} />

      {showValue && <div className="panel-controls-item-value">{sliderProps.value}</div>}
    </div>
  );
}
