import qs from "qs";
import cx from "classnames";
import React, { ReactNode, useMemo, useState } from "react";
import "./assets/promotion-share-complete.scss";

import { SocialButton } from "../ui/SocialButton";
import { BASE_HOST } from "../../constants/ApiConstants";
import { createWindow } from "../../helpers/SystemUtils";
import { PromotionSocial } from "../../api/promotions/PromotionDTO";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import {
  facebookIsEnabled,
  linkedinIsEnabled,
  pinterestIsEnabled,
  twitterIsEnabled,
} from "../../helpers/PromotionsHelpers";
import { PromotionPostLinkedInDialog } from "../promotion/PromotionPostLinkedInDialog";
import { propOr } from "ramda";
import { isEmpty } from "lodash";

export enum TraceSocial {
  Twitter = "TWITTER_SHARE",
  LinkedIn = "LINKEDIN_SHARE",
  Facebook = "FACEBOOK_SHARE",
  Pinterest = "PINTEREST_SHARE",
}

interface Props {
  readonly image?: string;
  readonly submitId?: string;
  readonly className?: string;
  readonly socialTracking?: any;
  readonly promotionSocial?: PromotionSocial;
  readonly onTrace: (social: TraceSocial, orderId) => void;
  readonly children: ReactNode;
}

export function PromotionShareComplete({
  image,
  onTrace,
  submitId,
  className,
  socialTracking,
  promotionSocial,
  children,
}: Props) {
  const { isMobile } = useMediaQuery();

  const [showLinkedInPost, setShowLinkedInPost] = useState(false);

  const instagramEnabled = useMemo(() => false, []);
  const twitterEnabled = useMemo(
    () => Boolean(twitterIsEnabled(promotionSocial) && socialTracking?.twitterShare),
    [promotionSocial, socialTracking],
  );
  const linkedinEnabled = useMemo(
    () => Boolean(linkedinIsEnabled(promotionSocial) && socialTracking?.linkedinShare),
    [promotionSocial, socialTracking],
  );
  const facebookEnabled = useMemo(
    () => Boolean(facebookIsEnabled(promotionSocial) && socialTracking?.facebookShare),
    [promotionSocial, socialTracking],
  );
  const pinterestEnabled = useMemo(
    () => Boolean(pinterestIsEnabled(promotionSocial) && socialTracking?.pinterestShare),
    [promotionSocial, socialTracking],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const linkedInLink = useMemo(() => {
    if (linkedinEnabled) {
      const url = socialTracking?.linkedinShare;

      return `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
    }
  }, [linkedinEnabled, socialTracking]);

  const facebookLink = useMemo(() => {
    if (facebookEnabled) {
      const url = socialTracking?.facebookShare;

      return `https://www.facebook.com/sharer.php?u=${url}&display=popup`;
    }
  }, [facebookEnabled, socialTracking]);

  const pinterestLink = useMemo(() => {
    if (pinterestEnabled) {
      const url = `${BASE_HOST}/share/pinterest/${submitId}`;

      const query = qs.stringify({
        url,
        media: image,
        description: promotionSocial?.pinterestDescription,
      });

      return `http://pinterest.com/pin/create/button/?${query}`;
    }
  }, [pinterestEnabled, promotionSocial, image, submitId]);

  const twitterLink = useMemo(() => {
    if (twitterEnabled) {
      const url = socialTracking?.twitterShare;

      if (url) {
        const query = qs.stringify({
          url,
          text: promotionSocial?.twitterHeadline,
        });

        return `https://twitter.com/intent/tweet?${query}`;
      }
    }
  }, [twitterEnabled, promotionSocial, socialTracking]);

  return (
    <div className={cx("d-flex align-items-center flex-column", className)}>
      {children}

      <PromotionPostLinkedInDialog
        show={showLinkedInPost}
        submitId={submitId}
        targetUrl={propOr("", "targetUrl", promotionSocial)}
        onCancel={() => setShowLinkedInPost(false)}
        onSubmit={(redirectUrl) => {
          onTrace(TraceSocial.LinkedIn, submitId);

          if (!isEmpty(redirectUrl)) {
            window.location.href = redirectUrl;
          }
        }}
      />

      <div className="my-3 share-socials">
        {linkedinEnabled && (
          <SocialButton
            social="linkedIn"
            onClick={() => {
              setShowLinkedInPost(true);
            }}
            size={isMobile ? 36 : 96}
            className="social-button linkedin"
          />
        )}
        {twitterEnabled && (
          <SocialButton
            social="twitter"
            onClick={() => {
              createWindow(twitterLink, { width: 750 });

              onTrace(TraceSocial.Twitter, submitId);
            }}
            size={isMobile ? 36 : 96}
            className="social-button twitter"
          />
        )}
        {facebookEnabled && (
          <SocialButton
            social="facebook"
            size={isMobile ? 36 : 96}
            onClick={() => {
              createWindow(facebookLink);

              onTrace(TraceSocial.Facebook, submitId);
            }}
            className="social-button facebook"
          />
        )}
        {pinterestEnabled && (
          <SocialButton
            social="pinterest"
            onClick={() => {
              createWindow(pinterestLink, { width: 800 });

              onTrace(TraceSocial.Pinterest, submitId);
            }}
            size={isMobile ? 36 : 96}
            className="social-button pinterest"
          />
        )}
        {instagramEnabled && <SocialButton social="instagram" size={isMobile ? 36 : 96} />}
      </div>
    </div>
  );
}
