import React from "react";
import { Input } from "antd";

const { TextArea } = Input;

interface Props {
  readonly rows: number;
  readonly value: string;
  readonly containerClass?: string;
  readonly showError?: boolean;
}

export function TextareaInput(props: Props) {
  const { value, containerClass = "", rows = 2, showError, ...attr } = props;
  return (
    <div
      className={`textarea-container field-input flex-shrink-1 flex-grow-1 fs-3 px-0 text-dark font-weight-light ${containerClass}`}
    >
      <TextArea {...attr} className={showError ? "error" : ""} rows={rows} value={value} />
    </div>
  );
}
