import React, { useEffect } from "react";

import { useFonts } from "../../context/auth/hooks";

interface Props {
  readonly currentFont?: string;
}

export function FontsWrapper({ currentFont }: Props) {
  const { fonts } = useFonts();

  useEffect(() => {
    const templateElement = document.querySelector("#template-preview-id");

    if (templateElement) {
      templateElement.innerHTML = "";

      fonts.forEach((x) => {
        const span = document.createElement("span");

        span.innerText = x.name;

        span.setAttribute("style", `font-family: '${x.name}'`);

        if (span) {
          templateElement.appendChild(span);
        }
      });
    }
  }, [fonts, currentFont]);

  return (
    <div
      id="template-preview-id"
      className="position-absolute d-flex flex-wrap"
      style={{ opacity: 0, pointerEvents: "none" }}
    />
  );
}
