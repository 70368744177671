import cx from "classnames";
import React, { CSSProperties, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { useFormikContext } from "formik";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";

import { TemplateLogoDnd } from "./TemplateLogoDnd";
import { TemplateDndTarget } from "./TemplateDndTarget";
import { detectMobile } from "../../hooks/useMediaQuery";
import { TemplateDndPreview } from "./TemplateDndPreview";
import { TemplateHeadlineDnd } from "./TemplateHeadlineDnd";
import { TemplateDndProvider } from "./TemplateDndContext";
import { isBoolean } from "lodash";

export enum TemplateDndType {
  Logo = "logo",
  Headline = "headline",
}

interface Props {
  readonly canDrag?: boolean;
  readonly className?: string;
  readonly templateId?: string;
  readonly style?: CSSProperties;
  readonly loading?: boolean;
  readonly background?: any;
  readonly edit?: boolean;
  readonly width?: number;
  readonly height?: number;
}

export function TemplateDnd({
  style,
  canDrag,
  className,
  templateId = "TemplatePreviewDndTarget",
  loading,
  background = { blur: 0, brightness: 100, saturation: 100, vibrance: 100 },
  edit = true,
  width,
  height,
}: Props) {
  const formik = useFormikContext<any>();

  const isMobile = useMemo(() => detectMobile(), []);

  const backend = useMemo(() => {
    if (isMobile) {
      return TouchBackend;
    } else return HTML5Backend;
  }, [isMobile]);

  const showLogo = useMemo(() => {
    if (isBoolean(formik.values.zones?.logoZone.hideLogo)) {
      return !formik.values.zones.logoZone.hideLogo;
    }

    return true;
  }, [formik.values.zones]);

  return (
    <div className={cx("position-relative", className)} style={style}>
      <TemplateDndProvider id={templateId}>
        <DndProvider backend={backend}>
          <TemplateDndTarget
            id={templateId}
            width={width || 600}
            height={height || 315}
            image={formik.values.zones?.backgroundZone.imagePreview}
            background={
              edit
                ? background
                : {
                    blur: formik.values.zones.backgroundZone.options.blur,
                    brightness: formik.values.zones.backgroundZone.options.brightness + 100,
                    saturation: formik.values.zones.backgroundZone.options.saturation + 100,
                    vibrance: formik.values.zones.backgroundZone.options.vibrance + 100,
                  }
            }
          >
            <TemplateHeadlineDnd
              canDrag={canDrag}
              headlineZone={formik.values.zones?.headlineZone}
              loading={loading}
              width={width || 600}
            />

            {showLogo && (
              <TemplateLogoDnd
                canDrag={canDrag}
                logoZone={formik.values.zones?.logoZone}
                hideLogo={Boolean(formik.values.hideLogo)}
                loading={loading}
                width={width || 600}
              />
            )}
          </TemplateDndTarget>

          <TemplateDndPreview />
        </DndProvider>
      </TemplateDndProvider>
    </div>
  );
}
