import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import "./assets/settings-overview.scss";

import { SettingsCompanyLogo } from "./SettingsCompanyLogo";
import { SettingsPaymentMethod } from "../settings/SettingsPaymentMethod";

import { SettingsCompanyPlan } from "../settings-company/SettingsCompanyPlan";
import { formatSettingsCompanyInitialValues } from "../../helpers/SettingsHelpers";
import { SettingsTitle } from "../settings/SettingsTitle";
import { Button } from "antd";
import { PhoneField } from "../form/PhoneField";
import { SelectField } from "../form/SelectField";
import { TimeZones } from "../../constants/timezone";
import { InputField } from "../form/InputField";

SettingsOverview.propTypes = {
  card: PropTypes.object,
  onSubmit: PropTypes.func,
  company: PropTypes.object,
  subscription: PropTypes.object,
  onViewPlansClick: PropTypes.func,
  onViewHistoryClick: PropTypes.func,
  onViewBillingClick: PropTypes.func,
};

export function SettingsOverview({
  card,
  company,
  onSubmit,
  subscription,
  onViewBillingClick,
  onViewHistoryClick,
}) {
  const initialValues = useMemo(() => formatSettingsCompanyInitialValues(company), [company]);

  return (
    <Formik onSubmit={onSubmit} enableReinitialize={true} initialValues={initialValues}>
      {({ handleSubmit, loading }) => (
        <form className="d-flex flex-column flex-lg-row mx-6 mt-4" onSubmit={handleSubmit}>
          <div className="d-flex flex-column flex-1 mr-lg-2">
            <div className="d-flex flex-column flex-sm-row">
              <SettingsCompanyPlan
                subscription={subscription}
                onViewHistoryClick={onViewHistoryClick}
              />
              <SettingsPaymentMethod
                subscription={subscription}
                card={card}
                onViewBillingClick={onViewBillingClick}
              />
            </div>

            <div className="settings-company-form d-flex flex-column mb-6 mb-lg-0">
              <SettingsTitle className="mb-3">Account Settings:</SettingsTitle>
              <div className="mb-4 input-field d-flex flex-column">
                <InputField
                  name="name"
                  label="COMPANY NAME"
                  transparent={false}
                  className="flex-1"
                />
              </div>
              <div className="mb-4 input-field d-flex flex-column">
                <InputField
                  name="website"
                  label="COMPANY URL"
                  transparent={false}
                  className="flex-1"
                />
              </div>
              <div className="mb-4 input-field d-flex flex-column">
                <PhoneField
                  label="COMPANY PHONE NUMBER"
                  name="phone"
                  placeholder="Enter phone"
                  containerClass="ant-input field-input flex-shrink-1 flex-grow-1 fs-3 px-0 text-dark font-weight-light bg-dark-white px-2"
                />
              </div>
              <div className="mb-4 input-field d-flex flex-column">
                <SelectField
                  className="mb-4 mt-1"
                  inputClassName="timezone-field"
                  name="timeZone"
                  label="COMPANY TIME ZONE"
                  placeholder="Choose Time Zone"
                  data={TimeZones}
                  style={{ height: "41px" }}
                />
              </div>
              <div>
                <Button loading={loading} type="primary" htmlType="submit">
                  Save changes
                </Button>
              </div>
            </div>
          </div>

          <SettingsCompanyLogo />
        </form>
      )}
    </Formik>
  );
}
