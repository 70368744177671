import "./assets/template-dnd-preview.scss";

import React, { useMemo } from "react";
import { useDragLayer } from "react-dnd";
import { useFormikContext } from "formik";

import { TemplateLogo } from "./TemplateLogo";
import { TemplateDndType } from "./TemplateDnd";
import { TemplateHeadline } from "./TemplateHeadline";
import { useTemplateDnd } from "./TemplateDndContext";

export function TemplateDndPreview() {
  const dndContext = useTemplateDnd();
  const formik = useFormikContext<any>();

  const { item, itemType, isDragging, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
    initialOffset: monitor.getInitialSourceClientOffset(),
  }));

  const styles = useMemo(() => {
    if (!initialOffset || !currentOffset || !item) {
      return {
        display: "none",
      };
    }

    const translateTop = Math.max(dndContext.rootSize.y, currentOffset.y);
    const translateLeft = Math.max(dndContext.rootSize.x, currentOffset.x);

    const translateRight = dndContext.rootSize.x + dndContext.rootSize.width - item.width;
    const translateBottom = dndContext.rootSize.y + dndContext.rootSize.height - item.height;

    const translateX = Math.min(translateLeft, translateRight);
    const translateY = Math.min(translateTop, translateBottom);

    const transform = `translate(${translateX >= 0 ? translateX : 0}px, ${
      translateY >= 0 ? translateY : 0
    }px)`;

    return {
      transform,
      WebkitTransform: transform,
    };
  }, [currentOffset, dndContext.rootSize, initialOffset, item]);

  if (!isDragging) {
    return null;
  }

  return (
    <div className="h-100 position-fixed template-dnd-preview">
      <div style={styles}>
        {itemType == TemplateDndType.Logo && (
          <TemplateLogo
            canDrag={true}
            logoZone={formik.values.zones.logoZone}
            hideLogo={Boolean(formik.values.hideLogo)}
          />
        )}

        {itemType == TemplateDndType.Headline && (
          <TemplateHeadline canDrag={true} headlineZone={formik.values.zones.headlineZone} />
        )}
      </div>
    </div>
  );
}
