import * as yup from "yup";
import { Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import valid from "card-validator";

import { useQuery } from "../../hooks/useQuery";
import { useAuthContext } from "../../api/auth/AuthContext";
import { SignUpForm } from "../../components/public/SignUpForm";
import { SignUpTitle } from "../../components/public/SignUpTitle";
import { PublicLayout } from "../../core/layout/public/PublicLayout";
import useRouter from "../../hooks/useRouter";
import { useNotification } from "../../hooks/useNotification";
import { PublicAlert } from "../../components/public/PublicAlert";
import { Button } from "antd";
import { getErrorMessage } from "../../utils/utils";
import { CardDetailsForm } from "../../components/card-details/CardDetailsForm";
import { useResource } from "../../hooks/useResource";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useChargebeeContext } from "../../api/chargebee/ChargebeeContext";
import { tokenizeCard } from "../../utils/BraintreeApi";
import momentTimezone from "moment-timezone";

enum SignUpSteps {
  SIGN = "sign",
  CARD = "card",
  RESEND = "resend",
}

const signUpSerializer = (data, planId) => {
  return {
    "company-name": data.companyName,
    "company-website": data.companyUrl,
    customer: {
      billing_address: {
        email: data.email,
        last_name: data.lastName,
        company: data.companyName,
        first_name: data.firstName,
      },
      customer: {
        email: data.email,
        last_name: data.lastName,
        company: data.companyName,
        first_name: data.firstName,
      },
    },
    plan_id: planId,
    email: data.email,
    password: data.password,
    "last-name": data.lastName,
    "first-name": data.firstName,
    "time-zone": data.timeZone,
  };
};

export const validationSchema = yup.object().shape({
  email: yup.string().email("Please provide a valid email address").required("Email is required"),
  firstName: yup.string().min(3).required("First Name is required"),
  lastName: yup.string().min(3).required("Last Name is required"),
  companyName: yup.string().min(3).required("Company name is required"),
  companyUrl: yup.string().min(3).required("Company URL is required"),
  password: yup.string().min(8).required("Password is required"),
  confirmationPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirmation password is required"),
  agreement: yup
    .boolean()
    .test("agreement", "You have to agree with our Terms and Conditions!", (value) =>
      Boolean(value),
    )
    .required("You have to agree with our Terms and Conditions!"),
  timeZone: yup.string().min(8).required("Time zone is required"),
});

const initialValues = {
  email: "",
  password: "",
  lastName: "",
  firstName: "",
  companyUrl: "",
  companyName: "",
  agreement: false,
  confirmationPassword: "",
  timeZone: momentTimezone.tz.guess(),
};

const validationSchemaCard = yup.object().shape({
  cardNumber: yup
    .string()
    .test("cardNumber", "Credit Card number is invalid", (number) => valid.number(number).isValid)
    .required("Card Number is required"),
  expirationMonth: yup
    .string()
    .test(
      "expirationMonth",
      "Expiration Month is invalid",
      (month) => valid.expirationMonth(String(Number(month) + 1)).isValid,
    )
    .required("Expiration Month is required"),
  expirationYear: yup
    .string()
    .test(
      "expirationYear",
      "ExpirationYear is invalid",
      (year) => valid.expirationYear(year).isValid,
    )
    .required("Expiration Year is required"),
  cvv: yup
    .string()
    .test("cvv", "CVV is invalid", (cvv) => valid.cvv(cvv).isValid)
    .required("CVV is required"),
  holderName: yup.string().required("Card Holder Name is required"),
  country: yup.string().required("Country is required"),
  state: yup
    .string()
    .when("country", (field, schema) =>
      field === "US" ? schema.required("State is required") : schema,
    ),
  postalCode: yup
    .string()
    .test(
      "postalCode",
      "Postal Code is invalid",
      (postalCode) => valid.postalCode(postalCode).isValid,
    )
    .required("Zip Code is required"),
  agreement: yup
    .boolean()
    .test("agreement", "You have to agree with our Terms and Conditions!", (value) =>
      Boolean(value),
    )
    .required("You have to agree with our Terms and Conditions!"),
});

const initialValuesCard = {
  cardNumber: "",
  expirationMonth: "",
  expirationYear: "",
  cvv: "",
  holderName: "",
  country: "US",
  state: "",
  postalCode: "",
  agreement: false,
};

interface Query {
  readonly planId: string;
  readonly skipPayment: string;
}

export function SignUpContainer() {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { showError } = useNotification();
  const { planId, skipPayment } = useQuery<Query>();
  const isSkipPayment = skipPayment === "true";
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(SignUpSteps.SIGN);
  const [authData, setAuthData] = useState({} as any);
  const [status, setStatus] = useState({
    error: null,
    loading: false,
    isSuccess: false,
  });

  const { AuthApi } = useAuthContext();
  const { ChargebeeApi } = useChargebeeContext();

  const stateListResponse = useResource(() => AuthApi.getStateList(), []);
  const plansList = useResource(() => ChargebeeApi.getPlanList(), []);

  const planByPlanId = useMemo(() => {
    const plans = plansList?.data?.list || [];
    return plans.find((plan) => plan.plan.id === planId);
  }, [plansList, planId]);

  useResourceHandler(stateListResponse);

  const stateList = useMemo(
    () =>
      (stateListResponse.data || []).map(({ value, code }) => ({
        text: value,
        value: code,
      })),
    [stateListResponse.data],
  );

  useEffect(() => {
    if (!planId) {
      router.push("https://www.vsbl.biz/vsbl-subscription-options/");
    }
  }, [router, planId]);

  const onSubmitWithCard = useCallback(
    (data) => {
      setLoading(true);
      const {
        cardNumber,
        expirationMonth,
        expirationYear,
        cvv,
        holderName,
        country,
        state,
        postalCode,
      } = data;
      ChargebeeApi.getBraintreeToken().then(({ token }) => {
        tokenizeCard(token, {
          cvv,
          postalCode,
          expirationYear,
          expirationMonth: String(Number(expirationMonth) + 1),
          number: cardNumber,
          cardholderName: holderName,
          billingAddress: { postalCode },
        }).then((cardResponse) => {
          const { email, password, plan_id } = authData;
          const authBody = {
            "company-name": authData["company-name"],
            "company-website": authData["company-website"],
            customer: {
              braintree_client_token: cardResponse,
              customer: {
                billing_address: {
                  company: authData["company-name"],
                  country,
                  email,
                  first_name: authData["first-name"],
                  last_name: authData["last-name"],
                  state,
                  zip: postalCode,
                },
                company: authData["company-name"],
                cvv,
                email,
                first_name: authData["first-name"],
                last_name: authData["last-name"],
              },
            },
            email,
            "first-name": authData["first-name"],
            "last-name": authData["last-name"],
            password,
            plan_id,
            "time-zone": authData["time-zone"],
          };
          AuthApi.signUp(authBody)
            .then(() => {
              setLoading(false);
              setEmail(data.email);
              setStep(SignUpSteps.RESEND);
            })
            .catch((error) => {
              showError(error);
              setLoading(false);
            });
        });
      });
    },
    [showError, AuthApi, ChargebeeApi, authData],
  );

  const onSubmit = useCallback(
    (data) => {
      setLoading(true);
      AuthApi.checkUsername(data.email)
        .then(() => {
          const signUpData = signUpSerializer(data, planId);
          if (isSkipPayment) {
            AuthApi.signUp(signUpData)
              .then(() => {
                setLoading(false);
                setEmail(data.email);
                setStep(SignUpSteps.RESEND);
              })
              .catch((error) => {
                showError(error);
                setLoading(false);
              });
          } else {
            setAuthData(signUpData);
            setStep(SignUpSteps.CARD);
            setLoading(false);
          }
        })
        .catch((error) => {
          showError(error);
          setLoading(false);
        });
    },
    [showError, AuthApi, planId, isSkipPayment],
  );

  const resendClickHandler = useCallback(async () => {
    setStatus({ error: null, isSuccess: false, loading: true });

    try {
      await AuthApi.resendActivation(email);
      setStatus((status) => ({ ...status, isSuccess: true, loading: false }));
    } catch (e) {
      setStatus((status) => ({
        ...status,
        error: getErrorMessage(e),
        loading: false,
      }));
    }
  }, [AuthApi, email]);

  const stepTitle = useMemo(() => {
    if (isSkipPayment) {
      if (step === SignUpSteps.SIGN) {
        return "Step 1 of 2";
      } else if (step === SignUpSteps.RESEND) {
        return "Step 2 of 2";
      }
    } else {
      if (step === SignUpSteps.SIGN) {
        return "Step 1 of 3";
      } else if (step === SignUpSteps.CARD) {
        return "Step 2 of 3";
      } else if (step === SignUpSteps.RESEND) {
        return "Step 3 of 3";
      }
    }
  }, [isSkipPayment, step]);

  return (
    <PublicLayout
      title={<SignUpTitle description={planByPlanId?.plan?.description} />}
      step={stepTitle}
      HTMLHeadTitle="Sign Up"
    >
      {step === SignUpSteps.SIGN && (
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => <SignUpForm handleSubmit={handleSubmit} loading={loading} />}
        </Formik>
      )}
      {step === SignUpSteps.CARD && (
        <Formik
          onSubmit={onSubmitWithCard}
          initialValues={initialValuesCard}
          validationSchema={validationSchemaCard}
        >
          {({ handleSubmit }) => (
            <CardDetailsForm handleSubmit={handleSubmit} stateList={stateList} loading={loading} />
          )}
        </Formik>
      )}
      {step === SignUpSteps.RESEND && (
        <>
          <PublicAlert error={status.error} isSuccess={status.isSuccess}>
            We have resent your activation link to {email}. If you mistyped your email address,
            please contact us at <a href="mailto:support@vsbl.biz">support@vsbl.biz</a>.
          </PublicAlert>

          <span className="fs-3 mb-5">
            We sent an email to your inbox. Please click the activation link in that email to
            complete the activation of your new account.
          </span>

          <Button onClick={resendClickHandler} type="primary" loading={status.loading} block={true}>
            Resend activation link
          </Button>
        </>
      )}
    </PublicLayout>
  );
}
