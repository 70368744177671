import React, { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { AppRoutes } from "../constants/Routes";
import { useApiBase } from "../api/ApiContext";
import { NotFoundContainer } from "./NotFoundContainer";
import { SubscriptionContainer } from "./admin/SubscriptionContainer";
import { replaceParams } from "src/helpers/UrlUtils";
import { useUser } from "../context/auth/hooks";
import { SignInContainer } from "./auth/SignInContainer";
import { SignUpContainer } from "./auth/SignUpContainer";
import { ResetPasswordContainer } from "./auth/ResetPasswordContainer";
import { ForgotPasswordMemberContainer } from "./auth/ForgotPasswordMemberContainer";
import { ResendInvitationContainer } from "./auth/ResendInvitationContainer";
import { ForgotPasswordAdminContainer } from "./auth/ForgotPasswordAdminContainer";
import { VerificationContainer } from "./auth/VerificationContainer";
import { AcceptTeamVerificationContainer } from "./auth/AcceptTeamVerificationContainer";
import { TeamMembers } from "../modules/dashboard/team/TeamMembers";
import { RemindersEditContainer } from "./admin/RemindersEditContainer";
import { RemindersListContainer } from "./admin/RemindersListContainer";
import { PromotionsItemContainer } from "./admin/PromotionsItemContainer";
import { PromotionsListContainer } from "./admin/PromotionsListContainer";
import { AccountContainer } from "./admin/AccountContainer";
import { SettingsContainer } from "./admin/SettingsContainer";
import { PromotionChooseContainer } from "./team/PromotionChooseContainer";
import { PromotionCompleteContainer } from "./team/PromotionCompleteContainer";
import { PromotionListContainer } from "./team/PromotionListContainer";
import { TeamAccountContainer } from "./team/TeamAccountContainer";

interface Props {
  readonly redirect: string | undefined;
}

export function RootContainer({ redirect }: Props) {
  const { clientId } = useUser();
  const { administrator } = useApiBase();

  const authRedirect = useMemo(() => {
    if (redirect) {
      return `${replaceParams(AppRoutes.AuthSignIn, { id: "emails" })}?redirect=${redirect}`;
    }

    return replaceParams(AppRoutes.AuthSignIn, { id: "emails" });
  }, [redirect]);

  return (
    <Routes>
      <Route path={AppRoutes.AuthExpired} element={<SubscriptionContainer />} />
      {!clientId && (
        <>
          <Route path={AppRoutes.AuthSignIn} element={<SignInContainer />} />
          <Route path={AppRoutes.AuthSignUpInfo} element={<SignUpContainer />} />
          <Route path={AppRoutes.AuthResetPassword} element={<ResetPasswordContainer />} />
          <Route
            path={AppRoutes.AuthMemberForgotPassword}
            element={<ForgotPasswordMemberContainer />}
          />
          <Route
            path={AppRoutes.AuthAdminForgotPassword}
            element={<ForgotPasswordAdminContainer />}
          />
          <Route path={AppRoutes.AuthResend} element={<ResendInvitationContainer />} />
          <Route path={AppRoutes.AuthEmailVerification} element={<VerificationContainer />} />
          <Route path={AppRoutes.AuthTeamResetPassword} element={<ResetPasswordContainer />} />
          <Route
            path={AppRoutes.AuthTeamEmailVerification}
            element={<AcceptTeamVerificationContainer />}
          />
          <Route path="*" element={<Navigate replace={true} to={authRedirect} />} />
        </>
      )}
      {clientId && (
        <>
          {administrator && (
            <>
              <Route path={AppRoutes.AdminUsers} element={<TeamMembers />} />
              <Route path={AppRoutes.AdminRemindersItem} element={<RemindersEditContainer />} />
              <Route path={AppRoutes.AdminReminders} element={<RemindersListContainer />} />
              <Route path={AppRoutes.AdminPromotionsItem} element={<PromotionsItemContainer />} />
              <Route path={AppRoutes.AdminPromotions} element={<PromotionsListContainer />} />
              <Route path={AppRoutes.AdminProfile} element={<AccountContainer />} />
              <Route path={AppRoutes.AdminSettings} element={<SettingsContainer />} />
              <Route
                path="*"
                element={<Navigate replace={true} to={AppRoutes.AdminPromotions} />}
              />
            </>
          )}
          {!administrator && (
            <Route
              path="*"
              element={<Navigate replace={true} to={AppRoutes.TeamPortalPromotions} />}
            />
          )}
          <Route path={AppRoutes.TeamPortalAccount} element={<TeamAccountContainer />} />
          <Route path={AppRoutes.TeamPortalChoose} element={<PromotionChooseContainer />} />
          <Route path={AppRoutes.TeamPortalShare} element={<PromotionCompleteContainer />} />
          <Route path={AppRoutes.TeamPortalPromotions} element={<PromotionListContainer />} />
          <Route element={<NotFoundContainer />} />
        </>
      )}
    </Routes>
  );
}
