import React, { useMemo } from "react";
import moment from "moment";

import { RepeatWeekly } from "./repeat/RepeatWeekly";
import { RepeatAnnual } from "./repeat/RepeatAnnual";
import { RepeatMonthly } from "./repeat/RepeatMonthly";
import { VSBLButton } from "../../../../widgets/form";
import { SelectField } from "../../../../components/form/SelectField";
import { DateTimeField } from "../../../../components/form/DateTimeField";
import {
  reminderRepeatConstants,
  ReminderRepeatType,
} from "../../../../constants/reminderConstants";
import { prop } from "ramda";

interface Props {
  readonly values: any;
  readonly setFieldValue: (name: string, value: any) => void;
  readonly reminder: any;
}

export function TeamDashboard({ values, setFieldValue, reminder }: Props) {
  const repeat = prop("repeat", values);

  const isWeekly = useMemo(() => repeat === ReminderRepeatType.WEEKLY, [repeat]);
  const isMonthly = useMemo(() => repeat === ReminderRepeatType.MONTHLY, [repeat]);
  const isAnnually = useMemo(() => repeat === ReminderRepeatType.ANNUALLY, [repeat]);
  const isOneTime = useMemo(() => repeat === ReminderRepeatType.ONE_TIME, [repeat]);

  return (
    <div className="edit-teamdashboard">
      <div className="mb-3">
        <SelectField
          name="repeat"
          label="Repeat"
          defaultValue="ONE_TIME"
          placeholder="Choose"
          style={{ width: "100px" }}
          data={reminderRepeatConstants}
          className="mb-3 mb-lg-0 mr-lg-3"
        />
      </div>
      <div className="d-flex">
        <DateTimeField
          showTime={false}
          name="startdate"
          label="Start date:"
          className="mr-3"
          dateClassName="date-time-picker"
          disabledDateBeforeNow={true}
          timeClassName="date-time-picker"
        />
        {isWeekly && (
          <div className="d-flex flex-column mb-3 mb-lg-0 mr-lg-3">
            <label
              className="mb-1 font-weight-light font-family-museo"
              style={{ fontSize: "12px", color: "#4a4a4a" }}
            >
              Repeat:
            </label>

            <RepeatWeekly values={values} setFieldValue={setFieldValue} reminder={reminder} />
          </div>
        )}

        {isAnnually && (
          <div className="mb-3 mb-lg-0 mr-lg-3">
            <RepeatAnnual />
          </div>
        )}

        {(isAnnually || isMonthly) && (
          <div className="mb-3 mb-lg-0 mr-lg-3">
            <div
              className="mb-1 font-family-museo font-weight-light field-label"
              style={{ fontSize: "10px" }}
            >
              On this Day:
            </div>
            <RepeatMonthly values={values} setFieldValue={setFieldValue} reminder={reminder} />
          </div>
        )}

        {isOneTime && (
          <DateTimeField
            timeLabel="Time"
            showDate={false}
            name="time"
            dateClassName="date-time-picker"
            timeClassName="date-time-picker"
            disabledTimeBeforeNow={moment().isSame(values.startdate, "day")}
          />
        )}
        {!isOneTime && (
          <DateTimeField
            timeLabel="Time"
            showDate={false}
            name="time"
            dateClassName="date-time-picker"
            timeClassName="date-time-picker"
          />
        )}
      </div>
      {!values.enddate && !isOneTime && (
        <VSBLButton
          type="link"
          className="p-0"
          htmlType="button"
          onClick={() => setFieldValue("enddate", moment())}
        >
          + Add End Date
        </VSBLButton>
      )}
      {values.enddate && !isOneTime && (
        <DateTimeField
          name="enddate"
          showTime={false}
          disabledDateBeforeNow={true}
          label="End date:"
          showClearButton={true}
          className="position-relative"
          dateClassName="date-time-picker"
          timeClassName="date-time-picker"
          closeButtonClassName="position-absolute date-picker-close-button"
        />
      )}
    </div>
  );
}
