import "./assets/user-status-pending.scss";

import React from "react";

export function UserStatusPending() {
  return (
    <div className="d-flex align-items-center justify-content-center bg-warning user-status-pending">
      <div className="mr-1 bg-white pending-dot" />
      <div className="mr-1 bg-white pending-dot" />
      <div className="bg-white pending-dot" />
    </div>
  );
}
