import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import { InputField } from "../form/InputField";
import { SelectField } from "../form/SelectField";
import { PaymentCardIcons } from "./PaymentCardIcons";
import { months, years } from "../../widgets/form/PreSetData";
import { countryConstants } from "../../constants/geographicConstants";

CardDetailsForm.propTypes = {
  stateList: PropTypes.array,
};
export function CardDetailsForm({ stateList }) {
  const { values } = useFormikContext();

  return (
    <>
      <InputField
        className="m-2"
        name="cardNumber"
        label="Card Number"
        transparent={false}
        after={<PaymentCardIcons />}
      />

      <div className="d-flex flex-column flex-md-row">
        <SelectField
          data={months}
          transparent={false}
          name="expirationMonth"
          className="m-2 flex-1"
          label="Expiration Month"
        />

        <div className="d-flex flex-column flex-sm-row">
          <SelectField
            data={years}
            transparent={false}
            name="expirationYear"
            className="m-2 flex-1"
            label="Expiration Year"
          />
          <InputField transparent={false} label="CVV" name="cvv" className="m-2 flex-1" />
        </div>
      </div>

      <div className="d-flex flex-column flex-sm-row">
        <InputField
          name="holderName"
          transparent={false}
          className="m-2 flex-1"
          label="Card Holder Name"
        />

        <SelectField
          data={countryConstants}
          name="country"
          label="Country"
          transparent={false}
          className="m-2 flex-1"
        />

        {values.country !== "US" && (
          <InputField
            name="postalCode"
            transparent={false}
            className="m-2 flex-1"
            label="Zip Code / Postal Code"
          />
        )}
      </div>

      <div className="d-flex flex-column flex-sm-row mb-3">
        {values.country === "US" && (
          <SelectField
            name="state"
            data={stateList}
            transparent={false}
            className="m-2 flex-1"
            label="State / Province / Region"
          />
        )}

        {values.country === "US" && (
          <InputField
            name="postalCode"
            transparent={false}
            className="m-2 flex-1"
            label="Zip Code / Postal Code"
          />
        )}
      </div>
    </>
  );
}
