import { useCallback, useEffect } from "react";

export function useOutsideClick({ ref, onClick, active }) {
  const handleClickOutside = useCallback(
    (event) => {
      if (active && ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    },
    [active, ref, onClick],
  );

  useEffect(() => {
    if (active) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [active, handleClickOutside]);
}
