import React from "react";

interface Props {
  readonly size?: number;
  readonly className?: string;
}

export function ArrowRightIcon({ size = 24, className }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" className={className}>
      <path
        fill="currentColor"
        d="M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z"
      />
    </svg>
  );
}
