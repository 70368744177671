import "./assets/departments-table.scss";

import React, { useCallback, useEffect, useState } from "react";
import { ContainerLoader } from "../../../components/ui/ContainerLoader";
import { DepartmentsTableItem } from "./DepartmentsTableItem";
import { Button } from "antd";
import { DepartmentsModalCreate } from "./DepartmentsModalCreate";
import { useDepartmentContext } from "../../../api/departments/DepartmentsContext";
import { useUser } from "../../../context/auth/hooks";

export function DepartmentsTable() {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState({ items: [] } as any);
  const [update, setUpdate] = useState(false);
  const { DepartmentsApi } = useDepartmentContext();
  const [createModal, setCreateModal] = useState(false);
  const { clientId } = useUser();

  useEffect(() => {
    DepartmentsApi.getDepartments().then((x) => {
      setList(x);
      setLoading(false);
    });
  }, [DepartmentsApi, update]);

  const onSubmit = useCallback(
    (value) => {
      setLoading(false);
      DepartmentsApi.createDepartment({
        id: clientId,
        departmentName: value.name,
        description: value.description,
      }).then(() => {
        setCreateModal(false);
        setUpdate((value) => !value);
      });
    },
    [clientId, DepartmentsApi, setUpdate],
  );

  if (loading && list.items.length === 0) return <ContainerLoader show={true} />;

  return (
    <>
      <div className="team-members-header2 px-3 mb-5 pb-5">
        <div className="d-flex align-items-start align-items-md-center flex-md-row flex-column">
          <Button
            className="mb-3 mb-md-0 mr-md-3"
            type="primary"
            onClick={() => setCreateModal(true)}
          >
            Create new group
          </Button>
        </div>
        {createModal && (
          <DepartmentsModalCreate
            visible={createModal}
            hideModal={() => setCreateModal(false)}
            onSubmit={onSubmit}
            loading={loading}
          />
        )}
      </div>
      {!loading && list.items.length === 0 ? (
        <h5 className="text-center">Create your 1st group!</h5>
      ) : (
        <div className="table-responsive">
          <div className="w-100 d-flex flex-column d-md-table departments-table">
            <div className="departments-table-header d-none d-md-table-row">
              <div className="d-table-cell text-uppercase pl-4">name</div>
              <div className="d-table-cell text-uppercase">description</div>
              <div className="d-table-cell text-uppercase">members count</div>
            </div>
            {list.items.map((item) => (
              <DepartmentsTableItem item={item} key={item.id} setUpdate={setUpdate} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
