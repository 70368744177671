import PropTypes from "prop-types";
import { update } from "immupdate";
import React, { useMemo } from "react";

import { SettingsBillingForm } from "./SettingsBillingForm";
import { SettingsPaymentMethod } from "../settings/SettingsPaymentMethod";

SettingsBilling.propTypes = {
  card: PropTypes.object,
  onSubmit: PropTypes.func,
  customer: PropTypes.object,
  stateList: PropTypes.array,
  subscription: PropTypes.object,
  onEditCardClick: PropTypes.func,
};

export function SettingsBilling({
  card,
  stateList,
  onSubmit,
  subscription = {},
  customer = {},
  onEditCardClick,
}) {
  const accountOwnerName = useMemo(() => {
    if (customer.firstName || customer.lastName) {
      return [customer.firstName, customer.lastName].filter(Boolean).join(" ");
    }
  }, [customer.firstName, customer.lastName]);

  const initialValues = useMemo(
    () =>
      update(
        {
          accountOwnerName,
          company: customer.company,
        },
        customer.billingAddress,
      ),
    [accountOwnerName, customer.company, customer.billingAddress],
  );

  return (
    <div className="container d-flex flex-column-reverse flex-md-row mt-4">
      <SettingsBillingForm
        onSubmit={onSubmit}
        stateList={stateList}
        initialValues={initialValues}
      />

      <div className="ml-md-2">
        <SettingsPaymentMethod
          subscription={subscription}
          card={card}
          onEditCardClick={onEditCardClick}
        />
      </div>
    </div>
  );
}
