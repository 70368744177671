import React from "react";

interface Props {
  readonly size?: number;
  readonly className?: string;
}

export function ArrowBottomLeftIcon({ size = 24, className }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" className={className}>
      <path
        fill="currentColor"
        d="M15.5,5.69L18.31,8.5L11.94,14.89H16.89V18.31H5.69V7.11H9.12V12.06L15.5,5.69Z"
      />
    </svg>
  );
}
