import React, { useMemo } from "react";

import { AlertCircleIcon } from "../icons/AlertCircleIcon";

interface Props {
  readonly errors: string[];
}

export function PromotionTabErrors({ errors = [] }: Props) {
  const showErrors = useMemo(() => errors.length > 0, [errors.length]);

  if (!showErrors) {
    return null;
  }

  return (
    <div className="mx-md-4 d-flex flex-column">
      {errors.map((x) => (
        <div key={x} className="alert alert-danger p-2">
          <AlertCircleIcon className="mr-2" />
          {x}
        </div>
      ))}
    </div>
  );
}
