import "./assets/select.scss";

import React from "react";
import cx from "classnames";
import { uid } from "react-uid";
import PropTypes from "prop-types";
import { Select as AntdSelect } from "antd";

Select.propTypes = {
  className: PropTypes.string,
  transparent: PropTypes.bool,
  data: PropTypes.array.isRequired,
};

export function Select({ data = [], className, transparent = true, ...props }) {
  return (
    <AntdSelect
      {...props}
      className={cx(
        "select-ui",
        { "not-transparent-select border-bottom bg-dark-white": !transparent },
        className,
      )}
    >
      {data.map((item) => (
        <AntdSelect.Option key={uid(item)} value={item.value}>
          {item.text}
        </AntdSelect.Option>
      ))}
    </AntdSelect>
  );
}
