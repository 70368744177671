import "./assets/promotion-social-item.scss";

import cx from "classnames";
import React, { useMemo } from "react";

import { TwitterIcon } from "../icons/TwitterIcon";
import { LinkedInIcon } from "../icons/LinkedInIcon";
import { FacebookIcon } from "../icons/FacebookIcon";
import { PinterestIcon } from "../icons/PinterestIcon";

export enum SocialType {
  Twitter = "twitter",
  Facebook = "facebook",
  LinkedIn = "linked-in",
  Pinterest = "pinterest",
}

interface Props {
  readonly count: number;
  readonly className?: string;
  readonly socialType: SocialType;

  readonly twitterEnabled?: boolean;
  readonly linkedInEnabled?: boolean;
  readonly facebookEnabled?: boolean;
  readonly pinterestEnabled?: boolean;
  readonly size?: number;
}

export function PromotionSocialItem(props: Props) {
  const {
    count = 0,
    className,
    socialType,
    twitterEnabled,
    facebookEnabled,
    linkedInEnabled,
    pinterestEnabled,
    size = 16,
  } = props;
  const isTwitter = useMemo(() => socialType === SocialType.Twitter, [socialType]);
  const isFacebook = useMemo(() => socialType === SocialType.Facebook, [socialType]);
  const isLinkedIn = useMemo(() => socialType === SocialType.LinkedIn, [socialType]);
  const isPinterest = useMemo(() => socialType === SocialType.Pinterest, [socialType]);

  const enabled = useMemo(
    () =>
      (isTwitter && twitterEnabled) ||
      (isFacebook && facebookEnabled) ||
      (isLinkedIn && linkedInEnabled) ||
      (isPinterest && pinterestEnabled),
    [
      isTwitter,
      isFacebook,
      isLinkedIn,
      isPinterest,
      twitterEnabled,
      facebookEnabled,
      linkedInEnabled,
      pinterestEnabled,
    ],
  );

  return (
    <div
      className={cx(
        "br-1 d-flex align-items-center px-1 promotion-social-item",
        { "bg-secondary": enabled, "bg-gray-light": !enabled },
        className,
      )}
    >
      {socialType === SocialType.Twitter && <TwitterIcon size={size} className="mr-1" />}
      {socialType === SocialType.Pinterest && <PinterestIcon size={size} className="mr-1" />}
      {socialType === SocialType.Facebook && <FacebookIcon size={size} className="mr-1" />}
      {socialType === SocialType.LinkedIn && <LinkedInIcon size={size} className="mr-1" />}

      <span className="text-white item-title">{count}</span>
    </div>
  );
}
