import "./assets/promotion-messaging-facebook.scss";

import React from "react";

import { SwitchField } from "../form/SwitchField";
import { InputWithCountField } from "../form/InputWithCountField";
import { PromotionMessagingFacebookPost } from "./PromotionMessagingFacebookPost";
import InputUrlField from "../form/InputUrlField";
import { useFormikContext } from "formik";

export function PromotionMessagingFacebook() {
  const { values } = useFormikContext() as any;
  const enabled = values.promotionSocial.facebookEnabled;

  return (
    <div className="messaging-tab-body-layout">
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-1 mr-lg-3 client-promotion-edit-messaging-facebook-controls">
          <SwitchField name="promotionSocial.facebookEnabled" text="Facebook" />

          <InputWithCountField
            type="textarea"
            maxLength={200}
            label="Headline"
            isRequired={true}
            disabled={!enabled}
            withErrors={false}
            className="messaging-form-item"
            name="promotionSocial.facebookHeadline"
            footerTextClassName="messaging-form-item-characters"
            footerContainerClassName="messaging-form-item-footer"
            placeholder="Facebook post headline goes here"
          />
          <InputWithCountField
            type="textarea"
            maxLength={256}
            label="Description"
            disabled={!enabled}
            withErrors={false}
            className="messaging-form-item"
            name="promotionSocial.facebookDescription"
            footerTextClassName="messaging-form-item-characters"
            footerContainerClassName="messaging-form-item-footer"
            placeholder="Facebook post description goes here"
          />

          <InputUrlField active={enabled} values={values} valueName="facebookOverrideUrl" />
        </div>
        <PromotionMessagingFacebookPost />
      </div>
    </div>
  );
}
