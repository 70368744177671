import { BaseApi } from "../BaseApi";
import { toKebabCase } from "../../helpers/CaseUtils";

export class SettingsApi extends BaseApi {
  getCompany(options) {
    return this.get("settings/company", options).then(({ data }) => data);
  }
  getFonts(options) {
    return this.get("settings/fonts", options).then(({ data }) => data);
  }
  updateCompany(body) {
    return this.post("settings/company", { json: toKebabCase(body) }).then(({ data }) => data);
  }
  getExtraDays() {
    return this.patch("settings/extra-days", {}).then(({ data }) => data);
  }
}
