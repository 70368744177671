import "./assets/input-field.scss";

import cx from "classnames";
import { useField } from "formik";
import React, { useMemo } from "react";

import { TextareaInput } from "../ui/TextareaInput";

interface Props {
  readonly style?: any;
  readonly name: string;
  readonly rows?: number;
  readonly label?: string;
  readonly className?: string;
  readonly placeholder?: string;
  readonly labelClassName?: string;
  readonly containerClass?: string;
}

export function TextareaField({
  name,
  label,
  className,
  rows = 2,
  labelClassName,
  ...props
}: Props) {
  const [field, meta] = useField(name);

  const showError = useMemo(() => Boolean(meta.touched && meta.error), [meta]);

  return (
    <>
      <div className={cx(className, "textarea-field d-flex flex-column")}>
        {Boolean(label) && (
          <label
            className={cx(labelClassName, "mb-1 font-family-museo font-weight-light field-label")}
          >
            {label}
          </label>
        )}

        <TextareaInput {...props} {...field} rows={rows} showError={showError} />
        {meta.error && <span className="text-danger mt-1">{meta.error}</span>}
      </div>
    </>
  );
}
