import React, { useEffect, useMemo, useState } from "react";
import { useTemplateDnd } from "./TemplateDndContext";
import { getImagePosition } from "../../helpers/ImageHelpers";
import { propOr } from "ramda";
import { useFormikContext } from "formik";
import { getHeadlineHeight } from "../../helpers/PromotionsHelpers";

export enum AnchorImageType {
  MiddleCenter = "MIDDLE_CENTER",
}

export enum ZoneIndexType {
  Background = 1,
  Overlay = 2,
  Logo = 3,
  Headline = 4,
}

export enum AlignType {
  Left = "left",
  Right = "right",
  Center = "center",
}

export interface HeadlineZoneProps {
  readonly align: AlignType;
  readonly anchorImage: AnchorImageType;
  readonly color: string;
  readonly dynamicTag: boolean;
  readonly foatx: number;
  readonly foaty: number;
  readonly font: string;
  readonly gif: boolean;
  readonly id: number;
  readonly image: string;
  readonly active: boolean;
  readonly imageScaled: boolean;
  readonly modifiedDate: number;
  readonly overFlowShown: boolean;
  readonly size: number;
  readonly text: string;
  readonly textLineSpacing: number;
  readonly textNumberOfLines: number;
  readonly textWidth: number;
  readonly type: string;
  readonly uniqueIdentifier: string;
  readonly useAsDefault: boolean;
  readonly useCompression: boolean;
  readonly zindex: ZoneIndexType;
  readonly hideHeadline: boolean;
}

interface Props {
  readonly canDrag?: boolean;
  readonly onClick?: () => void;
  readonly headlineZone: HeadlineZoneProps;
  readonly loading?: boolean;
  readonly isBig?: boolean;
}

export function TemplateHeadline({ canDrag, onClick, headlineZone, loading, isBig }: Props) {
  const breakWord: "break-word" = "break-word";
  const { aspectRatio } = useTemplateDnd();
  const formik = useFormikContext<any>();
  const fontSize = isBig ? headlineZone.size * 2 : headlineZone.size * aspectRatio;
  const [previousFoatX, setPreviousFoatX] = useState(0);
  const [previousFoatY, setPreviousFoatY] = useState(0);

  useEffect(() => {
    const foatx = propOr(0, "foatx", headlineZone);
    const foaty = propOr(0, "foaty", headlineZone);
    if (foatx >= 10) {
      setPreviousFoatX(foatx);
    }
    if (foaty >= 10) {
      setPreviousFoatY(foaty);
    }
  }, [headlineZone]);

  useEffect(() => {
    const foatx = propOr(0, "foatx", headlineZone);
    const foaty = propOr(0, "foaty", headlineZone);
    if (foatx < 0) {
      formik.setFieldValue("zones.headlineZone.foatx", previousFoatX);
    }
    if (foaty < 0) {
      formik.setFieldValue("zones.headlineZone.foaty", previousFoatY);
    }
  }, [formik, headlineZone, previousFoatX, previousFoatY]);

  const headlineHeight = useMemo(() => getHeadlineHeight(headlineZone), [headlineZone]);

  const rootStyles = useMemo(() => {
    const widthText = isBig ? headlineZone.textWidth * 2 : headlineZone.textWidth;
    const justifyContent = getImagePosition(headlineZone.anchorImage).justify;

    return {
      justifyContent,
      height: isBig ? headlineHeight * 2 : headlineHeight,
      width: `${widthText}px`,
      ...(canDrag
        ? {
            cursor: "all-scroll",
            borderWidth: "1px",
            borderStyle: canDrag && !loading ? "dashed" : "unset",
            borderColor: canDrag && !loading ? "#ffffff33" : "unset",
            boxShadow: canDrag && !loading ? "0 0 2px 0px #888888" : "unset",
            ...(headlineZone.active
              ? {
                  borderColor: canDrag && !loading ? "white" : "unset",
                  boxShadow: canDrag && !loading ? "0 0 4px 0px #888888" : "unset",
                }
              : {}),
          }
        : {}),
    };
  }, [headlineHeight, isBig, canDrag, aspectRatio, headlineZone, loading]);

  const headlineStyles = useMemo(() => {
    const aligns = propOr("L_L", "anchorImage", headlineZone).split("_");
    const leftAlign = aligns[1];
    const left = leftAlign === "LEFT" ? "left" : leftAlign === "CENTER" ? "center" : "right";
    return {
      lineHeight: headlineZone.textLineSpacing,
      marginBottom: 0,
      fontSize: `${fontSize}px`,
      textAlign: left as any,
      fontFamily: headlineZone.font,
      color: `#${headlineZone.color}`,
      wordWrap: breakWord,
    };
  }, [fontSize, headlineZone]);

  return (
    <div
      role="button"
      onDragEnter={onClick}
      style={rootStyles}
      id="TemplateHeadline"
      className="d-flex flex-column"
      onClick={onClick}
    >
      <p style={headlineStyles}>
        {headlineZone?.text ? headlineZone?.text : "Headline content displays here"}
      </p>
    </div>
  );
}
