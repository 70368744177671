import React, { useCallback, useState } from "react";
import { PublicLayout } from "../../core/layout/public/PublicLayout";
import { ForgotMemberForm } from "../../components/auth/ForgotMemberForm";
import { ForgotTitle } from "../../components/auth/ForgotTitle";
import { useAuthContext } from "../../api/auth/AuthContext";
import { useNotification } from "../../hooks/useNotification";

export function ForgotPasswordMemberContainer() {
  const { AuthApi } = useAuthContext();
  const { showSuccessNotification, showError, showErrorNotificationLegacy } = useNotification();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [loading, setLoading] = useState(false);

  const onSubmitEmail = useCallback(
    ({ email }: { email: string }) => {
      if (selectedCompany) {
        setLoading(true);
        return AuthApi.forgotPasswordMember(selectedCompany, email)
          .then(() => {
            setLoading(false);
            showSuccessNotification({
              message: "Reset password instruction was sent to your email",
            });
            setCompanies([]);
          })
          .catch((error) => {
            setLoading(false);
            showError(error);
          });
      } else {
        showErrorNotificationLegacy({ message: "Please Select Company" });
      }
    },
    [selectedCompany, AuthApi, showError, showSuccessNotification, showErrorNotificationLegacy],
  );

  const onSubmitPhone = useCallback(
    ({ phone }: { phone: string }) => {
      if (selectedCompany) {
        setLoading(true);
        return AuthApi.forgotPasswordMobileMember(selectedCompany, phone)
          .then(() => {
            setLoading(false);
            showSuccessNotification({
              message: "Reset password instruction was sent to your mobile",
            });
            setCompanies([]);
          })
          .catch((error) => {
            setLoading(false);
            showError(error);
          });
      } else {
        showErrorNotificationLegacy({ message: "Please Select Company" });
      }
    },
    [AuthApi, selectedCompany, showError, showSuccessNotification, showErrorNotificationLegacy],
  );

  const onNextStepPhone = useCallback(
    ({ phone }: { phone: string }) => {
      setLoading(true);
      AuthApi.checkMemberPhone(phone)
        .then((response) => {
          setCompanies(response);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showError(error);
        });
    },
    [showError, AuthApi],
  );

  const onNextStepEmail = useCallback(
    ({ email }: { email: string }) => {
      setLoading(true);
      AuthApi.checkMember(email)
        .then((response) => {
          setCompanies(response);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showError(error);
        });
    },
    [showError, AuthApi],
  );

  return (
    <PublicLayout title={<ForgotTitle />} HTMLHeadTitle="Sign In">
      <ForgotMemberForm
        onSubmitEmail={onSubmitEmail}
        onNextStepEmail={onNextStepEmail}
        onNextStepPhone={onNextStepPhone}
        onSubmitPhone={onSubmitPhone}
        companies={companies}
        loading={loading}
        setSelectedCompany={setSelectedCompany}
        setCompanies={setCompanies}
      />
    </PublicLayout>
  );
}
