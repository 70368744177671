import { update } from "immupdate";
import { ArgsProps } from "antd/es/notification";

import { AppError } from "../helpers/AppError";
import { createNotification } from "../helpers/NotificationHelpers";

interface NotificationHelpersProps {
  readonly showSuccessNotification: (props: ArgsProps) => void;
  readonly showErrorNotificationLegacy: (props: ArgsProps) => void;
  readonly showError: (error: AppError, options?: ArgsProps) => void;
}

export function useNotification(): NotificationHelpersProps {
  return {
    showError: (error, options = {} as ArgsProps) =>
      createNotification({
        type: "error",
        duration: null,
        ...options,
        message: error.toString(),
      }),
    showErrorNotificationLegacy: (props) => createNotification(update(props, { type: "error" })),
    showSuccessNotification: (props) => createNotification(update(props, { type: "success" })),
  };
}
