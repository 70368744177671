import "./assets/app-menu.scss";

import { isFunction } from "lodash";
import React, { ReactNode, useCallback, useRef, useState } from "react";

import { useOutsideClick } from "../../hooks/useOutsideClick";

interface Props {
  readonly children?: ReactNode;
}

export function AppMenu({ children }: Props) {
  const ref = useRef(null);

  const [show, setShow] = useState(false);

  const closeHandler = useCallback(() => setShow(false), []);

  useOutsideClick({
    ref,
    active: show,
    onClick: () => setShow(false),
  });

  return (
    <div className="d-flex app-menu justify-content-end">
      <div className="flex-1">
        {isFunction(children) && children({ onClose: closeHandler })}
        {!isFunction(children) && children}
      </div>
    </div>
  );
}
