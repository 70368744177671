import "./assets/promotion-choose-button.scss";

import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";

PromotionChooseButton.propTypes = {
  children: PropTypes.node,
};

export function PromotionChooseButton({ children, ...props }) {
  return (
    <Button {...props} type="primary" className="mb-2 promotion-choose-button">
      {children}
    </Button>
  );
}
