import React, { useMemo, useState } from "react";

import { useResource } from "../../../../hooks/useResource";
import { SelectField } from "../../../../components/form/SelectField";
import { useResourceHandler } from "../../../../hooks/useResourceHandler";
import { usePromotionContext } from "../../../../api/promotions/PromotionContext";
import { useUser } from "../../../../context/auth/hooks";

export function SpecificPromotion() {
  const [list, setList] = useState([] as any);
  const { clientId } = useUser();

  const { PromotionApi } = usePromotionContext();

  const promotionsResource = useResource(
    () => PromotionApi.getPromotionList(clientId, { isCompanyUser: false }),
    [clientId],
  );

  useResourceHandler(promotionsResource, {
    onSuccess: (data) => setList(data?.items || []),
  });

  const data = useMemo(() => list.map(({ id, name }) => ({ value: id, text: name })), [list]);

  return (
    <SelectField
      data={data}
      className="mt-3"
      inputClassName=""
      name="promotionId"
      label="Choose promotion"
      style={{ maxWidth: "300px" }}
      placeholder="Show Most Recent Promotion"
    />
  );
}
