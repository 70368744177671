export enum AppRoutes {
  // Auth routes
  Auth = "/auth",
  AuthSignIn = "/auth/sign-in",
  AuthSignUpInfo = "/auth/sign-up-info",
  AuthMemberForgotPassword = "/auth/team/forgot-password",
  AuthAdminForgotPassword = "/auth/forgot-password",
  AuthResend = "/auth/resend",
  AuthResetPassword = "/auth/reset-password/:key",
  AuthEmailVerification = "/auth/email-verification/:key",
  AuthTeamResetPassword = "/auth/team/reset-password/:key",
  AuthTeamEmailVerification = "/auth/accept-team-verification/:key",
  AuthExpired = "/expired",

  // Team routes
  TeamPortal = "/team-portal",
  TeamPortalShare = "/team-portal/share/:id",
  TeamPortalAccount = "/team-portal/account",
  TeamPortalChoose = "/team-portal/choose/:id",
  TeamPortalPromotions = "/team-portal/promotions",

  // Admin routes
  AdminUsers = "/admin/users",
  AdminProfile = "/admin/profile",
  AdminReminders = "/admin/reminders",
  AdminRemindersItem = "/admin/reminders/:id",
  AdminPromotions = "/admin/promotions",
  AdminPromotionsItem = "/admin/promotions/:id",
  AdminSettings = "/admin/settings/:tab",
  AdminImageEditor = "/admin/image-editor",

  // Shared routes
  AdminSubscription = "/subscription",
}
