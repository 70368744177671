import qs from "qs";
import cx from "classnames";
import React, { ReactNode, useMemo } from "react";

import { SocialButton } from "../ui/SocialButton";
import { BASE_HOST } from "../../constants/ApiConstants";
import { createWindow } from "../../helpers/SystemUtils";
import { PromotionSocial } from "../../api/promotions/PromotionDTO";
import { detectAppleMobile, useMediaQuery } from "../../hooks/useMediaQuery";
import {
  facebookIsEnabled,
  linkedinIsEnabled,
  pinterestIsEnabled,
  twitterIsEnabled,
} from "../../helpers/PromotionsHelpers";

export enum TraceSocial {
  Twitter = "TWITTER_SHARE",
  LinkedIn = "LINKEDIN_SHARE",
  Facebook = "FACEBOOK_SHARE",
  Pinterest = "PINTEREST_SHARE",
}

interface Props {
  readonly settings?: any;
  readonly image?: string;
  readonly promotion: any;
  readonly submitId?: string;
  readonly className?: string;
  readonly children: ReactNode;
  readonly socialTracking?: any;
  readonly contentClassName?: string;
  readonly promotionSocial?: PromotionSocial;
  readonly onTrace: (social: TraceSocial, orderId) => void;
}

export function PromotionShareComplete({
  image,
  onTrace,
  submitId,
  children,
  settings,
  className,
  promotion,
  socialTracking,
  promotionSocial,
  contentClassName = "d-flex",
}: Props) {
  const { isMobile } = useMediaQuery();
  const instagramEnabled = useMemo(() => false, []);
  const twitterEnabled = useMemo(
    () => Boolean(twitterIsEnabled(promotionSocial) && socialTracking?.twitterShare),
    [promotionSocial, socialTracking],
  );
  const linkedinEnabled = useMemo(
    () => Boolean(linkedinIsEnabled(promotionSocial) && socialTracking?.linkedinShare),
    [promotionSocial, socialTracking],
  );
  const facebookEnabled = useMemo(
    () => Boolean(facebookIsEnabled(promotionSocial) && socialTracking?.facebookShare),
    [promotionSocial, socialTracking],
  );
  const pinterestEnabled = useMemo(
    () => Boolean(pinterestIsEnabled(promotionSocial) && socialTracking?.pinterestShare),
    [promotionSocial, socialTracking],
  );

  const linkedInLink = useMemo(() => {
    if (linkedinEnabled) {
      const url = socialTracking?.linkedinShare;
      const isAppleMobile = detectAppleMobile();

      if (isAppleMobile) {
        return `linkedin://share?url=${url}`;
      } else {
        return `http://www.linkedin.com/shareArticle?mini=true&url=${url}`;
      }
    }
  }, [linkedinEnabled, socialTracking]);

  const facebookLink = useMemo(() => {
    if (facebookEnabled) {
      const url = socialTracking?.facebookShare;

      return `https://www.facebook.com/sharer.php?u=${url}&display=popup`;
    }
  }, [facebookEnabled, socialTracking]);

  const pinterestLink = useMemo(() => {
    if (pinterestEnabled) {
      const url = `${BASE_HOST}/share/pinterest/${submitId}`;

      const query = qs.stringify({
        url,
        media: image,
        description: promotionSocial?.pinterestDescription,
      });

      return `http://pinterest.com/pin/create/button/?${query}`;
    }
  }, [pinterestEnabled, promotionSocial, image, submitId]);

  const twitterLink = useMemo(() => {
    if (twitterEnabled) {
      const url = socialTracking?.twitterShare;

      if (url) {
        const query = qs.stringify({
          url,
          text: promotionSocial?.twitterHeadline,
        });

        return `https://twitter.com/intent/tweet?${query}`;
      }
    }
  }, [twitterEnabled, promotionSocial, socialTracking]);

  return (
    <div className={cx("d-flex flex-column", className)}>
      <h1 className="text-center" style={{ color: settings?.primaryColor || "#025692" }}>
        {promotion.name}
      </h1>
      <span
        className="text-center font-weight-light"
        style={{ color: settings?.primaryColor || "#025692" }}
      >
        {promotion.description}
      </span>
      <hr className="w-100 my-6" />

      <div className={cx("justify-content-center d-flex flex-column", contentClassName)}>
        {children}
      </div>

      <div className="d-flex justify-content-center my-3">
        {linkedinEnabled && (
          <SocialButton
            social="linkedIn"
            onClick={() => {
              createWindow(linkedInLink);

              onTrace(TraceSocial.LinkedIn, submitId);
            }}
            size={isMobile ? 36 : 96}
            className={cx({
              "mr-3 mr-sm-5":
                instagramEnabled || pinterestEnabled || twitterEnabled || facebookEnabled,
            })}
          />
        )}
        {facebookEnabled && (
          <SocialButton
            social="facebook"
            size={isMobile ? 36 : 96}
            onClick={() => {
              createWindow(facebookLink);

              onTrace(TraceSocial.Facebook, submitId);
            }}
            className={cx({
              "mr-3 mr-sm-5": instagramEnabled || pinterestEnabled || twitterEnabled,
            })}
          />
        )}
        {twitterEnabled && (
          <SocialButton
            social="twitter"
            onClick={() => {
              createWindow(twitterLink, { width: 750 });

              onTrace(TraceSocial.Twitter, submitId);
            }}
            size={isMobile ? 36 : 96}
            className={cx({ "mr-3 mr-sm-5": instagramEnabled || pinterestEnabled })}
          />
        )}
        {pinterestEnabled && (
          <SocialButton
            social="pinterest"
            onClick={() => {
              createWindow(pinterestLink, { width: 800 });

              onTrace(TraceSocial.Pinterest, submitId);
            }}
            size={isMobile ? 36 : 96}
            className={cx({ "mr-3 mr-sm-5": instagramEnabled })}
          />
        )}
        {instagramEnabled && <SocialButton social="instagram" size={isMobile ? 36 : 96} />}
      </div>
    </div>
  );
}
