import React, { ReactNode } from "react";

import cx from "classnames";

interface Props {
  readonly title: string;
  readonly button?: ReactNode;
  readonly className?: string;
}

export function RemindersHeader({ title, button, className }: Props) {
  return (
    <div
      className={cx(
        "d-flex flex-column align-items-center flex-sm-row justify-content-sm-between px-4",
        className,
      )}
    >
      <div className="text-secondary font-family-museo fs-6">{title}</div>
      {button}
    </div>
  );
}
