import { Button, notification } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { propOr } from "ramda";

import { UserStatusSwitchWrapper } from "./UserStatusSwitchWrapper";
import { capitalizeFirstLetter, getErrorMessage } from "../../utils/utils";
import { useUsersContext } from "../../api/users/UsersContext";

SettingsUsersListItem.propTypes = {
  user: PropTypes.object,
  onEditClick: PropTypes.func,
  onChangeStatusComplete: PropTypes.func,
};

export function SettingsUsersListItem({ user, onEditClick, onChangeStatusComplete }) {
  const { UsersApi } = useUsersContext();
  const [resendActivationLoading, setResendActivationLoading] = useState(false);

  const handleResendActivation = useCallback(async () => {
    setResendActivationLoading(true);
    UsersApi.resendInvite(user.email)
      .then(() => {
        setResendActivationLoading(false);
        notification.open({
          type: "success",
          message: "Resend activation",
          description: "Invitation has been resent successfully",
        });
      })
      .catch((e) => {
        setResendActivationLoading(false);
        notification.open({
          type: "error",
          message: "Resend activation error",
          description: getErrorMessage(e),
          duration: null,
        });
      });
  }, [UsersApi, user.email]);

  const firstName = propOr("", "firstName", user);
  const lastName = propOr("", "lastName", user);

  return (
    <div className="d-flex border-bottom border-gray-light px-6 flex-column flex-sm-row pt-5 align-users-sm-center">
      <div className="d-flex flex-1">
        <UserStatusSwitchWrapper user={user} onComplete={onChangeStatusComplete} />

        <div className="d-flex flex-column flex-1">
          <span className="fs-5 font-family-museo text-primary">
            {capitalizeFirstLetter(firstName)} {capitalizeFirstLetter(lastName)}
          </span>

          {!user.verified && <span className="text-danger">Invitation Pending</span>}
        </div>
      </div>

      <div className="d-table-cell p-0 pt-md-4 pb-md-4 pr-md-4 w-auto align-middle">
        <div className="d-flex justify-content-end">
          {!user.verified && (
            <Button
              size="small"
              type="primary"
              className="mr-2"
              onClick={handleResendActivation}
              loading={resendActivationLoading}
            >
              resend invite
            </Button>
          )}
          <Button size="small" type="primary" onClick={onEditClick}>
            edit
          </Button>
        </div>
      </div>
    </div>
  );
}
