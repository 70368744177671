import React from "react";
import { Button } from "antd";
import { object, string } from "yup";
import { Form, Formik } from "formik";

import { InputField } from "../form/InputField";
import { MemberCredentialsProps } from "../../api/auth/AuthDTO";

const validationSchema = object({
  email: string().email("Please provide a valid email address").required("Email is required"),
  password: string()
    .min(8)
    .when("isAdministrator", (isAdministrator, schema) => {
      return isAdministrator ? schema.required("Password is required") : schema;
    }),
});

interface Props {
  readonly onSubmit: (values: MemberCredentialsProps) => void;
}

export function SettingsAuthForm({ onSubmit }: Props) {
  return (
    <div className="d-flex justify-content-center align-items-center flex-1">
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={{ email: "", password: "", clientId: 0, phone: "" }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form className="d-flex flex-column" style={{ width: "300px" }} onSubmit={handleSubmit}>
            <InputField
              name="email"
              type="email"
              label="Email"
              className="mb-4"
              transparent={false}
              placeholder="Your Email"
              labelClassName="input-label font-family-museo-sans font-weight-medium"
            />

            <InputField
              type="password"
              name="password"
              label="Password"
              className="mb-4"
              transparent={false}
              placeholder="Your Password"
              labelClassName="input-label font-family-museo-sans font-weight-medium"
            />

            <Button
              loading={isSubmitting}
              htmlType="submit"
              className="mb-1 align-self-center"
              type="primary"
            >
              Open
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
