import "./assets/settings-billing-history-list-item.scss";

import { Button } from "antd";
import { DateTime } from "luxon";
import { toFinite } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { formatCurrencyCode } from "../../helpers/CurrencyHelpers";

SettingsBillingHistoryListItem.propTypes = {
  invoice: PropTypes.object,
  onEditClick: PropTypes.func,
};

export function SettingsBillingHistoryListItem({ invoice, onEditClick }) {
  const date = useMemo(() => {
    const dateTime = DateTime.fromSeconds(invoice.invoice.date);

    return dateTime.setLocale("en").toLocaleString(DateTime.DATE_HUGE);
  }, [invoice]);
  const sum = useMemo(() => {
    const total = (toFinite(invoice.invoice.total) / 100).toFixed(2);
    const currency = formatCurrencyCode(invoice.invoice.currencyCode);

    return `${currency}${total}`;
  }, [invoice]);

  return (
    <div className="d-flex py-4 border-bottom flex-column flex-sm-row border-gray-light px-6 align-items-center settings-billing-history-list-item">
      <div className="d-flex flex-column flex-shrink-1 flex-grow-1 mb-3">
        <span className="fs-5 font-family-museo text-primary">INVOICE #{invoice.invoice.id}</span>
        <span className="fs-5 font-family-museo text-primary">
          PLAN: {invoice.invoice.lineItems[0].description}
        </span>
        <span className="text-primary text-center text-sm-left">{date}</span>
      </div>

      <div className="d-flex align-items-center">
        <div className="mr-7">
          <span className="text-secondary title-text">{sum}</span>
        </div>

        <div>
          <Button type="primary" onClick={onEditClick}>
            View
          </Button>
        </div>
      </div>
    </div>
  );
}
