import "./assets/reminders-item.scss";

import cx from "classnames";
import { Button } from "antd";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { AppRoutes } from "../../constants/Routes";
import { RemindersIcons } from "../ui/RemindersIcons";
import { replaceParams } from "../../helpers/UrlUtils";
import { SwitchReminderWrapper } from "./SwitchReminderWrapper";
import { ReminderProps, ReminderTriggerType } from "../../api/reminders/RemindersDTO";
import { CopyReminderButtonWrapper } from "./CopyReminderButtonWrapper";
import { ArchiveReminderButtonWrapper } from "./ArchiveReminderButtonWrapper";
import { formatMillisecondsToString } from "../../helpers/FormatUtils";
import { DateTime } from "luxon";

interface Props {
  readonly archive?: boolean;
  readonly className?: string;
  readonly item: ReminderProps;
  readonly onSendTestClick?: () => void;
  readonly setList: (value: any) => void;
  readonly setArchived: (value: any) => void;
}

export function formatDateInterval(item: ReminderProps) {
  const endDate = item?.enddate ? new Date(item.enddate).getTime() : undefined;
  const startDate = item?.startdate ? new Date(item.startdate).getTime() : undefined;

  const start = formatMillisecondsToString(startDate, DateTime.DATE_SHORT);
  const end = formatMillisecondsToString(endDate, DateTime.DATE_SHORT, "No End");

  if (item.triggerType === ReminderTriggerType.OneTime) {
    return `ONE-TIME: ${start}`;
  }

  return `${item.triggerType}: ${start} - ${end}`;
}

export function RemindersItem(props: Props) {
  const { item, archive, className, onSendTestClick, setList, setArchived } = props;
  const dateInterval = useMemo(() => formatDateInterval(item), [item]);

  return (
    <div
      className={cx(
        "reminders-item d-flex flex-column flex-md-row align-items-center align-items-md-start pl-3 pr-3 pt-5 pb-5 border-bottom",
        className,
      )}
    >
      {!archive && (
        <SwitchReminderWrapper
          id={item.id}
          state={item.active}
          className="mt-0 mt-md-1 mb-2 mb-md-0"
          setList={setList}
        />
      )}

      <div className="d-flex flex-column align-items-center align-items-md-start flex-grow-1 mw-100 pr-3 pl-3 mb-2 mb-md-0 overflow-hidden">
        <div className="reminders-item-name">{item.name}</div>
        <div className="reminders-item-date">{dateInterval}</div>
      </div>

      <div className="mr-4 mb-2 mb-md-0 flex-grow-0 flex-shrink-0">
        <RemindersIcons phone={Boolean(item.sms)} email={Boolean(item.email)} />
      </div>

      <div className="d-flex flex-grow-0 flex-shrink-0">
        {!archive && (
          <>
            <Button className="mr-1" type="primary" size="small">
              <Link to={replaceParams(AppRoutes.AdminRemindersItem, { id: item.id })}>Edit</Link>
            </Button>

            <Button className="mr-1" type="primary" size="small" onClick={onSendTestClick}>
              Send Test
            </Button>

            <CopyReminderButtonWrapper className="mr-1" item={item} />
          </>
        )}

        <ArchiveReminderButtonWrapper
          archive={archive}
          item={item}
          setList={setList}
          setArchived={setArchived}
        />
      </div>
    </div>
  );
}
