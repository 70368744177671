import "./assets/promotion-messaging.scss";

import { Tabs } from "antd";
import React, { useMemo } from "react";
import { useFormikContext } from "formik";

import { TwitterIconLegacy } from "../icons/TwitterIconLegacy";
import { LinkedInIconLegacy } from "../icons/LinkedInIconLegacy";
import { FacebookIconLegacy } from "../icons/FacebookIconLegacy";
import { PinterestIconLegacy } from "../icons/PinterestIconLegacy";
import { InputWithCountField } from "../form/InputWithCountField";
import { PromotionMessagingTwitter } from "./PromotionMessagingTwitter";
import { PromotionMessagingLinkedIn } from "./PromotionMessagingLinkedIn";
import { PromotionMessagingFacebook } from "./PromotionMessagingFacebook";
import { PromotionMessagingPinterest } from "./PromotionMessagingPinterest";
import { PromotionFieldTitle } from "../promotion/PromotionFieldTitle";

const { TabPane } = Tabs;

export function PromotionMessaging() {
  const { values } = useFormikContext();

  const twitterActive = useMemo(
    () => Boolean(values.promotionSocial && values.promotionSocial.twitterEnabled),
    [values.promotionSocial],
  );
  const linkedInActive = useMemo(
    () => Boolean(values.promotionSocial && values.promotionSocial.linkedinEnabled),
    [values.promotionSocial],
  );
  const facebookActive = useMemo(
    () => Boolean(values.promotionSocial && values.promotionSocial.facebookEnabled),
    [values.promotionSocial],
  );
  const pinterestActive = useMemo(
    () => Boolean(values.promotionSocial && values.promotionSocial.pinterestEnabled),
    [values.promotionSocial],
  );

  if (!values.clonedFromId || values.wrongTemplate) {
    return (
      <div className="d-flex flex-column">
        <span className="text-center">Please select template</span>
      </div>
    );
  }

  return (
    <div className="mx-md-4 promotion-messaging">
      <PromotionFieldTitle>TARGET CLICK THRU URL:</PromotionFieldTitle>
      <InputWithCountField
        maxLength={200}
        withErrors={false}
        name="promotionSocial.targetUrl"
        footerContainerClassName="client-promotion-edit-messaging-linked-in-target-url-footer"
        placeholder="https://www.clickmoremedia.com/test"
      />

      <PromotionFieldTitle isRequired={true}>SOCIAL PLATFORMS:</PromotionFieldTitle>
      <Tabs defaultActiveKey="linked-in" animated={false}>
        <TabPane
          key="linked-in"
          tab={<LinkedInIconLegacy className="social-icon" active={linkedInActive} />}
        >
          <PromotionMessagingLinkedIn />
        </TabPane>
        <TabPane tab={<TwitterIconLegacy active={twitterActive} />} key="twitter">
          <PromotionMessagingTwitter />
        </TabPane>
        <TabPane tab={<FacebookIconLegacy active={facebookActive} />} key="facebook">
          <PromotionMessagingFacebook />
        </TabPane>
        <TabPane tab={<PinterestIconLegacy active={pinterestActive} />} key="pinterest">
          <PromotionMessagingPinterest />
        </TabPane>
      </Tabs>
    </div>
  );
}
