import "./assets/error-page.scss";

import React from "react";

import { Link } from "react-router-dom";
import { Button } from "antd";
import PropTypes from "prop-types";

ErrorPage.propTypes = {
  error: PropTypes.number.isRequired,
  children: PropTypes.string.isRequired,
};

export function ErrorPage({ error, children }) {
  return (
    <div className="error-page vh-100 d-flex flex-column align-items-center justify-content-center text-center">
      <div className="error-page-title text-secondary mb-3">
        <span className="title-text text-uppercase">error</span> <br />
        <span className="title-error">{error}</span>
      </div>

      <div className="error-page-description mb-3">{children}</div>

      <Link to="/">
        <Button type="primary" size="large">
          back home
        </Button>
      </Link>
    </div>
  );
}
