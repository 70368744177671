import "./assets/image-builder-item.scss";

import React from "react";
import cx from "classnames";
import { Button } from "antd";

import { TemplateDnd } from "../template-dnd/TemplateDnd";
import { useFormikContext } from "formik";

export function ImageBuilderItem({
  onCancel,
  hasBuilder,
  showPreview = true,
  onSelectTemplateClick,
  loading = false,
  background = {},
}) {
  const formikContext = useFormikContext();

  return (
    <div className="image-builder-item">
      <div
        className={cx(
          "d-flex flex-column flex-sm-row mb-4 align-items-center justify-content-between",
          { "align-self-center": showPreview },
        )}
      >
        {!showPreview && (
          <div className="mb-3 mb-sm-0 post-template-title">
            <div className="post-template-title-name">Choose template</div>
          </div>
        )}

        {!hasBuilder ? (
          <Button
            type="primary"
            onClick={() => {
              if (showPreview) {
                onSelectTemplateClick();
              } else {
                onCancel();
              }
            }}
          >
            {!showPreview ? "Cancel" : hasBuilder ? "change template" : "select template"}
          </Button>
        ) : null}
      </div>

      {formikContext.values.promotionItem && (
        <div
          className={cx("justify-content-center", {
            "d-flex": Boolean(formikContext.values.zones),
            "d-none": Boolean(!formikContext.values.zones),
          })}
        >
          <TemplateDnd loading={loading} background={background} />
          <div style={{ opacity: 0, position: "absolute", zIndex: -10 }}>
            <TemplateDnd
              loading={loading}
              background={background}
              width={1200}
              height={630}
              templateId="TemplatePreviewDndTargetBig"
              canDrag={false}
            />
          </div>
        </div>
      )}

      <div className="d-flex align-self-center mt-5">
        {hasBuilder && (
          <Button
            type="primary"
            onClick={() => {
              if (showPreview) {
                onSelectTemplateClick();
              } else {
                onCancel();
              }
            }}
          >
            {!showPreview ? "Cancel" : hasBuilder ? "change template" : "select template"}
          </Button>
        )}
      </div>
    </div>
  );
}
