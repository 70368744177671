import React from "react";
import { Button } from "antd";

import { Slider } from "../ui/Slider";

interface Props {
  readonly disabled: boolean;

  readonly zoomValue: number;
  readonly onChangeZoom: (value: number) => void;

  readonly onFullClick: () => void;
  readonly onPerfectClick: () => void;
}

export function PromotionBackgroundModalActions({
  disabled,
  zoomValue,
  onFullClick,
  onChangeZoom,
  onPerfectClick,
}: Props) {
  return (
    <div className="d-flex flex-column w-100">
      <span className="d-flex justify-content-center font-weight-bold">SCALE BACKGROUND</span>
      <Slider
        max={2}
        min={1}
        label=""
        step={0.01}
        className="mb-3"
        showValue={false}
        value={zoomValue}
        disabled={disabled}
        wrapperClassName="px-0"
        onChange={onChangeZoom}
      />

      <div className="d-flex justify-content-center">
        <Button disabled={disabled} className="mr-2" type="primary" onClick={onFullClick}>
          Full screen
        </Button>
        <Button type="primary" className="ml-2" disabled={disabled} onClick={onPerfectClick}>
          Perfect, let's go
        </Button>
      </div>
    </div>
  );
}
