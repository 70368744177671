import React from "react";

export function StoppedIcon({ size = 24, ...svgProps }: any) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="10" stroke="red" strokeWidth="1.5" />
      <path
        d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5"
        stroke="red"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
