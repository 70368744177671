import "./assets/input-field.scss";

import cx from "classnames";
import { useField } from "formik";
import React, { ReactNode, useMemo } from "react";

import { PhoneInput } from "../ui/PhoneInput";

interface Props {
  readonly style?: any;
  readonly name: string;
  readonly type?: string;
  readonly label?: string;
  readonly after?: ReactNode;
  readonly before?: ReactNode;
  readonly className?: string;
  readonly placeholder?: string;
  readonly onChange?: () => void;
  readonly transparent?: boolean;
  readonly labelClassName?: string;
  readonly inputClassName?: string;
  readonly containerClass?: string;
}

export function PhoneField({ name, label, className, labelClassName, ...props }: Props) {
  const [field, meta, helpers] = useField(name);

  const showError = useMemo(() => Boolean(meta.touched && meta.error), [meta]);

  return (
    <>
      <div className={cx(className, "input-field d-flex flex-column")}>
        {Boolean(label) && (
          <label
            className={cx(labelClassName, "mb-1 font-family-museo font-weight-light field-label")}
          >
            {label}
          </label>
        )}

        <PhoneInput
          {...props}
          {...field}
          onChange={(x) => helpers.setValue(x)}
          showError={showError}
        />
        {meta.error && <span className="text-danger mt-1">{meta.error}</span>}
      </div>
    </>
  );
}
