import "./assets/position-control.scss";

import React, { useMemo } from "react";
import cx from "classnames";

import { CircleIcon } from "../icons/CircleIcon";
import { ArrowTopIcon } from "../icons/ArrowTopIcon";
import { ArrowLeftIcon } from "../icons/ArrowLeftIcon";
import { ArrowRightIcon } from "../icons/ArrowRightIcon";
import { ArrowBottomIcon } from "../icons/ArrowBottomIcon";
import { ArrowTopLeftIcon } from "../icons/ArrowTopLeftIcon";
import { ArrowTopRightIcon } from "../icons/ArrowTopRightIcon";
import { ArrowBottomLeftIcon } from "../icons/ArrowBottomLeftIcon";
import { ArrowBottomRightIcon } from "../icons/ArrowBottomRightIcon";
import {
  ANCHOR_ALIGN_CENTER,
  ANCHOR_ALIGN_LEFT,
  ANCHOR_ALIGN_RIGHT,
  ANCHOR_JUSTIFY_BOTTOM,
  ANCHOR_JUSTIFY_MIDDLE,
  ANCHOR_JUSTIFY_TOP,
} from "../../constants/ImageConstants";

interface Props {
  readonly value: string;
  readonly className?: string;
  readonly onSelect: (align: string, justify: string) => void;
}

export function PositionControl({ onSelect, className, value = "" }: Props) {
  const data = useMemo(() => {
    const arr = value.toUpperCase().split("_");

    if (arr.length === 2) {
      return {
        align: arr[1],
        justify: arr[0],
      };
    }

    return {
      align: "",
      justify: "",
    };
  }, [value]);

  const isTopLeft = data.justify === ANCHOR_JUSTIFY_TOP && data.align === ANCHOR_ALIGN_LEFT;
  const isTopCenter = data.justify === ANCHOR_JUSTIFY_TOP && data.align === ANCHOR_ALIGN_CENTER;
  const isTopRight = data.justify === ANCHOR_JUSTIFY_TOP && data.align === ANCHOR_ALIGN_RIGHT;
  const isMiddleLeft = data.justify === ANCHOR_JUSTIFY_MIDDLE && data.align === ANCHOR_ALIGN_LEFT;
  const isMiddleCenter =
    data.justify === ANCHOR_JUSTIFY_MIDDLE && data.align === ANCHOR_ALIGN_CENTER;
  const isMiddleRight = data.justify === ANCHOR_JUSTIFY_MIDDLE && data.align === ANCHOR_ALIGN_RIGHT;
  const isBottomLeft = data.justify === ANCHOR_JUSTIFY_BOTTOM && data.align === ANCHOR_ALIGN_LEFT;
  const isBottomCenter =
    data.justify === ANCHOR_JUSTIFY_BOTTOM && data.align === ANCHOR_ALIGN_CENTER;
  const isBottomRight = data.justify === ANCHOR_JUSTIFY_BOTTOM && data.align === ANCHOR_ALIGN_RIGHT;

  return (
    <div className={cx("d-flex flex-column position-control", className)}>
      <div className="d-flex flex-shrink-1 flex-grow-1">
        <div
          role="button"
          onClick={() => onSelect(ANCHOR_ALIGN_LEFT, ANCHOR_JUSTIFY_TOP)}
          className={cx(
            "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center arrow-button",
            { active: isTopLeft },
          )}
        >
          <ArrowTopLeftIcon />
        </div>
        <div
          role="button"
          onClick={() => onSelect(ANCHOR_ALIGN_CENTER, ANCHOR_JUSTIFY_TOP)}
          className={cx(
            "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center arrow-button",
            { active: isTopCenter },
          )}
        >
          <ArrowTopIcon />
        </div>
        <div
          role="button"
          onClick={() => onSelect(ANCHOR_ALIGN_RIGHT, ANCHOR_JUSTIFY_TOP)}
          className={cx(
            "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center arrow-button",
            { active: isTopRight },
          )}
        >
          <ArrowTopRightIcon />
        </div>
      </div>
      <div className="d-flex flex-shrink-1 flex-grow-1">
        <div
          role="button"
          onClick={() => onSelect(ANCHOR_ALIGN_LEFT, ANCHOR_JUSTIFY_MIDDLE)}
          className={cx(
            "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center arrow-button",
            { active: isMiddleLeft },
          )}
        >
          <ArrowLeftIcon />
        </div>
        <div
          role="button"
          onClick={() => onSelect(ANCHOR_ALIGN_CENTER, ANCHOR_JUSTIFY_MIDDLE)}
          className={cx(
            "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center arrow-button",
            { active: isMiddleCenter },
          )}
        >
          <CircleIcon />
        </div>
        <div
          role="button"
          onClick={() => onSelect(ANCHOR_ALIGN_RIGHT, ANCHOR_JUSTIFY_MIDDLE)}
          className={cx(
            "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center arrow-button",
            { active: isMiddleRight },
          )}
        >
          <ArrowRightIcon />
        </div>
      </div>
      <div className="d-flex flex-shrink-1 flex-grow-1">
        <div
          role="button"
          onClick={() => onSelect(ANCHOR_ALIGN_LEFT, ANCHOR_JUSTIFY_BOTTOM)}
          className={cx(
            "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center arrow-button",
            { active: isBottomLeft },
          )}
        >
          <ArrowBottomLeftIcon />
        </div>
        <div
          role="button"
          onClick={() => onSelect(ANCHOR_ALIGN_CENTER, ANCHOR_JUSTIFY_BOTTOM)}
          className={cx(
            "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center arrow-button",
            { active: isBottomCenter },
          )}
        >
          <ArrowBottomIcon />
        </div>
        <div
          role="button"
          onClick={() => onSelect(ANCHOR_ALIGN_RIGHT, ANCHOR_JUSTIFY_BOTTOM)}
          className={cx(
            "d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center arrow-button",
            { active: isBottomRight },
          )}
        >
          <ArrowBottomRightIcon />
        </div>
      </div>
    </div>
  );
}
