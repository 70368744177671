import React from "react";
import "./assets/post-activity.scss";
import { PromotionSocialItem, SocialType } from "../promotion/PromotionSocialItem";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { propOr } from "ramda";

interface Props {
  readonly lastActivities: any;
}

export function PostActivity({ lastActivities }: Props) {
  const { isMobile } = useMediaQuery();
  const linkedinShare = propOr(0, "linkedinShare", lastActivities);
  const twitterShare = propOr(0, "twitterShare", lastActivities);
  const facebookShare = propOr(0, "facebookShare", lastActivities);
  const pinterestShare = propOr(0, "pinterestShare", lastActivities);
  return (
    <div className="post-activity-root mt-3">
      <div className="title">
        <span>My Post Activity</span>
        <span>(Last 30 days)</span>
      </div>
      <div className="post-activities">
        <PromotionSocialItem
          size={isMobile ? 14 : 16}
          count={linkedinShare}
          socialType={SocialType.LinkedIn}
          linkedInEnabled={true}
        />
        {Boolean(twitterShare) && (
          <PromotionSocialItem
            size={isMobile ? 14 : 16}
            count={twitterShare}
            socialType={SocialType.Twitter}
            twitterEnabled={true}
          />
        )}
        {Boolean(facebookShare) && (
          <PromotionSocialItem
            size={isMobile ? 14 : 16}
            count={facebookShare}
            socialType={SocialType.Facebook}
            facebookEnabled={true}
          />
        )}
        {Boolean(pinterestShare) && (
          <PromotionSocialItem
            size={isMobile ? 14 : 16}
            count={pinterestShare}
            socialType={SocialType.Pinterest}
            pinterestEnabled={true}
          />
        )}
      </div>
    </div>
  );
}

export default PostActivity;
