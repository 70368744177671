import "./assets/sign-up-form.scss";

import React from "react";
import { Checkbox, Form, Input, SubmitButton } from "formik-antd";
import { InputField } from "../form/InputField";
import { SelectField } from "../form/SelectField";
import { TimeZones } from "../../constants/timezone";

interface Props {
  readonly handleSubmit: () => void;
  readonly loading: boolean;
}

export function SignUpForm({ handleSubmit, loading }: Props) {
  return (
    <Form onSubmit={handleSubmit} className="d-flex flex-column sign-up-form">
      <Form.Item name="email" label="Email">
        <Input name="email" placeholder="Your email" />
      </Form.Item>

      <div className="row">
        <div className="col-sm-6">
          <Form.Item name="firstName" label="First Name">
            <Input name="firstName" placeholder="Your first name" />
          </Form.Item>
        </div>

        <div className="col-sm-6">
          <Form.Item name="lastName" label="Last Name">
            <Input name="lastName" placeholder="Your last name" />
          </Form.Item>
        </div>
      </div>

      <Form.Item name="companyName" label="Company Name">
        <Input name="companyName" placeholder="Your Company name" />
      </Form.Item>

      <Form.Item name="companyUrl" label="Company URL">
        <Input name="companyUrl" placeholder="Your Company URL" />
      </Form.Item>

      <Form.Item name="timezone" label="Time Zone">
        <SelectField
          className="mb-4 mt-1"
          inputClassName="timezone-field"
          name="timeZone"
          placeholder="Choose Time Zone"
          data={TimeZones}
        />
      </Form.Item>

      <div className="row">
        <div className="col-sm-6">
          <Form.Item name="password" label="Password">
            <InputField
              type="password"
              name="password"
              className="mb-4"
              transparent={false}
              placeholder="Your Password"
              showError={false}
            />
          </Form.Item>
        </div>

        <div className="col-sm-6">
          <Form.Item name="confirmationPassword" label="Confirm password">
            <InputField
              type="password"
              name="confirmationPassword"
              className="mb-4"
              transparent={false}
              showError={false}
              placeholder="Your password confirmation"
            />
          </Form.Item>
        </div>
      </div>

      <Form.Item name="agreement" className="px-2">
        <Checkbox name="agreement" className="lh-5 d-flex agreement-checkbox">
          I have read and agree to the{" "}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.vsbl.biz/terms-of-service/"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="https://www.vsbl.biz/privacy-policy/" target="_blank" rel="noreferrer noopener">
            Privacy Policy
          </a>
          .
        </Checkbox>
      </Form.Item>

      <SubmitButton block={true} type="primary" loading={loading} disabled={false}>
        create my account
      </SubmitButton>
    </Form>
  );
}
