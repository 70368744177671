import "./assets/settings-company-logo-field.scss";

import { Button, notification } from "antd";
import { useField } from "formik";
import React, { useEffect, useMemo } from "react";

import { Loader } from "../ui/Loader";
import { useNotification } from "../../hooks/useNotification";
import { useUploadLogo } from "../../hooks/useUploadLogo";

export function SettingsCompanyLogoField() {
  const { showError } = useNotification();
  const [field, , helpers] = useField("logo");

  const [input, files] = useUploadLogo({ multiple: false });

  const hasImage = useMemo(() => Boolean(field.value && field.value?.second?.url), [field.value]);

  useEffect(() => {
    if (files.list.length > 0 && !files.error) {
      if (files.list[0].width < 500) {
        notification.open({
          type: "error",
          message: "Minimum logo width 500px",
          duration: null,
        });
      } else {
        helpers.setValue(files.list[0]);
      }

      files.reset();
      input.value = "";
    }
  }, [files, helpers, showError]);

  return (
    <div className="d-flex flex-column settings-company-logo-field">
      <div className="d-flex flex-column align-items-center justify-content-center position-relative mb-3 image-container">
        {hasImage && <img alt="logo" className="mw-100 mh-100" src={field.value.second.url} />}

        {files.loading && (
          <div className="d-flex position-absolute-fill align-items-center justify-content-center bg-white-50">
            <Loader />
          </div>
        )}
      </div>

      <div className="d-flex flex-column">
        <Button
          type="primary"
          onClick={() => {
            if (input) {
              input.click();
            }
          }}
        >
          Upload logo
        </Button>

        <span className="text-center" style={{ fontSize: "12px" }}>
          Upload PNG or JPG file (minimum width 500px)
        </span>
      </div>
    </div>
  );
}
