import React from "react";
import "./assets/sign-up-title.scss";

interface Props {
  readonly description: string;
}

export function SignUpTitle({ description }: Props) {
  return (
    <div className="sign-up-headline">
      <span>{description ? description : "Start creating campaigns that ignite your team"}</span>
    </div>
  );
}
