import "./assets/image-builder-field.scss";

import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { PromotionTextField } from "../promotion-fields/PromotionTextField";
import { PromotionListField } from "../promotion-fields/PromotionListField";
import { PromotionCheckboxField } from "../promotion-fields/PromotionCheckboxField";
import { PromotionShareLinkField } from "../promotion-fields/PromotionShareLinkField";
import { PromotionVideoUploadField } from "../promotion-fields/PromotionVideoUploadField";
import { PromotionImageUploadField } from "../promotion-fields/PromotionImageUploadField";
import { PromotionQrCodeReaderField } from "../promotion-fields/PromotionQrCodeReaderField";
import { PromotionImageCheckboxField } from "../promotion-fields/PromotionImageCheckboxField";
import { PromotionSocialPersonalDetailsField } from "../promotion-fields/PromotionSocialPersonalDetailsField";

ImageBuilderField.propTypes = {
  name: PropTypes.string,
  onAddValueClick: PropTypes.func,
  onUpdateFields: PropTypes.func,
  onRemoveValueClick: PropTypes.func,
  field: PropTypes.shape({
    show: PropTypes.bool,
    visible: PropTypes.bool,
    uiType: PropTypes.number,
  }),
};

export function ImageBuilderField({
  field,
  name,
  onRemoveValueClick,
  onAddValueClick,
  onUpdateFields,
}) {
  const isTextField = useMemo(() => Boolean(field.uiType === 1 && field.show && field.visible), [
    field.show,
    field.uiType,
    field.visible,
  ]);
  const isListField = useMemo(
    () =>
      Boolean(
        (field.uiType === 2 || field.uiType === 3 || field.uiType === 4) &&
          field.show &&
          field.visible,
      ),
    [field.show, field.uiType, field.visible],
  );
  const isCheckboxField = useMemo(
    () => Boolean(field.uiType === 3 && field.show && field.visible),
    [field.show, field.uiType, field.visible],
  );
  const isImageCheckboxField = useMemo(
    () => Boolean(field.uiType === 5 && field.show && field.visible),
    [field.show, field.uiType, field.visible],
  );
  const isImageUploadField = useMemo(
    () => Boolean(field.uiType === 7 && field.show && field.visible),
    [field.show, field.uiType, field.visible],
  );
  const isQrCodeReaderField = useMemo(
    () => Boolean(field.uiType === 8 && field.show && field.visible),
    [field.show, field.uiType, field.visible],
  );
  const isSocialPersonalDetailsField = useMemo(
    () => Boolean(field.uiType === 9 && field.show && field.visible),
    [field.show, field.uiType, field.visible],
  );
  const isVideoUploadField = useMemo(
    () => Boolean(field.uiType === 10 && field.show && field.visible),
    [field.show, field.uiType, field.visible],
  );
  const isShareLinkField = useMemo(
    () => Boolean(field.uiType === 11 && field.show && field.visible),
    [field.show, field.uiType, field.visible],
  );

  return (
    <div className="image-builder-field">
      {isTextField && <PromotionTextField name={name} field={field} />}
      {isCheckboxField && <PromotionCheckboxField name={name} field={field} />}
      {isShareLinkField && <PromotionShareLinkField name={name} field={field} />}
      {isImageUploadField && <PromotionImageUploadField name={name} field={field} />}
      {isVideoUploadField && <PromotionVideoUploadField name={name} field={field} />}
      {isQrCodeReaderField && <PromotionQrCodeReaderField name={name} field={field} />}
      {isImageCheckboxField && <PromotionImageCheckboxField name={name} field={field} />}
      {isSocialPersonalDetailsField && (
        <PromotionSocialPersonalDetailsField name={name} field={field} />
      )}
      {isListField && (
        <PromotionListField
          name={name}
          field={field}
          onUpdateFields={onUpdateFields}
          onAddValueClick={onAddValueClick}
          onRemoveValueClick={onRemoveValueClick}
        />
      )}
    </div>
  );
}
