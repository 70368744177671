import React from "react";

import { Form, SubmitButton } from "formik-antd";
import { InputField } from "../form/InputField";

interface Props {
  readonly handleSubmit: () => void;
  readonly loading: boolean;
}

export function ResetPasswordForm({ handleSubmit, loading }: Props) {
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item name="password" label="Password">
        <InputField
          type="password"
          name="password"
          className="mb-4"
          transparent={false}
          placeholder="Your Password"
          showError={false}
        />
      </Form.Item>

      <Form.Item name="confirmationPassword" label="Confirm Password">
        <InputField
          type="password"
          name="confirmationPassword"
          className="mb-4"
          transparent={false}
          placeholder="Your password confirmation"
          showError={false}
        />
      </Form.Item>

      <SubmitButton type="primary" block={true} loading={loading}>
        set new password
      </SubmitButton>
    </Form>
  );
}
