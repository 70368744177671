import React, { useEffect, useState } from "react";

import { useResource } from "../../hooks/useResource";
import { PromotionPreviewDialog } from "./PromotionPreviewDialog";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { usePromotionContext } from "../../api/promotions/PromotionContext";
import {
  formatEditInitialPromotion,
  isValidPromotionTemplate,
} from "../../helpers/PromotionsHelpers";
import { notification } from "antd";
import { sleep } from "../../helpers/SystemUtils";

interface Props {
  readonly id: number;
  readonly show: boolean;
  readonly onCancel: () => void;
}

export function PromotionPreviewDialogWrapper({ id, show, onCancel }: Props) {
  const [promotion, setPromotion] = useState();
  const [loading, setLoading] = useState(false);

  const { PromotionApi } = usePromotionContext();

  const promotionResource = useResource(
    () => (id && show ? PromotionApi.getPromotion(id) : undefined),
    [id, show],
  );

  useResourceHandler(promotionResource, {
    onSuccess: async (data) => {
      try {
        setLoading(true);

        if (data) {
          await sleep();

          const isValidTemplate = await isValidPromotionTemplate(data);

          if (isValidTemplate) {
            const res = await formatEditInitialPromotion(data);

            setPromotion(res as any);
          } else {
            onCancel();

            throw new Error("Wrong template");
          }
        }

        setLoading(false);
      } catch ({ message }) {
        notification.open({
          type: "error",
          message: "Error",
          description: message as any,
          duration: null,
        });

        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!show && promotion) {
      setPromotion(undefined);
    }
  }, [show, promotion]);

  return (
    <PromotionPreviewDialog
      show={show}
      onCancel={onCancel}
      promotion={promotion}
      loading={promotionResource.loading || loading}
    />
  );
}
