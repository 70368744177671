import "./theme/scss/bootstrap.scss";

import React from "react";
import ReactDOM from "react-dom";

import { AppContainer } from "./containers/AppContainer";

ReactDOM.render(<AppContainer />, document.getElementById("vsbl"));

if (module.hot) {
  module.hot.accept();
}
