import { BaseApi } from "../BaseApi";
import { toCamelCase, toKebabCase } from "../../helpers/CaseUtils";

export class PromotionApi extends BaseApi {
  getPromotionList(clientId, query?: any) {
    return this.get("promotions/:clientId/list", { query, params: { clientId } }).then(
      ({ data = {} }) => data,
    );
  }

  getPromotion(id, query = {}) {
    return this.get("promotions/:id", { params: { id }, query }).then(({ data }) => data);
  }

  getLastActivity() {
    return this.post("promotions/last-activities", {}).then(({ data }) => data);
  }

  updatePromotionStatus(id, status) {
    return this.patch("promotions/:id", { params: { id }, query: { status } });
  }

  createPromotion(body) {
    return this.post("promotions/create", { json: toKebabCase(body) }).then(({ data }) =>
      toCamelCase(data),
    );
  }

  updatePromotion(body) {
    return this.patch("promotions/update", { json: toKebabCase(body) }).then(({ data }) =>
      toCamelCase(data),
    );
  }

  createDynamicZone(body) {
    return this.post("promotions/dynamiczone/create", { json: toKebabCase(body) });
  }

  updateDynamicZone(body) {
    return this.put("promotions/dynamiczone/update", { json: toKebabCase(body) });
  }

  submitAnswer(responses) {
    return this.post("promotions/submit", {
      json: responses,
    }).then(({ data }) => toCamelCase(data));
  }

  getTemplateList(query) {
    return this.get("promotions/templates", { query }).then(({ data }) => data.items);
  }

  deletePromotion(id) {
    return this.delete("promotions/:id", { params: { id } });
  }

  changeStatus(id, status) {
    return this.patch("promotions/:id/status", { params: { id }, query: { status } });
  }

  traceSocial(body) {
    return this.post("promotions/trace-social", { json: toKebabCase(body) });
  }

  postToLinkedIn(query) {
    return this.get("linkedin/share", { query });
  }
}
