import "./assets/phone-input.scss";
import "react-phone-number-input/style.css";

import React from "react";
import PhoneNumberInput from "react-phone-number-input";

interface Props {
  readonly value: string;
  readonly onChange: (phone: string) => void;
  readonly containerClass?: string;
  readonly buttonClass?: string;
  readonly inputClass?: string;
  readonly showError?: boolean;
}

export function PhoneInput(props: Props) {
  const {
    onChange,
    value,
    containerClass = "",
    buttonClass = "",
    inputClass = "",
    showError,
    ...attr
  } = props;
  return (
    <div
      className={`phone-container ant-input field-input flex-shrink-1 flex-grow-1 fs-3 px-0 text-dark font-weight-light bg-dark-white ${containerClass} ${
        showError ? "error" : ""
      }`}
    >
      <PhoneNumberInput
        {...attr}
        international={true}
        defaultCountry="US"
        value={value}
        onChange={onChange}
        buttonClass={`border-0 phone-button ${buttonClass}`}
        inputClass={`phone-input w-100 br-0 border-0 ${inputClass}`}
      />
    </div>
  );
}
