import React, { useMemo, useState } from "react";

import { AppRoutes } from "../../constants/Routes";
import { useResource } from "../../hooks/useResource";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { ContainerLoader } from "../../components/ui/ContainerLoader";
import { usePromotionContext } from "../../api/promotions/PromotionContext";
import { PromotionsHeader } from "../../components/promotion/PromotionsHeader";
import { PromotionsListItem } from "../../components/promotion/PromotionsListItem";
import { DeletePromotionDialogWrapper } from "../../components/promotion/DeletePromotionDialogWrapper";
import { PromotionPreviewDialogWrapper } from "../../components/promotion/PromotionPreviewDialogWrapper";
import { useUser } from "../../context/auth/hooks";
import useRouter from "../../hooks/useRouter";
import { ClientAppLayout } from "../../components/app/ClientAppLayout";

export function PromotionsListContainer() {
  const { clientId } = useUser();
  const [list, setList] = useState([] as any);
  const [previewId, setPreviewId] = useState(0);
  const [deleteItem, setDeleteItem] = useState<any>();

  const router = useRouter();

  const { PromotionApi } = usePromotionContext();

  const listResponse = useResource(
    () => PromotionApi.getPromotionList(clientId, { limit: 100, isCompanyUser: true }),
    [clientId],
  );

  useResourceHandler(listResponse, {
    onSuccess: (data) => setList(data?.items || []),
  });

  const hasActiveItems = useMemo(() => list.some((x) => x.status === "APPROVED"), [list]);

  return (
    <ClientAppLayout>
      <div className="d-flex flex-column">
        <ContainerLoader show={listResponse.loading} />

        <PromotionsHeader
          disabledPortal={!hasActiveItems}
          onVisitClick={() => router.push(AppRoutes.TeamPortalPromotions)}
          onCreateClick={() => router.push(AppRoutes.AdminPromotionsItem, { id: 0 })}
        />

        {Boolean(!listResponse.loading && list.length === 0) && (
          <h5 className="text-center">Add your 1st promotion!</h5>
        )}

        {list.map((item) => (
          <PromotionsListItem
            item={item}
            key={item.id}
            onComplete={() => listResponse.refetch()}
            onDeleteClick={() => setDeleteItem(item)}
            onPreviewClick={() => setPreviewId(item.id)}
          />
        ))}

        <PromotionPreviewDialogWrapper
          id={previewId}
          show={Boolean(previewId)}
          onCancel={() => setPreviewId(0)}
        />

        <DeletePromotionDialogWrapper
          promotion={deleteItem}
          show={Boolean(deleteItem)}
          onCancel={() => setDeleteItem(undefined)}
          onComplete={() => {
            setList((prevList) => prevList.filter((item) => item.id !== deleteItem.id));
            setDeleteItem(undefined);
          }}
        />
      </div>
    </ClientAppLayout>
  );
}
