import React, { ReactNode, useMemo } from "react";
import cx from "classnames";
import { FontsWrapper } from "../promotion/FontsWrapper";

import { HTMLHead } from "../../widgets/HTMLHead";
import { useResource } from "../../hooks/useResource";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useSettingsContext } from "../../api/settings/SettingsContext";
import { useFonts, useToken } from "../../context/auth/hooks";
import { useNotification } from "../../hooks/useNotification";

interface Props {
  readonly title?: string;
  readonly children?: ReactNode;
  readonly contentClassName?: string;
  readonly headerComponent: ReactNode;
}

export function AppLayout({ title, children, headerComponent, contentClassName }: Props) {
  const { token } = useToken();
  const { SettingsApi } = useSettingsContext();
  const { setFontList } = useFonts();
  const { showErrorNotificationLegacy } = useNotification();

  const authorized = useMemo(() => Boolean(token), [token]);
  const fontsResource = useResource(() => authorized && SettingsApi.getFonts(), []);

  useResourceHandler(fontsResource, {
    onSuccess: ({ items = [] }) => setFontList(items),
    onFail: () =>
      showErrorNotificationLegacy({
        message: "Fonts",
        description: "Error while loading fonts, please refresh the page",
      }),
  });

  return (
    <div className="d-flex position-relative flex-shrink-1 flex-grow-1 flex-column top-root">
      <HTMLHead title={title} />
      <FontsWrapper />

      {headerComponent}

      <div className={cx("d-flex flex-shrink-1 flex-grow-1 flex-column pt-6", contentClassName)}>
        {children}
      </div>
    </div>
  );
}
