import "./assets/select-template-wrapper.scss";

import PropTypes from "prop-types";
import { notification } from "antd";
import { useFormikContext } from "formik";
import { DELETE, update } from "immupdate";
import React, { useMemo, useState } from "react";

import { Loader } from "../ui/Loader";
import { SelectTemplate } from "./SelectTemplate";
import { useResource } from "../../hooks/useResource";
import { toCamelCase } from "../../helpers/CaseUtils";
import { useSettingsContext } from "../../api/settings/SettingsContext";
import { usePromotionContext } from "../../api/promotions/PromotionContext";
import {
  formatCreateInitialPromotion,
  formatTemplateInitialPromotion,
  isValidPromotionTemplate,
} from "../../helpers/PromotionsHelpers";
import { useSettings, useUser } from "../../context/auth/hooks";

SelectTemplateWrapper.propTypes = {
  isEdit: PropTypes.bool,
  onSetTemplate: PropTypes.func,
};

export function SelectTemplateWrapper({ onSetTemplate, ...props }) {
  const { values } = useFormikContext();
  const { user } = useUser();
  const { settings } = useSettings();

  const [loadingTemplate, setLoadingTemplate] = useState(false);

  const { SettingsApi } = useSettingsContext();
  const { PromotionApi } = usePromotionContext();

  const companyResponse = useResource(() => SettingsApi.getCompany(), []);
  const templateListResponse = useResource(() => PromotionApi.getTemplateList(), []);

  const list = useMemo(() => templateListResponse.data || [], [templateListResponse]);

  return (
    <div className="select-template-wrapper">
      {Boolean(loadingTemplate || templateListResponse.loading) && (
        <div className="d-flex align-items-center justify-content-center position-fixed bg-white-50 loader-container">
          <Loader />
        </div>
      )}

      <SelectTemplate
        {...props}
        list={list}
        currentTemplateId={values.clonedFromId}
        onSelectClick={({ id }) => {
          setLoadingTemplate(true);

          PromotionApi.getPromotion(id)
            .then(async (data = {}) => {
              const template = toCamelCase(data);

              if (!(await isValidPromotionTemplate(template))) {
                throw new Error("Wrong template");
              }

              const templateValues = update(template, {
                id: props.isEdit && values.wrongTemplate ? values.id : DELETE,
              });

              const x =
                props.isEdit && !values.wrongTemplate
                  ? await formatTemplateInitialPromotion(
                      templateValues,
                      {
                        values,
                        templateId: template.id,
                        company: companyResponse.data,
                      },
                      settings,
                    )
                  : values.clonedFromId && !values.wrongTemplate
                  ? await formatTemplateInitialPromotion(
                      templateValues,
                      {
                        values,
                        templateId: template.id,
                        company: companyResponse.data,
                      },
                      settings,
                    )
                  : await formatCreateInitialPromotion(templateValues, {
                      values,
                      user: user,
                      templateId: template.id,
                      company: companyResponse.data,
                    });

              onSetTemplate(x);

              setLoadingTemplate(false);
            })
            .catch((e) => {
              notification.open({
                type: "error",
                message: "Error",
                description: e.message,
                duration: null,
              });

              setLoadingTemplate(false);
            });
        }}
      />
    </div>
  );
}
