import "./assets/app-header.scss";

import React, { ReactNode } from "react";
import cx from "classnames";

interface Props {
  readonly className?: string;
  readonly logoComponent?: ReactNode;
  readonly mainMenuComponent?: ReactNode;
  readonly topMenuComponent?: ReactNode;
  readonly logoContainerClassName?: string;
}

export function AppHeader(props: Props) {
  const {
    className,
    logoComponent,
    mainMenuComponent,
    topMenuComponent,
    logoContainerClassName,
  } = props;
  return (
    <div className={cx("d-flex pt-2 pb-3 px-4 bg-white position-sticky app-header", className)}>
      <div className={cx("d-flex flex-column mr-5 justify-content-end", logoContainerClassName)}>
        {logoComponent}
      </div>

      <div className="d-flex flex-column flex-1 justify-content-between">
        <div className="d-flex justify-content-end">{topMenuComponent}</div>
        <div className="d-flex flex-1 flex-column justify-content-end">{mainMenuComponent}</div>
      </div>
    </div>
  );
}
