import "./assets/team-members-modal-edit.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Form, Input, SubmitButton } from "formik-antd";
import { Button } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import cx from "classnames";
import { RemindersIcons } from "../../../components/ui/RemindersIcons";
import { useTeamContext } from "../../../api/team/TeamContext";
import { TeamMembersSwitch } from "./TeamMembersSwitch";
import { Dialog } from "../../../components/ui/Dialog";
import { PhoneField } from "../../../components/form/PhoneField";
import { useNotification } from "../../../hooks/useNotification";
import { CheckboxField } from "../../../components/form/CheckboxField";
import { useDepartmentContext } from "../../../api/departments/DepartmentsContext";
import { Loader } from "../../../components/ui/Loader";

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  phone: yup
    .string()
    .matches(
      /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
      "It doesn’t look like a phone number",
    ),
});

interface Props {
  readonly visible: boolean;
  readonly hideModal: () => void;
  readonly setList: (value: any) => void;
  readonly item: any;
}

export function TeamMembersModalEdit({ visible, hideModal, setList, item }: Props) {
  const { active, verified, id, firstName, lastName, phone = "", email } = item;

  const { TeamApi } = useTeamContext();
  const { showError } = useNotification();
  const { DepartmentsApi } = useDepartmentContext();

  const [status, setStatus] = useState(active);
  const [loading, setLoading] = useState(false);
  const [isDifferent, setIsDifferent] = useState(false);
  const [chosenDepartments, setChosenDepartments] = useState([] as any);
  const [departments, setDepartments] = useState([] as any);
  const [departmentsLoading, setDepartmentsLoading] = useState(false);

  const notDefaultDepartments = useMemo(
    () => item.departments.filter((department) => !department.system),
    [item],
  );

  useEffect(() => {
    const newDepartments = JSON.stringify(chosenDepartments);
    const oldDepartments = JSON.stringify(notDefaultDepartments);
    setIsDifferent(newDepartments !== oldDepartments);
  }, [chosenDepartments, notDefaultDepartments]);

  useEffect(() => {
    if (visible) {
      setDepartmentsLoading(true);
      DepartmentsApi.getDepartments().then(({ items = [] }) => {
        setDepartments(items);
        setChosenDepartments(notDefaultDepartments);
        setDepartmentsLoading(false);
      });
    }
  }, [notDefaultDepartments, item, DepartmentsApi, visible]);

  const isDisabled = useMemo(() => !status && verified, [status, verified]);

  const handleDelete = useCallback(() => {
    setLoading(true);
    TeamApi.deleteMember(id)
      .then(() => {
        setLoading(false);
        setList((prevList) => prevList.filter((item) => item.id !== id));
        hideModal();
      })
      .catch((e) => {
        setLoading(false);
        showError(e);
      });
  }, [showError, hideModal, setList, TeamApi, id]);

  const handleSubmit = useCallback(
    (values) => {
      const defaultDepartment = departments.find((department) => department.system);
      setLoading(true);
      const everyDepartment = [...chosenDepartments, defaultDepartment];
      const transformatedData = {
        ...item,
        phone: values.phone || undefined,
        firstName: values.firstName,
        lastName: values.lastName,
        active: status,
        departments: everyDepartment,
      };
      TeamApi.updateTeamMember(transformatedData)
        .then((member) => {
          if (isDifferent) {
            TeamApi.addDepartmentMember({
              departmentIds: everyDepartment.map((department) => department.id),
              memberId: member.id,
            }).then(() => {
              setLoading(false);
              setList((prevList) => {
                const others = prevList.filter((listItem) => listItem.id !== id);
                return [transformatedData, ...others];
              });
              hideModal();
            });
          } else {
            setLoading(false);
            setList((prevList) => {
              const others = prevList.filter((listItem) => listItem.id !== id);
              return [transformatedData, ...others];
            });
            hideModal();
          }
        })
        .catch((e) => {
          showError(e);
          setLoading(false);
        });
    },
    [
      departments,
      isDifferent,
      showError,
      item,
      hideModal,
      setList,
      TeamApi,
      id,
      status,
      chosenDepartments,
    ],
  );

  const handleChangeDepartments = useCallback(
    (value) => {
      const isAlreadyChosen = chosenDepartments.find(
        (department: any) => department.id === value.id,
      );
      const isDefaultDepartment = value.system;
      if (!isDefaultDepartment) {
        if (isAlreadyChosen) {
          const otherDepartments = chosenDepartments.filter(
            (department: any) => department.id !== value.id,
          );
          setChosenDepartments(otherDepartments);
        } else {
          setChosenDepartments([...chosenDepartments, value]);
        }
      }
    },
    [chosenDepartments, setChosenDepartments],
  );

  const header = (
    <div className="d-flex flex-column flex-sm-row align-items-center pr-sm-5">
      <TeamMembersSwitch
        pending={!verified}
        checked={status}
        onChange={useCallback(() => setStatus((prevStatus) => !prevStatus), [])}
      />
      <span
        className={cx("ant-modal-title flex-grow-1 mt-3 mt-sm-0 mb-0 pl-sm-3 pr-sm-3", {
          disabled: isDisabled,
        })}
      >
        {firstName} {lastName}
      </span>
      <RemindersIcons
        phone={!!phone}
        email={!!email}
        disabled={isDisabled}
        className="flex-grow-0 flex-shrink-0 pr-sm-5"
      />
    </div>
  );

  return (
    <Dialog
      width={767}
      footer={null}
      show={visible}
      title={header}
      onCancel={hideModal}
      className="team-members-modal-edit"
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          firstName,
          lastName,
          email,
          phone: phone ? phone : "",
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit }) => (
          <Form className="p-3" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <Form.Item name="firstName" label="Firstname">
                  <Input name="firstName" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item name="lastName" label="Lastname">
                  <Input name="lastName" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <PhoneField
                  labelClassName="phone-label"
                  name="phone"
                  label="Phone"
                  placeholder="Enter phone"
                />
              </div>
              <div className="col-sm-6">
                <Form.Item name="email" label="Email">
                  <Input name="email" disabled={true} />
                </Form.Item>
              </div>
            </div>

            <div className="d-flex flex-column mt-2">
              <span className="assign-groups">ASSIGN TO GROUPS</span>
              {departmentsLoading && (
                <div className="departments-loader-promotion">
                  <Loader />
                </div>
              )}
              <div className="d-flex mt-2 ml-1 departments-boxes">
                {departments.map((department) => {
                  const isDefault = department.system;
                  if (isDefault) {
                    return (
                      <span className="mr-4">
                        {department.departmentName} ({department.membersCount})
                      </span>
                    );
                  }
                  const isSelected = chosenDepartments.find(
                    (chosen) => chosen.id === department.id,
                  );
                  return (
                    <CheckboxField
                      checked={isSelected}
                      key={department.departmentName}
                      className="mb-1 mr-4 ml-0"
                      name={department.departmentName}
                      label={`${department.departmentName} (${department.membersCount})`}
                      onChange={() => handleChangeDepartments(department)}
                    />
                  );
                })}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <Button onClick={handleDelete} loading={loading} block={true}>
                  delete user
                </Button>
              </div>

              <div className="col-sm-6">
                <SubmitButton type="primary" block={true} loading={loading}>
                  save changes
                </SubmitButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
