import "./assets/settings.scss";

import { toFinite } from "lodash";
import { Tabs } from "antd";
import { useParams } from "react-router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { pathOr } from "ramda";

import { AppRoutes } from "../../constants/Routes";
import { useResource } from "../../hooks/useResource";
import { useAuthContext } from "../../api/auth/AuthContext";
import { useOtherContext } from "../../api/other/useOtherContext";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { ContainerLoader } from "../../components/ui/ContainerLoader";
import { useSettingsContext } from "../../api/settings/SettingsContext";
import { useChargebeeContext } from "../../api/chargebee/ChargebeeContext";
import { SettingsBilling } from "../../components/settings-billing/SettingsBilling";
import { SettingsOverview } from "../../components/settings-overview/SettingsOverview";
import { SettingsEditCardDialog } from "../../components/settings/SettingsEditCardDialog";
import { SettingsUsersWrapper } from "../../components/settings-users/SettingsUsersWrapper";
import { SettingsBillingHistoryList } from "../../components/settings-billing/SettingsBillingHistoryList";
import { BillingHistoryDetailsDialog } from "../../components/settings-billing/BillingHistoryDetailsDialog";
import { toCamelCase } from "../../helpers/CaseUtils";
import { useSettings } from "../../context/auth/hooks";
import useRouter from "../../hooks/useRouter";
import { ClientAppLayout } from "../../components/app/ClientAppLayout";
import { useNotification } from "../../hooks/useNotification";

const { TabPane } = Tabs;

export function SettingsContainer() {
  const router = useRouter();
  const { setSettings } = useSettings();
  const { tab: activeTab } = useParams<any>();
  const { showError, showSuccessNotification } = useNotification();

  const [invoiceList, setInvoiceList] = useState([]);
  const [editInvoiceId, setEditInvoiceId] = useState<any>();
  const [openEditCardDialog, setOpenEditCardDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const { AuthApi } = useAuthContext();
  const { BraintreeApi } = useOtherContext();
  const { SettingsApi } = useSettingsContext();
  const { ChargebeeApi } = useChargebeeContext();

  const stateListResponse = useResource(() => AuthApi.getStateList(), []);
  const profileResponse = useResource(() => ChargebeeApi.getProfile(), []);
  const companyResponse = useResource(() => SettingsApi.getCompany({}), []);
  const invoicesListResource = useResource(() => ChargebeeApi.getInvoiceList(), []);
  const subscriptionsResponse = useResource(() => ChargebeeApi.getSubscriptions(), []);

  useResourceHandler(invoicesListResource, {
    onSuccess: (data = []) => setInvoiceList(data),
  });

  useEffect(() => {
    if (!activeTab) {
      router.push(AppRoutes.AdminSettings, { tab: "overview" });
    }
  }, [activeTab, router]);

  const companyName = useMemo(() => pathOr("", ["data", "name"], companyResponse), [
    companyResponse,
  ]);

  const hasData = useMemo(() => Boolean(companyResponse.data && subscriptionsResponse.data), [
    companyResponse,
    subscriptionsResponse,
  ]);

  const loadingData = useMemo(
    () => companyResponse.loading || subscriptionsResponse.loading || loading,
    [companyResponse.loading, subscriptionsResponse.loading, loading],
  );

  const handleUpdateCard = useCallback(
    ({
      cvv,
      state,
      country,
      postalCode,
      cardNumber,
      holderName,
      expirationYear,
      expirationMonth,
    }) => {
      const { customer } = subscriptionsResponse.data;
      setLoading(true);
      ChargebeeApi.getBraintreeToken()
        .then(({ token }) => {
          BraintreeApi.tokenizeCard(token, {
            cvv,
            expirationYear,
            number: cardNumber,
            cardholderName: holderName,
            expirationMonth: toFinite(expirationMonth) + 1,
          })
            .then((response) => {
              ChargebeeApi.updateCard({
                braintreeClientToken: response,
                id: customer.id,
                billingAddress: {
                  state,
                  country,
                  zip: postalCode,
                },
              })
                .then(() => {
                  subscriptionsResponse.refetch();
                  showSuccessNotification({ message: "Update successfully" });
                  setLoading(false);
                })
                .catch((error) => {
                  showError(error);
                  setLoading(false);
                });
            })
            .catch((error) => {
              showError(error);
              setLoading(false);
            });
        })
        .catch((error) => {
          showError(error);
          setLoading(false);
        });
    },
    [BraintreeApi, ChargebeeApi, showError, showSuccessNotification, subscriptionsResponse],
  );

  const handleUpdateCompany = useCallback(
    (values) => {
      setLoading(true);
      const data = {
        ...companyResponse,
        ...values,
        smsLogo: values?.logo?.second,
        logo: values?.logo?.first,
      };
      SettingsApi.updateCompany(data)
        .then((res) => {
          setSettings(toCamelCase(res));
          companyResponse.refetch();
          showSuccessNotification({ message: "Update successfully" });
          setLoading(false);
        })
        .catch((err) => {
          showError(err);
          setLoading(false);
        });
    },
    [SettingsApi, showError, showSuccessNotification, companyResponse, setSettings],
  );

  const handleUpdateAddress = useCallback(
    ({ zip, state, country, company, accountOwnerName = "" }) => {
      setLoading(true);
      const { customer } = subscriptionsResponse.data;
      const [firstName, lastName] = accountOwnerName.split(" ");
      const billingAddress = {
        zip,
        company,
        country,
        lastName,
        firstName,
        state_code: country === "US" ? state : "",
        email: customer.email,
        phone: customer.phone,
      };
      ChargebeeApi.updateBillingAddress({
        billingAddress,
        id: customer.id,
        lastName: customer.lastName,
        firstName: customer.firstName,
      })
        .then(() => {
          subscriptionsResponse.refetch();
          setLoading(false);
        })
        .catch((err) => {
          showError(err);
          setLoading(false);
        });
    },
    [ChargebeeApi, subscriptionsResponse, showError],
  );

  const setActiveTab = useCallback(
    (tab) => {
      router.push(AppRoutes.AdminSettings, { tab });
    },
    [router],
  );

  const stateList = useMemo(
    () => (stateListResponse.data || []).map(({ value, code }) => ({ text: value, value: code })),
    [stateListResponse.data],
  );

  return (
    <ClientAppLayout>
      <div className="overview-main">
        <ContainerLoader show={loadingData} />

        {hasData && (
          <div className="d-flex flex-column">
            <div className="text-secondary font-family-museo mx-3 mb-2 fs-6">{companyName}</div>

            <Tabs animated={false} onChange={setActiveTab} activeKey={activeTab}>
              <TabPane tab="Overview" key="overview">
                <SettingsOverview
                  company={companyResponse.data}
                  onSubmit={handleUpdateCompany}
                  card={subscriptionsResponse.data.card}
                  subscription={subscriptionsResponse.data.subscription}
                  onViewPlansClick={() => setActiveTab("account")}
                  onViewBillingClick={() => setActiveTab("billing")}
                  onViewHistoryClick={() => setActiveTab("billing-history")}
                />
              </TabPane>

              <TabPane tab="Users" key="users">
                <SettingsUsersWrapper />
              </TabPane>

              <TabPane tab="Billing" key="billing">
                <SettingsBilling
                  stateList={stateList}
                  card={subscriptionsResponse.data.card}
                  subscription={subscriptionsResponse.data.subscription}
                  onEditCardClick={() => setOpenEditCardDialog(true)}
                  customer={subscriptionsResponse.data.customer}
                  onSubmit={handleUpdateAddress}
                />
              </TabPane>

              <TabPane tab="Billing History" key="billing-history">
                <SettingsBillingHistoryList
                  list={invoiceList}
                  card={subscriptionsResponse.data.card}
                  loading={invoicesListResource.loading}
                  onEditClick={setEditInvoiceId}
                  subscription={subscriptionsResponse.data.subscription}
                />
              </TabPane>
            </Tabs>

            <SettingsEditCardDialog
              stateList={stateList}
              show={openEditCardDialog}
              card={subscriptionsResponse.data.card}
              onCancel={() => setOpenEditCardDialog(false)}
              onSubmit={handleUpdateCard}
            />

            <BillingHistoryDetailsDialog
              invoiceList={invoiceList}
              invoiceId={editInvoiceId}
              show={Boolean(editInvoiceId)}
              company={companyResponse.data}
              profile={profileResponse.data}
              customer={subscriptionsResponse.data.customer}
              onCancel={() => setEditInvoiceId(undefined)}
            />
          </div>
        )}
      </div>
    </ClientAppLayout>
  );
}
