import React from "react";

import { ClientAppHeader } from "./ClientAppHeader";
import { useAuthContext } from "../../api/auth/AuthContext";
import { useUser } from "../../context/auth/hooks";

export function ClientAppHeaderWrapper() {
  const { user } = useUser();

  const { logout } = useAuthContext();

  return <ClientAppHeader user={user} onLogout={() => logout()} />;
}
