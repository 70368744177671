import "./assets/sign-in-form.scss";

import { Button } from "antd";
import { Form, Formik } from "formik";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { boolean, object, string } from "yup";
import Cookies from "js-cookie";

import { AppRoutes } from "../../constants/Routes";
import { InputField } from "../form/InputField";
import { CheckboxField } from "../form/CheckboxField";
import { SignInFormProps } from "../../api/auth/AuthDTO";
import { isValidEmail } from "./ForgotFormEmail";

const validationSchema = object({
  isAdministrator: boolean(),
  email: string()
    .required("Email is required")
    .test({
      test: (email) => isValidEmail(email),
      message: "Please enter valid email",
    }),
  password: string()
    .min(8)
    .when("isAdministrator", (isAdministrator, schema) => {
      return isAdministrator ? schema.required("Password is required") : schema;
    }),
});

interface Props {
  readonly onSubmit: (values: SignInFormProps) => Promise<unknown>;
}

export function SignInFormEmail({ onSubmit }: Props) {
  const [isBlur, setIsBlur] = useState(false);

  const initialEmail = useMemo(() => {
    const email = Cookies.get("signEmail") || "";
    Cookies.set("signEmail", "");
    return email;
  }, []);

  const changeCheckbox = () => {
    setIsBlur(!isBlur);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ email: initialEmail, password: "", isAdministrator: false }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, isSubmitting, values }) => {
        return (
          <Form onSubmit={handleSubmit} className="d-flex flex-column sign-in-form">
            <InputField
              name="email"
              label="Email"
              transparent={false}
              placeholder="Your email"
              className="mb-4"
            />
            <CheckboxField
              className="mb-4"
              name="isAdministrator"
              label="Sign-In as Administrator"
              onChange={() => changeCheckbox()}
            />
            {values.isAdministrator && (
              <InputField
                type="password"
                name="password"
                label="Password"
                className="mb-4"
                transparent={false}
                placeholder="Your Password"
              />
            )}
            <Button loading={isSubmitting} htmlType="submit" className="mb-1" type="primary">
              SIGN-IN
            </Button>

            {values.isAdministrator && (
              <Button type="link" htmlType="button" block={true}>
                <Link to={AppRoutes.AuthAdminForgotPassword}>forgot password</Link>
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
