import "./assets/field.scss";

import cx from "classnames";
import { useField } from "formik";
import React, { useMemo } from "react";

export function Field({
  label,
  children,
  className,
  withError = true,
  labelClassName,
  errorClassName,
  ...inputProps
}) {
  const [field, meta, helpers] = useField(inputProps.name);

  const showError = useMemo(() => Boolean(meta.touched && meta.error && withError), [
    withError,
    meta.error,
    meta.touched,
  ]);

  return (
    <div className={cx("field", className)}>
      {Boolean(label) && <label className={cx("field-label", labelClassName)}>{label}</label>}

      {children({ meta, field, helpers, inputProps })}

      {showError && <span className={cx("field-error-text", errorClassName)}>{meta.error}</span>}
    </div>
  );
}
