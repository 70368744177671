import { GOOGLE_ANALYTICS_URL, GOOGLE_TAG_MANAGER_URL, IS_PROD } from "../constants/AppConstants";

export function createGoogleAnalyticsScripts() {
  if (IS_PROD) {
    const linkScriptElement = document.createElement("script");
    const inlineScriptElement = document.createElement("script");

    linkScriptElement.setAttribute("async", "");
    linkScriptElement.setAttribute("src", GOOGLE_ANALYTICS_URL);

    inlineScriptElement.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-MZQYSG6J02');
    `;

    document.head.appendChild(linkScriptElement);
    document.head.appendChild(inlineScriptElement);
  }
}

export function createAdrollAnalyticsScripts() {
  if (IS_PROD) {
    const inlineScriptElement = document.createElement("script");

    inlineScriptElement.innerHTML = `
      adroll_adv_id = "4BFMQPJH2RFXPGRUN22SZY";
      adroll_pix_id = "Q73KW7AMP5EIDLEVDAZJUA";
      adroll_version = "2.0";

      (function(w, d, e, o, a) {
      w.__adroll_loaded = true;
      w.adroll = w.adroll || [];
      w.adroll.f = [ 'setProperties', 'identify', 'track' ];
      var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id
      + "/roundtrip.js";
      for (a = 0; a < w.adroll.f.length; a++) {
      w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
      return function() {
      w.adroll.push([ n, arguments ])
      }

      })(w.adroll.f[a])
      }

      e = d.createElement('script');
      o = d.getElementsByTagName('script')[0];
      e.async = 1;
      e.src = roundtripUrl;
      o.parentNode.insertBefore(e, o);
      })(window, document);
      adroll.track("pageView");
    `;

    document.head.appendChild(inlineScriptElement);
  }
}

export function createGoogleTagManagerScrips() {
  if (IS_PROD) {
    const inlineHeadScriptElement = document.createElement("script");
    const inlineBodyIframeElement = document.createElement("iframe");
    const inlineBodyNoscriptElement = document.createElement("noscript");

    inlineHeadScriptElement.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-T95V27G');
    `;

    inlineBodyIframeElement.setAttribute("width", "0");
    inlineBodyIframeElement.setAttribute("height", "0");
    inlineBodyIframeElement.setAttribute("src", GOOGLE_TAG_MANAGER_URL);
    inlineBodyIframeElement.setAttribute("style", "display:none;visibility:hidden");

    inlineBodyNoscriptElement.appendChild(inlineBodyIframeElement);

    document.head.appendChild(inlineHeadScriptElement);
    document.body.appendChild(inlineBodyNoscriptElement);
  }
}
