import React, { ReactNode } from "react";
import cx from "classnames";

import { AppLayout } from "./AppLayout";
import { ClientAppHeaderWrapper } from "./ClientAppHeaderWrapper";
import { useLocation } from "react-router";
import { AppRoutes } from "../../constants/Routes";

interface Props {
  readonly title?: string;
  readonly contentClassName?: string;
  readonly children?: ReactNode;
}

export function ClientAppLayout({ title, children, contentClassName }: Props) {
  const location = useLocation();

  if (location.pathname === AppRoutes.AdminSubscription) {
    return <>{children}</>;
  }

  return (
    <AppLayout
      title={title}
      headerComponent={<ClientAppHeaderWrapper />}
      contentClassName={cx("d-flex flex-column ", contentClassName)}
    >
      {children}
    </AppLayout>
  );
}
