import { AppError } from "./AppError";
import {
  ANCHOR_ALIGN_LEFT,
  ANCHOR_ALIGN_RIGHT,
  ANCHOR_CENTER,
  ANCHOR_JUSTIFY_BOTTOM,
  ANCHOR_JUSTIFY_TOP,
} from "../constants/ImageConstants";

export function cropImage(src, sx, sy, sw, sh, dx, dy, dw, dh) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const element = document.createElement("canvas");

      element.setAttribute("width", dw);
      element.setAttribute("height", dh);

      if (element.getContext) {
        const ctx = element.getContext("2d");

        ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh);
      }

      resolve(element.toDataURL());
    };

    img.onerror = reject;

    img.src = src;
  });
}

export function getLogoSizesForContainer(logoWidth, logoHeight) {
  return {
    width: logoWidth,
    height: logoHeight,
  };
}

export function getImageSizesForContainer(iw, ih, cw, ch) {
  const isPortrait = ih > iw;

  const imageRatio = isPortrait ? ih / ch : iw / cw;

  const imageWidth = iw / imageRatio;
  const imageHeight = ih / imageRatio;

  if (imageHeight > ch) {
    const ratio = imageHeight / ch;

    return {
      width: imageWidth / ratio,
      height: imageHeight / ratio,
    };
  }

  if (imageWidth > cw) {
    const ratio = imageWidth / cw;

    return {
      width: imageWidth / ratio,
      height: imageHeight / ratio,
    };
  }

  return {
    width: imageWidth,
    height: imageHeight,
  };
}

export function getImageCoords(anchor = ANCHOR_CENTER, iw, ih, cw, ch) {
  const [topPosition, leftPosition] = anchor.split("_");

  let top;
  let left;

  switch (topPosition) {
    case ANCHOR_JUSTIFY_TOP:
      top = 0;
      break;
    case ANCHOR_JUSTIFY_BOTTOM:
      top = ch - ih;
      break;

    default:
      top = ch / 2 - ih / 2;
  }

  switch (leftPosition) {
    case ANCHOR_ALIGN_LEFT:
      left = 0;
      break;
    case ANCHOR_ALIGN_RIGHT:
      left = cw - iw;
      break;

    default:
      left = cw / 2 - iw / 2;
  }

  return {
    top,
    left,
  };
}

export function getImagePosition(anchor = ANCHOR_CENTER) {
  const [justifyPosition, alignPosition] = anchor.split("_");

  let align;
  let justify;

  switch (justifyPosition) {
    case ANCHOR_JUSTIFY_TOP:
      justify = "flex-start";
      break;
    case ANCHOR_JUSTIFY_BOTTOM:
      justify = "flex-end";
      break;

    default:
      justify = "center";
  }

  switch (alignPosition) {
    case ANCHOR_ALIGN_LEFT:
      align = "flex-start";
      break;
    case ANCHOR_ALIGN_RIGHT:
      align = "flex-end";
      break;

    default:
      align = "center";
  }

  return {
    align,
    justify,
  };
}

export function applyCamanFilters(id, options, cb) {
  const { Caman } = window;
  // eslint-disable-next-line new-cap
  Caman(`#${id}`, function () {
    // eslint-disable-next-line no-invalid-this
    this.reset();

    this.vibrance(options.vibrance).render(() => {
      const canvas = document.querySelector(`#${id}`);

      if (canvas) {
        const imagePreview = canvas.toDataURL("image/png");

        cb(imagePreview);
      }
    });
  });
}

export function convertImgToBase64URL(url, width, height) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.crossOrigin = "Anonymous";
    img.onload = () => {
      let canvas = document.createElement("canvas");

      const ctx = canvas.getContext("2d");

      canvas.width = width;
      canvas.height = height;

      if (img.width >= 1200 && img.height >= 630) {
        ctx.drawImage(img, 0, 0, width, height, 0, 0, width, height);

        resolve(canvas.toDataURL());

        canvas = null;
      } else {
        reject(
          new AppError({
            status: 400,
            name: "Load image",
            message: "Minimum size is 1200x630",
            data: [{ userMsg: "Minimum size is 1200x630" }],
          }),
        );
      }
    };

    img.src = url;
  });
}
