import React from "react";

export function PendingIcon({ size = 24, ...svgProps }: any) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      className="icon"
      version="1.1"
    >
      <path
        d="M511.9 183c-181.8 0-329.1 147.4-329.1 329.1s147.4 329.1 329.1 329.1c181.8 0 329.1-147.4 329.1-329.1S693.6 183 511.9 183z m0 585.2c-141.2 0-256-114.8-256-256s114.8-256 256-256 256 114.8 256 256-114.9 256-256 256z"
        fill="gray"
      />
      <path d="M548.6 365.7h-73.2v161.4l120.5 120.5 51.7-51.7-99-99z" fill="gray" />
    </svg>
  );
}
