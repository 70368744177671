import { object, ref, string } from "yup";

export const settingsCompanyValidationSchema = object({
  website: string().url("Valid URL Required"),
  name: string().min(3).required("Company name is required"),
});

export const settingsEditUsersValidationSchema = object({
  email: string().email("Valid Email Required"),
  lastName: string().min(3).required("Last Name is required"),
  firstName: string().min(3).required("First Name is required"),
});

export const settingsInviteUsersValidationSchema = object({
  email: string().email("Valid Email Required"),
  lastName: string().min(3).required("Last Name is required"),
  firstName: string().min(3).required("First Name is required"),
  password: string().min(8).required("Password is required"),
  confirmPassword: string()
    .min(8)
    .required("Confirm password is required")
    .oneOf([ref("password"), null], "Passwords must match"),
});

export function formatSettingsCompanyInitialValues(company) {
  return {
    primaryColor: "#002049",
    secondaryColor: "#025692",
    ...company,
    logo: { second: company.logo },
  };
}
