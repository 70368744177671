import { noop } from "lodash";
import { update } from "immupdate";
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useReducer } from "react";

interface RootSize {
  readonly width: number | null;
  readonly height: number | null;
}

type RealSizeType = Pick<DOMRect, "width" | "height">;

interface ActionsProps {
  readonly getSizes: () => void;
  readonly setRealSize: (size: RealSizeType) => void;
}

interface StateProps {
  readonly rootSize: DOMRect;
  readonly aspectRatio: number;
  readonly realSize: RealSizeType;
}

type DataProps = ActionsProps & StateProps;

enum ReducerActions {
  SetRootSize = "TemplateDnd/SetRootSize",
  SetRealSize = "TemplateDnd/SetRealSize",
  SetAspectRatio = "TemplateDnd/SetAspectRatio",
}

interface Action {
  readonly payload?: any;
  readonly type: ReducerActions;
}

function reducer(state: StateProps, action: Action) {
  switch (action.type) {
    case ReducerActions.SetRootSize:
      return update(state, {
        rootSize: action.payload,
        // aspectRatio: action.payload.width / state.realSize.width,
      });

    case ReducerActions.SetRealSize:
      return update(state, {
        realSize: action.payload,
        // aspectRatio: state.rootSize.width / action.payload.width,
      });

    default:
      return state;
  }
}

function createInitialState(): StateProps {
  return {
    aspectRatio: 1,
    rootSize: {} as DOMRect,
    realSize: {} as RealSizeType,
  };
}

function createInitialData(): DataProps {
  const initialState = createInitialState();

  return {
    ...initialState,

    getSizes: noop,
    setRealSize: noop,
  };
}

export const TemplateDndContext = React.createContext<DataProps>(createInitialData());

interface Props {
  readonly id: string;
  readonly children: ReactNode;
}

export function TemplateDndProvider({ id, ...props }: Props) {
  const [state, dispatch] = useReducer(reducer, createInitialState());

  const resizeHandler = useCallback(() => {
    const targetElement = document.querySelector(`#${id}`);

    if (targetElement) {
      const payload = targetElement.getBoundingClientRect();

      dispatch({
        payload: {
          ...payload,
          x: payload.x,
          y: payload.y,
          height: payload.height,
          width:
            payload.width > 0 ? payload.width : Math.min(document.body.clientWidth || 600, 600),
        },
        type: ReducerActions.SetRootSize,
      });
    }
  }, [id]);

  const setRealSizeHandler = useCallback(
    (payload: RealSizeType) => dispatch({ type: ReducerActions.SetRealSize, payload }),
    [],
  );

  const value = useMemo(
    (): DataProps => ({
      ...state,

      getSizes: resizeHandler,
      setRealSize: setRealSizeHandler,
    }),
    [state, setRealSizeHandler, resizeHandler],
  );

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [resizeHandler]);

  return <TemplateDndContext.Provider {...props} value={value} />;
}

export function useTemplateDnd() {
  return useContext<DataProps>(TemplateDndContext);
}
