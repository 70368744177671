import { uid } from "react-uid";
import React, { useState } from "react";

import { Loader } from "../ui/Loader";
import { useResource } from "../../hooks/useResource";
import { SettingsUsersListItem } from "./SettingsUsersListItem";
import { SettingsUserListHeader } from "./SettingsUserListHeader";
import { useUsersContext } from "../../api/users/UsersContext";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { SettingsUsersControlWrapper } from "./SettingsUsersControlWrapper";
import { SettingsEditUserDialogWrapper } from "./SettingsEditUserDialogWrapper";
import { SettingsInviteUserDialogWrapper } from "./SettingsInviteUserDialogWrapper";
import { useUser } from "../../context/auth/hooks";

export function SettingsUsersWrapper() {
  const { clientId } = useUser();
  const [list, setList] = useState([]);
  const [editUser, setEditUser] = useState();
  const [showInvite, setShowInvite] = useState(false);

  const { UsersApi } = useUsersContext();

  const usersListResource = useResource(() => UsersApi.getUsersList(clientId), [clientId]);

  useResourceHandler(usersListResource, {
    onSuccess: (data) => setList(data?.items || []),
  });

  return (
    <div className="d-flex flex-column mt-4">
      <SettingsUsersControlWrapper
        onInviteClick={() => setShowInvite(true)}
        onUploadComplete={() => usersListResource.refetch()}
      />

      {usersListResource.loading && <Loader />}

      <SettingsUserListHeader />

      <div className="d-flex flex-column border-top border-warning">
        {list.map((user) => (
          <SettingsUsersListItem
            user={user}
            key={uid(user)}
            onEditClick={() => setEditUser(user)}
            onChangeStatusComplete={() => usersListResource.refetch()}
          />
        ))}
      </div>

      <SettingsEditUserDialogWrapper
        user={editUser}
        show={Boolean(editUser)}
        onCancel={() => setEditUser(undefined)}
        onComplete={() => {
          setEditUser(undefined);

          usersListResource.refetch();
        }}
      />

      <SettingsInviteUserDialogWrapper
        show={showInvite}
        clientId={clientId}
        onCancel={() => setShowInvite(false)}
        onComplete={() => {
          setShowInvite(false);
          usersListResource.refetch();
        }}
      />
    </div>
  );
}
