export enum PixabayLanguage {
  Thai = "th",
  Czech = "cs",
  Dutch = "nl",
  Greek = "el",
  Danish = "da",
  French = "fr",
  Slovak = "sk",
  Korean = "ko",
  Polish = "pl",
  German = "de",
  Italian = "it",
  Chinese = "zh",
  Russian = "ru",
  English = "en",
  Spanish = "es",
  Finnish = "fi",
  Swedish = "sv",
  Turkish = "tr",
  Romanian = "ro",
  Japanese = "ja",
  Hungarian = "hu",
  Norwegian = "no",
  Bulgarian = "bg",
  Indonesian = "id",
  Portuguese = "pt",
  Vietnamese = "vi",
}

export enum PixabayImageType {
  All = "all",
  Photo = "photo",
  Vector = "vector",
  Illustration = "illustration",
}

export enum PixabayVideoType {
  All = "all",
  Film = "film",
  Animation = "vector",
}

export enum PixabayOrientation {
  All = "all",
  Vertical = "vertical",
  Horizontal = "horizontal",
}

export enum PixabayCategory {
  Food = "food",
  Music = "music",
  Nature = "nature",
  Health = "health",
  People = "people",
  Places = "places",
  Travel = "travel",
  Sports = "sports",
  Fashion = "fashion",
  Science = "science",
  Animals = "animals",
  Industry = "industry",
  Computer = "computer",
  Feelings = "feelings",
  Religion = "religion",
  Business = "business",
  Education = "education",
  Buildings = "buildings",
  Backgrounds = "backgrounds",
  Transportation = "transportation",
}

export enum PixabayColors {
  Red = "red",
  Blue = "blue",
  Pink = "pink",
  Gray = "gray",
  Green = "green",
  Lilac = "lilac",
  White = "white",
  Black = "black",
  Brown = "brown",
  Orange = "orange",
  Yellow = "yellow",
  Turquoise = "turquoise",
  Grayscale = "grayscale",
  Transparent = "transparent",
}

export enum PixabayOrder {
  Latest = "latest",
  Popular = "popular",
}

export interface PixabaySearchProps {
  readonly q?: string;
  readonly page?: number;
  readonly perPage?: number;
  readonly minWidth?: number;
  readonly minHeight?: number;
  readonly order?: PixabayOrder;
  readonly safesearch?: boolean;
  readonly lang?: PixabayLanguage;
  readonly editorsChoice?: boolean;
  readonly colors?: PixabayColors[];
  readonly category?: PixabayCategory;
}

export interface PixabaySearchImageProps extends PixabaySearchProps {
  readonly imageType?: PixabayImageType;
  readonly orientation?: PixabayOrientation;
}

export interface PixabaySearchImageProps extends PixabaySearchProps {
  readonly videoType?: PixabayVideoType;
}

export interface PixabayImageResponseHitProps {
  readonly id: number;
  readonly type: string;
  readonly tags: string;
  readonly user: string;
  readonly views: number;
  readonly likes: number;
  readonly pageURL: string;
  readonly userId: number;
  readonly comments: number;
  readonly imageURL: string;
  readonly fullHDURL: string;
  readonly imageSize: number;
  readonly downloads: number;
  readonly favorites: number;
  readonly imageWidth: number;
  readonly previewUrl: string;
  readonly imageHeight: number;
  readonly previewWidth: number;
  readonly userImageURL: string;
  readonly webformatUrl: string;
  readonly previewHeight: number;
  readonly largeImageUrl: string;
  readonly webformatWidth: number;
  readonly webformatHeight: number;
}

export interface PixabayImageResponseProps {
  readonly total: number;
  readonly totalHits: number;
  readonly hits: PixabayImageResponseHitProps[];
}
