import "../assets/styles/index.scss";

import { BrowserRouter } from "react-router-dom";
import React, { Suspense, useEffect, useMemo } from "react";

import { AppRoutes } from "../constants/Routes";
import { ApiContainer } from "./ApiContainer";
import { RootContainer } from "./RootContainer";
import { Loader } from "../components/ui/Loader";
import {
  createGoogleAnalyticsScripts,
  createGoogleTagManagerScrips,
  createAdrollAnalyticsScripts,
} from "../utils/GoogleUtils";
import AuthProvider from "../context/auth/auth-provder";
import moment from "moment";
import momentTimezone from "moment-timezone";

export function AppContainer() {
  const redirect = useMemo(() => {
    const canSetRedirectPath =
      location?.pathname?.indexOf(AppRoutes.TeamPortal) === 0 ||
      location?.pathname?.indexOf(AppRoutes.AdminPromotions) === 0;

    if (canSetRedirectPath) {
      return location.pathname;
    }
  }, []);

  useEffect(() => {
    createGoogleAnalyticsScripts();
    createGoogleTagManagerScrips();
    createAdrollAnalyticsScripts();
    moment.tz.setDefault(momentTimezone.tz.guess());
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <AuthProvider>
          <ApiContainer>
            <RootContainer redirect={redirect} />
          </ApiContainer>
        </AuthProvider>
      </BrowserRouter>
    </Suspense>
  );
}
