import "./assets/settings-users-list-header.scss";

import React from "react";

export function SettingsUserListHeader() {
  return (
    <div className="d-flex px-6 align-items-center mb-1 settings-users-list-header">
      <div className="list-header-active-container">
        <span className="text-uppercase header-title">Active</span>
      </div>

      <span className="text-uppercase header-title">Name</span>
    </div>
  );
}
