import { useMemo } from "react";

import { useApiBase } from "../ApiContext";
import { ChargebeeApi } from "./ChargebeeApi";

export function useChargebeeContext() {
  const data = useApiBase();

  const api = useMemo(() => new ChargebeeApi(data), [data]);

  return {
    ChargebeeApi: api,
  };
}
