import "./assets/reminders-icons.scss";

import React from "react";

import cx from "classnames";
import PropTypes from "prop-types";

import { CheckIcon } from "../icons/CheckIcon";
import { TimesIcon } from "../icons/TimesIcon";
import { propOr } from "ramda";
import { StoppedIcon } from "../icons/StoppedIcon";
import { ConfirmedIcon } from "../icons/ConfirmedIcon";
import { PendingIcon } from "../icons/PendingIcon";

// eslint-disable-next-line no-use-before-define
RemindersIcons.propTypes = {
  phone: PropTypes.bool.isRequired,
  email: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export function RemindersIcons({ phone, email, className, disabled = false }) {
  const smsStatus = propOr("STOPPED", "status", phone);
  const isStopped = smsStatus === "STOPPED";
  const isConfirmed = smsStatus === "CONFIRMED";
  const isPending = smsStatus === "PENDING";
  return (
    <div className={cx("reminders-icons", className)}>
      <div className="reminders-icons-item">
        {isStopped && <StoppedIcon className="reminders-icon" />}
        {isConfirmed && <ConfirmedIcon className="reminders-icon" />}
        {isPending && <PendingIcon className="reminders-icon" />}
        <span
          className="reminders-text"
          style={{ color: isStopped ? "red" : isConfirmed ? "green" : "gray" }}
        >
          {isStopped && "SMS STOPPED"}
          {isConfirmed && "SMS CONFIRMED"}
          {isPending && "SMS PENDING"}
        </span>
      </div>
      <RemindersIconsItem type="email" active={email} disabled={disabled} />
    </div>
  );
}

// eslint-disable-next-line no-use-before-define
RemindersIconsItem.propTypes = {
  type: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

function RemindersIconsItem({ type, active, disabled }) {
  return (
    <div className={cx("reminders-icons-item", { disabled: !active || disabled })}>
      {active ? <CheckIcon className="reminders-icon" /> : <TimesIcon className="reminders-icon" />}
      <span className="reminders-text">{type}</span>
    </div>
  );
}
