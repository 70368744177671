import { BaseApi } from "../BaseApi";

export class DepartmentsApi extends BaseApi {
  getDepartments() {
    return this.get("department/list").then(({ data }) => data);
  }
  createDepartment(data) {
    return this.post("department/create", { json: data }).then(({ r }) => r);
  }
  deleteDepartment(departmentid) {
    return this.delete("department/delete", { query: { departmentid } }).then(({ r }) => r);
  }
  updateDepartment(data) {
    return this.put("department/update", { json: data }).then(({ r }) => r);
  }
}
