import "./assets/sign-in-form.scss";
import React from "react";
import { SignInFormProps, SignInFormPropsPhone } from "../../api/auth/AuthDTO";
import { Tabs } from "antd";
import { SignInFormEmail } from "./SingInFormEmail";
import { SignInFormPhone } from "./SingInFormPhone";
import Cookies from "js-cookie";

const { TabPane } = Tabs;

interface Props {
  readonly onSubmit: (values: SignInFormProps) => Promise<unknown>;
  readonly submitHandlerPhone: (values: SignInFormPropsPhone) => Promise<unknown>;
}

export function SignInForm({ onSubmit, submitHandlerPhone }: Props) {
  const tab = Cookies.get("authTab");
  const defaultTab = tab ? tab : "email";

  return (
    <div className="sign-in-form">
      <Tabs
        defaultActiveKey={defaultTab}
        size="small"
        onChange={(key) => Cookies.set("authTab", key)}
      >
        <TabPane tab="EMAIL LOGIN" key="email">
          <SignInFormEmail onSubmit={onSubmit} />
        </TabPane>
        <TabPane tab="PHONE LOGIN" key="phone">
          <SignInFormPhone onSubmit={submitHandlerPhone} />
        </TabPane>
      </Tabs>
    </div>
  );
}
