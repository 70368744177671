import { toFinite } from "lodash";
import { useFormikContext } from "formik";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import React, { CSSProperties, useEffect, useMemo, useRef } from "react";

import { TemplateDndType } from "./TemplateDnd";
import { useTemplateDnd } from "./TemplateDndContext";
import { HeadlineZoneProps, TemplateHeadline } from "./TemplateHeadline";

function getStyles(left: number, top: number, isDragging: boolean): CSSProperties {
  const transform = `translate3d(${left}px, ${top}px, 0)`;
  return {
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
  };
}

interface Props {
  readonly canDrag?: boolean;
  readonly loading?: boolean;
  readonly headlineZone: HeadlineZoneProps;
  readonly width?: number;
}

export function TemplateHeadlineDnd({
  headlineZone = {} as HeadlineZoneProps,
  canDrag = true,
  loading,
  width,
}: Props) {
  const { aspectRatio, ...dndContext } = useTemplateDnd();
  const formikContext = useFormikContext<any>();
  const rootElement = useRef<HTMLDivElement | null>(null);
  const isBig = width === 1200;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      canDrag,
      item: {
        top: headlineZone.foaty * aspectRatio,
        left: headlineZone.foatx * aspectRatio,
        width: toFinite(rootElement.current?.clientWidth) * aspectRatio,
        height: toFinite(rootElement.current?.clientHeight) * aspectRatio,
      },
      type: TemplateDndType.Headline,
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (!monitor.didDrop()) {
          const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();

          if (
            typeof differenceFromInitialOffset?.x === "number" &&
            typeof differenceFromInitialOffset?.y === "number" &&
            width
          ) {
            let newLeft = headlineZone.foatx * aspectRatio + differenceFromInitialOffset?.x;
            if (newLeft > width - toFinite(rootElement.current?.clientWidth) * aspectRatio)
              newLeft = width - toFinite(rootElement.current?.clientWidth) * aspectRatio;
            if (newLeft < 0) newLeft = 0;

            let newTop = headlineZone.foaty * aspectRatio + differenceFromInitialOffset?.y;
            if (newTop > 314 - toFinite(rootElement.current?.clientHeight) * aspectRatio)
              newTop = 314 - toFinite(rootElement.current?.clientHeight) * aspectRatio;
            if (newTop < 0) newTop = 0;

            formikContext.setFieldValue("zones.headlineZone.foaty", newTop / aspectRatio);
            formikContext.setFieldValue("zones.headlineZone.foatx", newLeft / aspectRatio);
          }
        }
      },
    }),
    [
      canDrag,
      aspectRatio,
      headlineZone.foatx,
      headlineZone.foaty,
      rootElement.current?.clientWidth,
      rootElement.current?.clientHeight,
    ],
  );

  const rootStyles = useMemo(
    () => ({
      maxWidth: isBig ? headlineZone.textWidth * 2 : headlineZone.textWidth * aspectRatio,
      ...getStyles(
        isBig ? headlineZone.foatx * 2 : headlineZone.foatx * aspectRatio,
        isBig ? headlineZone.foaty * 2 : headlineZone.foaty * aspectRatio,
        isDragging,
      ),
    }),
    [
      isBig,
      aspectRatio,
      headlineZone.foatx,
      headlineZone.foaty,
      headlineZone.textWidth,
      isDragging,
    ],
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    const element = document.querySelector("#TemplateHeadline") as HTMLDivElement | null;

    if (element) {
      rootElement.current = element;
    }
  }, [headlineZone.text]);

  useEffect(() => {
    if (
      rootElement.current &&
      headlineZone.foatx + toFinite(rootElement.current?.clientWidth) > dndContext.rootSize.width
    ) {
      formikContext.setFieldValue(
        "zones.headlineZone.foatx",
        dndContext.rootSize.width - toFinite(rootElement.current?.clientWidth),
      );
    }
  }, [formikContext, headlineZone.text, headlineZone.foatx, dndContext.rootSize.width]);

  if (headlineZone.hideHeadline) {
    return null;
  }

  return (
    <div
      ref={drag}
      style={rootStyles}
      role="DraggableBox"
      className="position-absolute d-flex flex-column"
    >
      <TemplateHeadline
        canDrag={canDrag}
        headlineZone={headlineZone}
        onClick={() => {
          formikContext.setFieldValue("zones.logoZone.active", false);
          formikContext.setFieldValue("zones.headlineZone.active", true);
        }}
        loading={loading}
        isBig={isBig}
      />
    </div>
  );
}
