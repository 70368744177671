import { Input } from "antd";
import React, { useMemo } from "react";
import { useFormikContext } from "formik";

import companyLogo from "../../assets/images/company-logo.png";
import { ReactComponent as LikeIcon } from "./assets/like.svg";
import { ReactComponent as CommentIcon } from "./assets/comment.svg";
import { ReactComponent as ShareIcon } from "./assets/share.svg";
import { TemplateDnd } from "../template-dnd/TemplateDnd";

export function PromotionMessagingFacebookPost() {
  const { values } = useFormikContext();

  const hasTemplate = useMemo(() => Boolean(values.promotionItem), [values.promotionItem]);
  const link = useMemo(() => {
    if (values.promotionSocial) {
      if (values.promotionSocial.facebookOverrideUrl) {
        return values.promotionSocial.facebookOverrideUrl;
      }
      if (values.promotionSocial.targetUrl) {
        return values.promotionSocial.targetUrl;
      }
    }

    return "urlgoresher.com";
  }, [values.promotionSocial]);

  const headline = useMemo(() => {
    if (values.promotionSocial && values.promotionSocial.facebookHeadline) {
      return values.promotionSocial.facebookHeadline;
    }

    return "Headline Text Goes Here";
  }, [values.promotionSocial]);
  const description = useMemo(() => {
    if (values.promotionSocial && values.promotionSocial.facebookDescription) {
      return values.promotionSocial.facebookDescription;
    }

    return "Description Text GHoes Here";
  }, [values.promotionSocial]);

  return (
    <div className="d-flex flex-column client-promotion-edit-messaging-facebook-post">
      <div className="messaging-post-header">
        <div className="messaging-post-header-user">
          <div className="messaging-post-header-user-avatar" />
          <div className="messaging-post-header-user-info">
            <div className="messaging-post-header-user-name">Facebook User</div>
            <div className="messaging-post-header-user-description">
              January 16, 2020 • Vsbl •{/* TODO Add button with icon */}
            </div>
          </div>
        </div>
        <div className="messaging-post-header-button">
          <span className="dot" />
          <span className="dot" />
          <span className="dot" />
        </div>
      </div>

      <div className="messaging-post-template">
        {hasTemplate && (
          <TemplateDnd templateId="FacebookTemplatePreviewDndTarget" canDrag={false} />
        )}

        {!hasTemplate && (
          <>
            <div className="messaging-post-template-header">
              <div className="messaging-post-template-title">
                The “Post Message” that is selected by your end user is displayed here
              </div>
            </div>
            <div className="messaging-post-template-footer">
              <div className="messaging-post-template-description">
                Signature line can be used as a invitation to connect or make special offer.
              </div>
              <img src={companyLogo} alt="brand" />
            </div>
          </>
        )}
      </div>

      <div className="messaging-post-info">
        <span className="messaging-post-info-link">{link}</span>
        <div className="messaging-post-info-title">{headline}</div>
        <div className="messaging-post-info-description">{description}</div>
      </div>

      {/* TODO Replace with icons */}
      <div className="messaging-post-actions">
        <div className="messaging-post-actions-item">
          <LikeIcon />
          <span>Like</span>
        </div>
        <div className="messaging-post-actions-item">
          <CommentIcon />
          <span>Comment</span>
        </div>
        <div className="messaging-post-actions-item">
          <ShareIcon />
          <span>Share</span>
        </div>
      </div>

      <div className="messaging-post-comment">
        <div className="messaging-post-comment-avatar" />
        {/* TODO Add buttons with icons */}
        <Input disabled={true} placeholder="Write a comment..." style={{ pointerEvents: "none" }} />
      </div>
    </div>
  );
}
