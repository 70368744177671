import React from "react";

interface Props {
  readonly size?: number;
  readonly className?: string;
}

export function ArrowTopLeftIcon({ size = 24, className }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" className={className}>
      <path
        fill="currentColor"
        d="M9.12,11.94V16.89H5.69V5.69H16.89V9.12H11.94L18.31,15.5L15.5,18.31L9.12,11.94Z"
      />
    </svg>
  );
}
