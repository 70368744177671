import { noop } from "lodash";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import { Dialog } from "../ui/Dialog";
import { Loader } from "../ui/Loader";
import { PromotionChooseSubmit } from "./PromotionChooseSubmit";
import { PromotionShareComplete } from "./PromotionShareComplete";
import { TemplateDnd } from "../template-dnd/TemplateDnd";
import { useSettings } from "../../context/auth/hooks";

export function PromotionPreviewDialog({ show, loading, promotion, onCancel }) {
  const [state, setState] = useState("choose");
  const { settings } = useSettings();

  useEffect(() => {
    if (show) {
      setState("choose");
    }
  }, [show]);

  return (
    <Dialog
      show={show}
      footer={null}
      onCancel={onCancel}
      destroyOnClose={true}
      title="Preview Template"
      className="d-flex flex-column"
    >
      <div className="d-flex flex-column p-5">
        {loading && <Loader />}

        {promotion && (
          <Formik initialValues={promotion} onSubmit={noop}>
            {({ setFieldValue, values }) => (
              <>
                {state === "choose" && (
                  <PromotionChooseSubmit
                    settings={settings}
                    promotion={promotion}
                    onSelect={(_, item) => {
                      setFieldValue("zones.headlineZone.text", item.value);

                      setState("share");
                    }}
                  />
                )}

                {state === "share" && (
                  <PromotionShareComplete promotion={values} settings={settings}>
                    <TemplateDnd canDrag={false} className="align-self-center" />
                  </PromotionShareComplete>
                )}
              </>
            )}
          </Formik>
        )}
      </div>
    </Dialog>
  );
}
