import React from "react";
import PropTypes from "prop-types";
import { Button, Upload } from "antd";
import { LinkButton } from "../ui/LinkButton";
import { BASE_HOST } from "../../constants/ApiConstants";

SettingsUsersControl.propTypes = {
  uploading: PropTypes.bool,
  onUploadClick: PropTypes.func,
  onInviteClick: PropTypes.func,
};

export function SettingsUsersControl({ uploading, onUploadClick, onInviteClick }) {
  return (
    <div className="d-flex mb-8 px-6 flex-column flex-sm-row">
      <Button type="primary" className="mr-0 mr-sm-2 mr-md-3 mb-3 mb-sm-0" onClick={onInviteClick}>
        Invite administrator
      </Button>
      <Upload
        accept="text/csv"
        showUploadList={false}
        customRequest={onUploadClick}
        className="d-flex flex-column mr-0 mr-sm-2 mr-md-3 mb-3 mb-sm-0"
      >
        <Button loading={uploading} type="primary" className="w-100">
          upload administrator csv
        </Button>
      </Upload>
      <LinkButton className="align-self-center">
        <a
          target="_blank"
          className="text-primary"
          rel="noopener noreferrer"
          href={`${BASE_HOST}/csvtemplates/ImportUsers.csv`}
        >
          Download a CSV template
        </a>
      </LinkButton>
    </div>
  );
}
