import { Button } from "antd";
import React, { useCallback, useState } from "react";

import { useNotification } from "../../hooks/useNotification";
import { useRemindersContext } from "../../api/reminders/RemindersContext";
import { getErrorMessage } from "../../utils/utils";

interface Props {
  readonly item: any;
  readonly archive?: boolean;
  readonly setList: (value: any) => void;
  readonly setArchived: (value: any) => void;
}

export function ArchiveReminderButtonWrapper({ item, archive, setList, setArchived }: Props) {
  const [loading, setLoading] = useState(false);

  const { showError } = useNotification();

  const { RemindersApi } = useRemindersContext();

  const handleDelete = useCallback(
    (x: number) => {
      setLoading(true);
      RemindersApi.changeStatusReminder(x, { archive: !archive })
        .then(() => {
          setLoading(false);
          if (archive) {
            setList((prevList) => [item, ...prevList]);
            setArchived((prevList) => prevList.filter((archiveItem) => archiveItem.id !== item.id));
          } else {
            setList((prevList) => prevList.filter((archiveItem) => archiveItem.id !== item.id));
            setArchived((prevList) => [item, ...prevList]);
          }
        })
        .catch((e) => {
          setLoading(false);
          showError(getErrorMessage(e));
        });
    },
    [setList, showError, archive, setArchived, RemindersApi, item],
  );

  return (
    <Button
      size="small"
      type="primary"
      loading={loading}
      disabled={loading}
      onClick={() => handleDelete(item.id)}
    >
      {archive ? "Reactivate" : "Archive"}
    </Button>
  );
}
