import React from "react";

import { Alert } from "antd";

import { VSBLCol, VSBLDivider } from "./grid";

export function VSBLAlert(props) {
  if (!props.text) return null;

  return (
    <VSBLCol span={props.span}>
      <Alert message={props.text} type={props.type || "info"} showIcon={showIcon(props)} />
      <VSBLDivider hide={props.hideDivider} />
    </VSBLCol>
  );
}

function showIcon(props) {
  // eslint-disable-next-line no-prototype-builtins
  if (props.hasOwnProperty("showIcon")) {
    return props.showIcon;
  }

  return true;
}
