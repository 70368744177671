import "./assets/field.scss";
import "./assets/switch-field.scss";

import React from "react";
import cx from "classnames";
import { useField } from "formik";
import { Switch, SwitchProps } from "formik-antd";

interface Props extends SwitchProps {
  readonly text?: string;
  readonly textClassName?: string;
  readonly switchClassName?: string;
}

export function SwitchField({ text, textClassName, className, switchClassName, ...props }: Props) {
  const [field] = useField(props.name);

  return (
    <div className={cx("switch-field", className)}>
      <Switch
        {...field}
        onChange={(state, event) => field.onChange(event)}
        {...props}
        className={switchClassName}
      />
      <label htmlFor={props.name} className={cx("switch-title-text", textClassName)}>
        {text}
      </label>
    </div>
  );
}
