import qs from "qs";
import { toFinite } from "lodash";
import { useParams } from "react-router";
import React, { useCallback, useMemo, useState } from "react";
import { pathOr, prop, propOr } from "ramda";

import { AppRoutes } from "../../constants/Routes";
import { sleep } from "../../helpers/SystemUtils";
import { Loader } from "../../components/ui/Loader";
import { useResource } from "../../hooks/useResource";
import { replaceParams } from "../../helpers/UrlUtils";
import { useNotification } from "../../hooks/useNotification";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { ContainerLoader } from "../../components/ui/ContainerLoader";
import { usePromotionContext } from "../../api/promotions/PromotionContext";
import { PromotionChooseSubmit } from "../../components/team-portal/PromotionChooseSubmit";
import {
  formatEditInitialPromotion,
  isValidPromotionTemplate,
} from "../../helpers/PromotionsHelpers";
import { Button } from "antd";
import { useSettings, useUser } from "../../context/auth/hooks";
import useRouter from "../../hooks/useRouter";
import { TeamAppLayout } from "../../components/app/TeamAppLayout";
import domtoimage from "dom-to-image";

export function PromotionChooseContainer() {
  const { user } = useUser();
  const params = useParams<any>();
  const { settings } = useSettings();

  const { showError } = useNotification();

  const router = useRouter();

  const [item, setItem] = useState(null);
  const [promotion, setPromotion] = useState();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [backLoad, setBackLoad] = useState(true);
  const [logoLoad, setLogoLoad] = useState(true);
  const [overLoad, setOverLoad] = useState(true);

  const promotionId = useMemo(() => toFinite(params.id), [params]);

  const { PromotionApi } = usePromotionContext();

  const promotionResource = useResource(
    () =>
      promotionId > 0
        ? PromotionApi.getPromotion(promotionId, { isCompanyUser: false })
        : undefined,
    [promotionId],
  );

  useResourceHandler(promotionResource, {
    onSuccess: async (data) => {
      try {
        setLoading(true);

        if (data) {
          await sleep();

          const isValidTemplate = await isValidPromotionTemplate(data);

          if (isValidTemplate) {
            const res = await formatEditInitialPromotion(data);

            setPromotion(res as any);
          } else {
            throw new Error("Wrong template");
          }
        }
        setLoading(false);
      } catch (e) {
        showError(e as any);

        setLoading(false);
      }
    },
    onFail: async () => setIsError(true),
  });

  const submitHandler = useCallback(
    (values) => {
      PromotionApi.submitAnswer(values)
        .then((response) => {
          const url = [
            replaceParams(AppRoutes.TeamPortalShare, { id: promotionId }),
            qs.stringify({
              image: response.imageUrl,
              submitId: response.id,
              socialTracking: response.socialTracking,
            }),
          ]
            .filter(Boolean)
            .join("?");

          router.push(url);

          setLoading(false);
        })
        .catch(showError);
    },
    [promotionId, setLoading, PromotionApi, showError, router],
  );

  if (promotionResource.loading) {
    return <Loader />;
  }
  const dynamicZoneLayerList = pathOr(
    [],
    ["promotionItem", "dynamicZones", 0, "dynamicZoneLayerList"],
    promotion,
  ) as any;
  const headlineZone = dynamicZoneLayerList.find(({ zindex }) => zindex === 4);
  const logoZone = dynamicZoneLayerList.find(({ zindex }) => zindex === 3);
  const overlayZone = dynamicZoneLayerList.find(({ zindex }) => zindex === 2);
  const backgroundZone = dynamicZoneLayerList.find(({ zindex }) => zindex === 1);
  const hideLogo = prop("hideLogo", promotion);
  const hideHeadline = prop("hideHeadline", promotion);

  const headline = (headlineData) => {
    if (headlineZone) {
      const headlineAligns = propOr("MIDDLE_RIGHT", "anchorImage", headlineZone).split("_");
      const headlineTopAlign = headlineAligns[0];
      const headlineTop =
        headlineTopAlign === "BOTTOM"
          ? "flex-end"
          : headlineTopAlign === "MIDDLE"
          ? "center"
          : "flex-start";
      const headlineLeftAlign = headlineAligns[1];
      const headlineLeft =
        headlineLeftAlign === "LEFT" ? "left" : headlineLeftAlign === "CENTER" ? "center" : "right";
      const text = propOr(headlineZone.text, "value", headlineData);
      return (
        <div
          className="d-flex flex-column"
          style={{
            maxWidth: headlineZone.textWidth * 2,
            minHeight: headlineZone.size * 2,
            width: headlineZone.textWidth * 2,
            height: headlineZone.size * 2,
            justifyContent: headlineTop,
            zIndex: 1,
            position: "absolute",
            top: headlineZone.foaty * 2,
            left: headlineZone.foatx * 2,
          }}
          id="headline-preview"
        >
          <p
            style={{
              fontSize: headlineZone.size * 2,
              textAlign: headlineLeft,
              fontFamily: headlineZone.font,
              lineHeight: 1,
              marginBottom: 0,
              color: `#${headlineZone.color}`,
            }}
          >
            {text}
          </p>
        </div>
      );
    } else return <></>;
  };

  const logo = () => {
    if (logoZone) {
      const logoAligns = logoZone.anchorImage.split("_");
      const logoTopAlign =
        logoAligns[0] === "BOTTOM"
          ? "flex-end"
          : logoAligns[0] === "MIDDLE"
          ? "center"
          : "flex-start";
      const logoLeftAlign =
        logoAligns[1] === "LEFT"
          ? "flex-start"
          : logoAligns[1] === "CENTER"
          ? "center"
          : "flex-end";
      return (
        <div
          className="d-flex"
          style={{
            maxWidth: logoZone.width * 2,
            minHeight: logoZone.height * 2,
            width: logoZone.width * 2,
            height: logoZone.height * 2,
            justifyContent: logoLeftAlign,
            alignItems: logoTopAlign,
            zIndex: 1,
            position: "absolute",
            top: logoZone.foaty * 2,
            left: logoZone.foatx * 2,
          }}
        >
          <img
            src={logoZone.image}
            alt="logo-preview"
            style={{
              maxWidth: logoZone.width * 2,
              maxHeight: logoZone.height * 2,
              objectFit: "contain",
            }}
            onLoad={() => setLogoLoad(false)}
          />
        </div>
      );
    } else return <></>;
  };

  const onSelectHeadline = (item: any) => {
    setLoading(true);
    setItem(item);
    setTimeout(() => {
      const finalContainer = document.getElementById("PromotionPreviewCompleted") as any;
      domtoimage.toPng(finalContainer).then(() => {
        domtoimage.toPng(finalContainer).then(() => {
          domtoimage.toPng(finalContainer).then((image) => {
            const data = {
              image,
              "promotion-id": prop("id", promotion),
              values: {
                headline: [item],
                last_name: [{ value: user.lastName }],
                email_address: [{ value: user.email }],
                first_name: [{ value: user.firstName }],
              },
            };
            submitHandler(data);
          });
        });
      });
    }, 3000);
  };

  const logoLoading = hideLogo ? false : logoLoad;

  return (
    <TeamAppLayout title="Team Portal" contentClassName="choose-root">
      {isError && !loading && (
        <div className="d-flex flex-column container">
          <Button
            type="primary"
            className="border w-100"
            onClick={() => router.push(AppRoutes.TeamPortalPromotions)}
            style={{
              borderColor: settings?.primaryColor || "#002049",
              backgroundColor: settings?.primaryColor || "#002049",
            }}
          >
            Ok, Take me to the Team Portal
          </Button>
        </div>
      )}
      {!loading && (
        <div className="d-flex flex-column container">
          <PromotionChooseSubmit
            onSelect={onSelectHeadline}
            promotion={promotion}
            loading={loading || backLoad || logoLoading || overLoad}
            settings={settings}
            hideHeadline={hideHeadline}
          />
        </div>
      )}
      {dynamicZoneLayerList.length > 0 && (
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              width: 1200,
              height: 630,
              background: "white",
              zIndex: 2,
            }}
          />
          <div
            style={{ position: "absolute", width: 1200, height: 630, zIndex: 0 }}
            id="PromotionPreviewCompleted"
          >
            {!hideHeadline && headline(item)}
            {!hideLogo && logo()}
            <img
              src={backgroundZone.imagePreview}
              alt="background-preview"
              style={{
                minHeight: 630,
                minWidth: 1200,
                width: 1200,
                height: 630,
                maxHeight: 630,
                maxWidth: 1200,
                position: "absolute",
              }}
              onLoad={() => setBackLoad(false)}
            />
            <img
              style={{
                position: "absolute",
                zIndex: 1,
                width: overlayZone.width * 2,
                height: overlayZone.height * 2,
                bottom: 0,
              }}
              src={overlayZone.image}
              alt="overlay-preview"
              onLoad={() => setOverLoad(false)}
            />
          </div>
        </div>
      )}
      <ContainerLoader show={promotionResource.loading || loading} />
    </TeamAppLayout>
  );
}
