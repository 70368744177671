import "./assets/promotions-list-item.scss";

import { Button } from "antd";
import { DateTime } from "luxon";
import React, { useMemo } from "react";

import { formatMillisecondsToString } from "../../helpers/FormatUtils";
import { CopyPromotionButtonWrapper } from "./CopyPromotionButtonWrapper";
import { PromotionsStatusSwitchWrapper } from "./PromotionsStatusSwitchWrapper";
import { PromotionSocialItem, SocialType } from "./PromotionSocialItem";
import { replaceParams } from "../../helpers/UrlUtils";
import { AppRoutes } from "../../constants/Routes";
import { Link } from "react-router-dom";

interface Props {
  readonly item: any;
  readonly onComplete: () => void;
  readonly onDeleteClick: () => void;
  readonly onPreviewClick: () => void;
}

export function PromotionsListItem({ item, onComplete, onDeleteClick, onPreviewClick }: Props) {
  const date = useMemo(() => {
    const startDate = formatMillisecondsToString(item.startDate, DateTime.DATE_SHORT);
    const endDate = formatMillisecondsToString(item.endDate, DateTime.DATE_SHORT);

    return [startDate, endDate].filter(Boolean).join(" - ");
  }, [item]);

  const editLink = replaceParams(AppRoutes.AdminPromotionsItem, { id: item.id });

  return (
    <div className="font-family-museo">
      <div
        className="d-flex flex-column flex-md-row align-items-center
        align-items-md-start pl-3 pr-3 pt-5 pb-5 border-bottom border-top"
      >
        <PromotionsStatusSwitchWrapper
          promotion={item}
          onComplete={onComplete}
          className="mt-0 mt-md-1 mb-md-0 mb-2"
        />

        <div
          className="d-flex mw-100 flex-column align-items-center
          align-items-md-start pl-3 pr-3 mb-3 mb-md-0 flex-grow-1 overflow-hidden"
        >
          <div className="promotion-list-item-name">{item.name}</div>
          <div className="promotion-list-item-date mb-2">{date}</div>

          <div className="d-flex">
            <PromotionSocialItem
              className="mr-2"
              count={item.linkedinShares}
              socialType={SocialType.LinkedIn}
              linkedInEnabled={item.linkedinEnabled}
            />
            <PromotionSocialItem
              className="mr-2"
              count={item.twitterShares}
              socialType={SocialType.Twitter}
              twitterEnabled={item.twitterEnabled}
            />
            <PromotionSocialItem
              className="mr-2"
              count={item.facebookShares}
              socialType={SocialType.Facebook}
              facebookEnabled={item.facebookEnabled}
            />
            <PromotionSocialItem
              count={item.pinterestShares}
              socialType={SocialType.Pinterest}
              pinterestEnabled={item.pinterestEnabled}
            />
          </div>
        </div>

        <div className="d-flex align-items-center flex-grow-0 flex-shrink-0">
          <Button className="mr-1" type="primary" size="small">
            <Link to={editLink}>Edit</Link>
          </Button>
          <Button className="mr-1" type="primary" size="small" onClick={onPreviewClick}>
            Preview
          </Button>
          <CopyPromotionButtonWrapper id={item.id} />
          <Button type="primary" size="small" onClick={onDeleteClick}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
}
