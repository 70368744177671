import { isArray } from "lodash";
import PropTypes from "prop-types";
import { deepUpdate } from "immupdate";
import React, { useEffect, useState } from "react";

import { PromotionChooseButton } from "./PromotionChooseButton";
import { AVAILABLE_TYPES } from "../../constants/PromotionConstants";

PromotionChooseSubmit.propTypes = {
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  settings: PropTypes.object,
  promotion: PropTypes.object,
};

export function PromotionChooseSubmit({ loading, promotion = {}, settings = {}, onSelect }) {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (promotion.hideHeadline && list.length > 0) {
      const item = list[0];
      const newPromotion = deepUpdate(promotion)
        .at("zones")
        .withDefault({})
        .at("headlineZone")
        .withDefault({})
        .at("text")
        .set(item.value);

      onSelect(newPromotion, item);
    }
  }, [promotion, list]);

  useEffect(() => {
    if (promotion && isArray(promotion.promotionSteps)) {
      promotion.promotionSteps.forEach((step) => {
        if (step && isArray(step.fields)) {
          step.fields.forEach((field) => {
            if (AVAILABLE_TYPES.includes(field.uiType) && isArray(field.values)) {
              setList(field.values);
            }
          });
        }
      });
    }
  }, [promotion]);

  return (
    <>
      <h1
        className="text-center"
        style={{
          color: settings?.primaryColor || "#025692",
        }}
      >
        {promotion.name}
      </h1>
      <span
        className="text-center font-weight-light"
        style={{
          color: settings?.primaryColor || "#025692",
        }}
      >
        {promotion.description}
      </span>
      <hr className="w-100 my-6" />

      <h3
        className="text-center mb-3"
        style={{
          color: settings?.primaryColor || "#025692",
        }}
      >
        Choose an option below
      </h3>

      {list.map((item) => (
        <PromotionChooseButton
          key={item.id}
          style={{
            borderColor: settings?.primaryColor || "#025692",
            backgroundColor: settings?.primaryColor || "#025692",
          }}
          disabled={loading}
          onClick={() => {
            const newPromotion = deepUpdate(promotion)
              .at("zones")
              .withDefault({})
              .at("headlineZone")
              .withDefault({})
              .at("text")
              .set(item.value);

            onSelect(newPromotion, item);
          }}
        >
          {item.value}
        </PromotionChooseButton>
      ))}
    </>
  );
}
