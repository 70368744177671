import { Button } from "antd";
import { update } from "immupdate";
import React, { useCallback, useState } from "react";

import { useNotification } from "../../hooks/useNotification";
import { usePromotionContext } from "../../api/promotions/PromotionContext";
import { useUser } from "../../context/auth/hooks";
import { useNavigate } from "react-router";

interface Props {
  readonly id: number;
}

export function CopyPromotionButtonWrapper({ id }: Props) {
  const { clientId } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { showError } = useNotification();

  const { PromotionApi } = usePromotionContext();

  const handleCopy = useCallback(
    (id) => {
      setLoading(true);
      PromotionApi.getPromotion(id)
        .then((response) => {
          const newPromotion = update(response, {
            status: "NOT_APPROVED",
            id: 0,
            clientId,
            name: `${response.name}_copy`,
          });
          PromotionApi.createPromotion(newPromotion)
            .then(() => {
              navigate(0);
              setLoading(false);
            })
            .catch((error) => {
              showError(error);
              setLoading(false);
            });
        })
        .catch((error) => {
          showError(error);
          setLoading(false);
        });
    },
    [showError, navigate, PromotionApi, clientId],
  );

  return (
    <Button
      size="small"
      type="primary"
      className="mr-1"
      loading={loading}
      disabled={loading}
      onClick={() => handleCopy(id)}
    >
      Copy
    </Button>
  );
}
