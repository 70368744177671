import React, { useCallback, useState } from "react";
import "./assets/team-members-table-item.scss";
import { propOr } from "ramda";

import cx from "classnames";
import { Button, notification } from "antd";

import { TeamMembersSwitch } from "./TeamMembersSwitch";
import { capitalizeFirstLetter, getErrorMessage } from "../../../utils/utils";
import { TeamMembersModalEdit } from "./TeamMembersModalEdit";
import { useTeamContext } from "../../../api/team/TeamContext";
import { RemindersIcons } from "../../../components/ui/RemindersIcons";

interface Props {
  readonly item: any;
  readonly setList: (value: any) => void;
}

export function TeamMembersTableItem({ item, setList }: Props) {
  const { TeamApi } = useTeamContext();

  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const handleChangeStatus = useCallback(
    (checked) => {
      setStatusLoading(true);
      const data = {
        id: item.id,
        active: checked,
        phone: item.phone,
        lastName: item.lastName,
        firstName: item.firstName,
      };
      TeamApi.updateTeamMember(data)
        .then(() => {
          setStatusLoading(false);
          setList((prevList) => {
            const foundItem = prevList.filter((listItem) => listItem.id === item.id);
            const others = prevList.filter((listItem) => listItem.id !== item.id);
            return [{ ...foundItem[0], active: checked }, ...others];
          });
          notification.open({
            type: "success",
            message: "Change status",
            description: "Status changed successfully",
          });
        })
        .catch((e) => {
          setStatusLoading(false);
          notification.open({
            type: "error",
            message: "Upload status error",
            description: getErrorMessage(e),
            duration: null,
          });
        });
    },
    [setList, TeamApi, item],
  );

  const handleResendInvite = useCallback(() => {
    setLoading(true);
    TeamApi.resendActivation({ email: item.email })
      .then(() => {
        setLoading(false);
        notification.open({
          type: "success",
          message: "Resend activation",
          description: "Invitation has been resent successfully",
        });
      })
      .catch((e) => {
        setLoading(false);
        notification.open({
          type: "error",
          message: "Resend activation error",
          description: getErrorMessage(e),
          duration: null,
        });
      });
  }, [TeamApi, item]);

  const departmentsName = item.departments.reduce((a, b) => a + "," + b.departmentName, "");

  const firstName = propOr("", "firstName", item);
  const lastName = propOr("", "lastName", item);

  return (
    <>
      <div className="d-flex flex-column align-items-center d-md-table-row p-3 p-md-0 border-bottom border-top">
        <div className="d-table-cell p-0 p-md-4 mb-3 mb-md-0 w-auto align-middle">
          <TeamMembersSwitch
            pending={!item.verified}
            disabled={!item.verified}
            checked={item.active}
            onChange={handleChangeStatus}
            loading={statusLoading}
          />
        </div>

        <div className="team-members-table-name d-table-cell mb-3 mb-md-0 pt-md-4 pb-md-4 align-middle">
          <h2 className={cx("mb-0 text-primary font-family-museo")}>
            {capitalizeFirstLetter(firstName)} {capitalizeFirstLetter(lastName)}
          </h2>
          {!item.verified && <span className="team-members-pending-text">Invitation Pending</span>}
        </div>

        <div
          className="d-flex flex-column align-items-center d-md-table-cell
          mb-3 mb-md-0 p-0 pt-md-4 pb-md-4 w-25 align-middle"
        >
          <RemindersIcons email={!!item.email} phone={!!item.phone} />
        </div>

        <div
          className="d-flex flex-column align-items-center d-md-table-cell
          mb-3 mb-md-0 p-0 pt-md-4 pb-md-4 w-100 align-middle member-departments"
        >
          {departmentsName.substr(1)}
        </div>

        <div className="d-table-cell p-0 pt-md-4 pb-md-4 pr-md-4 w-auto align-middle">
          <div className="d-flex justify-content-end">
            {!item.verified && (
              <Button
                size="small"
                type="primary"
                className="mr-2"
                onClick={() => handleResendInvite()}
                loading={loading}
              >
                resend invite
              </Button>
            )}
            <Button
              size="small"
              type="primary"
              onClick={useCallback(() => setVisibleModal(true), [])}
            >
              edit
            </Button>
          </div>
        </div>
      </div>

      <TeamMembersModalEdit
        item={item}
        visible={visibleModal}
        hideModal={() => setVisibleModal(false)}
        setList={setList}
      />
    </>
  );
}
