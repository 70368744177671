import "./assets/select-template.scss";

import React from "react";
import { Button } from "antd";

export function SelectTemplate({ onSelectClick, currentTemplateId, isEdit, list = [] }) {
  return (
    <div className="d-flex flex-column flex-md-row justify-content-between select-template">
      {list.map((item) => {
        const currentTemplate = item.id === currentTemplateId;

        return (
          <div key={item.id} className="d-flex flex-column template">
            <img
              onClick={() => onSelectClick(item)}
              alt="template"
              src={item.url}
              className="w-100 mb-2 pointer"
            />
            <span className="template-title-text">{item.name}</span>
            <span className="template-caption mb-3">{item.description}</span>
            <div className="mb-6">
              <Button disabled={currentTemplate} type="primary" onClick={() => onSelectClick(item)}>
                {currentTemplate ? "Active" : isEdit ? "Choose" : "Select"}
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
