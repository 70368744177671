import { update } from "immupdate";
import { isBoolean } from "lodash";
import { useCallback, useEffect, useState } from "react";

export enum OrientationType {
  Portrait = "portrait",
  Landscape = "landscape",
  Undetermined = "undetermined",
}

export enum DeviceType {
  Mobile = "mobile",
  Tablet = "tablet",
  Desktop = "desktop",
  Undetermined = "undetermined",
  LargeDesktop = "large-desktop",
}

export interface MediaQueryProps {
  readonly isMobile: boolean;
  readonly isMobilePortrait: boolean;
  readonly isMobileLandscape: boolean;

  readonly isTablet: boolean;

  readonly isDesktop: boolean;
  readonly isLargeDesktop: boolean;

  readonly device: DeviceType;
  readonly orientation: OrientationType;
}

export function detectMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export function detectAppleMobile() {
  const toMatch = [/iPhone/i, /iPad/i, /iPod/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export function useMediaQuery(): MediaQueryProps {
  const [state, setState] = useState<MediaQueryProps>({
    isDesktop: false,
    isLargeDesktop: false,

    isTablet: false,

    isMobile: false,
    isMobilePortrait: false,
    isMobileLandscape: false,

    device: DeviceType.Undetermined,
    orientation: OrientationType.Undetermined,
  });

  //====================== Orientation ==========================
  const changeOrientationHandler = useCallback((mql: MediaQueryList) => {
    setState((x) =>
      update(x, {
        orientation: isBoolean(mql?.matches)
          ? mql.matches
            ? OrientationType.Portrait
            : OrientationType.Landscape
          : OrientationType.Undetermined,
      }),
    );
  }, []);

  useEffect(() => {
    const mql = window.matchMedia("(orientation: portrait)");

    if (mql) {
      changeOrientationHandler(mql);
    }

    if (mql?.addListener) {
      // @ts-ignore
      mql.addListener(changeOrientationHandler);
    }

    return () => {
      if (mql?.removeListener) {
        // @ts-ignore
        mql.removeListener(changeOrientationHandler);
      }
    };
  }, [changeOrientationHandler]);
  //====================== Orientation ==========================

  //========================= Device ============================
  const changeMobileDeviceHandler = useCallback((mql: MediaQueryList) => {
    if (mql.matches) {
      setState((x) =>
        update(x, {
          device: DeviceType.Mobile,

          isTablet: false,
          isDesktop: false,
          isLargeDesktop: false,

          isMobile: true,
          isMobilePortrait: x.orientation === OrientationType.Portrait,
          isMobileLandscape: x.orientation === OrientationType.Landscape,
        }),
      );
    }
  }, []);
  const changeTabletDeviceHandler = useCallback((mql: MediaQueryList) => {
    if (mql.matches) {
      setState((x) =>
        update(x, {
          isTablet: true,

          isDesktop: false,
          isLargeDesktop: false,

          isMobile: false,
          isMobilePortrait: false,
          isMobileLandscape: false,

          device: DeviceType.Tablet,
        }),
      );
    }
  }, []);
  const changeDesktopDeviceHandler = useCallback((mql: MediaQueryList) => {
    if (mql.matches) {
      setState((x) =>
        update(x, {
          isDesktop: true,

          isTablet: false,
          isLargeDesktop: false,

          isMobile: false,
          isMobilePortrait: false,
          isMobileLandscape: false,

          device: DeviceType.Desktop,
        }),
      );
    }
  }, []);
  const changeLargeDesktopDeviceHandler = useCallback((mql: MediaQueryList) => {
    if (mql.matches) {
      setState((x) =>
        update(x, {
          isLargeDesktop: true,

          isTablet: false,
          isDesktop: false,

          isMobile: false,
          isMobilePortrait: false,
          isMobileLandscape: false,

          device: DeviceType.LargeDesktop,
        }),
      );
    }
  }, []);

  useEffect(() => {
    const mobileMql = window.matchMedia(
      "(min-width: 320px) and (max-width: 480px), (min-width: 481px) and (max-width: 767px)",
    );

    if (mobileMql) {
      changeMobileDeviceHandler(mobileMql);
    }

    if (mobileMql?.addListener) {
      // @ts-ignore
      mobileMql.addListener(changeMobileDeviceHandler);
    }

    return () => {
      if (mobileMql?.removeListener) {
        // @ts-ignore
        mobileMql.removeListener(changeMobileDeviceHandler);
      }
    };
  }, [changeMobileDeviceHandler]);

  useEffect(() => {
    const tabletMql = window.matchMedia("(min-width: 768px) and (max-width: 1024px)");

    if (tabletMql) {
      changeTabletDeviceHandler(tabletMql);
    }

    if (tabletMql?.addListener) {
      // @ts-ignore
      tabletMql.addListener(changeTabletDeviceHandler);
    }

    return () => {
      if (tabletMql?.removeListener) {
        // @ts-ignore
        tabletMql.removeListener(changeTabletDeviceHandler);
      }
    };
  }, [changeTabletDeviceHandler]);

  useEffect(() => {
    const desktopMql = window.matchMedia("(min-width: 1025px) and (max-width: 1200px)");

    if (desktopMql) {
      changeDesktopDeviceHandler(desktopMql);
    }

    if (desktopMql?.addListener) {
      // @ts-ignore
      desktopMql.addListener(changeDesktopDeviceHandler);
    }

    return () => {
      if (desktopMql?.removeListener) {
        // @ts-ignore
        desktopMql.removeListener(changeDesktopDeviceHandler);
      }
    };
  }, [changeDesktopDeviceHandler]);

  useEffect(() => {
    const largeDesktopMql = window.matchMedia("(min-width: 1201px)");

    if (largeDesktopMql) {
      changeLargeDesktopDeviceHandler(largeDesktopMql);
    }

    if (largeDesktopMql?.addListener) {
      // @ts-ignore
      largeDesktopMql.addListener(changeLargeDesktopDeviceHandler);
    }

    return () => {
      if (largeDesktopMql?.removeListener) {
        // @ts-ignore
        largeDesktopMql.removeListener(changeLargeDesktopDeviceHandler);
      }
    };
  }, [changeLargeDesktopDeviceHandler]);
  //========================= Device ============================

  return state;
}
