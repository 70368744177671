import React, { ReactNode } from "react";

import { ApiProvider } from "../api/ApiContext";
import { useAuthContext } from "../api/auth/AuthContext";
import { useToken, useUser } from "../context/auth/hooks";

interface Props {
  readonly children: ReactNode;
}

export function ApiContainer({ children }: Props) {
  const { isAdministrator } = useUser();
  const { token } = useToken();
  const { logout } = useAuthContext();

  return (
    <ApiProvider data={{ token, administrator: isAdministrator, logout }}>{children}</ApiProvider>
  );
}
