import "./assets/reminders-list-container.scss";

import cx from "classnames";
import { Button } from "antd";
import { Link } from "react-router-dom";
import React, { useCallback, useState } from "react";

import { AppRoutes } from "../../constants/Routes";
import { useResource } from "../../hooks/useResource";
import { replaceParams } from "../../helpers/UrlUtils";
import { ReminderProps } from "../../api/reminders/RemindersDTO";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { ContainerLoader } from "../../components/ui/ContainerLoader";
import { RemindersItem } from "../../components/reminders/RemindersItem";
import { useRemindersContext } from "../../api/reminders/RemindersContext";
import { RemindersHeader } from "../../components/reminders/RemindersHeader";
import { SendTestModal } from "../../modules/dashboard/reminders/table/SendTestModal";
import { useUser } from "../../context/auth/hooks";
import { ClientAppLayout } from "../../components/app/ClientAppLayout";

export function RemindersListContainer() {
  const { clientId } = useUser();
  const [list, setList] = useState([] as any);
  const [archived, setArchived] = useState([] as any);
  const [testReminder, setTestReminder] = useState<ReminderProps>();

  const { RemindersApi } = useRemindersContext();

  const listResource = useResource(() => RemindersApi.getRemindersList(clientId), [clientId]);
  const archivedListResource = useResource(
    () => RemindersApi.getRemindersList(clientId, { deleted: true }),
    [clientId],
  );

  useResourceHandler(listResource, {
    onSuccess: ({ items = [] }) => setList(items),
  });

  useResourceHandler(archivedListResource, {
    onSuccess: ({ items = [] }) => setArchived(items),
  });

  const noReminder = Boolean(
    !listResource.loading &&
      !archivedListResource.loading &&
      list.length === 0 &&
      archived.length === 0,
  );

  const handleTestClick = useCallback((item: any) => {
    if (!item.sms && !item.email) {
      alert("Please select email or sms");
    } else {
      setTestReminder(item);
    }
  }, []);

  return (
    <ClientAppLayout>
      <RemindersHeader
        title="Reminders"
        className="reminders-header"
        button={
          <Button type="primary" size="large">
            <Link to={replaceParams(AppRoutes.AdminRemindersItem, { id: 0 })}>
              + add a new reminder
            </Link>
          </Button>
        }
      />
      {noReminder && <h5 className="text-center">Add your 1st reminder!</h5>}
      {list.length > 0 && (
        <div className="reminders-list">
          {list.map((item, idx) => (
            <RemindersItem
              item={item}
              key={item.id}
              className={cx({ "border-top": idx === 0 })}
              onSendTestClick={() => handleTestClick(item)}
              setList={setList}
              setArchived={setArchived}
            />
          ))}
        </div>
      )}

      {Boolean(!listResource.loading && archived.length > 0) && (
        <>
          <RemindersHeader title="Archived" className="reminders-archive-header" />
          <div>
            {archived.map((item, idx) => (
              <RemindersItem
                item={item}
                key={item.id}
                archive={true}
                className={cx({ "border-top": idx === 0 })}
                setList={setList}
                setArchived={setArchived}
              />
            ))}
          </div>
        </>
      )}

      <SendTestModal
        reminder={testReminder}
        visible={Boolean(testReminder)}
        hideModal={() => setTestReminder(undefined)}
      />

      <ContainerLoader
        show={
          list.length === 0 &&
          archived.length === 0 &&
          (archivedListResource.loading || listResource.loading)
        }
      />
    </ClientAppLayout>
  );
}
