import "./assets/repeat.scss";

import React from "react";

import { months } from "../../../../../constants/reminderConstants";
import { SelectField } from "../../../../../components/form/SelectField";

export function RepeatAnnual() {
  return (
    <SelectField
      name="month"
      label="Month"
      data={months}
      placeholder="Choose"
      style={{ width: "100px" }}
    />
  );
}
