import "./assets/delete-dialog.scss";

import React from "react";
import cx from "classnames";
import { Modal } from "antd";

export function DeleteDialog({
  show,
  icon = null,
  width = "80%",
  title = "Delete Confirmation",
  okText = "yes, delete",
  okType = "danger",
  className,
  maskClosable = true,
  okButtonProps = { size: "large" },
  cancelButtonProps = { size: "large" },
  ...props
}) {
  return (
    <Modal
      icon={icon}
      width={width}
      title={title}
      visible={show}
      okType={okType}
      okText={okText}
      maskClosable={maskClosable}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      {...props}
      className={cx("delete-dialog", className)}
    />
  );
}
