import { update } from "immupdate";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";

import { Dict } from "../../api/AppDTO";
import { PromotionTabs } from "./PromotionTabs";
import { PromotionsItemHeader } from "./PromotionsItemHeader";

interface Props {
  readonly loading: boolean;

  readonly isEdit: boolean;

  readonly onSaveClick: () => void;
  readonly onActiveClick: () => void;
  readonly onFinishLaterClick: () => void;
  readonly onActivateLaterClick: () => void;
  readonly setChosenDepartments: (value: any) => void;
  readonly chosenDepartments: any;
}

export function PromotionForm({
  isEdit,
  loading,
  onSaveClick,
  onActiveClick,
  onFinishLaterClick,
  onActivateLaterClick,
  setChosenDepartments,
  chosenDepartments,
}: Props) {
  const { validateForm } = useFormikContext();

  const [tabVisited, setTabVisited] = useState<Dict<boolean>>({
    design: false,
    settings: false,
    messaging: false,
  });

  useEffect(() => {
    if (!loading && isEdit) {
      setTabVisited({
        design: true,
        settings: true,
        messaging: true,
      });

      validateForm();
    }
  }, [isEdit, loading, validateForm]);

  return (
    <div className="d-flex flex-column flex-1 px-3 px-md-6 mb-7">
      <PromotionsItemHeader
        isEdit={isEdit}
        visitedTabs={tabVisited}
        onSaveClick={onSaveClick}
        onActiveClick={onActiveClick}
        onFinishLaterClick={onFinishLaterClick}
        onActivateLaterClick={onActivateLaterClick}
      />
      <PromotionTabs
        isEdit={isEdit}
        visitedTabs={tabVisited}
        onChangeVisitedTab={(tab) => setTabVisited((prev) => update(prev, { [tab]: true } as any))}
        loading={loading}
        setChosenDepartments={setChosenDepartments}
        chosenDepartments={chosenDepartments}
      />
    </div>
  );
}
