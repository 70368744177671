import React from "react";

export function ClearIcon({ size = 24, ...svgProps }) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#E9EDEF"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM16.4 9.05206L13.5746 11.8775L16.4 14.703C16.8703 15.1733 16.8749 15.9311 16.403 16.403C15.9344 16.8716 15.1771 16.8742 14.703 16.4L11.8775 13.5746L9.05206 16.4C8.5818 16.8703 7.82394 16.8749 7.35204 16.403C6.88341 15.9344 6.8809 15.1771 7.35501 14.703L10.1805 11.8775L7.35501 9.05206C6.88474 8.5818 6.88015 7.82394 7.35204 7.35204C7.82067 6.88341 8.57796 6.8809 9.05206 7.35501L11.8775 10.1805L14.703 7.35501C15.1733 6.88474 15.9311 6.88015 16.403 7.35204C16.8716 7.82067 16.8742 8.57796 16.4 9.05206Z"
      />
      <path
        fill="#BAC5CB"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5746 11.8775L16.4 9.05206C16.8742 8.57796 16.8716 7.82067 16.403 7.35204C15.9311 6.88015 15.1733 6.88474 14.703 7.35501L11.8775 10.1805L9.05206 7.35501C8.57796 6.8809 7.82067 6.88341 7.35204 7.35204C6.88015 7.82394 6.88474 8.5818 7.35501 9.05206L10.1805 11.8775L7.35501 14.703C6.8809 15.1771 6.88341 15.9344 7.35204 16.403C7.82394 16.8749 8.5818 16.8703 9.05206 16.4L11.8775 13.5746L14.703 16.4C15.1771 16.8742 15.9344 16.8716 16.403 16.403C16.8749 15.9311 16.8703 15.1733 16.4 14.703L13.5746 11.8775Z"
      />
    </svg>
  );
}
