import React from "react";
import cx from "classnames";

interface Props {
  readonly children: string;
  readonly isRequired?: boolean;

  readonly requireTitle?: string;

  readonly className?: string;
  readonly titleClassName?: string;
}

export function PromotionFieldTitle({
  children,
  className,
  isRequired,
  requireTitle,
  titleClassName,
}: Props) {
  return (
    <div
      className={cx(
        "availability-title font-family-museo font-weight-medium text-primary mb-2",
        className,
      )}
    >
      <span className={titleClassName}>
        {children} {isRequired && <span className="text-danger">* {requireTitle}</span>}
      </span>
    </div>
  );
}
