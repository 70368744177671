import "./assets/repeat.scss";

import cx from "classnames";
import { Button } from "antd";
import { uid } from "react-uid";
import { toFinite } from "lodash";
import React, { useState } from "react";

export function RepeatMonthly(props: any) {
  const days = [] as any;
  for (let idx = 1; idx < 32; idx++) {
    days.push({
      name: idx,
      value: toFinite(props.values.dayOfMonth) === idx,
    });
  }

  const initdays = [] as any;
  for (let idx = 1; idx < 32; idx++) {
    initdays.push({ name: idx, value: false });
  }

  const [daysChecked, setDaysChecked] = useState(days);

  function handleClickDay(index) {
    const updatedArray = initdays.map((item, idx) => {
      if (idx === index) {
        return { name: item.name, value: !days[index].value };
      } else {
        return item;
      }
    });
    setDaysChecked(updatedArray);
    props.setFieldValue("dayOfMonth", index + 1);
  }
  return (
    <div className="repeat-monthly">
      {daysChecked.map((item, idx) => {
        const index = idx + 1;
        const lastOnRow = index % 7 === 0;

        return (
          <Button
            key={uid(item)}
            type={item.value === true ? "primary" : undefined}
            onClick={() => handleClickDay(idx)}
            className={cx("m-0 p-0 mb-1", { "mr-1": !lastOnRow })}
          >
            {item.name}
          </Button>
        );
      })}
    </div>
  );
}
