import "./assets/team-members-table.scss";
import "./assets/team-members-header.scss";

import React, { useCallback, useState } from "react";
import { TeamMembersTableItem } from "./TeamMembersTableItem";
import { Button, notification, Upload } from "antd";
import { BASE_HOST } from "../../../constants/ApiConstants";
import { TeamMembersModalInvite } from "./TeamMembersModalInvite";
import { getErrorMessage } from "../../../utils/utils";
import { useFilesContext } from "../../../api/files/FilesContext";
import { useTeamContext } from "../../../api/team/TeamContext";
import { useResource } from "../../../hooks/useResource";
import { useResourceHandler } from "../../../hooks/useResourceHandler";
import { useUser } from "../../../context/auth/hooks";
import { ContainerLoader } from "../../../components/ui/ContainerLoader";

export function TeamMembersTable() {
  const { FilesApi } = useFilesContext();
  const { TeamApi } = useTeamContext();
  const { clientId } = useUser();
  const [list, setList] = useState([] as any);
  const [visibleModal, setVisibleModal] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const memberListResource = useResource(() => TeamApi.getMembersList(clientId), [clientId]);

  useResourceHandler(memberListResource, {
    onSuccess: ({ items = [] }) => setList(items),
  });

  const handleFileUpload = useCallback(
    (options) => {
      setLoadingUpload(true);
      const formData = new FormData();
      formData.append("file", options.file);
      FilesApi.importTeamMembers(formData)
        .then(() => {
          memberListResource.refetch();
          notification.open({
            type: "success",
            description: "Upload successful",
            message: "Upload file",
          });
          setLoadingUpload(false);
        })
        .catch((e) => {
          setLoadingUpload(false);
          notification.open({
            type: "error",
            description: getErrorMessage(e),
            message: "Upload file",
            duration: null,
          });
        });
    },
    [FilesApi, memberListResource],
  );

  if (memberListResource.loading && list.length === 0) return <ContainerLoader show={true} />;

  return (
    <>
      <div className="team-members-header2 px-3 mb-5 pb-5">
        <div className="d-flex align-items-start align-items-md-center flex-md-row flex-column">
          <Button
            className="mb-3 mb-md-0 mr-md-3"
            type="primary"
            onClick={() => setVisibleModal(true)}
          >
            invite a team member
          </Button>

          <Upload
            className="mb-3 mb-md-0 mr-md-3"
            accept="text/csv"
            customRequest={handleFileUpload}
            showUploadList={false}
          >
            <Button type="primary" loading={loadingUpload}>
              upload team csv
            </Button>
          </Upload>

          <a
            className="link"
            href={`${BASE_HOST}/csvtemplates/ImportMembers.csv`}
            target="_blank"
            rel="noopener noreferrer"
          >
            download a csv template
          </a>
        </div>
        <TeamMembersModalInvite
          visible={visibleModal}
          hideModal={() => {
            setVisibleModal(false);
            memberListResource.refetch();
          }}
        />
      </div>
      {!memberListResource.loading && list.length === 0 ? (
        <h5 className="text-center">Add your 1st team member!</h5>
      ) : (
        <div className="table-responsive">
          <div className="w-100 d-flex flex-column d-md-table team-members-table">
            <div className="team-members-table-header d-none d-md-table-row">
              <div className="d-table-cell pl-4 text-uppercase">active</div>
              <div className="d-table-cell text-uppercase">name</div>
              <div className="d-table-cell text-uppercase">reminders</div>
              <div className="d-table-cell text-uppercase">groups</div>
              <div className="d-table-cell">
                <span className="invisible">actions</span>
              </div>
            </div>
            {!memberListResource.loading &&
              list.map((item) => (
                <TeamMembersTableItem item={item} key={item.id} setList={setList} />
              ))}
          </div>
        </div>
      )}
    </>
  );
}
