import "./assets/promotions-item-header.scss";
import cx from "classnames";
import { isEmpty } from "lodash";
import { Button, Switch } from "antd";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Dict } from "../../api/AppDTO";
import { LinkButton } from "../ui/LinkButton";
import { InputField } from "../form/InputField";
import { isValidPromotionTemplate, someSocialIsActive } from "../../helpers/PromotionsHelpers";

interface Props {
  readonly isEdit: boolean;
  readonly visitedTabs: Dict<boolean>;
  readonly onSaveClick: () => void;
  readonly onActiveClick: () => void;
  readonly onFinishLaterClick: () => void;
  readonly onActivateLaterClick: () => void;
}

export function PromotionsItemHeader({
  isEdit,
  visitedTabs,
  onActiveClick,
  onSaveClick,
  onFinishLaterClick,
  onActivateLaterClick,
}: Props) {
  const [disabledFinish, setDisabledFinish] = useState(true);

  const { values, errors, setFieldValue } = useFormikContext<any>();

  const statusChecked = useMemo(() => values.status === "APPROVED", [values.status]);

  const canActiveStatus = useMemo(
    () =>
      isEmpty(errors) &&
      Object.values(visitedTabs).every(Boolean) &&
      someSocialIsActive(values.promotionSocial),
    [errors, visitedTabs, values.promotionSocial],
  );

  useEffect(() => {
    isValidPromotionTemplate(values).then((x) => setDisabledFinish(!x));
  }, [values]);

  return (
    <div className="promotions-item-header mb-5">
      <div className="client-promotion-edit-name d-flex flex-column flex-md-row">
        <div className="input-container mb-3 mb-md-0">
          <InputField
            type="text"
            name="name"
            transparent={true}
            placeholder="Promotion name"
            inputClassName="bg-transparent name-input"
          />
        </div>

        {!isEdit && (
          <div className="d-flex align-items-center justify-content-end">
            {!canActiveStatus && (
              <LinkButton disabled={disabledFinish} className="mx-3" onClick={onFinishLaterClick}>
                Finish later
              </LinkButton>
            )}

            {canActiveStatus && (
              <LinkButton className="mx-3" disabled={disabledFinish} onClick={onActivateLaterClick}>
                Activate later
              </LinkButton>
            )}
            <div
              role="button"
              className={cx("d-flex border br-1 p-2 align-items-center", {
                "border-success": canActiveStatus,
                "border-gray-light": !canActiveStatus,
              })}
              onClick={() => {
                if (canActiveStatus) {
                  setFieldValue("status", "APPROVED");

                  onActiveClick();
                }
              }}
            >
              <Switch className="switch" checked={statusChecked} />
              <span
                className={cx({
                  "text-success": canActiveStatus,
                  "text-gray-light": !canActiveStatus,
                })}
              >
                ACTIVATE
              </span>
            </div>
          </div>
        )}

        {isEdit && (
          <div className="d-flex align-items-center justify-content-end">
            <div
              role="button"
              className="d-flex p-2 align-items-center"
              onClick={() => {
                if (canActiveStatus) {
                  setFieldValue("status", "APPROVED");
                  onActiveClick();
                }
              }}
            >
              <Switch className="switch" checked={statusChecked} />
              <span
                className={cx({
                  "text-success": canActiveStatus,
                  "text-gray-light": !canActiveStatus,
                })}
              >
                {statusChecked && canActiveStatus ? "ACTIVE" : "ACTIVATE"}
              </span>
            </div>
            <Button type="primary" onClick={onSaveClick}>
              SAVE
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
