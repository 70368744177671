import React from "react";
import cx from "classnames";
import { Button } from "antd";
import { Formik } from "formik";
import PropTypes from "prop-types";
import "./assets/settings-billing-form.scss";

import { InputField } from "../form/InputField";
import { SelectField } from "../form/SelectField";
import { countryConstants } from "../../constants/geographicConstants";

SettingsBillingForm.propTypes = {
  onSubmit: PropTypes.func,
  stateList: PropTypes.array,
  initialValues: PropTypes.object,
};

export function SettingsBillingForm({ stateList = [], onSubmit, initialValues }) {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, values }) => (
        <form
          className="d-flex flex-column flex-1 mr-md-2 settings-billing-form"
          onSubmit={handleSubmit}
        >
          <InputField
            transparent={false}
            className="mb-3"
            name="accountOwnerName"
            label="Account Owner Name"
          />
          <InputField transparent={false} className="mb-3" name="company" label="Company Name" />

          <div className="d-flex mb-3">
            <SelectField
              transparent={false}
              className={cx("flex-1", { "mr-2": values.country === "US" })}
              label="Country"
              data={countryConstants}
              name="country"
            />

            {values.country === "US" && (
              <SelectField
                transparent={false}
                className="flex-1 ml-2"
                name="state"
                data={stateList}
                label="State / Province / Region"
              />
            )}
          </div>

          <InputField
            name="zip"
            className="mb-3"
            transparent={false}
            label="Zip Code / Postal Code"
          />

          <Button htmlType="submit" type="primary" className="align-self-sm-center">
            Save changes
          </Button>
        </form>
      )}
    </Formik>
  );
}
