import { BaseApi, BaseApiProps } from "../BaseApi";
import { toSnakeCase } from "../../helpers/CaseUtils";
import {
  PixabayImageResponseProps,
  PixabayImageType,
  PixabayOrientation,
  PixabaySearchImageProps,
} from "./PixabayDTO";

interface PixabayProps extends BaseApiProps {
  readonly key: string;
}

export class PixabayApi extends BaseApi {
  private readonly key: string;

  constructor({ key, ...props }: PixabayProps) {
    super(props);

    this.key = key;
  }

  public searchImage(search: PixabaySearchImageProps = {}): Promise<PixabayImageResponseProps> {
    return this.get("api/", {
      query: toSnakeCase({
        key: this.key,
        minWidth: 1200,
        minHeight: 630,
        editorsChoice: true,
        orientation: PixabayOrientation.Horizontal,
        imageType: [PixabayImageType.Photo, PixabayImageType.Illustration],
        ...search,
      }),
    });
  }
}
