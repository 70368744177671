import { Tabs } from "antd";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";

import { Dict } from "../../api/AppDTO";
import { PromotionTabTitle } from "./PromotionTabTitle";
import { PromotionTabErrors } from "./PromotionTabErrors";
import { ImageBuilder } from "../promotion-builder/ImageBuilder";
import { PromotionsItemSettings } from "./PromotionsItemSettings";
import { PromotionMessaging } from "../promotion-messaging/PromotionMessaging";
import { getPromotionTabErrors, isValidPromotionTemplate } from "../../helpers/PromotionsHelpers";

interface Props {
  readonly isEdit: boolean;
  readonly visitedTabs: Dict<boolean>;
  readonly onChangeVisitedTab: (tab: string) => void;
  readonly loading?: boolean;
  readonly setChosenDepartments: (value: any) => void;
  readonly chosenDepartments: any;
}

export function PromotionTabs(props: Props) {
  const {
    isEdit,
    visitedTabs,
    onChangeVisitedTab,
    loading,
    chosenDepartments,
    setChosenDepartments,
  } = props;
  const { errors, validateForm, values } = useFormikContext<any>();

  const [currentTab, setCurrentTab] = useState("settings");

  const { settingsErrors, designErrors, messagingErrors } = useMemo(
    () => getPromotionTabErrors({ visitedTabs, errors, values }),
    [visitedTabs, errors, values],
  );

  useEffect(() => {
    if (
      !visitedTabs.design &&
      values.promotionSteps &&
      currentTab === "design" &&
      (isValidPromotionTemplate(values) as any)
    ) {
      onChangeVisitedTab("design");
    }
  }, [values, visitedTabs.design, currentTab, onChangeVisitedTab]);

  return (
    <Tabs
      animated={false}
      activeKey={currentTab}
      defaultActiveKey="settings"
      onChange={(x) => {
        if (!visitedTabs[currentTab] && currentTab !== "messaging") {
          onChangeVisitedTab(currentTab);
        }

        if (
          values.promotionSteps &&
          currentTab === "messaging" &&
          (isValidPromotionTemplate(values) as any)
        ) {
          onChangeVisitedTab(currentTab);
        }

        if (
          x === "messaging" &&
          values.promotionSteps &&
          (isValidPromotionTemplate(values) as any)
        ) {
          onChangeVisitedTab(x);
        }

        setCurrentTab(x);

        validateForm();
      }}
    >
      <Tabs.TabPane
        key="settings"
        tab={
          <PromotionTabTitle
            title="Settings"
            visited={visitedTabs.settings}
            hasErrors={settingsErrors.length > 0}
          />
        }
      >
        <PromotionTabErrors
          errors={settingsErrors.filter((x) => x.indexOf("Name Required") === -1)}
        />

        <PromotionsItemSettings
          onChange={() => onChangeVisitedTab(currentTab)}
          setChosenDepartments={setChosenDepartments}
          chosenDepartments={chosenDepartments}
        />
      </Tabs.TabPane>
      <Tabs.TabPane
        disabled={settingsErrors.length > 0}
        key="design"
        tab={
          <PromotionTabTitle
            title="Design"
            visited={visitedTabs.design}
            hasErrors={designErrors.length > 0}
          />
        }
      >
        <PromotionTabErrors errors={designErrors} />

        <ImageBuilder isEdit={isEdit} loading={loading} />
      </Tabs.TabPane>
      <Tabs.TabPane
        disabled={settingsErrors.length > 0 || designErrors.length > 0}
        key="messaging"
        tab={
          <PromotionTabTitle
            title="Messaging"
            visited={visitedTabs.messaging}
            hasErrors={messagingErrors.length > 0}
          />
        }
      >
        <PromotionTabErrors errors={messagingErrors} />

        <PromotionMessaging />
      </Tabs.TabPane>
    </Tabs>
  );
}
