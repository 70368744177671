import "./assets/input-field.scss";

import cx from "classnames";
import { useField } from "formik";
import React, { ReactNode, useMemo, useState } from "react";

import { Input } from "../ui/Input";
import { EyeIcon } from "../icons/EyeIcon";

interface Props {
  readonly style?: any;
  readonly name: string;
  readonly min?: number;
  readonly type?: string;
  readonly label?: string;
  readonly after?: ReactNode;
  readonly before?: ReactNode;
  readonly className?: string;
  readonly placeholder?: string;
  readonly onChange?: () => void;
  readonly transparent?: boolean;
  readonly labelClassName?: string;
  readonly inputClassName?: string;
  readonly showError?: boolean;
  readonly autocomplete?: string;
}

export function InputField({
  name,
  label,
  className,
  labelClassName,
  inputClassName,
  type = "text",
  showError = true,
  autocomplete,
  ...props
}: Props) {
  const [field, meta] = useField(name);
  const [eyeClose, setEyeClose] = useState(false);

  const isError = useMemo(() => Boolean(meta.touched && meta.error), [meta.touched, meta.error]);
  const isPassword = type === "password";
  const isNumber = type === "number";
  const inputType = isPassword ? (eyeClose ? "text" : "password") : isNumber ? "number" : "text";

  return (
    <div className={cx(className, "input-field d-flex flex-column")}>
      {Boolean(label) && (
        <label
          className={cx(labelClassName, "mb-1 font-family-museo font-weight-light field-label")}
        >
          {label}
        </label>
      )}

      <Input
        {...props}
        {...field}
        autocomplete={autocomplete}
        type={inputType}
        inputClassName={inputClassName}
        className={`${inputClassName} input-ui`}
      />

      {isError && showError && <span className="text-danger input-error mt-1">{meta.error}</span>}
      {isPassword && (
        <div
          className={`eye ${label ? "margin-eye" : ""}`}
          onClick={() => setEyeClose((prev) => !prev)}
        >
          <EyeIcon open={inputType === "password"} />
        </div>
      )}
    </div>
  );
}
