import "./assets/promotion-status-switch-wrapper.scss";

import cx from "classnames";
import { notification, Switch } from "antd";
import { SwitchProps } from "antd/lib/switch";
import React, { useCallback, useMemo, useState } from "react";

import { usePromotionContext } from "../../api/promotions/PromotionContext";
import { getErrorMessage } from "../../utils/utils";

interface Props extends SwitchProps {
  readonly promotion: any;
  readonly onComplete: () => void;
}

export function PromotionsStatusSwitchWrapper({ promotion, onComplete, ...props }: Props) {
  const [loading, setLoading] = useState(false);

  const { PromotionApi } = usePromotionContext();

  const approved = useMemo(() => promotion.status === "APPROVED", [promotion.status]);

  const disabled = useMemo(
    () => !promotion.hasActiveSocial || (promotion.endDate && promotion.endDate <= Date.now()),
    [promotion],
  );

  const updateHandler = useCallback(async () => {
    setLoading(true);

    try {
      const nextStatus = approved ? "NOT_APPROVED" : "APPROVED";

      await PromotionApi.changeStatus(promotion.id, nextStatus);

      setLoading(false);
      onComplete();
    } catch (e) {
      setLoading(false);

      notification.open({
        type: "error",
        message: "Change Status",
        description: getErrorMessage(e),
        duration: null,
      });
    }
  }, [promotion, approved, onComplete, PromotionApi]);

  return (
    <Switch
      loading={loading}
      checked={approved}
      disabled={disabled}
      onClick={updateHandler}
      {...props}
      className={cx("border border-gray promotion-status-switch-wrapper", props.className, {
        disabled,
        active: approved,
      })}
    />
  );
}
