import { uid } from "react-uid";
import { update } from "immupdate";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useNotification } from "../../hooks/useNotification";
import { convertImgToBase64URL } from "../../helpers/ImageHelpers";
import { PromotionBackgroundModal } from "./PromotionBackgroundModal";
import { createNotification } from "../../helpers/NotificationHelpers";
import { PromotionBackgroundUpload } from "./PromotionBackgroundUpload";
import { PromotionBackgroundConfig } from "./PromotionBackgroundConfig";
import { applyBackgroundFilters, formatPromotionToForm } from "../../helpers/PromotionsHelpers";
import { PromotionBackgroundSearchDialogWrapper } from "./PromotionBackgroundSearchDialogWrapper";
import PropTypes from "prop-types";

PromotionBackground.propTypes = {
  background: PropTypes.object,
  setBackground: PropTypes.func,
};

const fileReader = new FileReader();

export function PromotionBackground({ setBackground, background }) {
  const { values, setFieldValue } = useFormikContext();

  const [initialImage, setInitialImage] = useState();
  const [loading, setLoading] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [initialConfig, setInitialConfig] = useState({ position: undefined });
  const [imageSomeSrc, setImageSrc] = useState("");

  const { showError } = useNotification();

  const {
    config,
    imageSrc,
    imageWidth,
    imageHeight,
    targetImageSrc,
    previewImageSrc,
  } = useMemo(() => {
    const { backgroundZone } = values.zones;

    return {
      config: backgroundZone.options,
      imageSrc: backgroundZone.image,
      imageWidth: backgroundZone.width,
      imageHeight: backgroundZone.height,
      targetImageSrc: backgroundZone.targetImage,
      previewImageSrc: backgroundZone.imagePreview,
    };
  }, [values]);

  const hasImage = useMemo(() => Boolean(previewImageSrc), [previewImageSrc]);

  const options = useMemo(
    () => ({
      blur: background.blur,
      vibrance: background.vibrance,
      brightness: background.brightness,
      saturation: background.saturation,
    }),
    [background],
  );

  const updateBackgroundHandler = useCallback(
    async (data) => {
      if (data) {
        const backgroundZone = update(values.zones.backgroundZone, data);

        const x = await formatPromotionToForm(
          update(values.zones, { backgroundZone }),
          values.hideLogo,
        );

        if (data.image) {
          setFieldValue("zones.backgroundZone.image", data.image);
        }
        if (data.imagePreview) {
          setFieldValue("zones.backgroundZone.imagePreview", data.imagePreview);
        }
        if (data.targetImage) {
          setFieldValue("zones.backgroundZone.targetImage", data.targetImage);
        }
        setFieldValue("promotionPreview", x.promotionPreview);
      }
    },
    [setFieldValue, values.zones, values.hideLogo],
  );

  useEffect(() => {
    fileReader.onload = (e) => {
      setImageSrc(e.target.result);
    };
  }, [showError]);

  const selectHandler = useCallback((file) => {
    setLoading(true);
    fileReader.readAsDataURL(file);
  }, []);

  useEffect(() => {
    if (imageSrc) {
      setInitialImage(imageSrc);
    }
  }, [imageSrc]);

  useEffect(() => {
    setInitialConfig({ position: config.position });
  }, [config.position]);

  return (
    <div className="d-flex flex-column">
      <span className="mb-4 text-dark-gray">
        Vsbl is all about stunning imagery that helps get attention and visually enhance the message
        of your post.
      </span>
      <img
        style={{ display: "none" }}
        src={imageSomeSrc}
        alt="alt"
        id="uploadedImage"
        onLoad={() => {
          const image = document.getElementById("uploadedImage");
          convertImgToBase64URL(image.src, image.width, image.height)
            .then((x) => {
              setInitialImage(x);
              setShowUploadDialog(true);
              setInitialConfig({ position: undefined });

              setLoading(false);
            })
            .catch((e) => {
              showError(e);
              setLoading(false);
            });
        }}
      />

      <div className="d-flex flex-column flex-lg-row justify-content-center">
        <PromotionBackgroundUpload
          loading={loading}
          width={imageWidth}
          height={imageHeight}
          image={previewImageSrc}
          key={uid(targetImageSrc)}
          onUploadClick={({ file }) => selectHandler(file)}
          onSearchClick={() => setShowSearchDialog(true)}
          options={options}
          onEditClick={() => {
            setInitialConfig({ position: config.position });

            setInitialImage(imageSrc);
            setShowUploadDialog(true);
          }}
        />
        <PromotionBackgroundConfig
          config={options}
          loading={loading}
          hasImage={hasImage}
          onResetClick={() => {
            setLoading(true);

            setFieldValue("zones.backgroundZone.options.blur", 0);
            setFieldValue("zones.backgroundZone.options.vibrance", 0);
            setFieldValue("zones.backgroundZone.options.saturation", 0);
            setFieldValue("zones.backgroundZone.options.brightness", 0);

            applyBackgroundFilters(
              {
                width: imageWidth,
                height: imageHeight,
                imagePreview: previewImageSrc,
                options: {
                  blur: 0,
                  vibrance: 0,
                  saturation: 0,
                  brightness: 0,
                },
              },
              "ResetOptionsBackgroundTemporaryCamanImage",
            ).then((targetImage) => {
              setLoading(false);
              updateBackgroundHandler({ targetImage });
            });
          }}
          onChange={(c) => {
            setLoading(true);

            setFieldValue("zones.backgroundZone.options.blur", c.blur);
            setFieldValue("zones.backgroundZone.options.vibrance", c.vibrance);
            setFieldValue("zones.backgroundZone.options.saturation", c.saturation);
            setFieldValue("zones.backgroundZone.options.brightness", c.brightness);

            setBackground(
              update(background, {
                blur: c.blur,
                vibrance: c.vibrance + 100,
                saturation: c.saturation + 100,
                brightness: c.brightness + 100,
              }),
            );

            applyBackgroundFilters(
              {
                options: c,
                width: imageWidth,
                height: imageHeight,
                imagePreview: previewImageSrc,
              },
              uid(c),
            ).then((targetImage) => {
              setLoading(false);
              updateBackgroundHandler({ targetImage });
            });
          }}
        />
      </div>

      <PromotionBackgroundSearchDialogWrapper
        show={showSearchDialog}
        onCancel={() => setShowSearchDialog(false)}
        onSelect={(x) => {
          setInitialImage(x);

          setInitialConfig({ position: undefined });

          setShowUploadDialog(true);
          setShowSearchDialog(false);
        }}
      />

      {showUploadDialog && (
        <PromotionBackgroundModal
          width={1200}
          height={630}
          image={initialImage}
          initialCrop={initialConfig.position}
          onSelect={async (img, originalSrc, crop) => {
            setFieldValue("zones.backgroundZone.options.position.x", crop.x);
            setFieldValue("zones.backgroundZone.options.position.y", crop.y);
            setFieldValue("zones.backgroundZone.options.position.width", crop.width);
            setFieldValue("zones.backgroundZone.options.position.height", crop.height);

            try {
              const targetImage = await applyBackgroundFilters(
                {
                  options,
                  width: imageWidth,
                  height: imageHeight,
                  imagePreview: img,
                },
                uid(options),
              );
              setShowUploadDialog(false);

              updateBackgroundHandler({
                targetImage,
                imagePreview: img,
                image: initialImage !== imageSrc ? originalSrc : imageSrc,
              });

              setInitialImage(originalSrc);
            } catch (e) {
              createNotification({ message: "Something wrong" });
            }
          }}
          onClose={() => {
            setShowUploadDialog(false);

            setInitialConfig({ position: config.position });

            if ((imageSrc && !initialImage) || imageSrc !== initialImage) {
              setInitialImage(imageSrc);
            }
          }}
        />
      )}
    </div>
  );
}
