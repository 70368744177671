import { noop } from "lodash";
import { compile } from "path-to-regexp";

export const development = process.env.NODE_ENV === "development";
export const production = process.env.NODE_ENV === "production";

export function combineClassName(defaultClassName, additionalClassName) {
  return `${defaultClassName}${additionalClassName ? ` ${additionalClassName}` : ""}`;
}

export function capitalizeFirstLetter(letter) {
  return letter.charAt(0).toUpperCase() + letter.slice(1);
}

export function logDebug(message) {
  if (development) {
    // eslint-disable-next-line no-console
    console.log("%c" + message, "color: blue;");
  }
}

export function makeUrl(path, params) {
  const toPath = compile(path);

  return toPath(params);
}

export function oneRouteUp(path) {
  const newPath = path.split("/");
  newPath.pop();
  return newPath.join("/");
}

export function callIf(getBoolean, callback) {
  return function (...args) {
    return getBoolean() ? callback(...args) : noop();
  };
}

export function logError(message) {
  if (development) {
    throw new Error(message);
  }
}

export function rename(callback, name) {
  if (production) return callback;

  // eslint-disable-next-line
  return Function(
    "callback",
    "return (function " + name + "(){\n  return callback.apply(this, arguments)\n});",
  )(callback); // eslint-disable-line no-new-func
}

export function fileToBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", function () {
      /* eslint-disable no-invalid-this */
      resolve(this.result);
      /* eslint-enable no-invalid-this */
    });
    reader.addEventListener("error", function () {
      /* eslint-disable no-invalid-this */
      reject(this.error);
      /* eslint-enable no-invalid-this */
    });
    reader.readAsArrayBuffer(file);
  });
}

export function isFunction(func) {
  return typeof func === "function";
}

/**
 * converts error to JSON and removes not serializable values
 */
export function normalize(error) {
  return JSON.parse(JSON.stringify(error.toJSON()));
}

export function getErrorMessage(e) {
  if (typeof e === "string") return e;
  if (e instanceof Error) return e.message;

  return e.data[0].userMsg;
}
