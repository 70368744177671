import "./assets/promotion-list-container.scss";

import cx from "classnames";
import { Button } from "antd";
import React, { useMemo, useState } from "react";

import { AppRoutes } from "../../constants/Routes";
import { useResource } from "../../hooks/useResource";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { ContainerLoader } from "../../components/ui/ContainerLoader";
import { usePromotionContext } from "../../api/promotions/PromotionContext";
import { PromotionPreviewButton } from "../../components/promotion/PromotionPreviewButton";
import { useSettings, useUser } from "../../context/auth/hooks";
import useRouter from "../../hooks/useRouter";
import { TeamAppLayout } from "../../components/app/TeamAppLayout";
import PostActivity from "../../components/team-portal/PostActivity";

export function PromotionListContainer() {
  const [list, setList] = useState([] as any);
  const [lastActivities, setLastActivities] = useState({});
  const { clientId, isAdministrator } = useUser();
  const { settings } = useSettings();
  const router = useRouter();

  const { PromotionApi } = usePromotionContext();

  const listResource = useResource(
    () => PromotionApi.getPromotionList(clientId, { limit: 100, isCompanyUser: false }),
    [clientId],
  );

  const lastActivitiesResource = useResource(() => PromotionApi.getLastActivity(), []);

  useResourceHandler(listResource, {
    onSuccess: (data) => setList(data?.items || []),
  });

  useResourceHandler(lastActivitiesResource, {
    onSuccess: setLastActivities,
  });

  const filteredList = useMemo(
    () => (isAdministrator ? list.filter(({ status }) => status === "APPROVED") : list) as any,
    [list, isAdministrator],
  );

  return (
    <TeamAppLayout title="Team Portal">
      <div className="container d-flex flex-column align-items-center promotion-list-container">
        {isAdministrator && (
          <Button
            type="primary"
            className="border w-max"
            onClick={() => router.push(AppRoutes.AdminPromotions)}
            style={{
              borderColor: settings?.primaryColor || "#002049",
              backgroundColor: settings?.primaryColor || "#002049",
            }}
          >
            Return To Dashboard
          </Button>
        )}
        <PostActivity lastActivities={lastActivities} />

        <span className="team-portal-choose-title">Choose a post to share with your network:</span>
        {filteredList.map((promotion, idx) => (
          <PromotionPreviewButton
            key={promotion.id}
            promotion={promotion}
            color={settings?.primaryColor}
            className={cx("promotion-preview-button", { "first-button": idx === 0 })}
            onClick={() => router.push(AppRoutes.TeamPortalChoose, { id: promotion.id })}
          />
        ))}
        {filteredList.length === 0 && listResource.loading && (
          <span className="team-portal-choose-title">No promotions to share</span>
        )}

        <ContainerLoader
          show={listResource.loading && list.length === 0}
          color={settings?.primaryColor || "#002049"}
        />
      </div>
    </TeamAppLayout>
  );
}
