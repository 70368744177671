import { BaseApi } from "../BaseApi";
import { toKebabCase } from "../../helpers/CaseUtils";

export class TeamApi extends BaseApi {
  sendInvite(body) {
    return this.post("team/invite", { json: toKebabCase(body), timeout: 20000 }).then(
      ({ data }) => data,
    );
  }
  addDepartmentMember(body) {
    return this.post("team/departments", { json: toKebabCase(body), timeout: 20000 }).then(
      ({ data }) => data,
    );
  }
  updateTeamMember(body) {
    return this.put("team/update", { json: toKebabCase(body) }).then(({ data }) => data);
  }
  getMemberById(id) {
    return this.get("team/:id", { params: { id } }).then(({ data }) => data);
  }
  getMembersList(clientId) {
    return this.get(`team/${clientId}/list`).then(({ data }) => data);
  }
  deleteMember(memberid) {
    return this.delete("team/delete", { query: { memberid } }).then(({ data }) => data);
  }
  resendActivation(query) {
    return this.patch("team/resend-activation", { query, timeout: 20000 }).then(({ data }) => data);
  }
  importTeamMembers(body) {
    return this.post("team/import", { body }).then(({ data }) => data);
  }

  changePassword(body) {
    return this.post("team/change-password", { json: body });
  }
}
