import React from "react";

import { Col } from "antd";

export function VSBLCol(props) {
  if (!props.span) return props.children;

  return (
    <Col span={props.span} offset={props.offset} style={{ padding: "0 8px" }}>
      {props.children}
    </Col>
  );
}
