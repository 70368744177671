import React from "react";

interface Props {
  readonly size?: number;
  readonly className?: string;
}

export function ArrowBottomRightIcon({ size = 24, className }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" className={className}>
      <path
        fill="currentColor"
        d="M14.89,12.06V7.11H18.31V18.31H7.11V14.89H12.06L5.69,8.5L8.5,5.69L14.89,12.06Z"
      />
    </svg>
  );
}
