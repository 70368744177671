import "./assets/promotion-background-modal.scss";

import { isFinite } from "lodash";
import Cropper from "react-easy-crop";
import React, { useMemo, useState } from "react";

import { Modal } from "../modal/Modal";
import { cropImage } from "../../helpers/ImageHelpers";
import { PromotionBackgroundModalHeader } from "./PromotionBackgroundModalHeader";
import { PromotionBackgroundModalActions } from "./PromotionBackgroundModalActions";

interface CropProps {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
}

interface Props {
  readonly width: number;
  readonly image?: string;
  readonly height: number;
  readonly onClose: () => void;
  readonly initialCrop?: CropProps;

  readonly onSelect: (image: string, originalImage: string, crop: CropProps) => void;
}

export function PromotionBackgroundModal({
  width,
  image,
  height,
  onClose,
  onSelect,
  initialCrop,
}: Props) {
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const initialCroppedArea = useMemo(() => {
    if (
      isFinite(initialCrop?.x) &&
      isFinite(initialCrop?.y) &&
      isFinite(initialCrop?.width) &&
      isFinite(initialCrop?.height)
    ) {
      return initialCrop;
    }

    return undefined;
  }, [initialCrop]);

  const notImage = useMemo(() => Boolean(!image), [image]);

  return (
    <Modal
      show={true}
      closable={false}
      fullScreen={true}
      onClose={onClose}
      withAnimation={false}
      id="PromotionBackgroundModal"
      className="promotion-background-modal"
      contentClassName="p-0 position-relative"
      actionsClassName="justify-content-center"
      header={<PromotionBackgroundModalHeader onCloseClick={onClose} />}
      actions={
        <PromotionBackgroundModalActions
          zoomValue={zoom}
          disabled={notImage}
          onChangeZoom={setZoom}
          onFullClick={() => setZoom(1)}
          onPerfectClick={async () => {
            const img = await cropImage(
              image,
              croppedAreaPixels.x,
              croppedAreaPixels.y,
              croppedAreaPixels.width,
              croppedAreaPixels.height,
              0,
              0,
              width,
              height,
            );

            onSelect(img, image!, croppedAreaPixels);
          }}
        />
      }
    >
      <Cropper
        crop={crop}
        zoom={zoom}
        maxZoom={2}
        image={image}
        aspect={2}
        showGrid={false}
        onZoomChange={setZoom}
        onCropChange={setCrop}
        initialCroppedAreaPixels={initialCroppedArea}
        onCropComplete={(_, x) => setCroppedAreaPixels(x)}
      />
    </Modal>
  );
}
