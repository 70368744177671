import "./assets/image-builder-step-container.scss";

import React from "react";
import PropTypes from "prop-types";

ImageBuilderStepContainer.propTypes = {
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export function ImageBuilderStepContainer({ description, children }) {
  return (
    <div className="image-builder-step-container">
      <div className="client-promotion-edit-builder-headline">
        {Boolean(description) && <div className="builder-headline-description">{description}</div>}

        <div className="client-promotion-edit-builder-headline-choices">{children}</div>
      </div>
    </div>
  );
}
