import React from "react";

import { Button } from "antd";

interface Props {
  readonly saving: boolean;
}

export function ChangePasswordHeader({ saving }: Props) {
  return (
    <>
      <div className="d-flex align-items-md-center pb-4 border-bottom flex-column flex-md-row mt-7">
        <div className="team-account-header-title mr-auto mr-3 mb-2 mb-md-0">Change Password</div>

        <div className="d-flex">
          <Button htmlType="submit" loading={saving} className="mr-1" type="primary">
            Change
          </Button>
        </div>
      </div>
    </>
  );
}
