import "./assets/field.scss";
import "./assets/date-time-field.scss";

import cx from "classnames";
import { useField } from "formik";
import React, { useMemo } from "react";
import { DatePicker, TimePicker } from "antd";

import { ClearIcon } from "../icons/ClearIcon";
import { UpDownIcon } from "../icons/UpDownIcon";
import { CalendarIcon } from "../icons/CalendarIcon";
import moment from "moment";

interface Props {
  readonly name: string;
  readonly label?: string;
  readonly showTime?: boolean;
  readonly showDate?: boolean;
  readonly className?: string;
  readonly timeLabel?: string;
  readonly withErrors?: boolean;
  readonly use12Hours?: boolean;
  readonly timeFormat?: string;
  readonly dateFormat?: string;
  readonly dateClassName?: string;
  readonly timeClassName?: string;
  readonly showClearButton?: boolean;
  readonly closeButtonClassName?: string;
  readonly onChange?: (value?: any) => void;
  readonly disabledDateBeforeNow?: boolean;
  readonly disabledTimeBeforeNow?: boolean;
}

export function DateTimeField(props: Props) {
  const {
    name,
    label,
    showTime = true,
    showDate = true,
    className,
    timeLabel,
    withErrors = true,
    use12Hours = true,
    timeFormat = "hh:mm a",
    dateFormat = "ddd, DD, MMM YYYY",
    dateClassName,
    timeClassName,
    showClearButton,
    closeButtonClassName,
    onChange,
    disabledDateBeforeNow,
    disabledTimeBeforeNow,
  } = props;

  const [field, meta, helpers] = useField(name);
  const showError = useMemo(() => Boolean(withErrors && meta.touched && meta.error), [
    withErrors,
    meta.touched,
    meta.error,
  ]);

  const disabledHours = () => {
    const hours = [] as number[];
    for (let i = 0; i < moment().hour(); i += 1) {
      hours.push(i);
    }
    return hours;
  };

  const disabledMinutes = (selectedHour) => {
    const minutes = [] as number[];
    if (selectedHour === moment().hour()) {
      for (let i = 0; i < moment().minute(); i += 1) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  return (
    <div className={cx("field date-time-field", className)}>
      <div className="d-flex align-items-end field-container mb-3 mb-md-3">
        {showDate && (
          <div className="d-flex flex-column justify-content-end">
            {Boolean(label) && <label className="field-label mb-1">{label}</label>}
            <DatePicker
              id={name}
              {...field}
              onChange={(x) => {
                helpers.setValue(x);

                if (onChange) {
                  onChange(x);
                }
              }}
              allowClear={false}
              format={dateFormat}
              disabledDate={(current: any) => {
                return disabledDateBeforeNow ? moment().add(-1, "days") >= current : false;
              }}
              suffixIcon={<CalendarIcon />}
              className={cx("field-picker date-picker", dateClassName)}
            />
          </div>
        )}

        {showTime && (
          <div className={cx("d-flex flex-column justify-content-end", { "ml-2": showDate })}>
            {Boolean(timeLabel) && <label className="field-label mb-1">{timeLabel}</label>}
            <TimePicker
              {...field}
              onChange={(x) => {
                helpers.setValue(x);
                if (onChange) {
                  onChange(x);
                }
              }}
              allowClear={false}
              format={timeFormat}
              use12Hours={use12Hours}
              suffixIcon={<UpDownIcon size={14} />}
              className={cx("field-picker time-picker", timeClassName)}
              disabledHours={disabledTimeBeforeNow ? disabledHours : undefined}
              disabledMinutes={disabledTimeBeforeNow ? disabledMinutes : undefined}
            />
          </div>
        )}

        {showClearButton && (
          <button
            onClick={() => {
              helpers.setValue(null);

              if (onChange) {
                onChange();
              }
            }}
            type="button"
            className={cx("mb-2 clear-button", closeButtonClassName)}
          >
            <ClearIcon />
          </button>
        )}
      </div>

      {showError && <span className="field-error-text">{meta.error}</span>}
    </div>
  );
}
