export enum ReminderTriggerType {
  Daily = "DAILY",
  OneTime = "ONE_TIME",
}

export enum ReminderType {
  TeamDashboard = "TEAM_DASHBOARD",
}

export interface ReminderProps {
  readonly id: number;
  readonly name: string;
  readonly sms: boolean;
  readonly email: boolean;
  readonly active: boolean;
  readonly friday: boolean;
  readonly monday: boolean;
  readonly sunday: boolean;
  readonly smsText: string;
  readonly tuesday: boolean;
  readonly enddate?: string;
  readonly clientId: number;
  readonly saturday: boolean;
  readonly thursday: boolean;
  readonly startdate: string;
  readonly wednesday: boolean;
  readonly dayofmonth: number;
  readonly monthofyear: number;
  readonly promotionId: number;
  readonly emailContent: string;
  readonly emailSubject: string;
  readonly modifiedDate: number;
  readonly modifiedMemberId: number;
  readonly reminderType: ReminderType;
  readonly triggerType: ReminderTriggerType;
}

export interface SendTestProps {
  readonly sms: boolean;
  readonly smsTo: string;
  readonly email: boolean;
  readonly smsText: string;
  readonly emailTo: string;
  readonly clientId: number;
  readonly promotionId: number;
  readonly emailContent: string;
  readonly emailSubject: string;
  readonly reminderType: ReminderType;
}
