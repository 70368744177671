import moment from "moment";
import "./assets/promotion-item-container.scss";
import { Formik } from "formik";
import { notification } from "antd";
import { toFinite } from "lodash";
import { useParams } from "react-router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ContainerLoader } from "../../components/ui/ContainerLoader";
import { PromotionForm } from "../../components/promotion/PromotionForm";
import { usePromotionContext } from "../../api/promotions/PromotionContext";
import {
  formatEditInitialPromotion,
  formatPromotionValues,
  isValidPromotionTemplate,
  promotionsValidationSchema,
} from "../../helpers/PromotionsHelpers";
import { useSettings, useUser } from "../../context/auth/hooks";
import useRouter from "../../hooks/useRouter";
import { ClientAppLayout } from "../../components/app/ClientAppLayout";
import { AppRoutes } from "../../constants/Routes";
import { useNotification } from "../../hooks/useNotification";
import { toCamelCase } from "../../helpers/CaseUtils";

export function PromotionsItemContainer() {
  const { id } = useParams<any>();
  const { clientId } = useUser();
  const { setSettings, settings } = useSettings();
  const { showError, showSuccessNotification } = useNotification();
  const [chosenDepartments, setChosenDepartments] = useState([] as any);

  const router = useRouter();

  const [initialValues, setInitialValues] = useState<any>({
    startDate: moment(),
    promotionSocial: {},
  });
  const [loading, setLoading] = useState(true);

  const promotionId = useMemo(() => toFinite(id), [id]);

  const { PromotionApi } = usePromotionContext();

  useEffect(() => {
    if (promotionId) {
      setLoading(true);
      PromotionApi.getPromotion(promotionId, { isCompanyUser: true })
        .then((response) => {
          const data = toCamelCase(response);
          isValidPromotionTemplate(data).then((validTemplate) => {
            formatEditInitialPromotion(data, false).then((newData) => {
              setInitialValues(newData);
              setLoading(false);
              if (!validTemplate) {
                notification.open({
                  type: "error",
                  message: "Error",
                  description: "Wrong template",
                  duration: null,
                });
              }
            });
          });
        })
        .catch((err) => {
          showError(err);
          setLoading(false);
        });
    }
  }, [promotionId]);

  const setDefaultSettingsLogo = useCallback(
    (values) => {
      const logoZone = values.promotionItem.dynamicZones[0].dynamicZoneLayerList.find(
        ({ zindex }) => zindex === 3,
      );
      if (logoZone.useAsDefault) {
        const logo = {
          image: logoZone.image,
          url: `https://image.conctraq.com/${logoZone.imageKey}`,
          height: logoZone.height,
        };
        setSettings({ ...settings, logo });
      }
    },
    [setSettings, settings],
  );

  const handleCreateUpdate = useCallback(
    (values) => {
      setLoading(true);
      formatPromotionValues({ ...values, departments: chosenDepartments }).then((data) => {
        if (promotionId !== 0) {
          PromotionApi.updatePromotion({ clientId, ...data })
            .then(() => {
              setDefaultSettingsLogo(values);
              setLoading(false);
              showSuccessNotification({ message: "Update successfully" });
              router.push(AppRoutes.AdminPromotions);
            })
            .catch((err) => {
              setLoading(false);
              showError(err);
            });
        } else {
          PromotionApi.createPromotion({ clientId, ...data })
            .then(() => {
              setDefaultSettingsLogo(values);
              setLoading(false);
              showSuccessNotification({ message: "Create successfully" });
              router.push(AppRoutes.AdminPromotions);
            })
            .catch((err) => {
              setLoading(false);
              showError(err);
            });
        }
      });
    },
    [
      settings,
      setSettings,
      chosenDepartments,
      router,
      clientId,
      PromotionApi,
      showError,
      promotionId,
      showSuccessNotification,
    ],
  );

  useEffect(() => {
    if (promotionId === 0) {
      setLoading(false);
    }
  }, [promotionId]);

  return (
    <ClientAppLayout contentClassName="pt-7 promotion-item-layout">
      {window.innerWidth <= 992 && (
        <span className="edit-mobile">
          Creating or editing a promotion is not available for smaller screens. Please try using a
          device with larger screen size.
        </span>
      )}
      {window.innerWidth > 992 && (
        <Formik
          validateOnMount={true}
          onSubmit={handleCreateUpdate}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={promotionsValidationSchema}
        >
          {({ handleSubmit, values }) => (
            <form className="d-flex flex-column flex-1">
              <PromotionForm
                loading={loading}
                isEdit={promotionId !== 0}
                onActiveClick={handleSubmit}
                onActivateLaterClick={handleSubmit}
                onSaveClick={handleSubmit}
                onFinishLaterClick={() => handleCreateUpdate(values)}
                setChosenDepartments={setChosenDepartments}
                chosenDepartments={chosenDepartments}
              />
              <ContainerLoader show={loading} />
            </form>
          )}
        </Formik>
      )}
    </ClientAppLayout>
  );
}
