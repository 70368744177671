import "./assets/promotion-messaging-twitter.scss";

import React from "react";

import { SwitchField } from "../form/SwitchField";
import { InputWithCountField } from "../form/InputWithCountField";
import { PromotionMessagingTwitterPost } from "./PromotionMessagingTwitterPost";
import InputUrlField from "../form/InputUrlField";
import { useFormikContext } from "formik";

export function PromotionMessagingTwitter() {
  const { values } = useFormikContext() as any;
  const enabled = values.promotionSocial.twitterEnabled;

  return (
    <div className="messaging-tab-body-layout">
      <div className="d-flex flex-column flex-md-row">
        <div className="flex-1 mr-md-3 client-promotion-edit-messaging-twitter-controls">
          <SwitchField name="promotionSocial.twitterEnabled" text="Twitter" />

          <InputWithCountField
            type="textarea"
            maxLength={200}
            label="Headline"
            disabled={!enabled}
            withErrors={false}
            isRequired={true}
            className="messaging-form-item"
            name="promotionSocial.twitterHeadline"
            footerTextClassName="messaging-form-item-characters"
            footerContainerClassName="messaging-form-item-footer"
            placeholder="Twitter post headline goes here"
          />

          <InputUrlField active={enabled} values={values} valueName="twitterOverrideUrl" />
        </div>
        <PromotionMessagingTwitterPost />
      </div>
    </div>
  );
}
