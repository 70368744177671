import React from "react";

export function CalendarIcon({ size = 25, ...svgProps }) {
  return (
    <svg
      {...svgProps}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 25 25"
    >
      <g fill="none" fillRule="nonzero">
        <path
          fill="#002049"
          d="M9 13.546A.546.546 0 0 0 8.453 13H6.546a.546.546 0 0 0-.546.546v1.907c0 .302.245.547.546.547h1.907A.546.546 0 0 0 9 15.453v-1.907zM14 13.546a.546.546 0 0 0-.546-.546h-1.908a.546.546 0 0 0-.546.546v1.907c0 .302.245.547.546.547h1.908a.546.546 0 0 0 .546-.547v-1.907zM19 13.546a.546.546 0 0 0-.546-.546h-1.907a.546.546 0 0 0-.547.546v1.907c0 .302.245.547.547.547h1.907a.546.546 0 0 0 .546-.547v-1.907zM9 18.546A.546.546 0 0 0 8.453 18H6.546a.546.546 0 0 0-.546.546v1.908c0 .302.245.546.546.546h1.907A.546.546 0 0 0 9 20.454v-1.908zM14 18.546a.546.546 0 0 0-.546-.546h-1.908a.546.546 0 0 0-.546.546v1.908c0 .302.245.546.546.546h1.908a.546.546 0 0 0 .546-.546v-1.908zM19 18.546a.546.546 0 0 0-.546-.546h-1.907a.546.546 0 0 0-.547.546v1.908c0 .302.245.546.547.546h1.907a.546.546 0 0 0 .546-.546v-1.908z"
        />
        <path
          fill="#025692"
          d="M22.775 2.011v3.13a2.541 2.541 0 0 1-2.532 2.554h-1.597a2.554 2.554 0 0 1-2.547-2.554V2H8.901v3.14a2.554 2.554 0 0 1-2.547 2.555H4.757A2.54 2.54 0 0 1 2.225 5.14V2.01C1.004 2.048 0 3.071 0 4.328v18.34C0 23.947 1.026 25 2.292 25h20.416C23.972 25 25 23.946 25 22.667V4.328c0-1.257-1.004-2.28-2.225-2.317zm-.742 19.514a.997.997 0 0 1-.99 1.003H3.913a.997.997 0 0 1-.99-1.003v-9.472a.997.997 0 0 1 .99-1.003h17.128c.547 0 .991.449.991 1.003v9.472z"
        />
        <path
          fill="#002049"
          d="M4.047 7h1.906C6.53 7 7 6.6 7 6.108V.893C7 .4 6.531 0 5.953 0H4.047C3.47 0 3 .4 3 .893v5.215C3 6.6 3.469 7 4.047 7zM19.047 7h1.906C21.531 7 22 6.6 22 6.108V.893C22 .4 21.531 0 20.953 0h-1.906C18.47 0 18 .4 18 .893v5.215c0 .493.469.892 1.047.892z"
        />
      </g>
    </svg>
  );
}
