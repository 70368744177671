import { BaseApi } from "../BaseApi";

export class FilesApi extends BaseApi {
  uploadFile(body) {
    return this.post("upload/upload", { body }).then(({ data }) => data);
  }
  uploadLogo(body) {
    return this.post("upload/upload-logo", { body }).then(({ data }) => data);
  }

  uploadFont(body) {
    return this.post("upload/upload-font", { body }).then(({ data }) => data);
  }

  importTeamMembers(body) {
    return this.post("team/import", { body }).then(({ data }) => data);
  }

  deleteFont(id) {
    return this.delete("settings/fonts/:id", { params: { id } }).then(({ data }) => data);
  }
}
