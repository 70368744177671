import "./assets/container-loader.scss";

import React from "react";

import { Loader } from "./Loader";

interface Props {
  readonly show: boolean;
  readonly color?: string;
}

export function ContainerLoader({ show, color }: Props) {
  if (!show) {
    return null;
  }

  return (
    <div className="d-flex align-items-center justify-content-center position-fixed bg-white-50 container-loader">
      <Loader color={color} />
    </div>
  );
}
