import React from "react";
import "./assets/team-account.scss";
import { Field, Formik } from "formik";
import { TeamAccountHeader } from "./TeamAccountHeader";
import { ChangePasswordHeader } from "./ChangePasswordHeader";
import { PhoneField } from "../form/PhoneField";
import { Form } from "formik-antd";
import { InputField } from "../form/InputField";

interface Props {
  readonly user: any;
  readonly onDeleteClick?: () => void;
  readonly onSubmit: (values: any) => void;
  readonly handleChangePassword: (values: any) => void;
  readonly loading: boolean;
}

export function TeamAccount({
  user,
  onSubmit,
  onDeleteClick,
  handleChangePassword,
  loading,
}: Props) {
  return (
    <div className="team-account-row my-5">
      <div className="pt-4 d-flex flex-column align-items-center justify-content-center">
        <Formik
          onSubmit={onSubmit}
          enableReinitialize={true}
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            phone: user?.phone,
          }}
        >
          {({ handleSubmit, isSubmitting, errors, touched }) => (
            <form
              onSubmit={handleSubmit}
              className="team-account container mt-5 font-family-museo-sans"
            >
              <TeamAccountHeader saving={isSubmitting} onDeleteClick={onDeleteClick} />
              <div className="mb-4 input-field d-flex flex-column">
                <Field
                  maxLength="15"
                  name="firstName"
                  label="First Name:"
                  className="ant-input field-input flex-shrink-1 flex-grow-1 fs-3 px-0 text-dark font-weight-light bg-dark-white px-2"
                  placeholder="Enter your first name"
                />
              </div>
              <div className="mb-4 input-field d-flex flex-column">
                <Field
                  maxLength="15"
                  name="lastName"
                  label="Last Name:"
                  className="ant-input field-input flex-shrink-1 flex-grow-1 fs-3 px-0 text-dark font-weight-light bg-dark-white px-2"
                  placeholder="Enter your last name"
                />
              </div>
              <div className="mb-4 input-field d-flex flex-column">
                <PhoneField
                  name="phone"
                  placeholder="Enter phone"
                  containerClass="ant-input field-input flex-shrink-1 flex-grow-1 fs-3 px-0 text-dark font-weight-light bg-dark-white px-2"
                />
              </div>
              {errors.phone && touched.phone && <div style={{ color: "red" }}>{errors.phone}</div>}
            </form>
          )}
        </Formik>

        <Formik
          onSubmit={handleChangePassword}
          initialValues={{ currentPassword: "", newPassword: "" }}
        >
          <Form className="team-account container mt-5 font-family-museo-sans">
            <ChangePasswordHeader saving={loading} />
            <Form.Item name="currentPassword" label="Current Password" className="mt-4">
              <InputField
                type="password"
                autocomplete="new-password"
                name="currentPassword"
                className="mb-4"
                transparent={false}
                placeholder="Your Current Password"
                showError={false}
              />
            </Form.Item>

            <Form.Item name="newPassword" label="New Password">
              <InputField
                type="password"
                name="newPassword"
                className="mb-4"
                transparent={false}
                placeholder="Your New Password"
                showError={false}
              />
            </Form.Item>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
