import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

// @ts-ignore
import logo from "./assets/logo.png";

interface Props {
  readonly className?: string;
  readonly onClick?: () => void;
}

export function AppLogo({ className, onClick }: Props) {
  return (
    <Link to="/" onClick={onClick} className={cx("logo", className)}>
      <img className="d-none d-md-inline" draggable="false" src={logo} alt="logo" />
      <img className="d-inline d-md-none" width={60} draggable="false" src={logo} alt="logo" />
    </Link>
  );
}
