export const tokenizeCard = (token, data) => {
  return new Promise((resolve, reject) => {
    const { braintree } = window;

    const client = new braintree.api.Client({ clientToken: token });

    client.tokenizeCard(data, (error, nonce) => {
      if (error) {
        reject(error);
      }

      if (!error && nonce) {
        resolve(nonce);
      }
    });
  });
};
