import "./assets/team-app-header.scss";

import React, { useMemo } from "react";

import { AppLogo } from "./AppLogo";
import { AppMenu } from "./AppMenu";
import { AppHeader } from "./AppHeader";
import { TopMenuItem } from "./TopMenuItem";
import { MainMenuItem } from "./MainMenuItem";
import { AppRoutes } from "../../constants/Routes";
import { replaceParams } from "../../helpers/UrlUtils";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useAuthContext } from "../../api/auth/AuthContext";
import { useSettings, useUser } from "../../context/auth/hooks";

export function TeamAppHeader() {
  const { user } = useUser();
  const { isMobile } = useMediaQuery();

  const { logout } = useAuthContext();
  const { settings } = useSettings();

  const loggedUser = useMemo(() => {
    if (user) {
      if (isMobile) {
        return user.firstName || user.lastName || "User ID";
      }

      return ["Logged in as", user.firstName, user.lastName].filter(Boolean).join(" ");
    }
  }, [user, isMobile]);

  const color = useMemo(() => {
    if (settings?.primaryColor) {
      return settings?.primaryColor;
    }

    return "#002049";
  }, [settings]);
  const logo = useMemo(() => {
    if (settings?.logo?.image) {
      return settings.logo.image;
    }
  }, [settings]);

  return (
    <AppHeader
      className="team-app-header"
      logoContainerClassName="justify-content-end"
      logoComponent={
        logo ? (
          <div className="d-flex flex-column align-items-center logo-container">
            <img src={logo} alt="logo" className="logo" />
          </div>
        ) : (
          <AppLogo />
        )
      }
      topMenuComponent={
        <div className="d-flex">
          <TopMenuItem
            to={user?.isAdministrator ? AppRoutes.AdminProfile : AppRoutes.TeamPortalAccount}
          >
            {loggedUser}
          </TopMenuItem>
          {Boolean(user?.isAdministrator) && (
            <TopMenuItem to={replaceParams(AppRoutes.AdminSettings, { tab: "overview" })}>
              Settings
            </TopMenuItem>
          )}
          <TopMenuItem onClick={() => logout()}>Logout</TopMenuItem>
        </div>
      }
      mainMenuComponent={
        <AppMenu>
          {({ onClose }) => (
            <div className="d-flex flex-column flex-md-row px-3 px-sm-0">
              <MainMenuItem
                style={{ color }}
                onClick={() => onClose()}
                to={AppRoutes.TeamPortalPromotions}
                className="fl-3 fl-md-5 text-center text-sm-left py-md-2 py-0 px-1"
              >
                Promotions
              </MainMenuItem>
            </div>
          )}
        </AppMenu>
      }
    />
  );
}
