import "./assets/promotion-background-search-dialog-wrapper.scss";

import Jimp from "jimp";
import { toFinite } from "lodash";
import { update } from "immupdate";
import React, { useCallback, useMemo, useState } from "react";

import { Dialog } from "../ui/Dialog";
import { Loader } from "../ui/Loader";
import { getErrorMessage } from "../../utils/utils";
import { useResource } from "../../hooks/useResource";
import { toCamelCase } from "../../helpers/CaseUtils";
import { useNotification } from "../../hooks/useNotification";
import { SearchBackgroundForm } from "./SearchBackgroundForm";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useOtherContext } from "../../api/other/useOtherContext";
import { PixabaySearchImageProps } from "../../api/other/PixabayDTO";
import { PromotionSearchBackgroundItem } from "./PromotionSearchBackgroundItem";

interface Props {
  readonly show: boolean;
  readonly onCancel: () => void;
  readonly onSelect: (image: string) => void;
}

const INITIAL = {
  page: 1,
  list: [],
  search: "",
  pageCount: 0,
};

export function PromotionBackgroundSearchDialogWrapper({ show, onCancel, onSelect }: Props) {
  const [pixabayImage, setPixabayImage] = useState(INITIAL);
  const [loading, setLoading] = useState(false);
  const [lastValues, setLastValues] = useState<PixabaySearchImageProps>({});

  const { showErrorNotificationLegacy } = useNotification();

  const { PixabayApi } = useOtherContext();

  const listResource = useResource(() => PixabayApi.searchImage(), [show]);

  useResourceHandler(listResource, {
    onSuccess: ({ totalHits = 1, hits = [] }) => {
      setPixabayImage({
        page: 1,
        list: hits,
        search: "",
        pageCount: Math.max(Math.ceil(totalHits / 20), 1),
      });
    },
  });

  const processing = useMemo(() => listResource.loading || loading, [
    loading,
    listResource.loading,
  ]);

  const searchHandle = useCallback(
    (values: PixabaySearchImageProps) => {
      setLoading(true);

      PixabayApi.searchImage(values)
        .then(({ totalHits = 1, hits = [] }) => {
          if (toFinite(values.page) > 1) {
            setPixabayImage({
              search: values.q as string,
              page: values.page as number,
              list: [...pixabayImage.list, ...toCamelCase(hits)] as any,
              pageCount: Math.max(Math.ceil(totalHits / 20), 1),
            });
          } else {
            setPixabayImage({
              page: 1,
              search: values.q as string,
              list: toCamelCase(hits),
              pageCount: Math.max(Math.ceil(totalHits / 20), 1),
            });
          }

          setLastValues(values);

          setLoading(false);
        })
        .catch((e) => {
          showErrorNotificationLegacy({
            message: "Pixabay",
            description: getErrorMessage(e),
          });

          setLoading(false);
        });
    },
    [PixabayApi, showErrorNotificationLegacy, pixabayImage],
  );

  const scrollHandle = useCallback(
    ({ target }) => {
      const scrollBottom = target.scrollTop + target.offsetHeight === target.scrollHeight;

      // @ts-ignore
      if (scrollBottom && pixabayImage.page < pixabayImage.pageCount) {
        // @ts-ignore
        searchHandle(update(lastValues, { page: pixabayImage.page + 1, q: pixabayImage.search }));
      }
    },
    [pixabayImage, lastValues, searchHandle],
  );

  const selectHandle = useCallback(
    async (link: string) => {
      try {
        setLoading(true);

        const image = await Jimp.read(link);

        if (image) {
          // @ts-ignore
          const src = await image.getBase64Async(Jimp.AUTO);

          onSelect(src);

          setLoading(false);
        } else {
          throw new Error("Internal error");
        }
      } catch (e) {
        showErrorNotificationLegacy({
          message: "Jimp",
          description: getErrorMessage(e),
        });

        setLoading(false);
      }
    },
    [onSelect, showErrorNotificationLegacy],
  );

  return (
    <Dialog
      show={show}
      footer={null}
      onCancel={onCancel}
      destroyOnClose={true}
      title="Search Background"
      className="promotion-background-search-dialog-wrapper"
    >
      <SearchBackgroundForm
        onSubmit={searchHandle}
        initialValues={{ page: 1, q: "" }}
        onResetClick={() => searchHandle(update(lastValues, { page: 1, q: "" }))}
      />

      <div
        onScroll={scrollHandle}
        id="BackgroundSearchListId"
        className="d-flex flex-wrap justify-content-center overflow-auto dialog-body"
      >
        {processing && <Loader className="position-absolute dialog-loader" />}

        {Boolean(!processing && pixabayImage.list.length === 0) && (
          <span className="text-center mt-3">No Match</span>
        )}

        {pixabayImage.list.map((item: any) => (
          <PromotionSearchBackgroundItem key={item.id} onClick={selectHandle} item={item} />
        ))}
      </div>
    </Dialog>
  );
}
