import React, { ReactNode } from "react";
import cx from "classnames";
import { Input as AntdInput } from "formik-antd";

interface Props {
  readonly after?: ReactNode;
  readonly className?: string;
  readonly transparent?: boolean;
  readonly inputClassName?: string;
  readonly disabled?: boolean;
  readonly value?: string;
  readonly addonBefore?: JSX.Element;
  readonly onChange?: (arg: any) => void;
  readonly type?: string;
  readonly autocomplete?: string;
}

export function Input({
  className,
  inputClassName,
  transparent = true,
  type,
  autocomplete = "off",
  ...props
}: Props) {
  return (
    <div
      className={cx(
        "d-flex",
        {
          "bg-dark-white": !transparent,
        },
        className,
      )}
    >
      {/*
         // @ts-ignore */}
      <AntdInput
        type={type}
        autoComplete={autocomplete}
        {...props}
        className={cx(
          "field-input flex-shrink-1 flex-grow-1 fs-3 px-0 text-dark font-weight-light",
          {
            "bg-dark-white px-2": !transparent,
          },
          inputClassName,
        )}
      />

      {props.after}
    </div>
  );
}
