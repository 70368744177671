import "./assets/promotion-background-upload.scss";

import PropTypes from "prop-types";
import { Button, Upload } from "antd";
import React, { useEffect, useMemo, useState } from "react";

import { Loader } from "../ui/Loader";
import { ImageUploadIcon } from "../icons/ImageUploadIcon";

PromotionBackgroundUpload.propTypes = {
  loading: PropTypes.bool,
  image: PropTypes.string,
  width: PropTypes.number,
  onEditClick: PropTypes.func,
  onUploadClick: PropTypes.func,
  onSearchClick: PropTypes.func,
  options: PropTypes.object,
};

export function PromotionBackgroundUpload({
  image,
  width,
  loading,
  onEditClick,
  onUploadClick,
  onSearchClick,
  options,
}) {
  const [clientWidth, setClientWidth] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      const element = document.querySelector("#PreviewImageId");

      if (element) {
        setClientWidth(element.clientWidth);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const height = useMemo(() => (clientWidth || 599) / 1.9, [clientWidth]);

  return (
    <div
      style={{ maxWidth: width }}
      className="promotion-background-upload mb-3 mb-lg-0 mr-lg-3 position-relative"
    >
      <div
        onClick={() => {
          if (!loading) {
            onEditClick();
          }
        }}
        className="d-flex flex-column position-relative cursor-pointer mb-2 builder-background-upload-button overflow-hidden"
        style={{ background: "#b1abab" }}
      >
        {Boolean(!image) && (
          <div className="d-flex flex-column align-items-center justify-content-center p-3">
            <ImageUploadIcon />
            <div className="text-center builder-background-upload-button-text">
              Upload a PNG or JPG 1200x630
            </div>
          </div>
        )}

        {Boolean(image) && (
          <img
            id="PreviewImageId"
            src={image}
            style={{
              maxWidth: width,
              height,
              maxHeight: 315,
              filter: `blur(${options.blur.toString()}px) brightness(${options.brightness.toString()}%) saturate(${options.saturation.toString()}%)`,
            }}
            width={600}
            height={315}
            alt="preview"
          />
        )}

        {loading && <Loader className="position-absolute-fill" />}
      </div>

      <div className="d-flex flex-column flex-md-row">
        <div className="flex-1">
          <Upload
            className="w-100"
            showUploadList={false}
            accept="image/jpeg,image/png"
            customRequest={onUploadClick}
          >
            <Button type="primary" disabled={loading} className="mb-2 mr-md-2">
              upload a background
            </Button>
          </Upload>
        </div>

        <div className="flex-1">
          <Button
            block={true}
            type="primary"
            disabled={loading}
            className="ml-md-2"
            onClick={onSearchClick}
          >
            search free backgrounds
          </Button>
        </div>
      </div>
    </div>
  );
}
