export const ANCHOR_JUSTIFY_TOP = "TOP";
export const ANCHOR_JUSTIFY_MIDDLE = "MIDDLE";
export const ANCHOR_JUSTIFY_BOTTOM = "BOTTOM";

export const ANCHOR_ALIGN_LEFT = "LEFT";
export const ANCHOR_ALIGN_RIGHT = "RIGHT";
export const ANCHOR_ALIGN_CENTER = "CENTER";

export const ANCHOR_TOP_LEFT = "TOP_LEFT";
export const ANCHOR_TOP_RIGHT = "TOP_RIGHT";
export const ANCHOR_TOP_CENTER = "TOP_CENTER";

export const ANCHOR_MIDDLE_LEFT = "MIDDLE_LEFT";
export const ANCHOR_MIDDLE_RIGHT = "MIDDLE_RIGHT";
export const ANCHOR_MIDDLE_CENTER = "MIDDLE_CENTER";

export const ANCHOR_BOTTOM_LEFT = "BOTTOM_LEFT";
export const ANCHOR_BOTTOM_RIGHT = "BOTTOM_RIGHT";
export const ANCHOR_BOTTOM_CENTER = "BOTTOM_CENTER";

export const ANCHOR_CENTER = "MIDDLE_CENTER";

export const LOGO_ANCHOR_OPTIONS = [
  { text: "Top Left", value: ANCHOR_TOP_LEFT },
  { text: "Top Center", value: ANCHOR_TOP_CENTER },
  { text: "Top Right", value: ANCHOR_TOP_RIGHT },
  { text: "Middle Left", value: ANCHOR_MIDDLE_LEFT },
  { text: "Middle Center", value: ANCHOR_MIDDLE_CENTER },
  { text: "Middle Right", value: ANCHOR_MIDDLE_RIGHT },
  { text: "Bottom Left", value: ANCHOR_BOTTOM_LEFT },
  { text: "Bottom Center", value: ANCHOR_BOTTOM_CENTER },
  { text: "Bottom Right", value: ANCHOR_BOTTOM_RIGHT },
];

export const HEADLINE_ANCHOR_OPTIONS = [
  { text: "Top", value: ANCHOR_TOP_CENTER },
  { text: "Middle", value: ANCHOR_MIDDLE_CENTER },
  { text: "Bottom", value: ANCHOR_BOTTOM_CENTER },
];

export const HEADLINE_ALIGN_OPTIONS = [
  { text: "Left", value: "left" },
  { text: "Center", value: "center" },
  { text: "Right", value: "right" },
];
