import "./assets/delete-promotion-dialog.scss";

import React from "react";

import { DeleteDialog } from "../ui/DeleteDialog";

export function DeletePromotionDialog({ promotion = {}, loading, show, onCancel, onDelete }) {
  return (
    <DeleteDialog show={show} onCancel={onCancel} onOk={onDelete} confirmLoading={loading}>
      <div className="delete-promotion-dialog">
        <div className="modal-title">Are you sure you want to delete:</div>
        <div className="modal-name">{promotion.name}</div>
        <div className="modal-undone">(This cannot be undone)</div>
      </div>
    </DeleteDialog>
  );
}
