import React, { useState } from "react";
import { Loader } from "../../components/ui/Loader";
import { PublicLayout } from "../../core/layout/public/PublicLayout";
import { ForgotTitle } from "../../components/auth/ForgotTitle";
import { useAuthContext } from "../../api/auth/AuthContext";
import { useNotification } from "../../hooks/useNotification";
import { Form } from "formik-antd";
import { Button } from "antd";
import { Formik } from "formik";
import { object, string } from "yup";
import { InputField } from "../../components/form/InputField";

const validationSchema = object({
  email: string().required("Email is required"),
});

export function ForgotPasswordAdminContainer() {
  const { AuthApi } = useAuthContext();
  const { showSuccessNotification, showError } = useNotification();
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);
    return AuthApi.forgotPasswordAdmin(values.email)
      .then(() => {
        setLoading(false);
        showSuccessNotification({
          message: "Reset password instruction was sent to your email",
        });
      })
      .catch((error) => {
        setLoading(false);
        showError(error);
      });
  };

  return (
    <PublicLayout title={<ForgotTitle />} HTMLHeadTitle="Sign In">
      {loading && <Loader className="mb-2" />}

      <Formik initialValues={{ email: "" }} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form className="d-flex flex-column sign-in-form">
          <InputField
            name="email"
            label="Email"
            transparent={false}
            placeholder="Your email"
            className="mb-4"
          />
          <Button loading={loading} htmlType="submit" className="mb-1" type="primary">
            RESET PASSWORD
          </Button>
        </Form>
      </Formik>
    </PublicLayout>
  );
}
