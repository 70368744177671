import "./assets/linkedin-post-dialog.scss";
import { Formik, Form } from "formik";
import React, { useCallback, useState } from "react";

import { Dialog } from "../ui/Dialog";
import { object, string } from "yup";

import { Button } from "antd";
import { TextareaField } from "../form/TextareaField";
import { getErrorMessage } from "../../utils/utils";
import { isEmpty } from "lodash";
import { usePromotionContext } from "../../api/promotions/PromotionContext";
import { useNotification } from "../../hooks/useNotification";
import PropTypes from "prop-types";

const validationSchema = object({
  comment: string().required("Comment is required"),
});

export function PromotionPostLinkedInDialog({ show, onCancel, onSubmit, targetUrl, submitId }) {
  const { PromotionApi } = usePromotionContext();
  const [loading, setLoading] = useState(false);
  const { showError } = useNotification();

  const onPostCommentToLinkedin = useCallback(
    ({ comment }) => {
      setLoading(true);

      let requestComment = comment;
      if (!isEmpty(targetUrl)) {
        requestComment = `${comment} \n\n ${targetUrl}`;
      }

      PromotionApi.postToLinkedIn({
        orderId: submitId,
        comment: requestComment,
      })
        .then((response) => {
          const url = response?.data;

          setLoading(false);

          onSubmit(url);
        })
        .catch((e) => {
          showError(getErrorMessage(e));
          setLoading(false);
        });
    },
    [PromotionApi, showError, targetUrl, submitId],
  );

  return (
    <Dialog
      width={400}
      show={show}
      footer={null}
      onCancel={onCancel}
      destroyOnClose={true}
      title="Post to LinkedIn"
      className="d-flex flex-column linkedin-post-dialog"
    >
      <div className="d-flex flex-column p-5">
        <p>The comments entered below will be shown along with your graphic</p>

        <Formik
          validationSchema={validationSchema}
          initialValues={{ comment: "" }}
          onSubmit={onPostCommentToLinkedin}
        >
          {() => (
            <Form className="d-flex mx-2 my-3 pb-3 flex-column">
              <label className="text-primary">Your Comment</label>

              <TextareaField name="comment" rows={4} placeholder="Share your thoughts here" />

              <div className="mt-4 d-flex justify-content-between">
                <Button onClick={onCancel} className="action-button" type="secondary">
                  Cancel
                </Button>

                <Button
                  loading={loading}
                  htmlType="submit"
                  className="action-button"
                  type="primary"
                >
                  Post
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
}

PromotionPostLinkedInDialog.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  targetUrl: PropTypes.string.isRequired,
  submitId: PropTypes.any,
};
