import "./assets/sendTestModal.scss";

import { Button } from "antd";
import { object, string } from "yup";
import { Form, Formik } from "formik";
import React, { useState } from "react";

import { VSBLAlert } from "../../../../widgets/VSBLAlert";
import { useNotification } from "../../../../hooks/useNotification";
import { useRemindersContext } from "../../../../api/reminders/RemindersContext";
import { Dialog } from "../../../../components/ui/Dialog";
import { PhoneField } from "../../../../components/form/PhoneField";
import { useUser } from "../../../../context/auth/hooks";
import { InputField } from "../../../../components/form/InputField";

interface Props {
  readonly reminder?: any;
  readonly visible: boolean;
  readonly hideModal: () => void;
}

export function SendTestModal({ visible, reminder, hideModal }: Props) {
  const { clientId } = useUser();

  const [isSms, setIsSms] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [errorInfo, setErrorInfo] = useState("");
  const { showSuccessNotification } = useNotification();

  const { RemindersApi } = useRemindersContext();

  const validationSmsSchema = object({
    phone: string().required("Enter phone"),
  });

  const validationEmailSchema = object({
    email: string().email("Enter valid email address").required("Enter email"),
  });

  const validationSmsEmailSchema = object({
    // @ts-ignore
    phone: reminder?.sms ? string().required("Enter phone") : undefined,
    // @ts-ignore
    email: reminder?.email
      ? string().email("Enter valid email address").required("Enter email")
      : undefined,
  });

  function handleSubmit(data) {
    setErrorInfo("");

    return RemindersApi.sentTestReminder({
      smsTo: data.phone,
      emailTo: data.email,
      clientId,
      smsText: reminder?.smsText,
      sms: reminder?.sms && isSms,
      email: reminder?.email && isEmail,
      promotionId: reminder?.promotionId,
      emailContent: reminder?.emailContent,
      emailSubject: reminder?.emailSubject,
      reminderType: reminder?.reminderType || reminder?.reminderFor,
    })
      .then(() => {
        hideModal();
        setIsSms(false);
        setIsEmail(false);
        showSuccessNotification({ message: "Sent successfully!" });
      })
      .catch((error) => {
        setIsSms(false);
        setIsEmail(false);

        setErrorInfo(error.toString());
      });
  }

  function handleCancel() {
    hideModal();
    setErrorInfo("");
  }

  return (
    <Dialog
      width={800}
      footer={null}
      show={visible}
      title="Send Test"
      desctroyOnClose={true}
      onCancel={() => handleCancel()}
    >
      <Formik
        validationSchema={
          isSms && !isEmail
            ? validationSmsSchema
            : isEmail && !isSms
            ? validationEmailSchema
            : validationSmsEmailSchema
        }
        initialValues={{ email: "", phone: "" }}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form className="d-flex flex-column p-3" onSubmit={handleSubmit}>
            <VSBLAlert text={errorInfo} span={24} />
            <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
              {reminder?.sms && (
                <div className="d-flex flex-column align-items-center">
                  <PhoneField
                    name="phone"
                    label="Phone"
                    placeholder="Enter phone"
                    containerClass="input-form"
                    className="mb-4"
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="mt-3 mt-sm-0"
                    loading={isSubmitting && isSms}
                    onClick={() => setIsSms(true)}
                  >
                    Send Test
                  </Button>
                </div>
              )}
              {reminder?.email && (
                <div className="d-flex flex-column align-items-center">
                  <InputField
                    name="email"
                    label="Email"
                    transparent={false}
                    placeholder="Your email"
                    className="mb-4"
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="mt-3 mt-sm-0"
                    loading={isSubmitting && isEmail}
                    onClick={() => setIsEmail(true)}
                  >
                    Send Test
                  </Button>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
