import "./assets/color-picker.scss";

import cx from "classnames";
import PropTypes from "prop-types";
import { ChromePicker } from "react-color";
import React, { useRef, useState } from "react";

import { useOutsideClick } from "../../hooks/useOutsideClick";

ColorPicker.propTypes = {
  title: PropTypes.string,
  hideTitle: PropTypes.bool,
  className: PropTypes.string,
  valueClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  onChangeComplete: PropTypes.func,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hideTitleWhenOpened: PropTypes.bool,
  anchor: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom"]),
    horizontal: PropTypes.oneOf(["left", "right"]),
  }),
};

export function ColorPicker({
  value,
  title,
  anchor = { vertical: "top", horizontal: "left" },
  onChange,
  hideTitle,
  className,
  valueClassName,
  buttonClassName,
  onChangeComplete,
  hideTitleWhenOpened,
}) {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useOutsideClick({
    ref,
    active: show,
    onClick: () => setShow(false),
  });

  return (
    <div ref={ref} className={cx("position-relative overflow-visible color-picker", className)}>
      <div
        role="button"
        onClick={() => setShow(true)}
        style={{ backgroundColor: value }}
        className={cx(
          "d-flex align-items-center cursor-pointer justify-content-center picker-button",
          buttonClassName,
        )}
      >
        <span
          className={cx(
            "text-white",
            { "hidden-title": Boolean(hideTitle || (show && hideTitleWhenOpened)) },
            valueClassName,
          )}
        >
          {title || value}
        </span>
      </div>
      {show && (
        <div
          className={cx("position-absolute overflow-visible picker-container p-5", {
            "picker-top": anchor.vertical === "top",
            "picker-left": anchor.horizontal === "left",
            "picker-right": anchor.horizontal === "right",
            "picker-bottom": anchor.vertical === "bottom",
          })}
        >
          <ChromePicker color={value} onChange={onChange} onChangeComplete={onChangeComplete} />
        </div>
      )}
    </div>
  );
}
