import React, { useContext, useMemo } from "react";

import { HOST } from "../constants/ApiConstants";
import { VSBL_APP_ID } from "../constants/AppConstants";

function createContentValue(apiData) {
  return {
    ...apiData,
    host: HOST,
    appId: VSBL_APP_ID,
  };
}

export const ApiContext = React.createContext(createContentValue({}));

export function ApiProvider({ data = {}, ...props }) {
  const value = useMemo(() => createContentValue(data), [data]);

  return <ApiContext.Provider {...props} value={value} />;
}

export function useApiBase() {
  return useContext(ApiContext);
}
