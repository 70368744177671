import { camelCase, isArray, isPlainObject } from "lodash";
import { toCamelCase } from "./CaseUtils";

export interface AppErrorProps<T = any> extends Error {
  data: T;
  status: number;
}

export class AppError implements AppErrorProps {
  public name: string;
  public status: number;
  public message: string;

  data: any;

  constructor({ data, name, status, message }: AppErrorProps) {
    const dataName = isPlainObject(data) ? data.name : name;
    const dataMessage = isPlainObject(data) ? data.message : "";

    this.name = dataName;
    this.status = status;
    this.data = toCamelCase(data);
    this.message = message || dataMessage;
  }

  public getErrors(): string[] {
    return this.data.map(({ userMsg }) => userMsg).filter(Boolean);
  }

  public toString(): string {
    return this.getErrors().join("\n");
  }

  public getFormErrors(): any[] {
    if (isArray(this.data)) {
      return this.data.map(({ field, message }) => ({
        errorText: message,
        field: camelCase(field),
      }));
    }

    return [];
  }
}
