import { Button } from "antd";
import { Formik } from "formik";
import { toFinite } from "lodash";
import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { AppRoutes } from "../../constants/Routes";
import { useResource } from "../../hooks/useResource";
import { useNotification } from "../../hooks/useNotification";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { ContainerLoader } from "../../components/ui/ContainerLoader";
import { useRemindersContext } from "../../api/reminders/RemindersContext";
import { SubmitFormControl } from "../../components/form/SubmitFormControl";
import { RemindersHeader } from "../../components/reminders/RemindersHeader";
import { SendTestModal } from "../../modules/dashboard/reminders/table/SendTestModal";
import { EditReminderBody } from "../../modules/dashboard/reminders/edit/EditReminderBody";
import { path } from "ramda";
import { useUser } from "../../context/auth/hooks";
import useRouter from "../../hooks/useRouter";
import { ClientAppLayout } from "../../components/app/ClientAppLayout";
import moment from "moment";
import {
  findMonthByName,
  findMonthByValue,
  ReminderForType,
} from "../../constants/reminderConstants";
import { object, string } from "yup";

function formatDate(params) {
  if (!params.time && params.startdate) {
    return moment(params.startdate).format("YYYY-MM-DD HH:mm");
  }
  if (!params.startdate && params.time) {
    return `${moment().format("YYYY-MM-DD")} ${moment(params.time).format("HH:mm")}`;
  }

  if (!params.startdate && !params.time) {
    return moment().format("YYYY-MM-DD HH:mm");
  } else {
    return (
      moment(params.startdate).format("YYYY-MM-DD") + " " + moment(params.time).format("HH:mm")
    );
  }
}

export function formatReminderItem(values): any {
  const triggerType = values.reminderFor === "NEW_PROMOTION" ? "DAILY" : values.repeat;
  const departments =
    values.reminderFor === ReminderForType.SINGLE_BROADCAST
      ? (values.departments || []).map((dep) => dep.id)
      : null;

  return {
    active: values.active,
    id: values.id,
    "client-id": values.clientId,
    dayofmonth: values.dayOfMonth,
    "modified-date": 0,
    "modified-member-id": 0,
    name: values.reminderName,
    "reminder-type": values.reminderFor,
    startdate: formatDate(values),
    "trigger-type": triggerType,
    enddate: values.enddate ? moment(values.enddate).format("YYYY-MM-DD hh:mm") : undefined,
    email: values.email,
    "email-content": values.emailContent,
    "email-subject": values.emailSubject,
    sms: values.sms,
    "sms-text": values.smsText,
    monthofyear: Number.isInteger(Number(values.month))
      ? values.month
      : findMonthByName(values.month),
    sunday: values.week[0] ? values.week[0].value : false,
    monday: values.week[1] ? values.week[1].value : false,
    tuesday: values.week[2] ? values.week[2].value : false,
    wednesday: values.week[3] ? values.week[3].value : false,
    thursday: values.week[4] ? values.week[4].value : false,
    friday: values.week[5] ? values.week[5].value : false,
    saturday: values.week[6] ? values.week[6].value : false,
    "promotion-id": values.promotionId,
    month: values.month,
    departments: departments,
  };
}

export function formatInitialReminderItem(item) {
  const time = item.startdate ? moment(item.startdate) : moment(new Date());
  const enddate = item.enddate ? moment(item.enddate) : moment(new Date());

  return {
    ...item,
    time,
    enddate,
    startdate: time,
    email: item.email,
    reminderName: item.name,
    repeat: item.triggerType,
    dayOfMonth: item.dayofmonth,
    reminderFor: item.reminderType,
    month: findMonthByValue(item.monthofyear),
    week: [
      { name: "S", value: item.sunday },
      { name: "M", value: item.monday },
      { name: "T", value: item.tuesday },
      { name: "W", value: item.wednesday },
      { name: "Th", value: item.thursday },
      { name: "F", value: item.friday },
      { name: "S", value: item.saturday },
    ],
  };
}

export const initialReminderItem = {
  active: false,
  reminderName: "",
  reminderFor: "",
  repeat: "ONE_TIME",
  month: "",
  startdate: moment(),
  week: [
    { name: "S", value: false },
    { name: "M", value: false },
    { name: "T", value: false },
    { name: "W", value: false },
    { name: "Th", value: false },
    { name: "F", value: false },
    { name: "S", value: false },
  ],
  time: moment(),
  sms: false,
  smsText: "",
  email: false,
  emailContent: "",
  emailSubject: "",
  promotionId: "",
};

export const remindersValidationSchema = object({
  reminderFor: string().required("Reminder type is required"),
  reminderName: string().required("Reminder name is required"),
  smsText: string().when("sms", (field, schema) =>
    field ? schema.required("Text is is required") : schema,
  ),
  emailSubject: string().when("email", (field, schema) =>
    field ? schema.required("Email subject is required") : schema,
  ),
  emailContent: string().when("email", (field, schema) =>
    field ? schema.required("Email content is required") : schema,
  ),
});

export function RemindersEditContainer() {
  const router = useRouter();
  const params = useParams<any>();
  const { clientId } = useUser();
  const { RemindersApi } = useRemindersContext();
  const { showError, showSuccessNotification } = useNotification();
  const [chosenDepartments, setChosenDepartments] = useState([] as any);

  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [initialValues, setInitialValues] = useState(initialReminderItem);

  const promotionId = useMemo(() => toFinite(params.id), [params]);

  const itemResource = useResource(
    () => (promotionId > 0 ? RemindersApi.getReminderItem(promotionId) : undefined),
    [promotionId],
  );

  useResourceHandler(itemResource);

  useEffect(() => {
    if (itemResource.data) {
      setInitialValues(formatInitialReminderItem(itemResource.data));
    }
  }, [itemResource.data]);

  const onSubmit = useCallback(
    (data: any, close = false) => {
      setLoading(true);
      const values = {
        ...formatReminderItem({ ...data, departments: chosenDepartments }),
        clientId,
      };
      if (promotionId === 0) {
        return RemindersApi.createReminder(values)
          .then((response) => {
            const id = path(["data", "id"], response);
            if (close) {
              router.push(AppRoutes.AdminReminders);
            } else {
              router.push(AppRoutes.AdminRemindersItem, { id });
            }
            showSuccessNotification({ message: "Reminder created" });
            setLoading(false);
          })
          .catch((e) => {
            showError(e);
            setLoading(false);
          });
      } else {
        return RemindersApi.updateReminder(values)
          .then((response) => {
            const id = path(["data", "id"], response);
            if (close) {
              router.push(AppRoutes.AdminReminders);
            } else {
              router.push(AppRoutes.AdminRemindersItem, { id });
            }
            showSuccessNotification({ message: "Reminder updated" });
            setLoading(false);
          })
          .catch((e) => {
            showError(e);
            setLoading(false);
          });
      }
    },
    [
      router,
      RemindersApi,
      clientId,
      promotionId,
      showError,
      showSuccessNotification,
      chosenDepartments,
    ],
  );

  return (
    <ClientAppLayout>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={remindersValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setValues, isValid }) => {
          const isSaveAble = isValid && Boolean(values.reminderName) && Boolean(values.reminderFor);
          return (
            <form className="reminders-edit-container">
              <RemindersHeader
                title="Reminders"
                className="mb-5"
                button={
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                      setValues(initialReminderItem, false);
                      router.push(AppRoutes.AdminRemindersItem, { id: 0 });
                    }}
                  >
                    + add a new reminder
                  </Button>
                }
              />

              <EditReminderBody
                setChosenDepartments={setChosenDepartments}
                chosenDepartments={chosenDepartments}
                reminder={values}
              />

              <ContainerLoader show={loading || itemResource.loading} />

              <SubmitFormControl
                disabled={!isSaveAble}
                loading={loading}
                onBack={() => router.goBack()}
                onSubmit={(close) => onSubmit(values, close)}
              >
                <Button
                  type="primary"
                  className="border-success bg-success fs-2 fs-sm-3"
                  onClick={() => {
                    if (!values.sms && !values.email) {
                      alert("Please select email or sms");
                    } else {
                      setVisibleModal(true);
                    }
                  }}
                  disabled={!isSaveAble}
                  loading={loading}
                >
                  Send test
                </Button>
              </SubmitFormControl>

              <SendTestModal
                reminder={values}
                visible={visibleModal}
                hideModal={() => setVisibleModal(false)}
              />
            </form>
          );
        }}
      </Formik>
    </ClientAppLayout>
  );
}
