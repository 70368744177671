import "./assets/settings-company-plan.scss";

import { DateTime } from "luxon";
import { capitalize } from "lodash";
import React, { useMemo } from "react";

import { LinkButton } from "../ui/LinkButton";
import { SettingsTitle } from "../settings/SettingsTitle";
import { formatCurrencyCode } from "../../helpers/CurrencyHelpers";
import { SubscriptionProps } from "../../api/chargebee/ChargebeeDTO";
import { propOr } from "ramda";

interface Props {
  readonly onViewHistoryClick: () => void;
  readonly subscription: SubscriptionProps;
}

export function SettingsCompanyPlan({ subscription, onViewHistoryClick }: Props) {
  const plan = useMemo(() => subscription?.plan?.name, [subscription]);
  const planAmount = useMemo(() => {
    const code = formatCurrencyCode(subscription?.currencyCode);
    const amount = subscription?.planAmount / 100;
    const periodUnit = capitalize(subscription?.billingPeriodUnit);

    return `${code}${amount} ${periodUnit}`;
  }, [subscription]);

  const trialEnd = propOr(0, "trialEnd", subscription);
  const trialEnds = DateTime.fromSeconds(trialEnd).toFormat("MM.dd.yyyy");

  return (
    <div className="d-flex flex-column flex-1 mb-6 mb-mb-0 settings-company-plan">
      <SettingsTitle className="current-plan-title">CURRENT PLAN:</SettingsTitle>
      <span color="primary" className="font-family-museo text-primary fs-5 text-capitalize">
        {plan}
      </span>
      <span className="fl-6 font-weight-light text-primary settings-subtitle">{planAmount}</span>
      {Boolean(trialEnd) && (
        <span className="text-primary font-weight-light settings-subtitle">
          Trial Ends {trialEnds}
        </span>
      )}
      <div className="d-flex">
        <LinkButton className="mr-3" onClick={onViewHistoryClick}>
          View billing history
        </LinkButton>
      </div>
    </div>
  );
}
