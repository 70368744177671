import "./assets/settings-company-logo.scss";

import React from "react";

import { SettingsTitle } from "../settings/SettingsTitle";
import { SettingsCompanyColors } from "../settings-company/SettingsCompanyColors";
import { SettingsCompanyLogoField } from "../settings-company/SettingsCompanyLogoField";

export function SettingsCompanyLogo() {
  return (
    <div className="d-flex flex-column flex-md-row flex-lg-column ml-lg-2 mb-6 settings-company-logo">
      <div className="d-flex flex-column flex-grow-1 flex-shrink-1 company-logo-container ml-lg-2">
        <SettingsTitle className="mb-3">Company logo</SettingsTitle>
        <div className="d-flex flex-column flex-md-row flex-lg-column">
          <SettingsCompanyLogoField />
          <SettingsCompanyColors />
        </div>
      </div>
    </div>
  );
}
