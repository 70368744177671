import React, { useMemo } from "react";

import { Dialog } from "../ui/Dialog";
import { BillingHistoryDetails } from "./BillingHistoryDetails";

interface Props {
  readonly profile: any;
  readonly company: any;
  readonly show: boolean;
  readonly customer: any;
  readonly onCancel: () => void;
  readonly invoiceId: string | number;
  readonly invoiceList: any;
}

export function BillingHistoryDetailsDialog({
  show,
  company,
  profile,
  onCancel,
  customer,
  invoiceId,
  invoiceList,
}: Props) {
  const invoice = useMemo(() => invoiceList.find((item) => item.id === invoiceId), [invoiceId]);
  return (
    <Dialog footer={null} width={767} title="Invoice Details" show={show} onCancel={onCancel}>
      <BillingHistoryDetails
        invoice={invoice}
        company={company}
        profile={profile}
        customer={customer}
      />
    </Dialog>
  );
}
