import Jimp from "jimp";
import moment from "moment";
import { DELETE, update } from "immupdate";
import { bool, date, object, string } from "yup";
import { head, isArray, isEmpty, toFinite } from "lodash";

import { combineCanvas } from "./CanvasHelpers";
import { requireWhenSchema } from "./FormUtils";
import { ANCHOR_CENTER } from "../constants/ImageConstants";
import { AVAILABLE_TYPES } from "../constants/PromotionConstants";
import { applyCamanFilters, getImageCoords, getImageSizesForContainer } from "./ImageHelpers";
import * as htmlToImage from "html-to-image";
import { propOr } from "ramda";

export async function getPromotionZones(values = {}) {
  return new Promise((resolve) => {
    if (values.promotionItem && values.promotionItem.dynamicZones) {
      const currentZone =
        values.promotionItem.dynamicZones.find(({ dynamicZoneLayerList }) =>
          Boolean(dynamicZoneLayerList),
        ) || {};

      const list = currentZone.dynamicZoneLayerList || [];

      resolve({
        logoZone: list.find(({ zindex }) => zindex === 3),
        overlayZone: list.find(({ zindex }) => zindex === 2),
        headlineZone: list.find(({ zindex }) => zindex === 4),
        backgroundZone: list.find(({ zindex }) => zindex === 1),
      });
    } else {
      resolve({});
    }
  });
}

export function getPromotionItemStep(steps) {
  if (steps && isArray(steps)) {
    const stepIndex = steps.findIndex(({ name }) => name.toLowerCase() === "promotion item");

    if (stepIndex >= 0) {
      const step = steps[stepIndex];

      const headline = step.fields.find(
        ({ fieldKey, uiType }) => fieldKey === "headline" || uiType === 2,
      );

      return { stepIndex, step, headline };
    }
  }

  return { stepIndex: -1 };
}

export const promotionsValidationSchema = object({
  description: string().required("Description must be provided"),
  name: string().required("Name required"),
  promotionType: string().required("Promotion type required"),
  startDate: date().required("Start date required"),
  endDate: date()
    .nullable(true)
    .test(
      "endDate",
      "The end date must be after the current date",
      (endDate) => !endDate || endDate.getTime() > Date.now(),
    ),
  promotionSocial: object().shape({
    someSocialEnabled: bool().when(
      ["twitterEnabled", "linkedinEnabled", "facebookEnabled", "pinterestEnabled"],
      (twitterEnabled, linkedinEnabled, facebookEnabled, pinterestEnabled, schema) => {
        if (!twitterEnabled && !linkedinEnabled && !facebookEnabled && !pinterestEnabled) {
          return schema.required("Please enable at least one social network");
        }

        return schema;
      },
    ),
    targetUrl: string().required("Please provide target url"),

    twitterHeadline: string().when(
      "twitterEnabled",
      requireWhenSchema("Twitter headline required"),
    ),
    linkedinHeadline: string().when(
      "linkedinEnabled",
      requireWhenSchema("LinkedIn headline required"),
    ),
    facebookHeadline: string().when(
      "facebookEnabled",
      requireWhenSchema("Facebook headline required"),
    ),
    linkedinOverrideUrl: string().when(
      ["linkedinEnabled", "targetUrl"],
      (linkedinEnabled, targetUrl, schema) => {
        if (!linkedinEnabled || targetUrl) {
          return schema.url("Valid URL for linkedin required");
        }

        return schema
          .url("Valid URL for linkedin required")
          .required("Please provide linkedin override target url");
      },
    ),
    facebookOverrideUrl: string().when(
      ["facebookEnabled", "targetUrl"],
      (facebookEnabled, targetUrl, schema) => {
        if (!facebookEnabled || targetUrl) {
          return schema.url("Valid URL for facebook required");
        }

        return schema
          .url("Valid URL for facebook Required")
          .required("Please provide facebook override target url");
      },
    ),
    pinterestOverrideUrl: string().when(
      ["pinterestEnabled", "targetUrl"],
      (pinterestEnabled, targetUrl, schema) => {
        if (!pinterestEnabled || targetUrl) {
          return schema.url("Valid URL for pinterest required");
        }

        return schema
          .url("Valid URL for pinterest required")
          .required("Please provide pinterest override target url");
      },
    ),
    twitterOverrideUrl: string().when(
      ["twitterEnabled", "targetUrl"],
      (twitterEnabled, targetUrl, schema) => {
        if (!twitterEnabled || targetUrl) {
          return schema.url("Valid URL for twitter required");
        }

        return schema
          .url("Valid URL for twitter required")
          .required("Please provide twitter override target url");
      },
    ),
  }),
});

export function formatPromotionSteps(steps = [], options = {}) {
  const { user } = options;

  return steps
    .map((step) => {
      const { fields = [] } = step;

      const mappedFields = fields
        .map((field) => {
          const visible = AVAILABLE_TYPES.includes(field.uiType);

          if (user && field.fieldKey === "first_name") {
            return update(field, {
              visible,
              value: user.firstName,
            });
          }

          if (user && field.fieldKey === "last_name") {
            return update(field, {
              visible,
              value: user.lastName,
            });
          }

          if (user && field.fieldKey === "email_address") {
            return update(field, {
              visible,
              value: user.email,
            });
          }

          return update(field, {
            visible,
          });
        })
        .sort((aField, bField) => aField.fieldNumber - bField.fieldNumber);

      return update(step, {
        fields: mappedFields,
        visible: mappedFields.some(
          ({ visible }) => visible && step.name.toLowerCase() !== "personal details",
        ),
      });
    })
    .sort((aStep, bStep) => aStep.stepnumber - bStep.stepnumber);
}

export function pinterestIsEnabled(promotionSocial) {
  return Boolean(
    promotionSocial &&
      (promotionSocial.pinterestOverrideUrl || promotionSocial.pinterestDescription),
  );
}

export function linkedinIsEnabled(promotionSocial) {
  return Boolean(
    promotionSocial &&
      (promotionSocial.linkedinHeadline ||
        promotionSocial.linkedinOverrideUrl ||
        promotionSocial.linkedinDescription),
  );
}

export function facebookIsEnabled(promotionSocial) {
  return Boolean(
    promotionSocial &&
      (promotionSocial.facebookHeadline ||
        promotionSocial.facebookOverrideUrl ||
        promotionSocial.facebookDescription),
  );
}

export function twitterIsEnabled(promotionSocial) {
  return Boolean(
    promotionSocial && (promotionSocial.twitterHeadline || promotionSocial.twitterOverrideUrl),
  );
}

export function someSocialIsActive(promotionSocial = {}) {
  return (
    promotionSocial.facebookEnabled ||
    promotionSocial.linkedinEnabled ||
    promotionSocial.pinterestEnabled ||
    promotionSocial.twitterEnabled
  );
}

export async function isValidPromotionTemplate(promotion) {
  const zones = await getPromotionZones(promotion);

  const isValidHeadlineZone = Boolean(zones && zones.headlineZone);
  const isValidLogoZone = Boolean(
    zones &&
      zones.logoZone &&
      zones.logoZone.image &&
      zones.logoZone.width &&
      zones.logoZone.height,
  );
  const isValidOverlayZone = Boolean(zones && zones.overlayZone && zones.overlayZone.image);
  const isValidBackgroundZone = Boolean(
    zones && zones.backgroundZone && zones.backgroundZone.image,
  );
  const isValidPromotionItem = Boolean(
    isArray(promotion.promotionSteps) &&
      promotion.promotionSteps.some(({ fields }) => {
        if (isArray(fields)) {
          return fields.some(({ fieldKey }) => fieldKey === "headline");
        }
      }),
  );

  return (
    isValidHeadlineZone &&
    isValidLogoZone &&
    isValidOverlayZone &&
    isValidBackgroundZone &&
    isValidPromotionItem
  );
}

export async function formatSocialItems(promotion, isEdit, company) {
  const { promotionSocial = {} } = promotion;

  return update(promotionSocial, {
    promotionId: isEdit ? promotion.id : DELETE,

    targetUrl: isEdit ? promotionSocial.targetUrl : company.website,

    twitterHeadline: promotionSocial.twitterHeadline,
    linkedinHeadline: promotionSocial.linkedinHeadline,
    facebookHeadline: promotionSocial.facebookHeadline,
    linkedinDescription: promotionSocial.linkedinDescription,
    facebookDescription: promotionSocial.facebookDescription,
    pinterestDescription: promotionSocial.pinterestDescription,
  });
}

export function applyBackgroundFilters(backgroundZone, id = "TemporaryCamanImage") {
  return new Promise((resolve) => {
    const { width, height, imagePreview, options, uniqueIdentifier } = backgroundZone;

    const canvasElement = document.createElement("canvas");

    canvasElement.setAttribute("width", width);
    canvasElement.setAttribute("height", height);
    canvasElement.setAttribute("style", "display:none");
    canvasElement.setAttribute("id", id + uniqueIdentifier);

    document.body.appendChild(canvasElement);

    if (canvasElement && imagePreview) {
      const ctx = canvasElement.getContext("2d");

      const image = new Image();

      image.src = imagePreview;
      image.onload = function () {
        ctx.drawImage(this, 0, 0, width, height);
      };
    }

    const timeout = setTimeout(() => {
      applyCamanFilters(id + uniqueIdentifier, options, (x) => {
        const element = document.body.querySelector(`#${id + uniqueIdentifier}`);

        if (element) {
          document.body.removeChild(element);
        }

        resolve(x);

        clearTimeout(timeout);
      });
    }, 1000);
  });
}

export async function formatPromotionToForm(zones) {
  const overlayJimp = zones.overlayZone.image
    ? await Jimp.read(zones.overlayZone.image)
    : undefined;
  const backgroundJimp = zones.backgroundZone.imagePreview
    ? await Jimp.read(zones.backgroundZone.imagePreview)
    : undefined;

  const overlayCoords = getImageCoords(
    zones.overlayZone.anchorImage,
    zones.overlayZone.width * 2,
    zones.overlayZone.height * 2,
    zones.overlayZone.width * 2,
    zones.overlayZone.height * 2,
  );

  const promotionPreview = await backgroundJimp
    .resize(1200, 630)
    .composite(
      overlayJimp.resize(zones.overlayZone.imageWidth * 2, zones.overlayZone.imageHeigth * 2 + 2),
      zones.overlayZone.foatx * 2 + overlayCoords.left,
      zones.overlayZone.foaty * 2 + overlayCoords.top,
    )
    .getBase64Async(Jimp.AUTO);

  return {
    zones,
    promotionPreview,
  };
}

export async function formatEditInitialPromotion(promotion = {}, wrongTemplate) {
  const promotionSocialItems = await formatSocialItems(promotion, true);

  const promotionSteps = formatPromotionSteps(promotion.promotionSteps);

  const promotionZones = await getPromotionZones(promotion);

  const image = await Jimp.read(promotionZones.logoZone.image);

  const logoSize = getImageSizesForContainer(
    image.bitmap.width,
    image.bitmap.height,
    promotionZones.logoZone.width,
    promotionZones.logoZone.height,
  );

  const newPromotionZones = update(promotionZones, {
    logoZone: update(promotionZones.logoZone, {
      logoWidth: logoSize.width,
      logoHeight: logoSize.height,
    }),
    headlineZone: update(promotionZones.headlineZone, {
      hideHeadline: promotion.hideHeadline,
    }),
    backgroundZone: update(promotionZones.backgroundZone, {
      options: {
        blur: 0,
        scale: 1,
        vibrance: 0,
        saturation: 0,
        brightness: 0,
        position: { x: 0, y: 0 },
        ...(promotionZones.backgroundZone.options || {}),
      },
    }),
  });

  const targetImage = await applyBackgroundFilters(newPromotionZones.backgroundZone);

  const zones = !wrongTemplate
    ? await formatPromotionToForm(
        update(newPromotionZones, {
          backgroundZone: update(newPromotionZones.backgroundZone, {
            targetImage,
          }),
        }),
        promotion.hideLogo,
      )
    : {};

  return update(promotion, {
    ...zones,
    wrongTemplate,
    promotionSteps,
    promotionSocial: promotionSocialItems,
    backgroundConfig: { x: 0, y: 0, scale: 1 },
    endDate: promotion.endDate ? moment(promotion.endDate) : undefined,
    startDate: promotion.startDate ? moment(promotion.startDate) : undefined,
  });
}

export async function formatCreateInitialPromotion(promotion = {}, options = {}) {
  const { company, templateId, values } = options;

  const promotionSocialItems = await formatSocialItems(
    values.wrongTemplate ? values : promotion,
    false,
    company,
  );

  const promotionZones = await getPromotionZones(promotion);
  const prevPromotionZones = await getPromotionZones(values);

  const promotionSteps = formatPromotionSteps(promotion.promotionSteps);

  const promotionItemStep = getPromotionItemStep(promotionSteps) || {};

  let { logoZone = {}, headlineZone = {}, overlayZone = {} } = promotionZones;

  const { backgroundZone = {} } = promotionZones;

  if (company && company.logo && company.logo.url) {
    logoZone = update(logoZone, {
      isLogo: true,
      image: company.logo.image,
      logoWidth: company.logo.width,
      logoHeight: company.logo.height,
      anchorImage: logoZone.anchorImage || ANCHOR_CENTER,
    });
  }

  if (promotionItemStep.headline) {
    const firstValue = head(promotionItemStep.headline.values);

    if (firstValue) {
      headlineZone = update(headlineZone, {
        text: firstValue.value,
      });
    }
  }

  let newBackgroundZone = update(backgroundZone, {
    imagePreview: backgroundZone.image,
    options: {
      blur: 0,
      scale: 1,
      vibrance: 0,
      saturation: 0,
      brightness: 0,
      position: { x: 0, y: 0 },
      ...(backgroundZone.options || {}),
    },
  });

  const targetImage = await applyBackgroundFilters(newBackgroundZone);

  logoZone = update(logoZone, {
    id: prevPromotionZones.logoZone ? prevPromotionZones.logoZone.id : DELETE,
  });
  headlineZone = update(headlineZone, {
    id: prevPromotionZones.headlineZone ? prevPromotionZones.headlineZone.id : DELETE,
  });
  newBackgroundZone = update(newBackgroundZone, {
    targetImage,
    id: prevPromotionZones.backgroundZone ? prevPromotionZones.backgroundZone.id : DELETE,
  });
  overlayZone = update(overlayZone, {
    id: prevPromotionZones.overlayZone ? prevPromotionZones.overlayZone.id : DELETE,
  });

  let { promotionItem } = promotion;

  if (values.wrongTemplate) {
    promotionItem = update(promotionItem, {
      promotionId: values.promotionItem.promotionId,
      promotionItemid: values.promotionItem.promotionItemid,
      dynamicZones: values.promotionItem.dynamicZones
        .map(({ zoneType, id }) => {
          const zone = promotionItem.dynamicZones.find((x) => x.zoneType === zoneType);

          if (zone) {
            return update(zone, { id });
          }
        })
        .filter(Boolean),
    });
  }

  const zones = await formatPromotionToForm(
    update(promotionZones, {
      logoZone,
      overlayZone,
      headlineZone,
      backgroundZone: newBackgroundZone,
    }),
    values.hideLogo,
  );

  return update(promotion, {
    ...zones,
    promotionItem,
    promotionSteps,
    hideLogo: false,
    promotionType: values.promotionType,
    otherPromotion: values.otherPromotion,
    name: values.name,
    status: values.status,
    clonedFromId: templateId,
    description: values.description,
    promotionSocial: promotionSocialItems,
    backgroundConfig: { x: 0, y: 0, scale: 1 },
    endDate: values.endDate ? moment(values.endDate) : undefined,
    startDate: values.startDate ? moment(values.startDate) : undefined,
  });
}

export async function formatTemplateInitialPromotion(promotion = {}, options = {}) {
  const { values = {}, templateId, company } = options;

  const promotionItemStep = getPromotionItemStep(values.promotionSteps) || {};

  const promotionSocialItems =
    Object.keys(values.promotionSocial).length > 0
      ? values.promotionSocial
      : await formatSocialItems(values.wrongTemplate ? values : promotion, false, company);

  const promotionZones = await getPromotionZones(promotion);
  const prevPromotionZones = values.zones;

  let { headlineZone = {}, overlayZone = {} } = promotionZones;
  let newLogoZone = {};

  if (promotionZones.logoZone) {
    const newWidth = company?.logo?.width
      ? company?.logo?.width
      : prevPromotionZones.logoZone.width;
    const newHeight = company?.logo?.height
      ? company?.logo?.height
      : prevPromotionZones.logoZone.height;
    newLogoZone = update(promotionZones.logoZone, {
      image: prevPromotionZones.logoZone.image,
      logoWidth: newWidth,
      logoHeight: newHeight,
      anchorImage: promotionZones.logoZone.anchorImage || ANCHOR_CENTER,
    });
  }

  if (promotionItemStep.headline) {
    const firstValue = head(promotionItemStep.headline.values);

    if (firstValue) {
      headlineZone = update(headlineZone, {
        text: firstValue.value,
        color: prevPromotionZones.headlineZone.color,
      });
    }
  }

  newLogoZone = update(newLogoZone, {
    id: prevPromotionZones.logoZone.id,
  });
  headlineZone = update(headlineZone, {
    id: prevPromotionZones.headlineZone.id,
  });
  overlayZone = update(overlayZone, {
    id: prevPromotionZones.overlayZone.id,
  });

  const zones = await formatPromotionToForm(
    update(promotionZones, {
      overlayZone,
      headlineZone,
      logoZone: newLogoZone,
      backgroundZone: prevPromotionZones.backgroundZone,
    }),
    values.hideLogo,
  );

  return update(values, {
    ...zones,
    hideLogo: false,
    wrongTemplate: DELETE,
    clonedFromId: templateId,
    promotionSocial: promotionSocialItems,
    backgroundConfig: { x: 0, y: 0, scale: 1 },
  });
}

export const getHeadlineHeight = (zone) => {
  const { size } = zone;
  return size;
};

export async function formatPromotionValues(values = {}) {
  const {
    promotionSocial = {},
    endDate,
    startDate,
    promotionType,
    otherPromotion,
    departments,
  } = values;

  const departmentArray = departments.map((department) => ({
    id: department.id,
    value: department.departmentName,
  }));
  const promotionTypeValue = promotionType === "other" ? otherPromotion : promotionType;

  const facebookSocial = {
    facebookEnabled: promotionSocial.facebookEnabled,
    facebookHeadline: promotionSocial.facebookHeadline,
    facebookOverrideUrl: promotionSocial.facebookOverrideUrl,
    facebookDescription: promotionSocial.facebookDescription,
  };
  const linkedinSocial = {
    linkedinEnabled: promotionSocial.linkedinEnabled,
    linkedinHeadline: promotionSocial.linkedinHeadline,
    linkedinOverrideUrl: promotionSocial.linkedinOverrideUrl,
    linkedinDescription: promotionSocial.linkedinDescription,
  };
  const twitterSocial = {
    twitterEnabled: promotionSocial.twitterEnabled,
    twitterHeadline: promotionSocial.twitterHeadline,
    twitterOverrideUrl: promotionSocial.twitterOverrideUrl,
  };
  const pinterestSocial = {
    pinterestEnabled: promotionSocial.pinterestEnabled,
    pinterestOverrideUrl: promotionSocial.pinterestOverrideUrl,
    pinterestDescription: promotionSocial.pinterestDescription,
  };

  const node = document.getElementById("TemplatePreviewDndTarget");

  const image = node ? await htmlToImage.toPng(node) : values.promotionItem.image;

  const promotionItem = update(values.promotionItem, {
    image: image,
    dynamicZones: values.promotionItem.dynamicZones.map((zone) => {
      if (zone && zone.dynamicZoneLayerList) {
        return update(zone, {
          dynamicZoneLayerList: [
            values.zones.backgroundZone,
            values.zones.overlayZone,
            values.zones.logoZone,
            values.zones.headlineZone,
          ],
        });
      }

      return zone;
    }),
  });

  const promotionSteps = propOr([], "promotionSteps", values);
  const promotionNotItemSteps = promotionSteps.filter((step) => step.name !== "Promotion Item");
  const promotionItemStep = promotionSteps.find((step) => step.name === "Promotion Item");
  const headlineField = promotionItemStep.fields.find((field) => field.fieldKey === "headline");
  const notHeadlineFields = promotionItemStep.fields.filter(
    (field) => field.fieldKey !== "headline",
  );
  const headlineFields = headlineField.values.filter((value) => Boolean(value.value));
  const defaultHeadline = {
    id: headlineField.values[0].id,
    value: "Default headline",
    value2: null,
    value2Id: null,
    value2Type: null,
    value2Url: null,
  };
  const newHeadlineField = {
    ...headlineField,
    values: headlineFields.length === 0 ? [defaultHeadline] : headlineFields,
  };
  const newPromotionItemStep = {
    ...promotionItemStep,
    fields: [...notHeadlineFields, newHeadlineField],
  };
  const newPromotionSteps = [...promotionNotItemSteps, newPromotionItemStep];
  const targetUrlHttp = promotionSocial.targetUrl.indexOf("http");
  const newTargetUrl =
    targetUrlHttp > -1 ? promotionSocial.targetUrl : `https://${promotionSocial.targetUrl}`;

  return update(values, {
    promotionItem,
    promotionType: promotionTypeValue,
    promotionSteps: newPromotionSteps,
    endDate: endDate ? toFinite(moment(endDate).format("x")) : 0,
    startDate: startDate ? toFinite(moment(startDate).format("x")) : 0,
    promotionSocial: {
      targetUrl: newTargetUrl,
      promotionId: promotionSocial.promotionId,

      ...twitterSocial,
      ...facebookSocial,
      ...linkedinSocial,
      ...pinterestSocial,
    },
    zones: DELETE,
    backgroundConfig: DELETE,
    promotionPreview: DELETE,
    departments: departmentArray,
    hideHeadline: values.zones.headlineZone.hideHeadline,
  });
}

export async function formatPromotionSubmitValues(
  promotion,
  item,
  user,
  backgroundZone,
  headlineZone,
  logoZone,
) {
  const image = await combineCanvas(
    1200,
    630,
    ["BackgroundTemplatePreview", "HeadlineTemplatePreview", "LogoTemplatePreview"],
    backgroundZone,
    headlineZone,
    logoZone,
    promotion.hideLogo,
  );

  return {
    image,
    "promotion-id": promotion.id,
    values: {
      headline: [item],
      last_name: [{ value: user.lastName }],
      email_address: [{ value: user.email }],
      first_name: [{ value: user.firstName }],
    },
  };
}

export function getPromotionTabErrors({ visitedTabs, errors, values }) {
  const isOtherPromotionType = values.promotionType === "other";
  const otherPromotion = propOr("", "otherPromotion", values);
  const isOtherTypeWrite = isOtherPromotionType
    ? otherPromotion.length === 0
      ? "Fill other promotion type"
      : 0
    : 0;
  const settingsErrors = visitedTabs.settings
    ? [
        errors.name,
        errors.description,
        errors.endDate,
        errors.promotionType,
        isOtherTypeWrite,
      ].filter(Boolean)
    : [];

  const designErrors =
    visitedTabs.design && !values.promotionSteps
      ? ["Please select template"]
      : visitedTabs.design && !isValidPromotionTemplate(values)
      ? ["Please select valid template"]
      : [];

  const messagingErrors =
    visitedTabs.messaging &&
    !errors.someSocialEnabled &&
    !isEmpty(errors.promotionSocial) &&
    values.promotionSteps &&
    isValidPromotionTemplate(values)
      ? Object.values(errors.promotionSocial)
      : [];

  return {
    designErrors,
    settingsErrors,
    messagingErrors,

    allValid:
      designErrors.length === 0 && settingsErrors.length === 0 && messagingErrors.length === 0,
  };
}
