import React from "react";

interface Props {
  readonly title: string;
  readonly showCloseButton?: boolean;
}

interface Props {
  readonly id: string;
}

export function ModalHeader({ id, title, showCloseButton }: Props) {
  return (
    <div className="modal-header">
      <h5 className="modal-title" id={`${id}Label`}>
        {title}
      </h5>
      {showCloseButton && (
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </div>
  );
}
