import { Switch } from "antd";
import React, { useState } from "react";

import { useNotification } from "../../hooks/useNotification";
import { useRemindersContext } from "../../api/reminders/RemindersContext";

interface Props {
  readonly id: number;
  readonly state: boolean;
  readonly className?: string;
  readonly setList: (value: any) => void;
}

export function SwitchReminderWrapper({ id, state, className, setList }: Props) {
  const [loading, setLoading] = useState(false);

  const { showError } = useNotification();
  const { RemindersApi } = useRemindersContext();

  return (
    <Switch
      checked={state}
      loading={loading}
      disabled={loading}
      className={className}
      onClick={() => {
        RemindersApi.switchReminder(id, { activate: !state })
          .then(() => {
            setLoading(false);
            setList((prevList) =>
              prevList.map((item) => {
                if (item.id === id) {
                  return { ...item, active: !item.active };
                } else return item;
              }),
            );
          })
          .catch((e) => {
            setLoading(false);
            showError(e);
          });
      }}
    />
  );
}
