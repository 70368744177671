import "./assets/history-billing-details.scss";

import { head } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { SettingsTitle } from "../settings/SettingsTitle";
import { DATETIME_HUG, formatPrice, formatSecondsToString } from "../../helpers/FormatUtils";

BillingHistoryDetails.propTypes = {
  company: PropTypes.object,
  profile: PropTypes.object,
  invoice: PropTypes.object,
  customer: PropTypes.object,
};

export function BillingHistoryDetails({ invoice = {}, customer = {}, company = {}, profile = {} }) {
  const totalPrice = useMemo(() => formatPrice(invoice.total), [invoice]);
  const subTotalPrice = useMemo(() => formatPrice(invoice.subTotal), [invoice]);
  const paidDate = useMemo(() => formatSecondsToString(invoice.paidAt, DATETIME_HUG), [invoice]);

  const issuedAddress = useMemo(
    () =>
      [
        [profile.line1, profile.line2].filter(Boolean).join(" "),
        profile.city,
        [profile.state, profile.zip].filter(Boolean).join(" "),
      ]
        .filter(Boolean)
        .join(", "),
    [profile],
  );
  const orderNumber = useMemo(() => {
    if (invoice) {
      const id = invoice?.id || "";
      const length = id.length || 0;

      const empty = Array(8 - length)
        .fill(0)
        .join("");

      return `IN${empty}${id}`;
    }

    return "IN00000000";
  }, [invoice]);
  const plan = useMemo(() => {
    const lineItems = head(invoice.lineItems);

    if (lineItems) {
      const { description } = lineItems;

      if (description) {
        return description;
      }
    }
  }, [invoice.lineItems]);
  const planAmount = useMemo(() => {
    const lineItems = head(invoice.lineItems);

    if (lineItems) {
      const { amount } = lineItems;

      if (amount) {
        return `$${(amount / 100).toFixed(2)}`;
      }
    }
  }, [invoice.lineItems]);

  const customerName = useMemo(
    () => [customer.firstName, customer.lastName].filter(Boolean).join(" "),
    [customer],
  );

  return (
    <div className="d-flex flex-column p-3 billing-history-details">
      <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start mb-5">
        <div className="d-flex flex-1 flex-column flex-sm-row mb-3 mb-md-0 issue-container">
          <div className="flex-1 d-flex flex-column mr-3 issue-column">
            <SettingsTitle className="pb-5">Issued to</SettingsTitle>
            <span>{customerName}</span>
            <span>{customer.company}</span>
            <span>{customer.email}</span>
            <span>Office phone: {company.phone}</span>
          </div>
          <div className="flex-1 d-flex flex-column mr-3 issue-column">
            <SettingsTitle className="pb-5">Issued by</SettingsTitle>
            <span>{profile.companyName}</span>
            <span>{issuedAddress}</span>
            <span>Phone: {profile.phone}</span>
          </div>
        </div>
        <div className="flex-1 d-flex flex-column issue-column">
          <SettingsTitle className="pb-5">Details</SettingsTitle>
          <span>
            <span className="font-weight-bold">Order #</span> {orderNumber}
          </span>
          <span>
            <span className="font-weight-bold">Date Paid:</span> {paidDate}
          </span>
        </div>
      </div>

      <div className="border-bottom border-gray-light py-4">
        <SettingsTitle>Billing statement</SettingsTitle>
      </div>

      <div className="d-flex align-items-center justify-content-between border-bottom border-gray-light py-3">
        <div className="d-flex flex-column flex-sm-row">
          <span className="mr-sm-3 font-weight-bold">Monthly plan</span>
          <span className="ml-sm-3 subscribers-title">{plan}</span>
        </div>

        <div>
          <span>{planAmount}</span>
        </div>
      </div>

      <div className="d-flex">
        <div className="d-none d-md-flex w-25" />
        <div className="d-flex flex-column flex-1">
          <div className="d-flex justify-content-between border-bottom border-gray-light py-3">
            <div>
              <span className="font-weight-bold">Subtotal</span>
            </div>
            <div>
              <span className="font-weight-bold">{subTotalPrice}</span>
            </div>
          </div>
          <div className="d-flex justify-content-between py-3">
            <div>
              <span className="font-weight-bold">Total</span>
            </div>
            <div>
              <span className="font-weight-bold">{totalPrice}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
