import { useMemo } from "react";
import { useLocation } from "react-router";

import { parseSearch } from "../helpers/FormatUtils";

export function useQuery<T = any>(): T {
  const location = useLocation();

  return useMemo(() => parseSearch<T>(location.search), [location.search]);
}
