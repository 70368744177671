import "./assets/sign-in-form.scss";

import { Button } from "antd";
import { Form, Formik } from "formik";
import React, { useMemo } from "react";
import { object, string } from "yup";
import Cookies from "js-cookie";

import { SignInFormPropsPhone } from "../../api/auth/AuthDTO";
import { PhoneField } from "../form/PhoneField";

const validationSchema = object({
  phone: string()
    .min(8, "Phone must be at least 8 characters")
    .max(15, "Phone must be at most 15 characters")
    .required("Phone is required"),
});

interface Props {
  readonly onSubmit: (values: SignInFormPropsPhone) => Promise<unknown>;
}

export function SignInFormPhone({ onSubmit }: Props) {
  const initialPhone = useMemo(() => {
    const phone = Cookies.get("signPhone") || "";
    Cookies.set("signPhone", "");
    return phone;
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ phone: initialPhone }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <Form onSubmit={handleSubmit} className="d-flex flex-column sign-in-form">
            <PhoneField name="phone" placeholder="Enter phone" />
            <Button loading={isSubmitting} htmlType="submit" className="mt-1" type="primary">
              SIGN-IN
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
