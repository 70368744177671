import * as yup from "yup";
import cx from "classnames";
import { Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";

import { AppRoutes } from "../../constants/Routes";
import { getErrorMessage } from "../../utils/utils";
import { toCamelCase } from "../../helpers/CaseUtils";
import { useAuthContext } from "../../api/auth/AuthContext";
import { PublicAlert } from "../../components/public/PublicAlert";
import { PublicLayout } from "../../core/layout/public/PublicLayout";
import { useSettingsContext } from "../../api/settings/SettingsContext";
import { AcceptTeamVerificationForm } from "../../components/public/AcceptTeamVerificationForm";
import { useSettings, useToken, useUser } from "../../context/auth/hooks";
import useRouter from "../../hooks/useRouter";

const validationSchema = yup.object().shape({
  password: yup.string().min(8).required("Password is required"),
  confirmationPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirmation password is required"),
});

const initialValues = {
  password: "",
  confirmationPassword: "",
};

export function AcceptTeamVerificationContainer() {
  const router = useRouter();
  const { pathname } = useLocation();
  const { setUser } = useUser();
  const { setToken } = useToken();
  const { setSettings } = useSettings();
  const params = useParams<any>();

  const { AuthApi } = useAuthContext();
  const { SettingsApi } = useSettingsContext();

  const [error, setError] = useState(null);

  const submitHandler = useCallback(
    async ({ password }, { setSubmitting }) => {
      setError(null);

      try {
        const { sessionToken, ...user } = await AuthApi.acceptTeamMember({
          key: params.key,
          password,
        });

        const response = await SettingsApi.getCompany({
          headers: [["vsbl-member-session-token", sessionToken]],
        });

        setSettings(toCamelCase(response));

        setUser({ ...user, isAdministrator: false });
        setToken(sessionToken);

        setSubmitting(false);

        router.push(AppRoutes.TeamPortalPromotions);
      } catch (e) {
        setError(getErrorMessage(e));
        setSubmitting(false);
      }
    },
    [router, setSettings, setToken, setUser, AuthApi, params.key, SettingsApi],
  );

  const isAdmin = useMemo(() => pathname.indexOf("/admin") === 0, [pathname]);
  const isTeamPortal = useMemo(() => pathname.indexOf("/team-portal") === 0, [pathname]);

  return (
    <PublicLayout
      title="Accept Team Member Invitation"
      withHeader={!isTeamPortal && !isAdmin}
      HTMLHeadTitle="Accept Team Member Invitation"
      className={cx({ "pt-5": isAdmin || isTeamPortal })}
    >
      <PublicAlert error={error} />

      <Formik
        onSubmit={submitHandler}
        initialValues={initialValues}
        validationSchema={validationSchema}
        component={AcceptTeamVerificationForm}
      />
    </PublicLayout>
  );
}
