import React from "react";
import { Button } from "antd";
import { Form, Formik } from "formik";

import { InputField } from "../form/InputField";
import { PixabaySearchImageProps } from "../../api/other/PixabayDTO";

interface Props {
  readonly onResetClick: () => void;
  readonly initialValues: Partial<PixabaySearchImageProps>;
  readonly onSubmit: (values: PixabaySearchImageProps) => void;
}

export function SearchBackgroundForm({ onSubmit, onResetClick, initialValues }: Props) {
  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form className="d-flex mx-2 my-3 flex-column" onSubmit={handleSubmit}>
          <InputField
            name="q"
            transparent={false}
            className="flex-shrink-1 flex-grow-1 mb-3"
            placeholder="Search for a Background Image"
          />
          <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center">
            <div className="d-flex mb-2 mb-md-0">
              <Button type="primary" className="mr-2" htmlType="submit">
                Search
              </Button>
              <Button onClick={onResetClick}>Reset</Button>
            </div>

            <div className="d-flex align-items-center">
              <span className="mr-2">Free images Powered By:</span>
              <a href="https://pixabay.com" rel="noopener noreferrer" target="_blank">
                <img alt="pixabay_logo" width={100} src="https://pixabay.com/static/img/logo.png" />
              </a>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
