import { useField } from "formik";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import { ColorPicker } from "../ui/ColorPicker";
import { useOutsideClick } from "../../hooks/useOutsideClick";

ColorPickerField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  anchor: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom"]),
    horizontal: PropTypes.oneOf(["left", "right"]),
  }),
};

export function ColorPickerField({ name, ...props }) {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  const [field, , helpers] = useField(name);

  useOutsideClick({
    ref,
    active: show,
    onClick: () => setShow(false),
  });

  return (
    <ColorPicker {...props} value={field.value} onChange={({ hex }) => helpers.setValue(hex)} />
  );
}
