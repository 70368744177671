import "./assets/member-companies-form.scss";

import React, { useMemo } from "react";
import { Form, Select, SubmitButton } from "formik-antd";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../constants/Routes";
import { Button } from "antd";
import { Formik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  company: yup.string().required("Company is required"),
});

interface Props {
  readonly companies: any;
  readonly onSubmit: (value: any) => void;
}

const transformCompanies = (companies) => {
  return companies.map(({ name, id }) => ({
    key: id,
    value: id,
    text: name,
  }));
};

export function MemberCompaniesForm({ companies, onSubmit }: Props) {
  const transformedCompanies = useMemo(() => transformCompanies(companies), [companies]);

  return (
    <Formik initialValues={{ company: "" }} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form className="member-companies-form">
        <Form.Item name="company" label="Select a Company" colon={true}>
          <Select name="company" placeholder="Select Company">
            {transformedCompanies.map(({ value, text }) => (
              <Select.Option value={value} key={value}>
                {text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <SubmitButton type="primary" block={true}>
          SUBMIT
        </SubmitButton>
        <Button type="link" htmlType="button" block={true}>
          <Link to={AppRoutes.AuthMemberForgotPassword}>forgot password</Link>
        </Button>
      </Form>
    </Formik>
  );
}
