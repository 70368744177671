import { Button } from "antd";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { AppRoutes } from "../../constants/Routes";
import { Loader } from "../../components/ui/Loader";
import { getErrorMessage } from "../../utils/utils";
import { useAuthContext } from "../../api/auth/AuthContext";
import { PublicLayout } from "../../core/layout/public/PublicLayout";
import { replaceParams } from "src/helpers/UrlUtils";

export function VerificationContainer() {
  const params = useParams<any>();
  const { AuthApi } = useAuthContext();

  const [status, setStatus] = useState({
    error: null,
    loading: true,
  });

  useEffect(() => {
    AuthApi.verify(params.key)
      .then(() => setStatus({ loading: false, error: null }))
      .catch((e) => setStatus({ loading: false, error: getErrorMessage(e) }));
  }, [AuthApi, params.key]);

  return (
    <PublicLayout
      title={status.error ? "Account Not Activated!" : "Account Activated!"}
      HTMLHeadTitle="Account verification"
    >
      {status.loading && <Loader />}
      {!status.loading && (
        <>
          <div className="fs-3 text-center">
            {status.error || "Congratulations your account is now ready to use."}
          </div>

          <div className="d-flex justify-content-center mt-4">
            <Button type="primary">
              <Link to={replaceParams(AppRoutes.AuthSignIn, { id: "emails" })}>Log In</Link>
            </Button>
          </div>
        </>
      )}
    </PublicLayout>
  );
}
