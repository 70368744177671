import React from "react";

import { Button } from "antd";

interface Props {
  readonly onCreateClick: () => void;
  readonly onVisitClick: () => void;
  readonly disabledPortal: boolean;
}

export function PromotionsHeader({ onCreateClick, onVisitClick, disabledPortal }: Props) {
  return (
    <div className="px-3 mb-5">
      <div className="text-secondary font-family-museo mb-2 fs-6">Promotions</div>
      <div className="row">
        <div className="col-sm-6 mb-sm-0 mb-2">
          <Button type="primary" size="large" block={true} onClick={onCreateClick}>
            + add a new promotion
          </Button>
        </div>
        <div className="col-sm-6">
          <Button
            disabled={disabledPortal}
            type="primary"
            size="large"
            block={true}
            onClick={onVisitClick}
          >
            visit promotion portal
          </Button>
        </div>
      </div>
    </div>
  );
}
