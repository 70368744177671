import "./assets/team-member-modal-invite.scss";

import React, { useCallback, useEffect, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { Button, notification } from "antd";

import { Dialog } from "../../../components/ui/Dialog";
import { getErrorMessage } from "../../../utils/utils";
import { useTeamContext } from "../../../api/team/TeamContext";
import { PhoneField } from "../../../components/form/PhoneField";
import { useUser } from "../../../context/auth/hooks";
import { CheckboxField } from "../../../components/form/CheckboxField";
import { useDepartmentContext } from "../../../api/departments/DepartmentsContext";
import { Loader } from "../../../components/ui/Loader";

const validationSchema = yup.object().shape({
  phone: yup
    .string()
    .matches(
      /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
      "It doesn’t look like a phone number",
    ),
  lastName: yup.string().required("Last name is required"),
  firstName: yup.string().required("First name is required"),
  email: yup.string().email("Please provide a valid email address").required("Email is required"),
});

interface Props {
  readonly visible: boolean;
  readonly hideModal: () => void;
}

export function TeamMembersModalInvite({ visible, hideModal }: Props) {
  const { clientId } = useUser();
  const { DepartmentsApi } = useDepartmentContext();

  const [loadingSmsReq] = useState(false);
  const [loadingEmailReq, setLoadingEmailReq] = useState(false);
  const [chosenDepartments, setChosenDepartments] = useState([] as any);
  const [departments, setDepartments] = useState([] as any);
  const [departmentLoading, setDepartmentLoading] = useState([] as any);

  const { TeamApi } = useTeamContext();

  useEffect(() => {
    if (visible) {
      setDepartmentLoading(true);
      DepartmentsApi.getDepartments().then(({ items = [] }) => {
        setChosenDepartments([]);
        setDepartments(items);
        setDepartmentLoading(false);
      });
    }
  }, [DepartmentsApi, visible]);

  const handleEmailInvitation = useCallback(
    async (values, { resetForm }) => {
      setLoadingEmailReq(true);
      const defaultDepartment = departments.find((department) => department.system);
      const everyDepartment = defaultDepartment
        ? [...chosenDepartments, defaultDepartment]
        : chosenDepartments;

      try {
        const member = await TeamApi.sendInvite({
          ...values,
          phone: values.phone || undefined,
          clientId,
          departments: everyDepartment,
        });

        if (chosenDepartments.length > 0) {
          await TeamApi.addDepartmentMember({
            departmentIds: everyDepartment.map((department) => department.id),
            memberId: member.id,
          });
        }

        setLoadingEmailReq(false);
        resetForm({});

        notification.open({
          type: "success",
          message: "Invite member",
          description: "Invitation has been sent successfully",
        });

        hideModal();
      } catch (e) {
        notification.open({
          type: "error",
          message: "Invitation error",
          description: getErrorMessage(e),
          duration: null,
        });
        setLoadingEmailReq(false);
      }
    },
    [departments, hideModal, TeamApi, clientId, chosenDepartments],
  );

  const handleChangeDepartments = useCallback(
    (value) => {
      const isAlreadyChosen = chosenDepartments.find(
        (department: any) => department.id === value.id,
      );
      if (isAlreadyChosen) {
        const otherDepartments = chosenDepartments.filter(
          (department: any) => department.id !== value.id,
        );
        setChosenDepartments(otherDepartments);
      } else {
        setChosenDepartments([...chosenDepartments, value]);
      }
    },
    [chosenDepartments, setChosenDepartments],
  );

  return (
    <Dialog
      footer={null}
      width={767}
      title="Invite Team Member"
      show={visible}
      onCancel={hideModal}
    >
      <Formik
        onSubmit={handleEmailInvitation}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "+1",
          actionType: "",
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Form className="p-3 team-member-modal-invite">
            <div className="row">
              <div className="col-sm-6">
                <Form.Item name="firstName" label="Firstname">
                  <Input name="firstName" placeholder="Enter first name" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item name="lastName" label="Lastname">
                  <Input name="lastName" placeholder="Enter last name" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <PhoneField
                  labelClassName="phone-label"
                  name="phone"
                  label="Phone"
                  placeholder="Enter phone"
                />
              </div>
              <div className="col-sm-6">
                <Form.Item name="email" label="Email">
                  <Input name="email" placeholder="Enter email" />
                </Form.Item>
              </div>
            </div>
            <div className="d-flex flex-column my-2">
              <span className="assign-groups">ASSIGN TO GROUPS</span>
              {departmentLoading && (
                <div className="departments-loader-promotion">
                  <Loader />
                </div>
              )}
              <div className="d-flex mt-2 ml-1 departments-boxes">
                {departments.map((department) => {
                  const isDefault = department.system;
                  if (isDefault) {
                    return (
                      <span className="mr-4">
                        {department.departmentName} ({department.membersCount})
                      </span>
                    );
                  }
                  const isSelected = chosenDepartments.find(
                    (chosen) => chosen.id === department.id,
                  );
                  return (
                    <CheckboxField
                      checked={isSelected}
                      key={department.departmentName}
                      className="mb-1 mr-4 ml-0"
                      name={department.departmentName}
                      label={`${department.departmentName} (${department.membersCount})`}
                      onChange={() => handleChangeDepartments(department)}
                    />
                  );
                })}
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-sm-6">
                <Button
                  onClick={(e) => {
                    setFieldValue("actionType", "email");
                    handleSubmit(e as any);
                  }}
                  loading={loadingSmsReq}
                  disabled={loadingEmailReq}
                  className="mb-1"
                  type="primary"
                  block={true}
                >
                  send email Invitation
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
