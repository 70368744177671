import React from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { Button } from "antd";

import { Dialog } from "../../../components/ui/Dialog";
import { InputWithCountField } from "../../../components/form/InputWithCountField";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
});

interface Props {
  readonly visible: boolean;
  readonly hideModal: () => void;
  readonly onSubmit: (value: any) => void;
  readonly loading: boolean;
}

export function DepartmentsModalCreate({ visible, hideModal, onSubmit, loading }: Props) {
  return (
    <Dialog footer={null} width={767} title="Create New Group" show={visible} onCancel={hideModal}>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          name: "",
          description: "",
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit }) => (
          <Form className="p-3 team-member-modal-invite">
            <div className="row">
              <div className="col-sm-6">
                <Form.Item name="name" label="Name">
                  <Input name="name" placeholder="Enter name" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item name="description" label="Description">
                  <InputWithCountField
                    withErrors={false}
                    type="textarea"
                    maxLength={1000}
                    name="description"
                    label={undefined}
                    className={undefined}
                    isRequired={undefined}
                    inputClassName={undefined}
                    requiredTitle={undefined}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-6">
                <Button
                  onClick={() => handleSubmit()}
                  loading={loading}
                  className="mb-1"
                  type="primary"
                  block={true}
                >
                  Create groups
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
