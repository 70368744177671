import debug from "debug";
import { IS_DEV } from "../constants/AppConstants";

if (IS_DEV) {
  debug.enable("App:*");
}

export class Logger {
  logger;

  constructor(namespace) {
    if (IS_DEV) {
      this.logger = debug(`App:${namespace}`);
    }
  }

  log(message, ...args) {
    if (this.logger) {
      this.logger(message, ...args);
    }
  }

  error(error, ...args) {
    this.log(error.message, ...args);
  }
}
