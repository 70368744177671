import React from "react";

interface Props {
  readonly size?: number;
  readonly className?: string;
}

export function ArrowTopRightIcon({ size = 24, className }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" className={className}>
      <path
        fill="currentColor"
        d="M8.5,18.31L5.69,15.5L12.06,9.12H7.11V5.69H18.31V16.89H14.89V11.94L8.5,18.31Z"
      />
    </svg>
  );
}
