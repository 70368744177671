import React from "react";

import { Button } from "antd";

interface Props {
  readonly saving: boolean;
  readonly onDeleteClick?: () => void;
}

export function TeamAccountHeader({ saving, onDeleteClick }: Props) {
  return (
    <>
      <div className="d-flex align-items-md-center pb-4 border-bottom flex-column flex-md-row">
        <div className="team-account-header-title mr-auto mr-3 mb-2 mb-md-0">Account Details</div>

        <div className="d-flex">
          <Button htmlType="submit" loading={saving} className="mr-1" type="primary">
            Save
          </Button>
          {Boolean(onDeleteClick) && (
            <Button type="primary" onClick={onDeleteClick}>
              Delete my account
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
