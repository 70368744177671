import React from "react";

import { DeleteDialog } from "../ui/DeleteDialog";

interface Props {
  readonly loading: boolean;
  readonly show: boolean;
  readonly onCancel: () => void;
  readonly onDelete: () => void;
}

export function DeleteAccountDialog({ loading, show, onCancel, onDelete }: Props) {
  return (
    <DeleteDialog
      show={show}
      onCancel={onCancel}
      onOk={onDelete}
      confirmLoading={loading}
      className={undefined}
    >
      <div className="d-flex flex-column my-4">
        <h2 className="text-center">Are you sure you want to delete account?</h2>
        <h3 className="text-center">(This cannot be undone)</h3>
      </div>
    </DeleteDialog>
  );
}
