import React from "react";
import { Formik } from "formik";
import { Button } from "antd";
import { object, string } from "yup";
import { Select, Form } from "formik-antd";
import { PhoneField } from "../form/PhoneField";

const validationSchema = object({
  phone: string().required("Phone is required"),
});

interface Props {
  readonly onSubmit: (values: { phone: string }) => void;
  readonly onNextStepPhone: (values: { phone: string }) => void;
  readonly companies: any;
  readonly loading: boolean;
  readonly setSelectedCompany: (value: number) => void;
}

export function ForgotFormPhone(props: Props) {
  const { onSubmit, companies, loading, onNextStepPhone, setSelectedCompany } = props;
  return (
    <Formik initialValues={{ phone: "" }} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ values }) => (
        <Form className="d-flex flex-column sign-in-form">
          {companies.length === 0 && (
            <>
              <PhoneField name="phone" placeholder="Enter phone" />
              <Button
                loading={loading}
                onClick={() => onNextStepPhone({ phone: values.phone })}
                htmlType="button"
                className="my-5"
                type="primary"
              >
                NEXT
              </Button>
            </>
          )}
          {companies.length > 0 && (
            <>
              <Form.Item name="company" label="Select a Company" colon={true}>
                <Select
                  onChange={setSelectedCompany}
                  disabled={companies.length === 0}
                  name="company"
                  placeholder="Select Company"
                >
                  {companies.map(({ id, name }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Button loading={loading} htmlType="submit" className="mb-1" type="primary">
                RESET PASSWORD
              </Button>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ForgotFormPhone;
