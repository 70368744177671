import React, { useState } from "react";

import { DeletePromotionDialog } from "./DeletePromotionDialog";
import { usePromotionContext } from "../../api/promotions/PromotionContext";

interface Props {
  readonly promotion: any;
  readonly show: boolean;
  readonly onCancel: () => void;
  readonly onComplete: () => void;
}

export function DeletePromotionDialogWrapper(props: Props) {
  const [deleting, setDeleting] = useState(false);

  const { PromotionApi } = usePromotionContext();

  return (
    <DeletePromotionDialog
      {...props}
      loading={deleting}
      onDelete={async () => {
        try {
          setDeleting(true);

          await PromotionApi.deletePromotion(props.promotion.id);

          setDeleting(false);

          props.onComplete();
        } catch (e) {
          setDeleting(false);
        }
      }}
    />
  );
}
