import { Formik } from "formik";
import { toFinite } from "lodash";
import { SubmitButton } from "formik-antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import valid from "card-validator";

import { Loader } from "../../components/ui/Loader";
import { getErrorMessage } from "../../utils/utils";
import { useResource } from "../../hooks/useResource";
import { LinkButton } from "../../components/ui/LinkButton";
import { useAuthContext } from "../../api/auth/AuthContext";
import { PublicAlert } from "../../components/public/PublicAlert";
import { useOtherContext } from "../../api/other/useOtherContext";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { PublicLayout } from "../../core/layout/public/PublicLayout";
import { useSettingsContext } from "../../api/settings/SettingsContext";
import { CardDetailsForm } from "../../components/payment/CardDetailsForm";
import { useChargebeeContext } from "../../api/chargebee/ChargebeeContext";
import { useUser } from "../../context/auth/hooks";
import useRouter from "../../hooks/useRouter";
import { AppRoutes } from "../../constants/Routes";

export const validationSchema = yup.object().shape({
  cardNumber: yup
    .string()
    .test("cardNumber", "Credit Card number is invalid", (number) => valid.number(number).isValid)
    .required("Card Number is required"),
  expirationMonth: yup
    .string()
    .test(
      "expirationMonth",
      "Expiration Month is invalid",
      (month) => valid.expirationMonth(String(toFinite(month) + 1)).isValid,
    )
    .required("Expiration Month is required"),
  expirationYear: yup
    .string()
    .test(
      "expirationYear",
      "ExpirationYear is invalid",
      (year) => valid.expirationYear(year).isValid,
    )
    .required("Expiration Year is required"),
  cvv: yup
    .string()
    .test("cvv", "CVV is invalid", (cvv) => valid.cvv(cvv).isValid)
    .required("CVV is required"),
  holderName: yup.string().required("Card Holder Name is required"),
  country: yup.string().required("Country is required"),
  state: yup
    .string()
    .when("country", (field, schema) =>
      field === "US" ? schema.required("State is required") : schema,
    ),
  postalCode: yup
    .string()
    .test(
      "postalCode",
      "Postal Code is invalid",
      (postalCode) => valid.postalCode(postalCode).isValid,
    )
    .required("Zip Code is required"),
});

export function SubscriptionContainer() {
  const { setCompanyStatus } = useUser();
  const [error, setError] = useState(null);
  const [loadingExtraData, setLoadingExtraData] = useState(false);

  const router = useRouter();

  const { AuthApi, logout } = useAuthContext();
  const { BraintreeApi } = useOtherContext();
  const { SettingsApi } = useSettingsContext();
  const { ChargebeeApi } = useChargebeeContext();

  const stateListResponse = useResource(() => AuthApi.getStateList(), []);
  const subscriptionResource = useResource(() => ChargebeeApi.getSubscriptions(), []);

  useResourceHandler(stateListResponse);
  useResourceHandler(subscriptionResource, {
    onSuccess: (data) => {
      if (data.subscription && data.subscription.status) {
        setCompanyStatus(data.subscription.status);
      }
    },
  });

  const plan = useMemo(() => {
    if (subscriptionResource.data && subscriptionResource.data.subscription) {
      return subscriptionResource.data.subscription?.plan?.name;
    }
  }, [subscriptionResource.data]);
  const status = useMemo(() => {
    if (subscriptionResource.data && subscriptionResource.data.subscription) {
      return subscriptionResource.data.subscription.status;
    }
  }, [subscriptionResource.data]);
  const planAmount = useMemo(() => {
    if (subscriptionResource.data && subscriptionResource.data.subscription) {
      return subscriptionResource.data.subscription.planAmount / 100;
    }
  }, [subscriptionResource.data]);
  const customer = useMemo(
    () => (subscriptionResource.data ? subscriptionResource.data.customer || {} : {}),
    [subscriptionResource.data],
  );

  const planTitle = useMemo(
    () => [plan, planAmount && `$${planAmount}`].filter(Boolean).join(" - "),
    [plan, planAmount],
  );

  const submitHandler = useCallback(
    async ({
      cvv,
      state,
      country,
      cardNumber,
      postalCode,
      expirationYear,
      holderName = "",
      expirationMonth,
    }) => {
      setError(null);

      try {
        const { token } = await ChargebeeApi.getBraintreeToken();

        const braintreeToken = await BraintreeApi.tokenizeCard(token, {
          cvv,
          postalCode,
          expirationYear,
          number: cardNumber,
          cardholderName: holderName,
          billingAddress: { postalCode },
          expirationMonth: toFinite(expirationMonth) + 1,
        });

        await ChargebeeApi.updateCard({
          braintreeClientToken: braintreeToken,
          id: customer.id,
          billingAddress: {
            state,
            country,
            zip: postalCode,
          },
        });

        await ChargebeeApi.reactivateSubscription();

        subscriptionResource.refetch();
      } catch (e) {
        setError(getErrorMessage(e));
      }
    },
    [ChargebeeApi, BraintreeApi, subscriptionResource, customer],
  );

  const stateList = useMemo(
    () =>
      (stateListResponse.data || []).map(({ value, code }) => ({
        text: value,
        value: code,
      })),
    [stateListResponse.data],
  );

  useEffect(() => {
    if (
      !subscriptionResource.loading &&
      status !== "extra_days_expired" &&
      status !== "extra_days_notstarted"
    ) {
      router.push("/");
    }
  }, [status, router, subscriptionResource.loading]);

  if (subscriptionResource.loading) {
    return <Loader />;
  }

  return (
    <PublicLayout
      onClick={() => logout()}
      title={
        <div className="d-flex flex-column">
          <h3 className="text-uppercase text-secondary font-family-museo mb-1">
            Your free trial is over
          </h3>
          <h6 className="text-uppercase text-secondary font-family-museo">
            Please activate your subscription
          </h6>
          <h5 className="text-uppercase text-secondary font-family-museo">{planTitle}</h5>
        </div>
      }
      HTMLHeadTitle="Sign Up"
    >
      <PublicAlert error={error} />

      <Formik
        initialValues={{
          cvv: "",
          state: "",
          country: "US",
          postalCode: "",
          holderName: "",
          cardNumber: "",
          expirationYear: "",
          expirationMonth: "",
        }}
        onSubmit={submitHandler}
        validationSchema={validationSchema}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="d-flex flex-column card-details-container">
            <CardDetailsForm stateList={stateList} />

            <SubmitButton className="mb-4 mx-2" type="primary" block={true}>
              Activate my VSBL account
            </SubmitButton>

            {status !== "extra_days_expired" && (
              <LinkButton
                className="align-self-center"
                onClick={() => {
                  setError(null);

                  setLoadingExtraData(true);
                  SettingsApi.getExtraDays()
                    .then((x) => {
                      if (x) {
                        subscriptionResource.refetch();
                      }
                      router.push(AppRoutes.AuthSignIn);
                      setLoadingExtraData(false);
                    })
                    .catch((e) => {
                      setLoadingExtraData(false);
                      setError(getErrorMessage(e));
                    });
                }}
              >
                {loadingExtraData ? <Loader /> : "Need more time? Ok, click here"}
              </LinkButton>
            )}
          </form>
        )}
      </Formik>
    </PublicLayout>
  );
}
