import { useMemo } from "react";

import { PixabayApi } from "./PixabayApi";
import { BraintreeApi } from "./BraintreeApi";
import { PIXABAY_HOST } from "../../constants/ApiConstants";
import { PIXABAY_TRANSACTION_KEY } from "../../constants/AppConstants";

interface OtherContextProps {
  PixabayApi: PixabayApi;
  BraintreeApi: BraintreeApi;
}

export function useOtherContext(): OtherContextProps {
  const braintreeApi = useMemo(() => new BraintreeApi(), []);
  const pixabayApi = useMemo(
    () => new PixabayApi({ key: PIXABAY_TRANSACTION_KEY, host: PIXABAY_HOST }),
    [],
  );

  return {
    PixabayApi: pixabayApi,
    BraintreeApi: braintreeApi,
  };
}
