import React from "react";
import "./assets/forgot-form.scss";
import { Tabs } from "antd";
import ForgotFormEmail from "../../components/auth/ForgotFormEmail";
import ForgotFormPhone from "../../components/auth/ForgotFormPhone";
import Cookies from "js-cookie";

enum ForgotFormTabs {
  EMAIL = "email",
  PHONE = "phone",
}

const { TabPane } = Tabs;

interface Props {
  readonly onSubmitEmail: (value: { email: string }) => void;
  readonly onNextStepEmail: (value: { email: string }) => void;
  readonly onSubmitPhone: (value: { phone: string }) => void;
  readonly onNextStepPhone: (value: { phone: string }) => void;
  readonly companies: any;
  readonly loading: boolean;
  readonly setSelectedCompany: (value: number) => void;
  readonly setCompanies: (value: any) => void;
}

export function ForgotMemberForm(props: Props) {
  const {
    onSubmitEmail,
    onSubmitPhone,
    companies,
    loading,
    onNextStepEmail,
    onNextStepPhone,
    setSelectedCompany,
    setCompanies,
  } = props;

  const tab = Cookies.get("forgotTab");
  const defaultTab = tab ? tab : ForgotFormTabs.EMAIL;
  return (
    <div className="forgot-form">
      <Tabs
        defaultActiveKey={defaultTab}
        size="small"
        onChange={(key) => {
          setCompanies([]);
          Cookies.set("forgotTab", key);
        }}
      >
        <TabPane tab="EMAIL LOGIN" key={ForgotFormTabs.EMAIL}>
          <ForgotFormEmail
            onNextStepEmail={onNextStepEmail}
            onSubmit={onSubmitEmail}
            companies={companies}
            loading={loading}
            setSelectedCompany={setSelectedCompany}
          />
        </TabPane>
        <TabPane tab="PHONE LOGIN" key={ForgotFormTabs.PHONE}>
          <ForgotFormPhone
            onNextStepPhone={onNextStepPhone}
            onSubmit={onSubmitPhone}
            companies={companies}
            loading={loading}
            setSelectedCompany={setSelectedCompany}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}
