export enum ReminderForType {
  TEAM_DASHBOARD = "TEAM_DASHBOARD",
  SPECIFIC_PROMOTION = "SPECIFIC_PROMOTION",
  NEW_PROMOTION = "NEW_PROMOTION",
  EXPIRING_PROMOTION = "EXPIRING_PROMOTION",
  SINGLE_BROADCAST = "SINGLE_BROADCAST",
}

export const reminderForConstants = [
  { text: "Team Dashboard", value: ReminderForType.TEAM_DASHBOARD },
  { text: "Specific Promotion", value: ReminderForType.SPECIFIC_PROMOTION },
  { text: "New Promotions (auto)", value: ReminderForType.NEW_PROMOTION },
  { text: "Expiring Promotions (auto)", value: ReminderForType.EXPIRING_PROMOTION },
  { text: "Single Broadcast", value: ReminderForType.SINGLE_BROADCAST },
];

export enum ReminderRepeatType {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
  ONE_TIME = "ONE_TIME",
}

export const reminderRepeatConstants = [
  { text: "Daily", value: ReminderRepeatType.DAILY },
  { text: "Weekly", value: ReminderRepeatType.WEEKLY },
  { text: "Monthly", value: ReminderRepeatType.MONTHLY },
  { text: "Annually", value: ReminderRepeatType.ANNUALLY },
  { text: "No Repeat", value: ReminderRepeatType.ONE_TIME },
];

export const months = [
  { text: "January", value: "0" },
  { text: "February", value: "1" },
  { text: "March", value: "2" },
  { text: "April", value: "3" },
  { text: "May", value: "4" },
  { text: "June", value: "5" },
  { text: "July", value: "6" },
  { text: "August", value: "7" },
  { text: "September", value: "8" },
  { text: "October", value: "9" },
  { text: "November", value: "10" },
  { text: "December", value: "11" },
];

export function findMonthByValue(val) {
  let ret = null as any;
  months.map((item) => {
    if (Number(item.value) === Number(val)) {
      ret = item;
      return ret ? ret.text : null;
    }
    return ret;
  });
  return ret ? ret.text : null;
}

export function findMonthByName(val) {
  let ret = null as any;
  months.map((item) => {
    if (item.text === val) {
      ret = item;
      return ret ? ret.value : null;
    }
    return ret;
  });
  return ret ? ret.value : null;
}
