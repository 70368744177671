import cx from "classnames";
import React, { ReactNode } from "react";

interface Props {
  readonly className?: string;
  readonly children: ReactNode;
}

export function ModalFooter({ children, className }: Props) {
  return <div className={cx("modal-footer", className)}>{children}</div>;
}
