import "./assets/field.scss";

import cx from "classnames";
import { useField } from "formik";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { Select } from "../ui/Select";

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

export function SelectField({
  name,
  defaultValue = "",
  label = "",
  className = "",
  inputClassName = "",
  ...props
}) {
  const [field, meta, helpers] = useField(name);

  const showError = useMemo(() => Boolean(meta.touched && meta.error), [meta.touched, meta.error]);

  return (
    <div className={cx("d-flex flex-column field", className)}>
      {Boolean(label) && (
        <label className="mb-1 font-family-museo font-weight-light field-label">{label}</label>
      )}
      <Select
        value={field.value === "" ? defaultValue : field.value}
        className={inputClassName}
        onChange={(x) => helpers.setValue(x)}
        {...props}
      />
      {showError && <span className="text-danger mt-1">{meta.error}</span>}
    </div>
  );
}
