import "./assets/sign-in-title.scss";

import React from "react";

import { useQuery } from "../../utils/hooks/hooks";
import { ReadyTitle } from "../public/ReadyTitle";

export function SignInTitle() {
  const { ready } = useQuery();

  if (ready) {
    return <ReadyTitle />;
  }

  return <div className="sign-in-title">Sign into your Vsbl account:</div>;
}
