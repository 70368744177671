import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { ImageBuilderField } from "./ImageBuilderField";
import { ImageBuilderStepContainer } from "./ImageBuilderStepContainer";

ImageBuilderStep.propTypes = {
  index: PropTypes.number,
  onAddValueClick: PropTypes.func,
  setFieldValue: PropTypes.func,
  onRemoveValueClick: PropTypes.func,
  step: PropTypes.shape({
    fields: PropTypes.array,
    helpText: PropTypes.string,
  }),
};

export function ImageBuilderStep({
  step,
  index,
  onAddValueClick,
  onRemoveValueClick,
  setFieldValue,
}) {
  const fields = useMemo(() => {
    if (step.fields) {
      return step.fields;
    }

    return [];
  }, [step]);

  return (
    <ImageBuilderStepContainer description={step.helpText}>
      {fields.map((field, idx) => {
        const name = `promotionSteps.${index}.fields.${idx}`;

        return (
          <ImageBuilderField
            name={name}
            field={field}
            key={field.id}
            onAddValueClick={() => onAddValueClick(idx)}
            onUpdateFields={(fields) => setFieldValue(name + ".values", fields)}
            onRemoveValueClick={(valueIndex) => onRemoveValueClick(idx, valueIndex)}
          />
        );
      })}
    </ImageBuilderStepContainer>
  );
}
