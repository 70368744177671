import "./assets/field.scss";

import cx from "classnames";
import { useField } from "formik";
import React, { useMemo } from "react";

import { InputWithCount } from "../ui/InputWithCount";

export function InputWithCountField({
  label = "",
  className = "",
  isRequired = false,
  inputClassName = "",
  requiredTitle = "",
  withErrors = true,
  ...props
}) {
  const [field, meta] = useField(props.name);

  const showError = useMemo(() => Boolean(meta.touched && meta.error && withErrors), [
    withErrors,
    meta.touched,
    meta.error,
  ]);

  return (
    <div className={cx("field", className)}>
      {Boolean(label) && (
        <label className="field-label">
          {label}
          {isRequired ? <span className="text-danger"> * {requiredTitle}</span> : ""}
        </label>
      )}
      <InputWithCount
        {...props}
        {...field}
        onChange={(x) => {
          field.onChange(x);

          if (props.onChange) {
            props.onChange(x);
          }
        }}
        className={inputClassName}
      />
      {showError && <span className="field-error-text">{meta.error}</span>}
    </div>
  );
}
