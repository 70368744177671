import { update } from "immupdate";
import PropTypes from "prop-types";
import { notification } from "antd";
import React, { useCallback } from "react";

import { Dialog } from "../ui/Dialog";
import { getErrorMessage } from "../../utils/utils";
import { SettingsInviteUserForm } from "./SettingsInviteUserForm";
import { useUsersContext } from "../../api/users/UsersContext";

SettingsInviteUserDialogWrapper.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  clientId: PropTypes.number,
  onComplete: PropTypes.func,
};

export function SettingsInviteUserDialogWrapper({ show, clientId, onCancel, onComplete }) {
  const { UsersApi } = useUsersContext();

  const inviteHandle = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    async ({ confirmPassword, ...values }) => {
      try {
        await UsersApi.inviteUser(update(values, { clientId }));

        notification.open({
          type: "success",
          message: "Invite user",
          description: "Invitation has been sent successfully",
        });

        onComplete();
      } catch (e) {
        notification.open({
          type: "error",
          duration: null,
          message: "Invite user",
          description: getErrorMessage(e),
        });
      }
    },
    [clientId, UsersApi, onComplete],
  );

  return (
    <Dialog footer={null} show={show} title="Invite user" onCancel={onCancel}>
      <SettingsInviteUserForm onSubmit={inviteHandle} />
    </Dialog>
  );
}
