import "./assets/promotion-background-config.scss";

import { Button } from "antd";
import PropTypes from "prop-types";
import { update } from "immupdate";
import React, { useEffect, useState } from "react";

import { Slider } from "../ui/Slider";

export const defaultConfig = {
  blur: 0,
  vibrance: 0,
  saturation: 0,
  brightness: 0,
};

PromotionBackgroundConfig.propTypes = {
  loading: PropTypes.bool,
  config: PropTypes.object,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  onResetClick: PropTypes.func,
};

export function PromotionBackgroundConfig({ loading, config, hasImage, onChange, onResetClick }) {
  const [state, setState] = useState(defaultConfig);

  useEffect(() => {
    setState({
      blur: config.blur,
      brightness: config.brightness - 100,
      vibrance: config.vibrance - 100,
      saturation: config.saturation - 100,
    });
  }, [config]);

  return (
    <div className="p-3 flex-1 promotion-background-config">
      <div className="panel-controls-title">Adjust Background Image</div>
      <div className="panel-controls-list">
        <Slider
          max={100}
          min={-100}
          label="brightness"
          value={state.brightness}
          disabled={!hasImage || loading}
          onChange={(value) => setState(update(state, { brightness: value }))}
          onAfterChange={() => onChange(state)}
        />
        <Slider
          min={-100}
          max={100}
          label="brilliance"
          value={state.vibrance}
          disabled={!hasImage || loading}
          onAfterChange={() => onChange(state)}
          onChange={(value) => setState(update(state, { vibrance: value }))}
        />
        <Slider
          min={-100}
          max={100}
          label="saturation"
          value={state.saturation}
          disabled={!hasImage || loading}
          onAfterChange={() => onChange(state)}
          onChange={(value) => setState(update(state, { saturation: value }))}
        />
        <Slider
          label="blur"
          max={10}
          value={state.blur}
          disabled={!hasImage || loading}
          onAfterChange={() => onChange(state)}
          onChange={(value) => setState(update(state, { blur: value }))}
        />
      </div>
      <div className="panel-controls-buttons">
        <Button
          disabled={!hasImage || loading}
          onClick={() => {
            onResetClick();
            setState(defaultConfig);
            onChange(defaultConfig);
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
}
