import "./assets/linkedin-post-dialog.scss";
import { ReactComponent as IconSuccess } from "../icons/icon-success.svg";
import React from "react";

import { Dialog } from "../ui/Dialog";
import { Button } from "antd";

export function PromotionPostLinkedInSuccessDialog({ show, onCancel }) {
  return (
    <Dialog
      width={400}
      show={show}
      footer={null}
      onCancel={onCancel}
      destroyOnClose={true}
      className="d-flex flex-column linkedin-post-dialog"
    >
      <div className="d-flex text-center flex-column p-5">
        <IconSuccess width={80} height={80} className="linkedin-success-icon" />

        <h2 className="linkedin-success-title">Your post was successfully published on LinkedIn</h2>

        <div className="mt-4 d-flex">
          <Button type="default" block={true} htmlType="button" onClick={onCancel}>
            Back to my Promotion
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
