import React, { useEffect, useMemo, useState } from "react";
import { getImagePosition } from "../../helpers/ImageHelpers";
import { propOr } from "ramda";
import { useFormikContext } from "formik";

export enum AnchorImageType {
  MiddleCenter = "MIDDLE_CENTER",
}

export enum ZoneIndexType {
  Background = 1,
  Overlay = 2,
  Logo = 3,
  Headline = 4,
}

export interface LogoZoneProps {
  readonly anchorImage: AnchorImageType;
  readonly dynamicTag: boolean;
  readonly foatx: number;
  readonly foaty: number;
  readonly gif: boolean;
  readonly height: number;
  readonly id: number;
  readonly image: string;
  readonly active: boolean;
  readonly imageHeigth: number;
  readonly imageKey: number;
  readonly imageScaled: boolean;
  readonly imageWidth: number;
  readonly modifiedDate: number;
  readonly type: string;
  readonly logoWidth: number;
  readonly logoHeight: number;
  readonly hideLogo: boolean;
  readonly uniqueIdentifier: string;
  readonly useAsDefault: boolean;
  readonly useCompression: boolean;
  readonly width: number;
  readonly zindex: ZoneIndexType;
}

interface Props {
  readonly canDrag?: boolean;
  readonly hideLogo?: boolean;
  readonly onClick?: () => void;
  readonly logoZone: LogoZoneProps;
  readonly loading?: boolean;
  readonly isBig?: boolean;
}

export function TemplateLogo({ logoZone, onClick, canDrag, hideLogo, loading, isBig }: Props) {
  const formik = useFormikContext<any>();
  const [previousFoatX, setPreviousFoatX] = useState(0);
  const [previousFoatY, setPreviousFoatY] = useState(0);

  useEffect(() => {
    const foatx = propOr(0, "foatx", logoZone);
    const foaty = propOr(0, "foaty", logoZone);
    if (foatx >= 1) {
      setPreviousFoatX(foatx);
    }
    if (foaty >= 1) {
      setPreviousFoatY(foaty);
    }
  }, [logoZone]);

  useEffect(() => {
    const foatx = propOr(0, "foatx", logoZone);
    const foaty = propOr(0, "foaty", logoZone);
    if (foatx < 0) {
      formik.setFieldValue("zones.logoZone.foatx", previousFoatX);
    }
    if (foaty < 0) {
      formik.setFieldValue("zones.logoZone.foaty", previousFoatY);
    }
  }, [formik, logoZone, previousFoatX, previousFoatY]);

  const rootStyles = useMemo(() => {
    const position = getImagePosition(logoZone.anchorImage);

    return {
      alignItems: position.justify,
      justifyContent: position.align,
      width: isBig ? logoZone.width * 2 : logoZone.width,
      height: isBig ? logoZone.height * 2 : logoZone.height,
      ...(canDrag
        ? {
            cursor: "all-scroll",
            borderWidth: "1px",
            borderStyle: canDrag && !loading ? "dashed" : "unset",
            borderColor: canDrag && !loading ? "#ffffff33" : "unset",
            boxShadow: canDrag && !loading ? "0 0 2px 0px #888888" : "unset",
            ...(logoZone.active
              ? {
                  borderColor: canDrag && !loading ? "white" : "unset",
                  boxShadow: canDrag && !loading ? "0 0 4px 0px #888888" : "unset",
                }
              : {}),
          }
        : {}),
    };
  }, [
    isBig,
    logoZone.anchorImage,
    logoZone.width,
    logoZone.height,
    logoZone.active,
    canDrag,
    loading,
  ]);

  const logoStyles = useMemo(() => {
    return {
      maxWidth: isBig ? logoZone.width * 2 : logoZone.width,
      maxHeight: isBig ? logoZone.height * 2 : logoZone.height,
      objectFit: "contain",
    };
  }, [isBig, logoZone]);

  if (hideLogo) {
    return null;
  }

  return (
    <div
      role="button"
      className="d-flex"
      style={rootStyles}
      onDragEnter={onClick}
      id="TemplateLogo"
      onClick={() => onClick && onClick()}
    >
      <img alt="dnd-logo" src={logoZone.image} style={logoStyles as any} />
    </div>
  );
}
