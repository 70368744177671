import { useContext } from "react";

import { AuthContext } from "./context";

export const useAuth = () => {
  const { isAuthorized, setAuthorized } = useContext(AuthContext);

  return { isAuthorized, setAuthorized };
};

export const useUser = () => {
  const { user, setUser, isAdministrator, clientId, isExpired, setCompanyStatus } = useContext(
    AuthContext,
  );

  return { user, setUser, isAdministrator, clientId, isExpired, setCompanyStatus };
};

export const useToken = () => {
  const { token, setToken } = useContext(AuthContext);

  return { token, setToken };
};

export const useSettings = () => {
  const { settings, setSettings } = useContext(AuthContext);

  return { settings, setSettings };
};

export const useFonts = () => {
  const { fonts, setFont, setFontList, deleteFont } = useContext(AuthContext);

  return { fonts, setFont, setFontList, deleteFont };
};
