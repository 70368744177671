import React, { useMemo } from "react";
import "./assets/app-header.scss";

import { AppLogo } from "./AppLogo";
import { AppMenu } from "./AppMenu";
import { AppHeader } from "./AppHeader";
import { TopMenuItem } from "./TopMenuItem";
import { MainMenuItem } from "./MainMenuItem";
import { AppRoutes } from "../../constants/Routes";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { UserProps } from "../../api/users/UsersDTO";
import useRouter from "../../hooks/useRouter";

interface Props {
  readonly user?: UserProps;
  readonly onLogout: () => void;
}

export function ClientAppHeader({ user, onLogout }: Props) {
  const { isMobile } = useMediaQuery();
  const router = useRouter();

  const loggedUser = useMemo(() => {
    if (user) {
      if (isMobile) {
        return user.firstName || user.lastName || "User ID";
      }

      return `Logged in as ${user.firstName} ${user.lastName}`;
    }
  }, [user, isMobile]);

  return (
    <AppHeader
      logoComponent={<AppLogo onClick={() => router.push("/")} />}
      topMenuComponent={
        <div className="d-flex">
          <TopMenuItem onClick={() => router.push(AppRoutes.AdminProfile)}>
            {loggedUser}
          </TopMenuItem>
          <TopMenuItem onClick={() => router.push(AppRoutes.AdminSettings, { tab: "overview" })}>
            Settings
          </TopMenuItem>
          <TopMenuItem onClick={() => onLogout()}>Logout</TopMenuItem>
        </div>
      }
      mainMenuComponent={
        <AppMenu>
          <div className="d-flex px-3 px-sm-0">
            <MainMenuItem
              to={AppRoutes.AdminPromotions}
              className="fl-4 fl-md-5 text-center text-sm-left menu-tab"
            >
              Promotions
            </MainMenuItem>
            <MainMenuItem
              to={AppRoutes.AdminUsers}
              className="fl-4 fl-md-5 text-center text-sm-left menu-tab"
            >
              Team
            </MainMenuItem>
            <MainMenuItem
              to={AppRoutes.AdminReminders}
              className="fl-4 fl-md-5 text-center text-sm-left menu-tab"
            >
              Reminders
            </MainMenuItem>
          </div>
        </AppMenu>
      }
    />
  );
}
