import "./assets/submit-form-control.scss";

import React, { ReactNode } from "react";
import { Button } from "antd";

interface Props {
  readonly onBack: () => void;
  readonly disabled: boolean;
  readonly children: ReactNode;
  readonly loading: boolean;
  readonly onSubmit: (value: boolean) => void;
}

export function SubmitFormControl({ onBack, disabled, children, loading, onSubmit }: Props) {
  return (
    <div className="d-flex justify-content-between align-items-center bg-secondary px-3 submit-form-control d-sm-flex ">
      <Button type="primary" onClick={onBack} className="bg-gray fs-2 fs-sm-3" disabled={loading}>
        Cancel
      </Button>

      <div className="d-flex">
        {children && <div className="d-flex mr-3  fs-2 fs-sm-3">{children}</div>}

        <Button
          type="primary"
          onClick={() => onSubmit(false)}
          disabled={disabled}
          loading={loading}
          className="mr-3 bg-success border border-success fs-2 fs-sm-3"
        >
          Save
        </Button>
        <Button
          type="primary"
          onClick={() => onSubmit(true)}
          disabled={disabled}
          loading={loading}
          className="bg-success border border-success fs-2 fs-sm-3"
        >
          Save & Close
        </Button>
      </div>
    </div>
  );
}
