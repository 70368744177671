import React from "react";
import { Button } from "antd";
import { Formik } from "formik";
import PropTypes from "prop-types";
import "./assets/settings-edit-users.scss";

import { InputField } from "../form/InputField";
import { settingsEditUsersValidationSchema } from "../../helpers/SettingsHelpers";
import { PhoneField } from "../form/PhoneField";

SettingsEditUserForm.propTypes = {
  user: PropTypes.object,
  deleting: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export function SettingsEditUserForm({ user, deleting, onSubmit, onDeleteClick }) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={user}
      validationSchema={settingsEditUsersValidationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form className="d-flex flex-column p-2" onSubmit={handleSubmit}>
          <div className="d-flex flex-column flex-sm-row">
            <InputField
              name="firstName"
              label="First Name"
              transparent={false}
              className="flex-1 m-2"
            />
            <InputField
              name="lastName"
              label="Last Name"
              transparent={false}
              className="flex-1 m-2"
            />
          </div>
          <div className="d-flex flex-column flex-sm-row">
            <PhoneField
              name="phone"
              label="Phone"
              placeholder="Enter phone"
              labelClassName="ml-2"
              containerClass="invite-phone m-2"
            />
            <InputField
              name="email"
              label="Email"
              disabled={true}
              transparent={false}
              className="flex-1 m-2"
            />
          </div>

          <div className="d-flex justify-content-sm-around my-3 flex-column-reverse flex-sm-row">
            <Button
              type="primary"
              loading={deleting}
              onClick={onDeleteClick}
              className="border-danger bg-danger mx-2"
            >
              Delete user
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              className="mx-2 mb-3 mb-sm-0"
            >
              Save changes
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
