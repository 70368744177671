import React, { useCallback, useMemo, useState } from "react";

import cx from "classnames";
import { DepartmentsModalEdit } from "./DepartmentsModalEdit";
import { Button } from "antd";

interface Props {
  readonly item: any;
  readonly setUpdate: (value: any) => void;
}

export function DepartmentsTableItem({ item, setUpdate }: Props) {
  const [visibleModal, setVisibleModal] = useState(false);

  const isDisabled = useMemo(() => !!(!item.active && item.verified), [item.active, item.verified]);

  return (
    <>
      <div className="d-flex flex-column align-items-center d-md-table-row p-3 p-md-0 border-bottom border-top">
        <div className="departments-table-name d-table-cell mb-3 mb-md-0 pt-md-4 pb-md-4 align-middle pl-4">
          <h2 className={cx("mb-0 text-primary font-family-museo", { disabled: isDisabled })}>
            {item.departmentName}
          </h2>
        </div>

        <div
          className="d-flex flex-column align-items-center d-md-table-cell
          mb-3 mb-md-0 p-0 pt-md-4 pb-md-4 align-middle w-50"
        >
          {item.description}
        </div>

        <div
          className="d-flex flex-column align-items-center d-md-table-cell
          mb-3 mb-md-0 p-0 pt-md-4 pb-md-4 w-50 align-middle"
        >
          {item.membersCount}
        </div>

        <div className="d-table-cell p-0 pt-md-4 pb-md-4 pr-md-4 w-auto align-middle">
          <div className="d-flex justify-content-end">
            <Button
              size="small"
              type="primary"
              onClick={useCallback(() => setVisibleModal(true), [])}
              disabled={item.system}
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
      {visibleModal && (
        <DepartmentsModalEdit
          id={item.id}
          active={item.active}
          visible={visibleModal}
          verified={item.verified}
          name={item.departmentName}
          description={item.description}
          hideModal={() => setVisibleModal(false)}
          setUpdate={setUpdate}
        />
      )}
    </>
  );
}
