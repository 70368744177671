import PropTypes from "prop-types";
import { notification } from "antd";
import React, { useCallback, useMemo, useState } from "react";

import { Dialog } from "../ui/Dialog";
import { getErrorMessage } from "../../utils/utils";
import { SettingsEditUserForm } from "./SettingsEditUserForm";
import { useUsersContext } from "../../api/users/UsersContext";

SettingsEditUserDialogWrapper.propTypes = {
  show: PropTypes.bool,
  user: PropTypes.object,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
};

export function SettingsEditUserDialogWrapper({ show, onCancel, onComplete, user = {} }) {
  const [deleting, setDeleting] = useState(false);

  const name = useMemo(() => [user.firstName, user.lastName].filter(Boolean).join(" "), [user]);

  const { UsersApi } = useUsersContext();

  const editingHandle = useCallback(
    async (values) => {
      try {
        await UsersApi.updateUser(values);

        notification.open({
          type: "success",
          message: "Edit user",
          description: "Edited successful",
        });

        onComplete();
      } catch (e) {
        notification.open({
          type: "error",
          duration: null,
          message: "Edit user",
          description: getErrorMessage(e),
        });
      }
    },
    [UsersApi, onComplete],
  );

  const deletingHandle = useCallback(
    async (id) => {
      setDeleting(true);

      try {
        await UsersApi.deleteUser(id);

        notification.open({
          type: "success",
          message: "Delete user",
          description: "Deleted successful",
        });

        onComplete();

        setDeleting(false);
      } catch (e) {
        notification.open({
          type: "error",
          duration: null,
          message: "Delete user",
          description: getErrorMessage(e),
        });
        setDeleting(false);
      }
    },
    [UsersApi, onComplete],
  );

  return (
    <Dialog
      footer={null}
      destroyOnClose={true}
      width={768}
      show={show}
      title={name}
      onCancel={onCancel}
    >
      <SettingsEditUserForm
        user={user}
        deleting={deleting}
        onSubmit={editingHandle}
        onDeleteClick={() => deletingHandle(user.id)}
      />
    </Dialog>
  );
}
