import "./assets/card-details-form.scss";

import React from "react";

import PropTypes from "prop-types";
import { Checkbox, Form, Input, Select, SubmitButton } from "formik-antd";

import { CardDetailsIcons } from "./CardDetailsIcons";

import { months, years } from "../../widgets/form/PreSetData";
import { countryConstants } from "../../constants/geographicConstants";

CardDetailsForm.propTypes = {
  stateList: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export function CardDetailsForm({ handleSubmit, stateList = [], loading }) {
  return (
    <Form onSubmit={handleSubmit} className="card-details-form">
      <Form.Item name="cardNumber" label="Card Number">
        <Input name="cardNumber" placeholder="Your card number" />
        <CardDetailsIcons />
      </Form.Item>

      <div className="row">
        <div className="col-sm-4">
          <Form.Item name="expirationMonth" label="Expiration Month">
            <Select name="expirationMonth" placeholder="Select Month">
              {months.map(({ value, text }) => (
                <Select.Option value={value} key={value}>
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-sm-4">
          <Form.Item name="expirationYear" label="Expiration Year">
            <Select name="expirationYear" placeholder="Select Year">
              {years.map(({ value, text }) => (
                <Select.Option value={value} key={value}>
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-sm-4">
          <Form.Item name="cvv" label="CVV">
            <Input name="cvv" placeholder="Your cvv" />
          </Form.Item>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <Form.Item name="holderName" label="Card Holder Name">
            <Input name="holderName" placeholder="Your card holder name" />
          </Form.Item>
        </div>
        <div className="col-sm-6">
          <Form.Item name="country" label="Country">
            <Select name="country" placeholder="Select country">
              {countryConstants.map(({ value, text }) => (
                <Select.Option value={value} key={value}>
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Form.Item name="state" label="State / Province / Region">
            <Select name="state" placeholder="Select state">
              {stateList.map(({ value, text }) => (
                <Select.Option value={value} key={value}>
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-6">
          <Form.Item name="postalCode" label="Zip Code / Postal Code">
            <Input name="postalCode" placeholder="Your zip / postal code" />
          </Form.Item>
        </div>
      </div>

      <Form.Item name="agreement">
        <Checkbox name="agreement">
          I have read and agree to the Terms of Service and Privacy Policy. I acknowledge that I
          will be automatically enrolled in the Basic Subscription and charged the monthly fee of
          $99 + Applicable taxes if I do not cancel my subscription proir to January 5th, 2020 via
          my user account.
        </Checkbox>
      </Form.Item>

      <SubmitButton loading={loading} className="mb-2" type="primary" block={true}>
        START YOUR FREE TRIAL
      </SubmitButton>
      <div className="text-center fs-3 text-primary">You will not be charged now.</div>
    </Form>
  );
}
