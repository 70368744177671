import "./assets/icons.scss";

import React from "react";
import cx from "classnames";

export function PinterestIconLegacy({ active, size = 54, className }) {
  return (
    <div className={cx("icon", className)}>
      <svg
        x="0px"
        y="0px"
        id="Layer_1"
        version="1.1"
        width={size}
        height={size}
        viewBox="0 0 291.319 291.319"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fill="#C8232C"
            d="M145.659,0c80.45,0,145.66,65.219,145.66,145.66c0,80.45-65.21,145.659-145.66,145.659
		S0,226.109,0,145.66C0,65.219,65.21,0,145.659,0z"
          />
          <path
            fill="#FFFFFF"
            d="M150.066,63.781c-51.327,0-77.19,35.632-77.19,65.337c0,17.989,7.028,33.993,22.122,39.956
		c2.467,0.974,4.698,0.036,5.408-2.613l2.203-8.403c0.728-2.613,0.446-3.541-1.548-5.826c-4.361-4.962-7.128-11.398-7.128-20.511
		c0-26.428,20.42-50.089,53.175-50.089c29.014,0,44.945,17.161,44.945,40.075c0,30.152-13.783,55.606-34.248,55.606
		c-11.289,0-19.755-9.04-17.042-20.156c3.241-13.237,9.541-27.539,9.541-37.107c0-8.548-4.743-15.704-14.575-15.704
		c-11.553,0-20.829,11.571-20.829,27.074c0,9.878,3.45,16.551,3.45,16.551l-13.901,56.998c-4.124,16.906-0.61,37.644-0.319,39.738
		c0.182,1.238,1.821,1.529,2.567,0.601c1.065-1.347,14.821-17.798,19.5-34.239c1.329-4.652,7.602-28.75,7.602-28.75
		c3.751,6.946,14.721,13.046,26.383,13.046c34.712,0,58.273-30.652,58.273-71.674C218.444,92.676,191.315,63.781,150.066,63.781z"
          />
        </g>
      </svg>

      {!active && <div className="disabled-icon" />}
    </div>
  );
}
