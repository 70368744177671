import "./assets/preview-promotion-button.scss";

import cx from "classnames";
import moment from "moment";
import React, { useMemo } from "react";

import { CheckCircleIcon } from "../icons/CheckCircleIcon";
import { PromotionSocialItem, SocialType } from "./PromotionSocialItem";
import { propOr, prop } from "ramda";
import { useMediaQuery } from "../../hooks/useMediaQuery";

interface Props {
  readonly color?: string;
  readonly onClick: () => void;
  readonly className?: string;
  readonly promotion: any;
}

export function PromotionPreviewButton(props: Props) {
  const { color = "#002049", onClick, className, promotion } = props;
  const { isMobile } = useMediaQuery();

  const endDate = prop("endDate", promotion);

  const ExpiryDate = useMemo(() => {
    if (!endDate) {
      return {
        smallEnd: false,
        value: "EXPIRES: NEVER",
      };
    } else {
      const newEndDate = moment(endDate);
      const newDateText = newEndDate.diff(moment(), "days");
      return {
        smallEnd: newDateText < 3,
        value: `EXPIRES: ${newDateText} DAYS`,
      };
    }
  }, [endDate]);

  const linkedinShares = propOr(0, "linkedinShares", promotion);
  const linkedinEnabled = prop("linkedinEnabled", promotion);
  const twitterShares = propOr(0, "twitterShares", promotion);
  const twitterEnabled = prop("twitterEnabled", promotion);
  const facebookShares = propOr(0, "facebookShares", promotion);
  const facebookEnabled = prop("facebookEnabled", promotion);
  const pinterestShares = propOr(0, "pinterestShares", promotion);
  const pinterestEnabled = prop("pinterestEnabled", promotion);

  const promotionType = propOr("TEST", "promotionType", promotion);
  const image = propOr("", "url", promotion);

  return (
    <div role="button" onClick={onClick} className={cx("preview-promotion-button", className)}>
      <div className="d-flex align-items-center">
        <div className="preview-image-root">
          <img alt={image} className="preview-image" src={image} />
          <div className="total-shared-root">
            <span className="total-title">TOTAL TEAM POSTS:</span>
            <div className="d-flex">
              {linkedinEnabled && (
                <PromotionSocialItem
                  className="mx-1"
                  count={linkedinShares}
                  socialType={SocialType.LinkedIn}
                  linkedInEnabled={true}
                  size={isMobile ? 14 : 16}
                />
              )}
              {twitterEnabled && (
                <PromotionSocialItem
                  className="mx-1"
                  count={twitterShares}
                  socialType={SocialType.Twitter}
                  twitterEnabled={true}
                  size={isMobile ? 14 : 16}
                />
              )}
              {facebookEnabled && (
                <PromotionSocialItem
                  className="mx-1"
                  count={facebookShares}
                  socialType={SocialType.Facebook}
                  facebookEnabled={true}
                  size={isMobile ? 14 : 16}
                />
              )}
              {pinterestEnabled && (
                <PromotionSocialItem
                  className="mx-1"
                  count={pinterestShares}
                  socialType={SocialType.Pinterest}
                  pinterestEnabled={true}
                  size={isMobile ? 14 : 16}
                />
              )}
            </div>
          </div>
        </div>
        <div className="promotion-preview-description">
          <span className="type">{`${promotionType}:`}</span>
          <span className="name" style={{ color }}>
            {promotion.name}
          </span>
          <div className="shared-checks">
            {promotion.usedValues.map((headline) => (
              <CheckCircleIcon key={headline.value} color="black" active={headline.used} />
            ))}
          </div>
          <span
            className="expires"
            style={{ color: ExpiryDate.smallEnd ? "RGB(255, 0, 23)" : color }}
          >
            {ExpiryDate.value}
          </span>
        </div>
      </div>
    </div>
  );
}
