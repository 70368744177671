import "./assets/input-with-count.scss";

import cx from "classnames";
import { Input } from "antd";
import React, { useCallback, useMemo, useState } from "react";

export function InputWithCount({
  type = "input",
  onChange,
  className,
  footerTextClassName,
  footerContainerClassName,
  ...inputProps
}) {
  const [value, setValue] = useState(inputProps.value || "");

  const withMaxLength = useMemo(() => Boolean(inputProps.maxLength), [inputProps.maxLength]);

  const changeHandler = useCallback(
    (event) => {
      if (!withMaxLength || (withMaxLength && event.target.value.length <= inputProps.maxLength)) {
        setValue(event.target.value);

        if (onChange) {
          onChange(event);
        }
      }
    },
    [withMaxLength, inputProps.maxLength, onChange],
  );

  const Field = type === "input" ? Input : Input.TextArea;

  const charCount = useMemo(() => inputProps.maxLength - value.length, [
    inputProps.maxLength,
    value.length,
  ]);

  return (
    <div className={cx("input-with-count", className)}>
      <Field onChange={changeHandler} {...inputProps} />
      <div className={cx("input-footer", footerContainerClassName)}>
        {withMaxLength && (
          <span className={cx("input-with-count-characters", footerTextClassName)}>
            characters: {charCount}
          </span>
        )}
      </div>
    </div>
  );
}
