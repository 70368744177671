import qs from "qs";
import { DateTime } from "luxon";
import { toFinite } from "lodash";

export const DATETIME_HUG = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "long",
};

export function formatPrice(price = 0, options = { prefix: "$", postfix: "" }) {
  const value = toFinite(price) / 100;

  return [options.prefix, value.toFixed(2), options.postfix].filter(Boolean).join("");
}

export function formatDate(date, options: any = {}) {
  return date.setLocale(options.locale || "en").toLocaleString(options.format);
}

export function formatSecondsToString(date, format = DateTime.DATE_FULL) {
  if (!date) {
    return;
  }

  const dateTime = DateTime.fromSeconds(date);

  return formatDate(dateTime, { format });
}

export function formatMillisecondsToString(
  date,
  format = DateTime.DATE_FULL,
  replaceValue = "",
): string {
  if (!date) {
    return replaceValue;
  }

  const dateTime = DateTime.fromMillis(date);

  return formatDate(dateTime, { format });
}

export function parseSearch<T>(search = ""): T {
  return qs.parse(search.replace("?", "")) as any;
}
