import "./assets/sign-in-footer.scss";

import React from "react";

export function SignInFooter() {
  return (
    <div className="sign-in-footer">
      Don’t have an account?
      <a href="https://www.vsbl.biz/vsbl-subscription-options/">Sign up now</a>
    </div>
  );
}
