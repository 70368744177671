import React, { useMemo } from "react";
import { useFormikContext } from "formik";

import companyLogo from "../../assets/images/company-logo.png";
import { ReactComponent as LikeIcon } from "./assets/like.svg";
import { ReactComponent as ShareIcon } from "./assets/share.svg";
import { ReactComponent as CommentIcon } from "./assets/comment.svg";
import { ReactComponent as GraphicIcon } from "./assets/graphic.svg";
import { TemplateDnd } from "../template-dnd/TemplateDnd";

// TODO Cut necessary icons and replace

export function PromotionMessagingLinkedInPost() {
  const { values } = useFormikContext();

  const hasTemplate = useMemo(() => Boolean(values.promotionItem), [values.promotionItem]);
  const link = useMemo(() => {
    if (values.promotionSocial) {
      if (values.promotionSocial.linkedinOverrideUrl) {
        return values.promotionSocial.linkedinOverrideUrl;
      }
      if (values.promotionSocial.targetUrl) {
        return values.promotionSocial.targetUrl;
      }
    }

    return "yourcompanywebsite.com";
  }, [values.promotionSocial]);

  const headline = useMemo(() => {
    if (values.promotionSocial && values.promotionSocial.linkedinHeadline) {
      return values.promotionSocial.linkedinHeadline;
    }

    return "Headline text will be placed here in the post details. The headline can hold up to 200 characters";
  }, [values.promotionSocial]);

  return (
    <div className="d-flex flex-column client-promotion-edit-messaging-post">
      <div className="messaging-post-header">
        <div className="messaging-post-header-top">
          <div className="messaging-post-user">
            <div className="messaging-post-avatar">
              <div className="messaging-post-avatar-icon" />
              <div className="messaging-post-avatar-status" />
            </div>
            <div className="messaging-post-user-info">
              <div className="messaging-post-user-name">LinkedIn User</div>
              <div className="messaging-post-user-company">User Title, Company Name</div>
              <div className="messaging-post-user-time">Time</div>
            </div>
          </div>
          <div className="messaging-post-header-button">
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </div>
        </div>
        <div className="messaging-post-header-bottom">Comments made by your team mates</div>
      </div>

      {hasTemplate && <TemplateDnd templateId="LinkedInTemplatePreviewDndTarget" canDrag={false} />}

      {!hasTemplate && (
        <div className="messaging-post-template">
          <div className="messaging-post-template-header">
            <div className="messaging-post-template-title">
              The “Post Message” that is selected by your end user is displayed here
            </div>
          </div>
          <div className="messaging-post-template-footer">
            <div className="messaging-post-template-description">
              Signature line can be used as a invitation to connect or make special offer.
            </div>
            <img src={companyLogo} alt="brand" />
          </div>
        </div>
      )}

      <div className="messaging-post-info">
        <div className="messaging-post-info-title">{headline}</div>
        <span className="messaging-post-info-link">{link}</span>
      </div>

      <div className="messaging-post-actions">
        <div className="messaging-post-actions-item">
          <LikeIcon />
          <span>Like</span>
        </div>
        <div className="messaging-post-actions-item">
          <CommentIcon />
          <span>Comment</span>
        </div>
        <div className="messaging-post-actions-item">
          <ShareIcon />
          <span>Share</span>
        </div>
      </div>

      <div className="messaging-post-stat">
        <GraphicIcon />
        <div className="messaging-post-stat-text">
          <span>223 views</span> of your post in the feed
        </div>
      </div>
    </div>
  );
}
