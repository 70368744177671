import React, { ReactNode } from "react";
import cx from "classnames";

import { AppLayout } from "./AppLayout";
import { TeamAppHeader } from "./TeamAppHeader";

interface Props {
  readonly title: string;
  readonly children: ReactNode;
  readonly contentClassName?: string;
}

export function TeamAppLayout({ title, children, contentClassName }: Props) {
  return (
    <AppLayout
      title={title}
      headerComponent={<TeamAppHeader />}
      contentClassName={cx("d-flex flex-column ", contentClassName)}
    >
      {children}
    </AppLayout>
  );
}
