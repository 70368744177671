import { DragSourceMonitor, useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { toFinite } from "lodash";
import React, { CSSProperties, useEffect, useMemo, useRef } from "react";

import { TemplateDndType } from "./TemplateDnd";
import { useTemplateDnd } from "./TemplateDndContext";
import { LogoZoneProps, TemplateLogo } from "./TemplateLogo";
import { useFormikContext } from "formik";

function getStyles(left: number, top: number, isDragging: boolean, isBig?: boolean): CSSProperties {
  const newLeft = isBig ? left * 2 : left;
  const newTop = isBig ? top * 2 : top;

  const transform = `translate3d(${newLeft}px, ${newTop}px, 0)`;

  // const transform = `translate3d(${width === 1200 ? left * 2 + 5 : left}px, ${
  //   width === 1200 ? top * 2 + 80 : top
  // }px, 0)`;

  return {
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : "",
  };
}

interface Props {
  readonly canDrag?: boolean;
  readonly hideLogo?: boolean;
  readonly logoZone: LogoZoneProps;
  readonly loading?: boolean;
  readonly width?: number;
}

export function TemplateLogoDnd({
  logoZone = {} as LogoZoneProps,
  canDrag = true,
  hideLogo,
  loading,
  width,
}: Props) {
  const { aspectRatio } = useTemplateDnd();
  const formikContext = useFormikContext();
  const rootElement = useRef<HTMLDivElement | null>(null);
  const isBig = width === 1200;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      canDrag,
      item: {
        top: logoZone.foaty * aspectRatio,
        left: logoZone.foatx * aspectRatio,
        width: toFinite(rootElement.current?.clientWidth) * aspectRatio,
        height: toFinite(rootElement.current?.clientHeight) * aspectRatio,
      },
      type: TemplateDndType.Logo,
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (!monitor.didDrop()) {
          const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();

          if (
            typeof differenceFromInitialOffset?.x === "number" &&
            typeof differenceFromInitialOffset?.y === "number" &&
            width
          ) {
            let newLeft = logoZone.foatx * aspectRatio + differenceFromInitialOffset?.x;
            if (newLeft > width - toFinite(rootElement.current?.clientWidth) * aspectRatio)
              newLeft = width - toFinite(rootElement.current?.clientWidth) * aspectRatio;
            if (newLeft < 0) newLeft = 0;

            let newTop = logoZone.foaty * aspectRatio + differenceFromInitialOffset?.y;
            if (newTop > 314 - toFinite(rootElement.current?.clientHeight) * aspectRatio)
              newTop = 314 - toFinite(rootElement.current?.clientHeight) * aspectRatio;
            if (newTop < 0) newTop = 0;

            formikContext.setFieldValue("zones.logoZone.foaty", newTop / aspectRatio);
            formikContext.setFieldValue("zones.logoZone.foatx", newLeft / aspectRatio);
          }
        }
      },
    }),
    [
      canDrag,
      logoZone.foatx,
      logoZone.foaty,
      rootElement.current?.clientWidth,
      rootElement.current?.clientHeight,
      aspectRatio,
    ],
  );

  const rootStyles = useMemo(
    () => ({
      width: isBig ? logoZone.width * 2 : logoZone.width,
      height: isBig ? logoZone.height * 2 : logoZone.height,
      ...getStyles(
        isBig ? logoZone.foatx * 2 : logoZone.foatx * aspectRatio,
        isBig ? logoZone.foaty * 2 : logoZone.foaty * aspectRatio,
        isDragging,
      ),
    }),
    [
      aspectRatio,
      isDragging,
      logoZone.foatx,
      logoZone.foaty,
      logoZone.height,
      logoZone.width,
      isBig,
    ],
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    const element = document.querySelector("#TemplateLogo") as HTMLDivElement | null;

    if (element) {
      rootElement.current = element;
    }
  }, []);

  return (
    <div ref={drag} className="position-absolute" style={rootStyles} role="DraggableBox">
      <TemplateLogo
        onClick={() => {
          formikContext.setFieldValue("zones.logoZone.active", true);
          formikContext.setFieldValue("zones.headlineZone.active", false);
        }}
        canDrag={canDrag}
        logoZone={logoZone}
        hideLogo={hideLogo}
        loading={loading}
        isBig={isBig}
      />
    </div>
  );
}
