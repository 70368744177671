import "./assets/main-menu-item.scss";

import React, { ReactNode } from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";

interface Props {
  readonly style?: any;
  readonly children?: ReactNode;
  readonly className?: string;
  readonly to?: any;
  readonly onClick?: () => void;
}

export function MainMenuItem({ to, className, children, ...props }: Props) {
  return (
    <NavLink
      {...props}
      to={to}
      className={cx("font-weight-light font-family-museo main-menu-item", className)}
    >
      {children}
    </NavLink>
  );
}
