import "./assets/repeat.scss";

import cx from "classnames";
import { Button } from "antd";
import { uid } from "react-uid";
import React, { useState } from "react";

function strToBool(s) {
  // will match one and only one of the string 'true','1', or 'on' rerardless
  // of capitalization and regardless off surrounding white-space.

  const regex = /^\s*(true|1|on)\s*$/i;

  return regex.test(s);
}

export function RepeatWeekly(props: any) {
  const initialValues = [
    { name: "S", value: strToBool(props.values.week[0].value) },
    { name: "M", value: strToBool(props.values.week[1].value) },
    { name: "T", value: strToBool(props.values.week[2].value) },
    { name: "W", value: strToBool(props.values.week[3].value) },
    { name: "Th", value: strToBool(props.values.week[4].value) },
    { name: "F", value: strToBool(props.values.week[5].value) },
    { name: "S", value: strToBool(props.values.week[6].value) },
  ];
  const [weeksChecked, setWeeksChecked] = useState(initialValues);
  function handleClickButtonWeek(index) {
    const updatedArray = weeksChecked.map((item, idx) => {
      if (idx === index) {
        return { name: item.name, value: !item.value };
      } else {
        return item;
      }
    });
    setWeeksChecked(updatedArray);
    props.setFieldValue("week", updatedArray);
  }

  return (
    <div className="repeat-weekly">
      {weeksChecked.map((item, idx) => {
        const last = idx + 1 === weeksChecked.length;

        return (
          <Button
            key={uid(item)}
            type={item.value ? "primary" : undefined}
            onClick={() => handleClickButtonWeek(idx)}
            className={cx("m-0 p-0", { "mr-1": !last })}
          >
            {item.name}
          </Button>
        );
      })}
    </div>
  );
}
