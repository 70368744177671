import React from "react";

import PropTypes from "prop-types";

// eslint-disable-next-line no-use-before-define
TimesIcon.propTypes = {
  size: PropTypes.number,
};

export function TimesIcon({ size = 14, ...svgProps }) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="nonzero">
        <path
          fill="#848484"
          d="M7 0c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.14-7-7 3.14-7 7-7zm0 12.8c3.198 0 5.8-2.602 5.8-5.8S10.198 1.2 7 1.2A5.807 5.807 0 0 0 1.2 7c0 3.198 2.602 5.8 5.8 5.8z"
        />
        <path
          fill="#999"
          d="M9.892 4.108a.64.64 0 0 0-.906 0L7 6.094 5.014 4.108a.64.64 0 1 0-.906.906L6.094 7 4.108 8.986a.64.64 0 1 0 .906.906L7 7.906l1.986 1.986a.639.639 0 0 0 .906 0 .64.64 0 0 0 0-.906L7.906 7l1.986-1.986a.64.64 0 0 0 0-.906z"
        />
      </g>
    </svg>
  );
}
