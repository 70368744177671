import React, { useCallback, useState } from "react";
import { DELETE, update } from "immupdate";

import { getErrorMessage } from "../../utils/utils";
import { useNotification } from "../../hooks/useNotification";
import { useUsersContext } from "../../api/users/UsersContext";
import { TeamAccount } from "../../components/account/TeamAccount";
import { useUser } from "../../context/auth/hooks";
import { ClientAppLayout } from "../../components/app/ClientAppLayout";

export function AccountContainer() {
  const { UsersApi } = useUsersContext();
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const { showErrorNotificationLegacy, showSuccessNotification, showError } = useNotification();

  const onChangePassword = useCallback(
    (values: any) => {
      setLoading(true);
      UsersApi.changePassword(values)
        .then(() => {
          showSuccessNotification({ message: "Password changed" });
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          showError(e);
        });
    },
    [UsersApi, setLoading, showError, showSuccessNotification],
  );

  const handleSubmit = useCallback(
    (values) => {
      if (user) {
        UsersApi.updateUser(
          update(user, {
            ...values,
            active: true,
            memberId: DELETE,
            companyStatus: DELETE,
            administrator: DELETE,
            id: user.memberId,
          }),
        )
          .then(() => {
            setUser({ ...user, ...values });

            showSuccessNotification({
              message: "Save account",
            });
          })
          .catch((e) =>
            showErrorNotificationLegacy({
              message: "Save account",
              description: getErrorMessage(e),
            }),
          );
      }
    },
    [UsersApi, setUser, showErrorNotificationLegacy, showSuccessNotification, user],
  );

  return (
    <ClientAppLayout>
      <TeamAccount
        user={user}
        onSubmit={handleSubmit}
        handleChangePassword={onChangePassword}
        loading={loading}
      />
    </ClientAppLayout>
  );
}
