import { useCallback, useMemo, useState } from "react";

import { useFilesContext } from "../api/files/FilesContext";
import { getErrorMessage } from "../utils/utils";
import { useNotification } from "./useNotification";

export function useUploadLogo() {
  const [error, setError] = useState();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showError } = useNotification();

  const { FilesApi } = useFilesContext();

  const uploadHandler = useCallback(
    async (formData) => {
      try {
        const response = await FilesApi.uploadLogo(formData);

        if (response) {
          setFiles((x = []) => {
            const list = x.slice() as any;

            list.push(response);

            return list;
          });
        }
      } catch (e) {
        showError(getErrorMessage(e));
        setError(e as any);
      }
    },
    [showError, FilesApi],
  );

  const onChangeHandle = useCallback(
    async ({ target }) => {
      setLoading(true);

      try {
        if (target.files.length > 0) {
          const formData = new FormData();

          formData.append("file", target.files[0]);

          await uploadHandler(formData);
        }

        setLoading(false);
      } catch (e) {
        showError(getErrorMessage(e));
        setError(e as any);
        setLoading(false);
      }
    },
    [showError, uploadHandler],
  );

  const input = useMemo(() => {
    const fileSelector = document.createElement("input");

    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute("accept", "image/jpeg,image/png");

    fileSelector.onchange = onChangeHandle;

    return fileSelector;
  }, [onChangeHandle]);

  return [input, { error, list: files, loading, reset: () => setFiles([]) }];
}
