import { toFinite } from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router";
import "./assets/promotion-complete-container.scss";

import { useQuery } from "../../hooks/useQuery";
import { useResource } from "../../hooks/useResource";
import { ContainerLoader } from "../../components/ui/ContainerLoader";
import { usePromotionContext } from "../../api/promotions/PromotionContext";
import { PromotionShareComplete } from "../../components/team-portal/PromotionShareComplete";
import { useSettings } from "../../context/auth/hooks";
import { TeamAppLayout } from "../../components/app/TeamAppLayout";
import { propOr, prop } from "ramda";
import { PromotionPostLinkedInSuccessDialog } from "../../components/promotion/PromotionPostLinkedInSuccessDialog";
// import PromotionAICommentForm from "../../components/team-portal/PromotionAICommentForm";
// import { useAiContext } from "../../api/ai/AiContext";
// // @ts-ignore
// import { onCopyToClipboard } from "../../helpers/SystemUtils";
// import { useNotification } from "../../hooks/useNotification";
// import { getErrorMessage } from "../../utils/utils";

interface Query {
  readonly shared?: boolean;
  readonly image?: string;
  readonly submitId?: string;
  readonly socialTracking?: any;
}

export function PromotionCompleteContainer() {
  const params = useParams<any>();
  const { image, submitId, socialTracking, shared } = useQuery<Query>();
  const { settings } = useSettings();

  const [showLinkedInSuccess, setShowLinkedInSuccess] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [comment, setComment] = useState("");

  const promotionId = useMemo(() => toFinite(params.id), [params]);

  // const { AiApi } = useAiContext();
  const { PromotionApi } = usePromotionContext();
  // const { showError, showSuccessNotification } = useNotification();

  const promotionResource = useResource(
    () => (promotionId ? PromotionApi.getPromotion(promotionId, { withZones: false }) : undefined),
    [promotionId],
  );

  useEffect(() => {
    if (shared) {
      setShowLinkedInSuccess(true);
    }
  }, [shared]);

  const promotionSocial = useMemo(() => promotionResource.data?.promotionSocial, [
    promotionResource.data,
  ]);
  const promotion = propOr({}, "data", promotionResource);

  const name = propOr("", "name", promotion);
  // const targetURL = pathOr("", ["promotionSocial", "targetUrl"], promotion);
  const description = propOr("", "description", promotion);
  const color = propOr("#025692", "primaryColor", settings);
  const promotionType = prop("promotionType", promotion);

  // const onCopySuccess = () => {
  //   showSuccessNotification({
  //     message: "Comment copied successfully!",
  //   });
  // };

  // const onGenerateAIComment = useCallback(
  //   ({
  //     category,
  //     maxTokens,
  //     seed,
  //     temperature,
  //   }: {
  //     category: string;
  //     maxTokens: number;
  //     seed?: number;
  //     temperature: number;
  //   }) => {
  //     setComment("");
  //     setLoading(true);
  //     AiApi.getSuggestedPostForLink({ category, maxTokens, seed, temperature, url: targetURL })
  //       .then((response) => {
  //         const comment = pipe(
  //           propOr([], "choices"),
  //           head,
  //           path(["message", "content"]),
  //           defaultTo(""),
  //         )(response);
  //
  //         setComment(comment);
  //         setLoading(false);
  //       })
  //       .catch((e) => {
  //         showError(getErrorMessage(e));
  //         setLoading(false);
  //       });
  //   },
  //   [AiApi, showError, targetURL],
  // );

  return (
    <TeamAppLayout title="Team Portal">
      <PromotionPostLinkedInSuccessDialog
        show={showLinkedInSuccess}
        onCancel={() => setShowLinkedInSuccess(false)}
      />

      <div className="d-flex flex-column justify-content-center align-items-center container">
        <div className="complete-choose-header">
          <div className="complete-choose-title">
            <span className="complete-choose-type">{promotionType ? `${promotionType}:` : ""}</span>
            <span className="complete-choose-name" style={{ color }}>
              {name}
            </span>
          </div>
        </div>
        <span className="complete-choose-description">{description}</span>
        <span className="complete-choose-button-title">
          Below is what your post will look like:
        </span>
      </div>
      <div className="d-flex flex-column container">
        {Boolean(!promotionResource.loading && promotionResource.data) && (
          <PromotionShareComplete
            image={image}
            className="mb-6"
            submitId={submitId}
            socialTracking={socialTracking}
            promotionSocial={promotionSocial}
            onTrace={(social, submitId) => {
              PromotionApi.traceSocial({
                social,
                "order-id": submitId,
                promotionId: promotionResource.data?.id,
              });
            }}
          >
            <div>
              <img
                alt="promotion"
                src={image}
                className="h-auto align-self-center complete-share-image"
              />
            </div>
          </PromotionShareComplete>
        )}

        <ContainerLoader show={promotionResource.loading} />
      </div>
    </TeamAppLayout>
  );
}
