import "./assets/promotions-item-header.scss";

import cx from "classnames";
import moment from "moment";
import { useField, useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { VSBLButton } from "../../widgets/form";
import { DateTimeField } from "../form/DateTimeField";
import { PromotionFieldTitle } from "./PromotionFieldTitle";
import { InputWithCountField } from "../form/InputWithCountField";
import { someSocialIsActive } from "../../helpers/PromotionsHelpers";
import { SelectField } from "../form/SelectField";
import { CheckboxField } from "../form/CheckboxField";
import { useDepartmentContext } from "../../api/departments/DepartmentsContext";
import { useResource } from "../../hooks/useResource";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { Loader } from "../ui/Loader";
import { propOr } from "ramda";

const promotionType = [
  {
    text: "Ad Campaigns",
    value: "Ad Campaigns",
  },
  {
    text: "Blog Posts",
    value: "Blog Posts",
  },
  {
    text: "Conferences/Events",
    value: "Conferences/Events",
  },
  {
    text: "Customer Testimonials",
    value: "Customer Testimonials",
  },
  {
    text: "Podcasts & Media",
    value: "Podcasts & Media",
  },
  {
    text: "Press & Releases",
    value: "Press & Releases",
  },
  {
    text: "Product Announcements",
    value: "Product Announcements",
  },
  {
    text: "Product/Service Demos",
    value: "Product/Service Demos",
  },
  {
    text: "Third Party Resources",
    value: "Third Party Resources",
  },
  {
    text: "Webinars",
    value: "Webinars",
  },
  {
    text: "White Papers",
    value: "White Papers",
  },
  {
    text: "Other (please specify)",
    value: "other",
  },
];

interface Props {
  readonly onChange: () => void;
  readonly setChosenDepartments: (value: any) => void;
  readonly chosenDepartments: any;
}

export function PromotionsItemSettings(props: Props) {
  const { onChange, chosenDepartments, setChosenDepartments } = props;
  const { values, setFieldValue } = useFormikContext<any>();
  const [departments, setDepartments] = useState([] as any);
  const { DepartmentsApi } = useDepartmentContext();
  const [field] = useField("promotionType");
  const isOtherPromotionType = field.value === "other";
  const promotionDepartments = propOr([], "departments", values);

  useEffect(() => {
    if (promotionDepartments.length > 0) {
      const newDepartments = promotionDepartments.map((promDepartment) => {
        return departments.find((department) => department.id === promDepartment.id);
      });
      setChosenDepartments(newDepartments);
    }
  }, [setChosenDepartments, departments, promotionDepartments]);

  const departmentListResource = useResource(() => DepartmentsApi.getDepartments(), []);

  useResourceHandler(departmentListResource, {
    onSuccess: ({ items = [] }) => {
      const defaultAllDepartment = items.find(
        (department: any) => department.departmentName === "All",
      );
      defaultAllDepartment && setChosenDepartments([defaultAllDepartment]);
      setDepartments(items);
    },
  });

  const statusChecked = useMemo(() => values.status === "APPROVED", [values.status]);

  const canActiveStatus = useMemo(() => someSocialIsActive(values.promotionSocial), [
    values.promotionSocial,
  ]);

  useEffect(() => {
    if (statusChecked && !canActiveStatus) {
      setFieldValue("status", "NOT_APPROVED");
    }
  }, [statusChecked, canActiveStatus, setFieldValue]);

  const handleChangeDepartments = useCallback(
    (value) => {
      const isAlreadyChosen = chosenDepartments.find(
        (department: any) => department.id === value.id,
      );
      const isDefaultDepartment = value.departmentName === "All";
      if (isAlreadyChosen) {
        const otherDepartments = chosenDepartments.filter(
          (department: any) => department.id !== value.id,
        );
        if (!isDefaultDepartment) {
          if (otherDepartments.length > 0) {
            setChosenDepartments(otherDepartments);
          } else {
            const defaultAllDepartment = departments.find(
              (department: any) => department.departmentName === "All",
            );
            setChosenDepartments([defaultAllDepartment]);
          }
        }
      } else {
        if (isDefaultDepartment) {
          setChosenDepartments([value]);
        } else {
          const notDefaultDepartments = chosenDepartments.filter(
            (department) => department.departmentName !== "All",
          );
          setChosenDepartments([...notDefaultDepartments, value]);
        }
      }
    },
    [chosenDepartments, setChosenDepartments, departments],
  );

  const getNextDate = () => {
    const D = moment(new Date());
    const N = new Date();
    N.setDate(D.date() + 1);
    return moment(N);
  };

  return (
    <div className="promotions-item-header mx-md-4">
      <PromotionFieldTitle>PROMOTION TYPE</PromotionFieldTitle>
      <SelectField
        className="mb-4 mt-1"
        name="promotionType"
        placeholder="Choose"
        data={promotionType}
        style={{ maxWidth: "331px" }}
      />
      {isOtherPromotionType && (
        <>
          <PromotionFieldTitle>OTHER PROMOTION TYPE</PromotionFieldTitle>
          <InputWithCountField
            maxLength={30}
            withErrors={false}
            name="otherPromotion"
            className="w-25"
            footerContainerClassName="client-promotion-edit-messaging-linked-in-target-url-footer"
            placeholder="Other Promotion Type"
          />
        </>
      )}
      <PromotionFieldTitle isRequired={true}>DESCRIPTION</PromotionFieldTitle>
      <InputWithCountField
        onChange={onChange}
        withErrors={false}
        type="textarea"
        maxLength={500}
        name="description"
      />

      <div
        className={cx(
          "client-promotion-edit-social justify-content-md-between flex-column flex-md-row",
          { "mb-6": Boolean(values.endDate) },
        )}
      >
        <div>
          <PromotionFieldTitle>SCHEDULE</PromotionFieldTitle>
          <div className="availability-content ">
            <DateTimeField onChange={onChange} name="startDate" label="Start date:" />

            {Boolean(values.endDate) && (
              <>
                <DateTimeField
                  name="endDate"
                  onChange={onChange}
                  label="End date:"
                  withErrors={false}
                  showClearButton={true}
                  className="end-date-field"
                />
              </>
            )}
          </div>

          {Boolean(!values.endDate) && (
            <VSBLButton
              type="link"
              htmlType="button"
              onClick={() => {
                setFieldValue("endDate", getNextDate());
              }}
            >
              + Add End Date
            </VSBLButton>
          )}
        </div>
      </div>

      <PromotionFieldTitle className="assign-groups">GROUPS</PromotionFieldTitle>
      {departmentListResource.loading && (
        <div className="departments-loader-promotion">
          <Loader />
        </div>
      )}
      <div className="d-flex mt-2">
        {departments.map((department) => {
          const isSelected = chosenDepartments.find((chosen) => chosen.id === department.id);
          return (
            <CheckboxField
              checked={isSelected}
              key={department.departmentName}
              className="mb-4"
              name={department.departmentName}
              label={`${department.departmentName} (${department.membersCount})`}
              onChange={() => handleChangeDepartments(department)}
            />
          );
        })}
      </div>
    </div>
  );
}
