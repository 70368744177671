import React from "react";

interface Props {
  readonly size?: number;
  readonly className?: string;
}

export function ArrowBottomIcon({ size = 24, className }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" className={className}>
      <path
        fill="currentColor"
        d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z"
      />
    </svg>
  );
}
