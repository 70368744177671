import { createContext } from "react";
import { UserProps } from "../../api/users/UsersDTO";

const DEFAULT = {
  setAuthorized: () => null,
  isAuthorized: false,
  setUser: () => null,
  user: {} as UserProps,
  clientId: 0,
  isAdministrator: false,
  isExpired: false,
  setCompanyStatus: () => null,
  token: "",
  setToken: () => null,
  settings: {},
  setSettings: () => null,
  fonts: [],
  setFont: () => null,
  setFontList: () => null,
  deleteFont: () => null,
};

interface AuthProp {
  readonly setAuthorized: (value: boolean) => void;
  readonly isAuthorized: boolean;
  readonly setUser: (value: UserProps) => void;
  readonly user: UserProps;
  readonly clientId: number;
  readonly isAdministrator: boolean;
  readonly isExpired: boolean;
  readonly setCompanyStatus: (value: string) => void;
  readonly token: string;
  readonly setToken: (value: string) => void;
  readonly settings: any;
  readonly setSettings: (value: any) => void;
  readonly fonts: any;
  readonly setFont: (value: any) => void;
  readonly setFontList: (value: any) => void;
  readonly deleteFont: (value: any) => void;
}

export const AuthContext = createContext<AuthProp>(DEFAULT);
