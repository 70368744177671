import "./assets/input-field.scss";

import { useField } from "formik";
import { Checkbox, CheckboxProps } from "formik-antd";
import React from "react";

interface Props extends CheckboxProps {
  readonly name: string;
  readonly label?: string;
  readonly className?: string;
}

export function CheckboxField({ name, label, ...props }: Props) {
  const [field] = useField(name);

  return (
    <Checkbox {...field} {...props} name={name}>
      {label}
    </Checkbox>
  );
}
