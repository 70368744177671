import PropTypes from "prop-types";
import { notification } from "antd";
import React, { useCallback, useState } from "react";

import { getErrorMessage } from "../../utils/utils";
import { SettingsUsersControl } from "./SettingsUserControl";
import { useUsersContext } from "../../api/users/UsersContext";

SettingsUsersControlWrapper.propTypes = {
  onInviteClick: PropTypes.func,
  onUploadComplete: PropTypes.func,
};

export function SettingsUsersControlWrapper({ onInviteClick, onUploadComplete }) {
  const [uploading, setUploading] = useState(false);

  const { UsersApi } = useUsersContext();

  const uploadHandle = useCallback(
    async (options) => {
      setUploading(true);

      try {
        const formData = new FormData();

        formData.append("file", options.file);

        const response = await UsersApi.importUsers(formData);

        if (response.failed) throw new Error(response.failed[0]);
        if (response.created) {
          onUploadComplete();

          notification.open({
            type: "success",
            message: "Upload file",
            description: "Upload successful",
          });
        }

        setUploading(false);
      } catch (e) {
        setUploading(false);

        notification.open({
          type: "error",
          message: "Upload file",
          description: getErrorMessage(e),
          duration: null,
        });
      }
    },
    [UsersApi, onUploadComplete],
  );

  return (
    <SettingsUsersControl
      uploading={uploading}
      onUploadClick={uploadHandle}
      onInviteClick={onInviteClick}
    />
  );
}
