/* global $ */

import "./assets/modal.scss";

import cx from "classnames";
import React, { ReactNode, useEffect } from "react";

import { ModalBody } from "./ModalBody";
import { ModalHeader } from "./ModalHeader";
import { ModalFooter } from "./ModalFooter";

interface Props {
  readonly show: boolean;

  readonly id: string;
  readonly title?: string;
  readonly closable?: boolean;

  readonly fullScreen?: boolean;

  readonly header?: ReactNode;
  readonly children: ReactNode;
  readonly actions?: ReactNode;

  readonly withAnimation?: boolean;

  readonly onClose?: () => void;

  readonly className?: string;
  readonly contentClassName?: string;
  readonly actionsClassName?: string;
}

export function Modal({
  id,
  show,
  title,
  header,
  onClose,
  actions,
  children,
  className,
  closable = true,
  fullScreen,
  withAnimation = true,
  contentClassName,
  actionsClassName,
}: Props) {
  useEffect(() => {
    if (onClose) {
      $(`#${id}`).on("hidden.bs.modal", onClose);
    }
  }, [id, onClose]);

  useEffect(() => {
    if (show) {
      $(`#${id}`).modal("show");
    } else {
      $(`#${id}`).modal("hide");
    }

    return () => {
      $(`#${id}`).modal("hide");
    };
  }, [id, show]);

  return (
    <div
      id={id}
      role="dialog"
      tabIndex={-1}
      aria-hidden="true"
      aria-labelledby={`${id}Label`}
      data-backdrop={closable ? true : "static"}
      className={cx(
        "modal modal-ui",
        { "modal-fullscreen": fullScreen, fade: withAnimation },
        className,
      )}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {Boolean(title && !header) && <ModalHeader id={id} title={title!} />}

          {Boolean(header) && header}

          <ModalBody className={contentClassName}>{children}</ModalBody>

          {Boolean(actions) && <ModalFooter className={actionsClassName}>{actions}</ModalFooter>}
        </div>
      </div>
    </div>
  );
}
