import "./assets/promotion-tab-title.scss";

import React from "react";
import cx from "classnames";

import { useMediaQuery } from "../../hooks/useMediaQuery";
import { CheckCircleIcon } from "../icons/CheckCircleIcon";
import { AlertCircleIcon } from "../icons/AlertCircleIcon";

interface Props {
  readonly title: string;
  readonly visited: boolean;
  readonly hasErrors: boolean;
}

export function PromotionTabTitle({ title, hasErrors, visited }: Props) {
  const { isMobile } = useMediaQuery();

  return (
    <div className="d-flex align-items-center promotion-tab-title">
      {Boolean(visited && !hasErrors) && (
        <CheckCircleIcon size={isMobile ? 18 : 24} active={true} className="mr-2" />
      )}

      {!visited && <CheckCircleIcon size={isMobile ? 18 : 24} className="mr-2" />}

      {Boolean(visited && hasErrors) && (
        <AlertCircleIcon size={isMobile ? 18 : 24} className="mr-2" />
      )}

      <span className={cx({ "mobile-title": isMobile })}>{title}</span>
    </div>
  );
}
