import React from "react";

interface Props {
  readonly size?: number;
  readonly className?: string;
}

export function ArrowLeftIcon({ size = 24, className }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" className={className}>
      <path
        fill="currentColor"
        d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z"
      />
    </svg>
  );
}
