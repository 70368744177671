import Jimp from "jimp/es";
import { update } from "immupdate";
import { useFormikContext } from "formik";
import { Button, notification, Upload } from "antd";
import React, { useEffect, useMemo, useState } from "react";

import { CheckboxField } from "../form/CheckboxField";
import { PositionControl } from "../ui/PositionControl";
import { formatPromotionToForm } from "../../helpers/PromotionsHelpers";
import { getImagePosition, getImageSizesForContainer } from "../../helpers/ImageHelpers";
import { useSettings } from "../../context/auth/hooks";

export function PromotionBuilderLogoStep() {
  const { values, setFieldValue } = useFormikContext();
  const { settings } = useSettings();

  const [logoStat, setLogoStat] = useState({});
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const logoZone = useMemo(() => values.zones.logoZone, [values.zones]);

  const companyLogo = useMemo(() => {
    if (settings && settings.logo && settings.logo.image) {
      return settings.logo.image;
    }
  }, [settings]);
  const logoZoneImage = useMemo(() => logoZone && logoZone.image, [logoZone]);

  const noCompanyLogo = useMemo(() => Boolean(!companyLogo), [companyLogo]);

  const showReset = useMemo(() => !noCompanyLogo && companyLogo !== logoZoneImage, [
    companyLogo,
    logoZoneImage,
    noCompanyLogo,
  ]);

  useEffect(() => {
    if (logoZone) {
      if (!logoZone.logoWidth || !logoZone.logoHeight) {
        Jimp.read(logoZone.image).then((image) => {
          const size = getImageSizesForContainer(
            image.bitmap.width,
            image.bitmap.height,
            logoZone.width,
            logoZone.height,
          );

          const position = getImagePosition(logoZone.anchorImage);

          setLogoStat({
            ...size,
            ...position,
          });
        });
      } else {
        const size = getImageSizesForContainer(
          logoZone.logoWidth,
          logoZone.logoHeight,
          logoZone.width,
          logoZone.height,
        );

        const position = getImagePosition(logoZone.anchorImage);

        setLogoStat({
          ...size,
          ...position,
        });
      }
    }
  }, [logoZone]);

  return (
    <div className="d-flex flex-column align-items-center align-items-sm-start">
      {noCompanyLogo && (
        <span className="mb-3">
          Your company logo has not been provided yet. You may add or change your company logo in
          the settings screen at any time. Or, upload a logo here and check the box “Use as default”
          to save some time.
        </span>
      )}

      <div className="d-flex mb-3 flex-column flex-sm-row">
        <div
          className="d-flex flex-column border border-gray mb-3 mb-sm-0 mr-sm-3"
          style={{
            alignItems: logoStat.align,
            justifyContent: logoStat.justify,
            boxSizing: "content-box",
            width: `${logoZone.width}px`,
            height: `${logoZone.height}px`,
          }}
        >
          <img width={logoStat.width} height="auto" src={logoZone && logoZone.image} />
        </div>

        <div>
          <PositionControl
            className="mr-4"
            value={values.zones.logoZone.anchorImage}
            onSelect={(align, justify) =>
              setFieldValue("zones.logoZone.anchorImage", `${justify}_${align}`)
            }
          />
        </div>

        <div className="d-flex flex-column">
          <Upload
            showUploadList={false}
            accept="image/jpeg,image/png"
            customRequest={async ({ file }) => {
              setLoading(true);

              try {
                const objectUrl = URL.createObjectURL(file);

                const image = await Jimp.read(objectUrl);
                if (image.bitmap.width < 500) {
                  throw new Error("Minimum logo width 500px");
                }

                if (image) {
                  const srcData = await image.getBase64Async(Jimp.AUTO);

                  if (srcData) {
                    const logoZone = update(values.zones.logoZone, {
                      image: srcData,
                      logoWidth: image.bitmap.width,
                      logoHeight: image.bitmap.height,
                    });

                    const x = await formatPromotionToForm(
                      update(values.zones, { logoZone }),
                      values.hideLogo,
                    );

                    setFieldValue(`zones`, x.zones);
                    setFieldValue(`promotionPreview`, x.promotionPreview);
                  } else {
                    throw new Error("Jimp image error");
                  }
                } else {
                  throw new Error("Jimp image error");
                }

                URL.revokeObjectURL(objectUrl);

                setLoading(false);
              } catch (e) {
                setLoading(false);
                notification.open({
                  type: "error",
                  message: "Upload logo",
                  description: e.message,
                  duration: null,
                });
              }
            }}
          >
            <Button loading={loading} type="primary">
              Upload logo
            </Button>
          </Upload>

          {showReset && (
            <Button
              onClick={async () => {
                setResetLoading(true);

                try {
                  if (settings && settings.logo) {
                    const logoZone = update(values.zones.logoZone, {
                      image: settings.logo.image,
                      logoWidth: settings.logo.width,
                      logoHeight: settings.logo.height,
                    });

                    const x = await formatPromotionToForm(
                      update(values.zones, { logoZone }),
                      values.hideLogo,
                    );

                    setFieldValue(`zones`, x.zones);
                    setFieldValue(`promotionPreview`, x.promotionPreview);
                  } else {
                    throw new Error("Jimp image error");
                  }
                  setResetLoading(false);
                } catch (e) {
                  setResetLoading(false);
                  notification.open({
                    type: "error",
                    message: "Reset Company logo",
                    description: e.message,
                    duration: null,
                  });
                }
              }}
              loading={resetLoading}
              type="danger"
              className="mt-2"
            >
              Reset to Company Logo
            </Button>
          )}
        </div>
      </div>

      <div className="d-flex flex-column align-self-start">
        <CheckboxField
          onChange={async ({ target }) => {
            const x = await formatPromotionToForm(values.zones, target.checked);

            setFieldValue(`zones`, x.zones);
            setFieldValue(`zones.logoZone.hideLogo`, target.checked);
            setFieldValue(`promotionPreview`, x.promotionPreview);
          }}
          className="mb-2"
          name="hideLogo"
          label="Hide logo"
        />
        <CheckboxField className="ml-0" name="zones.logoZone.useAsDefault" label="Use as default" />
      </div>
    </div>
  );
}
