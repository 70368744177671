import React, { useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie";

import { AuthContext } from "./context";
import { UserProps } from "../../api/users/UsersDTO";
import { OTHER_OS_FONT_LIST } from "../../constants/FontsConstants";

export const defaultFontIds = [
  999999,
  999998,
  999997,
  999996,
  999995,
  999994,
  999993,
  999992,
  999991,
  999990,
];

export const getToken = () => {
  if (typeof window === undefined) {
    return null;
  }
  return Cookies.get("token");
};

export const setLocaleStorage = (key: string, data: any) => {
  return localStorage.setItem(key, JSON.stringify(data));
};

export const getLocaleStorage = (key: string) => {
  return JSON.parse(localStorage.getItem(key) as string);
};

export interface State {
  isAuthorized: boolean;
  user: UserProps;
}

const initialState = {
  isAuthorized: !!getToken(),
  token: getToken(),
  user: getLocaleStorage("user") as UserProps,
  settings: getLocaleStorage("settings"),
  fonts: getLocaleStorage("fonts") || [],
};

interface Props {
  readonly children: React.ReactNode;
}

function AuthProvider({ children }: Props) {
  const [state, setState] = useState(initialState);
  const { isAuthorized, user, token, settings, fonts } = state;

  const setAuthorized = (data: boolean) => {
    setState((oldState) => ({ ...oldState, isAuthorized: data }));
  };

  const setToken = (newToken: string) => {
    Cookies.set("token", newToken);
    setState((oldState) => ({ ...oldState, token: newToken }));
  };

  const setUser = (data: UserProps) => {
    setState((oldState) => ({ ...oldState, user: data }));
    setLocaleStorage("user", data);
  };

  const setCompanyStatus = (status: string) => {
    setState((oldState) => ({ ...oldState, user: { ...user, companyStatus: status } }));
    setLocaleStorage("user", { ...user, companyStatus: status });
  };

  const setSettings = (settings: any) => {
    setState((oldState) => ({ ...oldState, settings }));
    setLocaleStorage("settings", settings);
  };

  const setFontList = (newFonts: any) => {
    setState((oldState) => ({ ...oldState, fonts: newFonts }));
    setLocaleStorage("fonts", newFonts);
  };

  const deleteFont = (font: any) => {
    const otherFonts = fonts.filter((f) => f.id !== font.value);
    setState((oldState) => ({ ...oldState, fonts: otherFonts }));
    setLocaleStorage("fonts", otherFonts);
  };

  const setFont = (newFont: any) => {
    setState((oldState) => ({ ...oldState, fonts: [...oldState.fonts, newFont] }));
    setLocaleStorage("fonts", [...fonts, newFont]);
  };

  useEffect(() => {
    const stylesElement = document.getElementById("CustomFontsId");
    if (!stylesElement) {
      const styles = document.createElement("style");
      styles.setAttribute("id", "CustomFontsId");
      styles.innerHTML = fonts
        .filter((x) => x.id < 999000)
        .map(
          (x) => `
            @font-face {
              font-family: '${x.name}';
              src: url('${x.eot}?');
              src: url('${x.eot}?#iefix') format('embedded-opentype'),
                   url('${x.ttf}') format('truetype'),
                   url('${x.woff1}') format('woff');
              font-weight: normal;
              font-style: normal;
            }
          `,
        )
        .join("\n");
      document.head.prepend(styles);
    } else {
      stylesElement.innerHTML = fonts
        .filter((x) => x.id < 999000)
        .map(
          (x) => `
            @font-face {
              font-family: '${x.name}';
              src: url('${x.eot}?');
              src: url('${x.eot}?#iefix') format('embedded-opentype'),
                   url('${x.ttf}') format('truetype'),
                   url('${x.woff1}') format('woff');
              font-weight: normal;
              font-style: normal;
            }
          `,
        )
        .join("\n");
    }
  }, [fonts]);

  const clientId = user?.clientId;
  const isAdministrator = useMemo(() => user?.isAdministrator, [user]);
  const isExpired =
    user?.isAdministrator &&
    (user?.companyStatus === "extra_days_notstarted" ||
      user?.companyStatus === "extra_days_expired");

  const value = {
    isAuthorized,
    setAuthorized,
    user,
    setUser,
    clientId,
    isAdministrator,
    isExpired,
    setCompanyStatus,
    token,
    setToken,
    settings,
    setSettings,
    fonts: [...fonts, ...OTHER_OS_FONT_LIST],
    setFont,
    setFontList,
    deleteFont,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
