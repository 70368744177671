import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { TwitterIconLegacy } from "../icons/TwitterIconLegacy";
import { FacebookIconLegacy } from "../icons/FacebookIconLegacy";
import { LinkedInIconLegacy } from "../icons/LinkedInIconLegacy";
import { PinterestIconLegacy } from "../icons/PinterestIconLegacy";
import { InstagramIcon } from "../icons/InstagramIcon";

SocialButton.propTypes = {
  href: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  social: PropTypes.oneOf(["linkedIn", "facebook", "instagram", "pinterest", "twitter"]).isRequired,
};

export function SocialButton({
  href,
  size = 96,
  onClick,
  social,
  disabled,
  className,
  iconClassName,
}) {
  return (
    <a
      href={href}
      onClick={onClick}
      target={onClick ? undefined : "_blank"}
      rel={onClick ? undefined : "noopener noreferrer"}
      className={cx("text-decoration-none", className)}
    >
      {social === "twitter" && (
        <TwitterIconLegacy className={iconClassName} active={!disabled} size={size} />
      )}
      {social === "instagram" && (
        <InstagramIcon className={iconClassName} active={!disabled} size={size} />
      )}
      {social === "facebook" && (
        <FacebookIconLegacy className={iconClassName} active={!disabled} size={size} />
      )}
      {social === "pinterest" && (
        <PinterestIconLegacy className={iconClassName} active={!disabled} size={size} />
      )}
      {social === "linkedIn" && (
        <LinkedInIconLegacy className={iconClassName} active={!disabled} size={size} />
      )}
    </a>
  );
}
