import { BaseApi } from "../BaseApi";
import { toCamelCase, toKebabCase } from "../../helpers/CaseUtils";

export class AuthApi extends BaseApi {
  checkUsername(email) {
    return this.post("auth/checkusername", {
      query: {
        email,
      },
    });
  }
  signUp(body) {
    return this.post("auth/sign-up", { json: body, timeout: 20000 }).then(({ data }) => data);
  }
  loginAdmin(body) {
    return this.post("auth/authorize", { json: body }).then(({ data }) => toCamelCase(data));
  }
  checkMember(username) {
    return this.post("auth/team/check", { query: { username } }).then(({ data }) =>
      toCamelCase(data),
    );
  }
  checkMemberPhone(phone) {
    return this.post("auth/team/check-by-phone", { query: { phone } }).then(({ data }) =>
      toCamelCase(data),
    );
  }
  authorizeMemberPhone(body) {
    return this.post("auth/team/authorize-by-phone/", {
      json: toKebabCase(body),
    }).then(({ data }) => toCamelCase(data));
  }

  authorizeMember(body) {
    return this.post("auth/team/authorize", { json: toKebabCase(body) }).then(({ data }) =>
      toCamelCase(data),
    );
  }
  forgotPasswordAdmin(email) {
    return this.post("auth/forgot-password", { json: { email } }).then(({ data }) => data);
  }
  resendInvitation(email) {
    return this.patch(`auth/team/resend-activation?email=${email}`, {}).then(({ data }) => data);
  }
  forgotPasswordMember(clientId, email) {
    return this.post("auth/team/forgot-password", { json: toKebabCase({ email, clientId }) }).then(
      ({ data }) => data,
    );
  }
  forgotPasswordMobileMember(clientId, phone) {
    return this.post("auth/team/forgot-password-by-phone", {
      json: toKebabCase({ phone, clientId }),
    }).then(({ data }) => data);
  }
  resetPassword(body) {
    return this.post("auth/reset-password", { json: body }).then(({ data }) => data);
  }
  resetTeamPassword(body) {
    return this.post("auth/team/reset-password", { json: body }).then(({ data }) => data);
  }
  resendActivation(email) {
    return this.patch("auth/resend-activation", { query: { email } }).then(({ data }) => data);
  }
  verify(key) {
    return this.get("auth/verify", { query: { key } }).then(({ data }) => data);
  }
  acceptTeamMember(body) {
    return this.post("auth/team/accept", { json: body }).then(({ data }) => toCamelCase(data));
  }
  getStateList() {
    return this.get("auth/global/states", {}).then(({ data = [] }) => data);
  }
}
