import { update } from "immupdate";

import { BaseApi } from "../BaseApi";
import { ListProps } from "../AppDTO";
import { toCamelCase, toKebabCase } from "../../helpers/CaseUtils";
import { ReminderProps, SendTestProps } from "./RemindersDTO";

interface RemindersListQuery {
  readonly start?: number;
  readonly limit?: number;
  readonly deleted?: boolean;
}

interface SwitchReminderQuery {
  readonly activate?: boolean;
}

interface ChangeStatusReminderQuery {
  readonly archive?: boolean;
}

export class RemindersApi extends BaseApi {
  public getRemindersList(
    clientId: number,
    query: RemindersListQuery = {} as RemindersListQuery,
  ): Promise<ListProps<ReminderProps>> {
    return this.get("reminders/:clientId/list", {
      params: { clientId },
      query: update(query, {
        start: query.start || 0,
        limit: query.limit || 100,
      }),
    }).then(({ data }) => toCamelCase(data));
  }

  public getReminderItem(id) {
    return this.get("reminders/:id", { params: { id } }).then(({ data }) => toCamelCase(data));
  }

  public switchReminder(id, query?: SwitchReminderQuery): Promise<number> {
    return this.patch("reminders/:id/activate", { query, params: { id } });
  }

  public changeStatusReminder(id, query?: ChangeStatusReminderQuery): Promise<ReminderProps> {
    return this.patch("reminders/:id/status", { query, params: { id } }).then(({ data }) =>
      toCamelCase(data),
    );
  }

  public createReminder(values: ReminderProps): Promise<ReminderProps> {
    return this.post("reminders/create", { json: toKebabCase(values) });
  }

  public updateReminder(values: ReminderProps): Promise<ReminderProps> {
    return this.patch("reminders/update", { json: toKebabCase(values) });
  }

  public sentTestReminder(values: SendTestProps): Promise<boolean> {
    return this.post("reminders/sendtest", { json: toKebabCase(values) });
  }
}
