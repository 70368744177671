import "./assets/promotion-list-field.scss";

import { get } from "lodash";
import PropTypes from "prop-types";
import { update } from "immupdate";
import { Button, Upload } from "antd";
import { useFormikContext } from "formik";
import { useDebouncedCallback } from "use-debounce";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Field } from "../form/Field";
import { Slider } from "../ui/Slider";
import { ColorPicker } from "../ui/ColorPicker";
import { sleep } from "../../helpers/SystemUtils";
import { InputWithCount } from "../ui/InputWithCount";
import { PositionControl } from "../ui/PositionControl";
import { useNotification } from "../../hooks/useNotification";
import { FontsSelectWrapper } from "../ui/FontsSelectWrapper";
import { useFilesContext } from "../../api/files/FilesContext";
import { useSettingsContext } from "../../api/settings/SettingsContext";
import { formatPromotionToForm } from "../../helpers/PromotionsHelpers";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DragDotsIcon } from "../icons/DragDotsIcon";
import { useFonts } from "../../context/auth/hooks";
import { Loader } from "../ui/Loader";

import { defaultFontIds } from "../../context/auth/auth-provder";
import { CheckboxField } from "../form/CheckboxField";

PromotionListField.propTypes = {
  name: PropTypes.string,
  onUpdateFields: PropTypes.func,
  onAddValueClick: PropTypes.func,
  onRemoveValueClick: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string,
    required: PropTypes.bool,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        value2: PropTypes.string,
        value2id: PropTypes.number,
        value2url: PropTypes.string,
        value2Type: PropTypes.string,
      }),
    ),
  }),
};

export function PromotionListField({
  name,
  field,
  onAddValueClick,
  onRemoveValueClick,
  onUpdateFields,
}) {
  const { values, setFieldValue } = useFormikContext();
  const { setFont, fonts, deleteFont } = useFonts();

  const { showError, showSuccessNotification } = useNotification();

  const { FilesApi } = useFilesContext();
  const { SettingsApi } = useSettingsContext();

  const [selectedFont, setSelectedFont] = useState({});
  const [size, setSize] = useState(25);
  const [color, setColor] = useState("#000000");
  const [uploadFont, setUploadFont] = useState(false);
  const [fontsList, setFontsList] = useState([]);
  const selectedFontId = selectedFont?.value;

  const isDefaultFont = defaultFontIds.includes(selectedFontId);

  useEffect(() => {
    const newFonts = fonts.map((x) => ({ text: x.name, value: x.id }));
    setFontsList(newFonts);
  }, [fonts]);

  const canAddValue = useMemo(() => field.values.length < 10, [field.values]);

  const updateHandler = useCallback(
    async (data = {}) => {
      const headlineZone = update(values.zones.headlineZone, data);

      const x = await formatPromotionToForm(
        update(values.zones, { headlineZone }),
        values.hideLogo,
      );

      setFieldValue(`zones`, x.zones);
    },
    [setFieldValue, values.zones, values.hideLogo],
  );

  const changeFontHandler = useCallback(
    async (font) => {
      setSelectedFont(font);
      const headlineZone = update(values.zones.headlineZone, { font: font.text });

      setFieldValue(`zones`, update(values.zones, { headlineZone }));
    },
    [setFieldValue, values.zones],
  );

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (event) => {
    if (!event.destination) {
      return;
    }

    const values = reorder(field.values, event.source.index, event.destination.index);

    onUpdateFields(values);
  };

  const [headlineCallback] = useDebouncedCallback(async (data) => updateHandler(data), 1000);

  const fontSize = useMemo(() => values.zones.headlineZone.size || 25, [
    values.zones.headlineZone.size,
  ]);
  const fontColor = useMemo(
    () => (values.zones.headlineZone.color ? `#${values.zones.headlineZone.color}` : "#000000"),
    [values.zones.headlineZone.color],
  );
  const fontName = useMemo(() => values.zones.headlineZone.font, [values.zones.headlineZone.font]);

  useEffect(() => {
    if (!selectedFont?.text && fontsList.length > 0) {
      const foundFont = fontsList.find((font) => font.text === fontName);
      setSelectedFont(foundFont);
    }
  }, [selectedFont, fontName, fontsList]);

  useEffect(() => {
    setSize(fontSize);
  }, [fontSize, setSize]);

  useEffect(() => {
    setColor(fontColor);
  }, [fontColor, setColor]);

  const handleUploadFile = useCallback(
    (file) => {
      const formData = new FormData();

      formData.append("file", file);
      setUploadFont(true);

      FilesApi.uploadFont(formData)
        .then((font) => SettingsApi.getFonts().then(({ items = [] }) => ({ items, font })))
        .then(({ font }) => {
          const newFonts = [...fontsList, { text: font.name, value: font.id }];
          setFontsList(newFonts);
          setFont(font);

          if (font?.name) {
            return sleep(2000).then(() => changeFontHandler(font));
          }
        })
        .catch(showError)
        .finally(() => setUploadFont(false));
    },
    [fontsList, FilesApi, SettingsApi, changeFontHandler, setFont, showError],
  );

  useEffect(() => {
    if (values.zones.headlineZone.text !== field.values[0].value) {
      headlineCallback({ text: field.values[0].value });
    }
  }, [field.values, values, headlineCallback]);

  const onDeleteFont = useCallback(() => {
    setUploadFont(true);
    FilesApi.deleteFont(selectedFontId)
      .then(() => {
        const otherFonts = fontsList.filter((font) => font.value !== selectedFontId);
        setFontsList(otherFonts);
        deleteFont(selectedFont);
        setSelectedFont(otherFonts[0]);
        setUploadFont(false);
        changeFontHandler(otherFonts[0]);
        showSuccessNotification({ message: "Font deleted" });
      })
      .catch((err) => {
        showError(err);
        setUploadFont(false);
      });
  }, [
    setFontsList,
    changeFontHandler,
    showSuccessNotification,
    showError,
    FilesApi,
    fontsList,
    selectedFontId,
    deleteFont,
    selectedFont,
  ]);

  return (
    <div className="promotion-list-field">
      <span className="list-field-description-text">
        Add up to 10 Text Overlay choices that your users can select from:
      </span>

      <div className="mb-4">
        <CheckboxField
          className="ml-0"
          name="zones.headlineZone.hideHeadline"
          label="Hide headline"
        />
      </div>
      <div className="d-flex mb-3 flex-column-reverse flex-md-row list-field-wrapper">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`${field.name}-droppable`}>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="d-flex flex-column flex-shrink-1 flex-grow-1 mr-md-3"
              >
                {field.values.map((value, idx) => {
                  return (
                    <Draggable key={value.id} draggableId={`draggable-${value.id}`} index={idx}>
                      {(provided) => (
                        <div
                          className="list-field"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DragDotsIcon className="drag-icon" />
                          <div className="list-field-container">
                            <Field name={`${name}.values.${idx}.value`} withError={field.required}>
                              {({ inputProps, field: inputField }) => (
                                <InputWithCount
                                  {...inputField}
                                  onChange={(e) => {
                                    inputField.onChange(e);

                                    if (idx === 0) {
                                      headlineCallback({ text: e.target.value });
                                    }
                                  }}
                                  {...inputProps}
                                  maxLength={256}
                                  placeholder="Headline Text"
                                />
                              )}
                            </Field>
                          </div>

                          {field.values.length > 1 && (
                            <button
                              type="button"
                              className="field-remove-button"
                              onClick={() => {
                                onRemoveValueClick(idx);

                                if (field.values.length > 1 && idx === 0) {
                                  const headline = get(values, `${name}.values.1.value`);

                                  headlineCallback({ text: headline });
                                }
                              }}
                            >
                              X
                            </button>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="d-flex flex-column border border-gray-middle ml-md-3 mb-3 mb-md-0 px-4 py-3 font-config">
          <span className="text-secondary mb-5">Adjust Headline Styles:</span>

          <Slider
            min={1}
            max={72}
            label="Size"
            value={size}
            onChange={(value) => setSize(value)}
            onAfterChange={(x) => updateHandler({ size: x })}
          />

          <div className="d-flex mt-3 arrows-container">
            <PositionControl
              className="mr-2"
              value={values.zones.headlineZone.anchorImage}
              onSelect={(align, justify) => {
                setFieldValue("zones.headlineZone.align", align);
                setFieldValue("zones.headlineZone.anchorImage", `${justify}_${align}`);
              }}
            />
            <div className="d-flex flex-shrink-1 flex-grow-1 flex-column ml-2">
              <ColorPicker
                value={color}
                hideTitle={true}
                title="Select color"
                onChange={({ hex }) => setColor(hex)}
                buttonClassName="color-button flex-shrink-1 flex-grow-1"
                className="flex-shrink-1 flex-grow-1 d-flex border border-primary"
                onChangeComplete={({ hex }) => updateHandler({ color: hex.slice(1) })}
              />
              <div className="d-flex flex-shrink-1 flex-grow-1 align-items-center justify-content-center border border-gray bg-light">
                {color}
              </div>
            </div>
          </div>

          {uploadFont && <Loader />}
          {!uploadFont && (
            <>
              <FontsSelectWrapper
                font={fontName}
                className="mt-5"
                onChange={(x) => changeFontHandler(x)}
                fonts={fontsList}
              />
              <Upload
                showUploadList={false}
                accept="application/x-font-ttf"
                className="mb-3 mb-md-0 mr-md-3 align-self-center"
                customRequest={({ file }) => handleUploadFile(file)}
              >
                <Button className="mt-3">+ Add A New font</Button>
              </Upload>
              <Button
                className="mt-3"
                onClick={onDeleteFont}
                disabled={!selectedFont?.value || isDefaultFont}
              >
                Delete font
              </Button>
            </>
          )}
        </div>
      </div>

      {canAddValue && (
        <div>
          <Button type="primary" onClick={onAddValueClick}>
            Add
          </Button>
        </div>
      )}
    </div>
  );
}
