import React, { useMemo } from "react";

import { useFormikContext } from "formik";
import { TemplateDnd } from "../template-dnd/TemplateDnd";

export function PromotionMessagingPinterestPost() {
  const { values } = useFormikContext();

  const hasTemplate = useMemo(() => Boolean(values.promotionItem), [values.promotionItem]);
  const link = useMemo(() => {
    if (values.promotionSocial) {
      if (values.promotionSocial.pinterestOverrideUrl) {
        return values.promotionSocial.pinterestOverrideUrl;
      }
      if (values.promotionSocial.targetUrl) {
        return values.promotionSocial.targetUrl;
      }
    }

    return "Urlgoeshere.com";
  }, [values.promotionSocial]);
  const description = useMemo(() => {
    if (values.promotionSocial && values.promotionSocial.pinterestDescription) {
      return values.promotionSocial.pinterestDescription;
    }

    return "Description goes here";
  }, [values.promotionSocial]);

  return (
    <div className="d-flex flex-column client-promotion-edit-messaging-pinterest-post">
      {hasTemplate && (
        <TemplateDnd templateId="PinterestTemplatePreviewDndTarget" canDrag={false} />
      )}

      {!hasTemplate && <div className="messaging-post-template" />}

      <div className="messaging-post-bottom">
        <span className="messaging-post-link">{link}</span>
        <div className="messaging-post-description">{description}</div>
      </div>
    </div>
  );
}
