import "./assets/card-details-icons.scss";

import React from "react";

import discover from "./assets/cc-discover.png";
import amex from "./assets/cc-amex.png";
import visa from "./assets/cc-visa.png";
import mastercard from "./assets/cc-mastercard.png";

export function CardDetailsIcons() {
  return (
    <div className="card-details-icons">
      <img draggable="false" src={discover} alt="Discover" />
      <img draggable="false" src={amex} alt="Amex" />
      <img draggable="false" src={visa} alt="Visa" />
      <img draggable="false" src={mastercard} alt="MasterCard" />
    </div>
  );
}
