import React from "react";
import { ImageBuilderStepContainer } from "./ImageBuilderStepContainer";
import { PromotionBackground } from "../promotion-background/PromotionBackground";
import PropTypes from "prop-types";

ImageBuilderBackgroundStep.propTypes = {
  background: PropTypes.object,
  setBackground: PropTypes.func,
};

export function ImageBuilderBackgroundStep({ setBackground, background }) {
  return (
    <ImageBuilderStepContainer>
      <PromotionBackground setBackground={setBackground} background={background} />
    </ImageBuilderStepContainer>
  );
}
