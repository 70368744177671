import { notification } from "antd";
import React, { useCallback, useState } from "react";
import { DELETE, update } from "immupdate";
import { AppError } from "../../helpers/AppError";
import { useAuthContext } from "../../api/auth/AuthContext";
import { useTeamContext } from "../../api/team/TeamContext";
import { useNotification } from "../../hooks/useNotification";
import { TeamAccount } from "../../components/account/TeamAccount";
import { DeleteInfoDialog } from "../../components/account/DeleteInfoDialog";
import { SettingsAuthForm } from "../../components/settings/SettingsAuthForm";
import { DeleteAccountDialog } from "../../components/account/DeleteAccountDialog";
import { useUser } from "../../context/auth/hooks";
import { TeamAppLayout } from "../../components/app/TeamAppLayout";

export function TeamAccountContainer() {
  const { user, setUser } = useUser();
  const [loggined, setLoggined] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showInfoDelete, setShowInfoDelete] = useState(false);

  const { showError, showSuccessNotification } = useNotification();

  const { TeamApi } = useTeamContext();
  const { AuthApi, logout } = useAuthContext();

  const onChangePassword = useCallback(
    (values) => {
      setLoading(true);
      TeamApi.changePassword(values)
        .then(() => {
          showSuccessNotification({ message: "Password changed" });
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          showError(e);
        });
    },
    [showError, TeamApi, showSuccessNotification],
  );

  if (!loggined) {
    return (
      <TeamAppLayout title="Team Portal">
        <SettingsAuthForm
          onSubmit={(values) =>
            // @ts-ignore
            AuthApi.authorizeMember(update(values, { clientId: user.clientId! }))
              .then((x) => {
                if (x.clientId === user?.clientId && x.memberId === user?.memberId) {
                  setLoggined(true);
                } else {
                  throw new AppError({
                    status: 401,
                    name: "auth",
                    message: "unauthorized",
                    data: [{ userMsg: "Invalid user" }],
                  });
                }
              })
              .catch((e) =>
                notification.open({
                  type: "error",
                  message: "Authorization",
                  description: e.data[0].userMsg + ". ",
                  duration: null,
                }),
              )
          }
        />
      </TeamAppLayout>
    );
  }

  return (
    <TeamAppLayout title="Team Portal">
      <TeamAccount
        user={user}
        onDeleteClick={() => setShowDelete(true)}
        onSubmit={(values) => {
          if (user) {
            TeamApi.updateTeamMember(
              update(user, {
                ...values,
                active: true,
                memberId: DELETE,
                id: user.memberId,
                companyStatus: DELETE,
                administrator: DELETE,
              }),
            )
              .then(() => {
                setUser({ ...user, ...values });

                showSuccessNotification({ message: "Save account" });
              })

              .catch((e) => showError(e));
          }
        }}
        handleChangePassword={onChangePassword}
        loading={loading}
      />

      <DeleteAccountDialog
        show={showDelete}
        loading={deleting}
        onCancel={() => setShowDelete(false)}
        onDelete={() => {
          if (user) {
            setDeleting(true);
            TeamApi.deleteMember(user.clientId)
              .then(() => {
                setDeleting(false);
                setShowDelete(false);
                setShowInfoDelete(true);
              })
              .catch((e) => {
                setDeleting(false);
                showError(e);
              });
          }
        }}
      />

      <DeleteInfoDialog
        show={showInfoDelete}
        onCancel={() => {
          setShowInfoDelete(false);

          logout();
        }}
      />
    </TeamAppLayout>
  );
}
