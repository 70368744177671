import React from "react";

import PropTypes from "prop-types";
import { HTMLHead } from "../../../widgets/HTMLHead";
import { AppLogo } from "../../../components/app/AppLogo";
import { DefaultHeader } from "../defaultHeader/DefaultHeader";

import "./publicLayout.scss";

PublicLayout.propTypes = {
  className: PropTypes.string,
  withHeader: PropTypes.bool,
  HTMLHeadTitle: PropTypes.node,
  step: PropTypes.node,
  title: PropTypes.node,
  footer: PropTypes.node,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export function PublicLayout({
  withHeader = true,
  className,
  HTMLHeadTitle,
  step,
  onClick,
  title,
  footer,
  children,
}) {
  return (
    <div className={className}>
      <HTMLHead title={HTMLHeadTitle} />
      {withHeader && (
        <DefaultHeader>
          <AppLogo onClick={onClick} />
          <IfExist className="step">{step}</IfExist>
        </DefaultHeader>
      )}
      <div className="pl-3 pr-3">
        <IfExist tag="h1" className="public-title">
          {title}
        </IfExist>
        <main className="d-flex flex-column public-body">{children}</main>
        <IfExist tag="footer" className="public-footer">
          {footer}
        </IfExist>
      </div>
    </div>
  );
}

IfExist.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.string,
  className: PropTypes.string,
};

function IfExist({ tag, children, className }) {
  if (!children) return null;

  const Tag = tag || "div";

  return <Tag className={className}>{children}</Tag>;
}
