import React from "react";

import amex from "../settings/assets/cc-amex.png";
import visa from "../settings/assets/cc-visa.png";
import discover from "../settings/assets/cc-discover.png";
import mastercard from "../settings/assets/cc-mastercard.png";

export function PaymentCardIcons() {
  return (
    <div className="d-sm-flex align-items-center d-none">
      <img className="mr-1" draggable={false} src={discover} alt="Discover" />
      <img className="mr-1" draggable={false} src={amex} alt="Amex" />
      <img className="mr-1" draggable={false} src={visa} alt="Visa" />
      <img className="mr-1" draggable={false} src={mastercard} alt="MasterCard" />
    </div>
  );
}
