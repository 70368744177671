import "./assets/departments-modal-edit.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Form, Input, SubmitButton } from "formik-antd";
import { Button } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import cx from "classnames";
import { PublicAlert } from "../../../components/public/PublicAlert";
import { getErrorMessage } from "../../../utils/utils";
import { Dialog } from "../../../components/ui/Dialog";
import { useDepartmentContext } from "../../../api/departments/DepartmentsContext";
import { InputWithCountField } from "../../../components/form/InputWithCountField";
import { useUser } from "../../../context/auth/hooks";

const validationSchema = yup.object().shape({
  name: yup.string().required("Firstname is required"),
  description: yup.string().required("Lastname is required"),
});

interface Props {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly active: boolean;
  readonly verified: boolean;
  readonly visible: boolean;
  readonly hideModal: () => void;
  readonly setUpdate: (value: any) => void;
}

export function DepartmentsModalEdit({
  id,
  name,
  description,
  active,
  verified,
  visible,
  hideModal,
  setUpdate,
}: Props) {
  const { DepartmentsApi } = useDepartmentContext();
  const { clientId } = useUser();

  const [error, setError] = useState(null);
  const [status, setStatus] = useState(false);
  const [loadingDeletion, setLoadingDeletion] = useState(false);

  const isDisabled = useMemo(() => !!(!status && verified), [status, verified]);

  useEffect(() => {
    setStatus(active);
  }, [active]);

  const handleDelete = useCallback(async () => {
    setLoadingDeletion(true);
    DepartmentsApi.deleteDepartment(id)
      .then(() => {
        setLoadingDeletion(false);
        hideModal();
        setUpdate((value) => !value);
      })
      .catch((err) => {
        setLoadingDeletion(false);
        setError(getErrorMessage(err));
      });
  }, [id, DepartmentsApi, hideModal, setUpdate]);

  const handleSubmit = useCallback(
    (value) => {
      DepartmentsApi.updateDepartment({
        clientId,
        departmentName: value.name,
        description: value.description,
        id: id,
      }).then(() => {
        hideModal();
        setUpdate((value) => !value);
      });
    },
    [hideModal, DepartmentsApi, clientId, id, setUpdate],
  );

  const header = (
    <div className="d-flex flex-column flex-sm-row align-items-center pr-sm-5">
      <span
        className={cx("ant-modal-title flex-grow-1 mt-3 mt-sm-0 mb-0 pl-sm-3 pr-sm-3", {
          disabled: isDisabled,
        })}
      >
        {name}
      </span>
    </div>
  );

  return (
    <Dialog
      width={767}
      footer={null}
      show={visible}
      title={header}
      onCancel={hideModal}
      className="departments-modal-edit"
    >
      <PublicAlert error={error} />

      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          name,
          description,
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit }) => (
          <Form className="p-3" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <Form.Item name="name" label="Name">
                  <Input name="name" />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item name="description" label="Description">
                  <InputWithCountField
                    withErrors={false}
                    type="textarea"
                    maxLength={1000}
                    name="description"
                    label={undefined}
                    className={undefined}
                    isRequired={undefined}
                    inputClassName={undefined}
                    requiredTitle={undefined}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <Button onClick={handleDelete} loading={loadingDeletion} block={true}>
                  Delete group
                </Button>
              </div>

              <div className="col-sm-6">
                <SubmitButton type="primary" block={true}>
                  Save changes
                </SubmitButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
