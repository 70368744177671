import React, { useMemo } from "react";

import { Select } from "./Select";

interface Props {
  readonly font?: string;
  readonly className?: string;
  readonly onChange: (font: any) => void;
  readonly fonts: any;
}

export function FontsSelectWrapper({ className = "", font, onChange, fonts }: Props) {
  const fontValue = useMemo(() => {
    const item = fonts.find((x) => x.text === font);

    if (item) {
      return item.value;
    }
  }, [font, fonts]);

  return (
    <Select
      data={fonts}
      value={fontValue}
      className={className}
      placeholder="Select font"
      onChange={(x) => {
        const item = fonts.find((f) => f.value === x);

        if (item) {
          onChange(item);
        }
      }}
    />
  );
}
