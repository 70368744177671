import React from "react";

import PropTypes from "prop-types";

// eslint-disable-next-line no-use-before-define
CheckIcon.propTypes = {
  size: PropTypes.number,
};

export function CheckIcon({ size = 14, ...svgProps }) {
  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#025692"
        fillRule="nonzero"
        d="M10.094 4.779a.547.547 0 0 1 0 .773l-3.668 3.67a.547.547 0 0 1-.774 0L3.906 7.474a.547.547 0 1 1 .773-.774l1.36 1.36L9.32 4.78a.547.547 0 0 1 .773 0zM14 7c0 3.87-3.131 7-7 7-3.87 0-7-3.131-7-7 0-3.87 3.131-7 7-7 3.87 0 7 3.131 7 7zm-1.094 0A5.903 5.903 0 0 0 7 1.094 5.903 5.903 0 0 0 1.094 7 5.903 5.903 0 0 0 7 12.906 5.903 5.903 0 0 0 12.906 7z"
      />
    </svg>
  );
}
