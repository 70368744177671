import { useMemo } from "react";

import { DepartmentsApi } from "./DepartmentsApi";
import { useApiBase } from "../ApiContext";

export function useDepartmentContext() {
  const data = useApiBase();

  const api = useMemo(() => new DepartmentsApi(data), [data]);

  return {
    DepartmentsApi: api,
  };
}
