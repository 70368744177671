export const MAC_OS_FONT_LIST = [
  { id: 999999, name: "Arial" },
  { id: 999998, name: "Helvetica" },
  { id: 999997, name: "Arial Black" },
  { id: 999996, name: "Gadget" },
  { id: 999995, name: "Courier New" },
  { id: 999994, name: "Georgia" },
  { id: 999993, name: "Lucida Grande" },
  { id: 999992, name: "Geneva" },
  { id: 999991, name: "Times" },
  { id: 999990, name: "Verdana" },
];

export const OTHER_OS_FONT_LIST = [
  { id: 999999, name: "Arial" },
  { id: 999998, name: "Arial Black" },
  { id: 999997, name: "Courier New" },
  { id: 999991, name: "Verdana" },
];
