import "./assets/team-members-switch.scss";

import React from "react";

import cx from "classnames";
import { Switch } from "antd";

interface Props {
  readonly pending: boolean;
  readonly checked: boolean;
  readonly onChange: (checked: boolean) => void;
  readonly disabled?: boolean;
  readonly loading?: boolean;
}

export function TeamMembersSwitch({ pending, ...props }: Props) {
  return (
    <div className={cx("team-members-switch", { pending })}>
      <Switch {...props} />

      <div className="team-members-switch-overlay">
        <div className="switch-overlay-dot" />
        <div className="switch-overlay-dot" />
        <div className="switch-overlay-dot" />
      </div>
    </div>
  );
}
