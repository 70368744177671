import { useCallback } from "react";
import { replaceParams } from "../helpers/UrlUtils";
import { useNavigate } from "react-router";

interface Props {
  readonly push: (url: string, options?: any) => void;
  readonly goBack: () => void;
}

export default function useRouter() {
  const navigate = useNavigate();
  const push = useCallback(
    (url, options) => {
      navigate(replaceParams(url, options));
    },
    [navigate],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return { push, goBack } as Props;
}
