import React, { useEffect } from "react";
import "./assets/promotion-choose-submit.scss";

import { PromotionChooseButton } from "./PromotionChooseButton";
import { propOr, prop } from "ramda";

interface Props {
  readonly loading?: boolean;
  readonly onSelect: (item: any) => void;
  readonly settings?: any;
  readonly promotion?: any;
  readonly hideHeadline?: boolean;
}

export function PromotionChooseSubmit({
  loading,
  promotion = {},
  settings = {},
  onSelect,
  hideHeadline,
}: Props) {
  const headlines = propOr([], "usedValues", promotion);

  const name = propOr("", "name", promotion);
  const description = propOr("", "description", promotion);
  const color = propOr("#025692", "primaryColor", settings);
  const promotionType = prop("promotionType", promotion);

  useEffect(() => {
    if (hideHeadline) {
      onSelect(headlines[0]);
    }
  }, [hideHeadline, headlines]);

  if (loading) return null;

  return (
    <div className="portal-choose-root">
      <div className="choose-header">
        <div className="choose-title">
          <span className="choose-type">{promotionType ? `${promotionType}:` : ""}</span>
          <span className="choose-name" style={{ color }}>
            {name}
          </span>
        </div>
      </div>
      <span className="choose-description">{description}</span>
      <span className="choose-button-title">Select a headline:</span>
      {headlines.map((headline) => (
        <PromotionChooseButton
          headline={headline}
          key={headline.value}
          settings={settings}
          disabled={loading}
          onClick={onSelect}
        />
      ))}
    </div>
  );
}
