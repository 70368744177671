import { useMemo } from "react";

import { useApiBase } from "../ApiContext";
import { PromotionApi } from "./PromotionApi";

export function usePromotionContext() {
  const data = useApiBase();

  const api = useMemo(() => new PromotionApi(data), [data]);

  return {
    PromotionApi: api,
  };
}
