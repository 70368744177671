import { useCallback, useMemo } from "react";

import { AuthApi } from "./AuthApi";
import { useApiBase } from "../ApiContext";
import { useFonts, useSettings, useToken, useUser } from "../../context/auth/hooks";
import { UserProps } from "../users/UsersDTO";

interface Props {
  readonly AuthApi: AuthApi;

  readonly logout: () => void;
}

export function useAuthContext(): Props {
  const { setUser } = useUser();
  const { setToken } = useToken();
  const { setSettings } = useSettings();
  const { setFont } = useFonts();

  const data = useApiBase();

  const api = useMemo(() => new AuthApi(data), [data]);

  const logoutHandler = useCallback(() => {
    setUser({} as UserProps);
    setToken("");
    setSettings({});
    setFont([]);
  }, []);

  return {
    AuthApi: api,

    logout: logoutHandler,
  };
}
