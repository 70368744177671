import React from "react";
import { Button } from "antd";

interface Props {
  readonly onCloseClick: () => void;
}

export function PromotionBackgroundModalHeader({ onCloseClick }: Props) {
  return (
    <div className="d-flex p-3 border-bottom border-gray-extra-light">
      <div className="d-flex flex-1 align-items-center">
        <span className="font-weight-bold">Upload background</span>
      </div>
      <Button onClick={onCloseClick} type="primary">
        X
      </Button>
    </div>
  );
}
