import { isArray } from "lodash";
import { useEffect, useMemo } from "react";
import { notification } from "antd";

export function useResourceHandler(resource, options = {}) {
  const { onFail, onSuccess, showError = true } = options;

  const error = useMemo(() => {
    if (!resource.loading && resource.error) {
      return resource.error;
    }
  }, [resource.loading, resource.error]);

  const data = useMemo(() => {
    if (!resource.loading && !resource.error && resource.data) {
      return resource.data;
    }
  }, [resource]);

  useEffect(() => {
    if (error && onFail) {
      onFail(error);
    }
  }, [error]);

  useEffect(() => {
    if (!resource.loading && data && onSuccess) {
      onSuccess(data);
    }
  }, [data, resource.loading]);

  useEffect(() => {
    const { showError = true } = options;

    if (!resource.loading && error && error.data && showError && isArray(error.data)) {
      error.data.forEach(({ userMsg }) => {
        notification.open({
          type: "error",
          message: "Error",
          description: userMsg,
          duration: null,
        });
      });
    }
  }, [error, showError, resource.loading]);
}
