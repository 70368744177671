import cx from "classnames";
import React, { CSSProperties, ReactNode } from "react";

interface Props {
  className?: string;
  onClick?: () => void;
  readonly disabled?: boolean;
  children: string | ReactNode;
  readonly style?: CSSProperties;
}

export function LinkButton({ onClick, children, className, disabled, style }: Props) {
  return (
    <div
      style={style}
      role="button"
      onClick={() => {
        if (!disabled) {
          onClick && onClick();
        }
      }}
      className={cx("text-decoration-underline font-weight-light text-uppercase", className, {
        "text-gray": disabled,
        "text-primary cursor-pointer": !disabled,
      })}
    >
      {children}
    </div>
  );
}
