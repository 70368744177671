import "./assets/editReminder.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";

import { TeamDashboard } from "./TeamDashboard";
import { InputField } from "../../../../components/form/InputField";
import { SelectField } from "../../../../components/form/SelectField";
import { SwitchField } from "../../../../components/form/SwitchField";
import { SpecificPromotion } from "./SpecificPromotion";
import { reminderForConstants, ReminderForType } from "../../../../constants/reminderConstants";
import { InputWithCountField } from "../../../../components/form/InputWithCountField";
import { prop, propOr } from "ramda";
import { DateTimeField } from "../../../../components/form/DateTimeField";
import moment from "moment";
import { Loader } from "../../../../components/ui/Loader";
import { CheckboxField } from "../../../../components/form/CheckboxField";
import { useDepartmentContext } from "../../../../api/departments/DepartmentsContext";
import { useResource } from "../../../../hooks/useResource";
import { useResourceHandler } from "../../../../hooks/useResourceHandler";

interface Props {
  readonly reminder: any;
  readonly setChosenDepartments: (value: any) => void;
  readonly chosenDepartments: any;
}

export function EditReminderBody({ reminder, setChosenDepartments, chosenDepartments }: Props) {
  const [departments, setDepartments] = useState([] as any);
  const { values, setFieldValue } = useFormikContext() as any;
  const reminderFor = prop("reminderFor", values);
  const oldDepartments = propOr([], "departments", values);

  const { DepartmentsApi } = useDepartmentContext();

  const departmentListResource = useResource(() => DepartmentsApi.getDepartments(), []);

  useEffect(() => {
    oldDepartments.map((oldDep) => {
      const foundDep = departments.find((dep) => dep.id === oldDep);
      setChosenDepartments((prevDep) => [...prevDep.filter((prev) => !prev.system), foundDep]);
    });
  }, [setChosenDepartments, departments, oldDepartments]);

  useResourceHandler(departmentListResource, {
    onSuccess: ({ items = [] }) => {
      const defaultAllDepartment = items.find(
        (department: any) => department.departmentName === "All",
      );
      setChosenDepartments([defaultAllDepartment]);
      setDepartments(items);
    },
  });

  const isTeamDashboard = useMemo(() => reminderFor === ReminderForType.TEAM_DASHBOARD, [
    reminderFor,
  ]);
  const isSpecificPromotion = useMemo(() => reminderFor === ReminderForType.SPECIFIC_PROMOTION, [
    reminderFor,
  ]);
  const isNewPromotion = useMemo(() => reminderFor === ReminderForType.NEW_PROMOTION, [
    reminderFor,
  ]);
  const isExpiringPromotion = useMemo(() => reminderFor === ReminderForType.EXPIRING_PROMOTION, [
    reminderFor,
  ]);
  const isSingleBroadcast = useMemo(() => reminderFor === ReminderForType.SINGLE_BROADCAST, [
    reminderFor,
  ]);

  const handleChangeDepartments = useCallback(
    (value) => {
      const isAlreadyChosen = chosenDepartments.find(
        (department: any) => department.id === value.id,
      );
      const isDefaultDepartment = value.departmentName === "All";
      if (isAlreadyChosen) {
        const otherDepartments = chosenDepartments.filter(
          (department: any) => department.id !== value.id,
        );
        if (!isDefaultDepartment) {
          if (otherDepartments.length > 0) {
            setChosenDepartments(otherDepartments);
          } else {
            const defaultAllDepartment = departments.find(
              (department: any) => department.departmentName === "All",
            );
            setChosenDepartments([defaultAllDepartment]);
          }
        }
      } else {
        if (isDefaultDepartment) {
          setChosenDepartments([value]);
        } else {
          const notDefaultDepartments = chosenDepartments.filter(
            (department) => department.departmentName !== "All",
          );
          setChosenDepartments([...notDefaultDepartments, value]);
        }
      }
    },
    [chosenDepartments, setChosenDepartments, departments],
  );

  return (
    <div className="d-flex flex-column mx-5 edit-reminder-body">
      <div className="d-flex w-100 align-items-center">
        <SwitchField name="active" />
        <InputField
          placeholder="Reminder Name"
          name="reminderName"
          className="flex-1"
          inputClassName="name-input"
        />
      </div>
      <SelectField
        className="mt-7"
        name="reminderFor"
        label="Reminder For"
        placeholder="Choose"
        data={reminderForConstants}
        style={{ maxWidth: "331px" }}
      />
      {isSpecificPromotion && <SpecificPromotion />}
      {(isNewPromotion || isExpiringPromotion) && (
        <DateTimeField
          name="time"
          showDate={false}
          timeLabel="Send Time:"
          timeClassName="date-time-picker"
          dateClassName="date-time-picker"
          className="d-flex flex-column mt-3"
        />
      )}
      {isSingleBroadcast && (
        <>
          <div className="d-flex mt-3">
            <DateTimeField
              showTime={false}
              name="startdate"
              label="Start date:"
              className="mr-3"
              dateClassName="date-time-picker"
              disabledDateBeforeNow={true}
              timeClassName="date-time-picker"
            />
            <DateTimeField
              timeLabel="Time"
              showDate={false}
              name="time"
              dateClassName="date-time-picker"
              timeClassName="date-time-picker"
              disabledTimeBeforeNow={moment().isSame(values.startdate, "day")}
            />
          </div>
          <div className="d-flex flex-column my-2">
            <span className="assign-groups">GROUPS</span>
            {departmentListResource.loading && (
              <div className="departments-loader-promotion">
                <Loader />
              </div>
            )}
            <div className="d-flex mt-2 ml-1 departments-boxes">
              {departments.map((department) => {
                const isSelected = chosenDepartments.find((chosen) => chosen.id === department.id);
                return (
                  <CheckboxField
                    checked={isSelected}
                    key={department.departmentName}
                    className="mb-4"
                    name={department.departmentName}
                    label={`${department.departmentName} (${department.membersCount})`}
                    onChange={() => handleChangeDepartments(department)}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
      {(isSpecificPromotion || isTeamDashboard) && (
        <TeamDashboard values={values} setFieldValue={setFieldValue} reminder={reminder} />
      )}

      <div className="mt-7">
        <SwitchField
          name="sms"
          text="SMS"
          className="mb-2"
          textClassName="fl-4 font-family-museo"
        />
        <InputWithCountField
          type="textarea"
          label="Message:"
          maxLength={200}
          name="smsText"
          className={undefined}
          isRequired={undefined}
          inputClassName={undefined}
          requiredTitle={undefined}
        />
      </div>
      <div className="mt-5">
        <SwitchField
          name="email"
          text="Email"
          className="mb-2"
          textClassName="fl-4 font-family-museo"
        />
        <InputWithCountField
          label="Subject:"
          maxLength={256}
          name="emailSubject"
          className={undefined}
          isRequired={undefined}
          inputClassName={undefined}
          requiredTitle={undefined}
        />
      </div>
      <div className="mt-5 mb-7">
        <InputWithCountField
          type="textarea"
          label="Message:"
          maxLength={256}
          name="emailContent"
          className={undefined}
          isRequired={undefined}
          inputClassName={undefined}
          requiredTitle={undefined}
        />
      </div>
    </div>
  );
}
