import React from "react";

import { Button } from "antd";
import { Link } from "react-router-dom";

import { VSBLFormItem } from "./VSBLFormItem";

export function VSBLButton(props) {
  return (
    <VSBLFormItem span={props.span} colon={props.colon} label={props.label} offset={props.offset}>
      <Button
        type={props.type || "primary"}
        size={props.size}
        htmlType={props.htmlType || "button"}
        block={props.block}
        className={props.className}
        onClick={props.onClick}
        loading={props.loading}
        disabled={props.disabled}
        style={props.style}
      >
        <VSBLLink to={props.to}>{props.children}</VSBLLink>
      </Button>
    </VSBLFormItem>
  );
}

function VSBLLink(props) {
  if (!props.to) return props.children;

  return <Link to={props.to}>{props.children}</Link>;
}
