import "./assets/image-builder-tab.scss";

import { Collapse } from "antd";
import { useFormikContext } from "formik";
import { deepUpdate, DELETE } from "immupdate";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ImageBuilderStep } from "./ImageBuilderStep";
import { ImageBuilderItem } from "./ImageBuilderItem";
import { PromotionBuilderLogoStep } from "./PromotionBuilderLogoStep";
import { ImageBuilderBackgroundStep } from "./ImageBuilderBackgroundStep";
import { SelectTemplateWrapper } from "../promotion/SelectTemplateWrapper";

export function ImageBuilder({ isEdit, loading }) {
  const { setValues, values, setFieldValue } = useFormikContext();

  const [currentTab, setCurrentTab] = useState();
  const [showTemplateList, setShowTemplateList] = useState(false);
  const [background, setBackground] = useState({
    blur: values.zones ? values.zones.backgroundZone.options.blur : 0,
    brightness: values.zones ? values.zones.backgroundZone.options.brightness + 100 : 100,
    saturation: values.zones ? values.zones.backgroundZone.options.saturation + 100 : 100,
    vibrance: values.zones ? values.zones.backgroundZone.options.vibrance + 100 : 100,
  });

  const steps = useMemo(() => values.promotionSteps || [], [values.promotionSteps]);

  const hasBuilder = useMemo(() => Boolean(steps.length > 0 && values.promotionItem), [
    steps,
    values.promotionItem,
  ]);

  const headlineStep = useMemo(() => {
    const index = steps.findIndex(({ fields = [] }) =>
      fields.some((x) => x.fieldKey === "headline"),
    );

    return {
      index,
      item: steps[index],
    };
  }, [steps]);

  const tabChangeHandler = useCallback((tab) => {
    setFieldValue("zones.logoZone.active", tab === "logo");
    setFieldValue("zones.headlineZone.active", tab === "headline");

    setCurrentTab(tab);
  }, []);

  const addValueHandler = useCallback(
    (stepIndex, fieldIndex) => {
      const nextValues = deepUpdate(values)
        .at("promotionSteps")
        .withDefault([])
        .at(stepIndex)
        .withDefault({})
        .at("fields")
        .withDefault([])
        .at(fieldIndex)
        .withDefault({})
        .at("values")
        .withDefault([])
        .modify((x) => {
          const list = x.slice();
          const listIds = x.map((item) => item.id);
          const lastIndex = Math.max.apply(null, listIds);

          list.push({
            id: lastIndex + 1,
            value: "",
          });

          return list;
        });

      setValues(nextValues);
    },
    [setValues, values, deepUpdate],
  );

  const removeValueHandler = useCallback(
    (stepIndex, fieldIndex, valueIndex) => {
      const nextValues = deepUpdate(values)
        .at("promotionSteps")
        .at(stepIndex)
        .withDefault({})
        .at("fields")
        .at(fieldIndex)
        .withDefault({})
        .at("values")
        .at(valueIndex)
        .set(DELETE);

      setValues(nextValues);
    },
    [setValues, values, deepUpdate],
  );

  useEffect(() => {
    if (values.zones?.headlineZone.active && currentTab !== "headline") {
      setCurrentTab("headline");
    }

    if (values.zones?.logoZone.active && currentTab !== "logo") {
      setCurrentTab("logo");
    }
  }, [currentTab, values.zones?.headlineZone.active, values.zones?.logoZone.active]);

  return (
    <div
      className="image-builder-tab"
      key={
        values.promotionItem
          ? `${values.promotionItem.promotionItemid}-${values.promotionItem.promotionId}`
          : ""
      }
    >
      <ImageBuilderItem
        hasBuilder={hasBuilder}
        showPreview={!showTemplateList}
        onCancel={() => setShowTemplateList(false)}
        onSelectTemplateClick={() => setShowTemplateList(true)}
        loading={loading}
        background={background}
      />

      {Boolean(hasBuilder && !showTemplateList && !values.wrongTemplate) && (
        <Collapse onChange={tabChangeHandler} activeKey={currentTab} accordion={true}>
          <Collapse.Panel key="background" header="Background">
            <ImageBuilderBackgroundStep setBackground={setBackground} background={background} />
          </Collapse.Panel>

          <Collapse.Panel className="headline-panel" key="headline" header="Headlines">
            <ImageBuilderStep
              step={headlineStep.item}
              index={headlineStep.index}
              setFieldValue={setFieldValue}
              onAddValueClick={(fieldIndex) => addValueHandler(headlineStep.index, fieldIndex)}
              onRemoveValueClick={(fieldIndex, valueIndex) =>
                removeValueHandler(headlineStep.index, fieldIndex, valueIndex)
              }
            />
          </Collapse.Panel>

          <Collapse.Panel key="logo" header="Logo">
            <PromotionBuilderLogoStep />
          </Collapse.Panel>
        </Collapse>
      )}

      {showTemplateList && (
        <SelectTemplateWrapper
          isEdit={isEdit}
          onCancel={() => setShowTemplateList(false)}
          onSetTemplate={(item) => {
            if (values.zones) {
              item.zones.headlineZone.font = values.zones.headlineZone.font;
            }
            setValues(item);
            setShowTemplateList(false);
          }}
        />
      )}
    </div>
  );
}
