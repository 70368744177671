import { BaseApi } from "../BaseApi";
import { toSnakeCase } from "../../helpers/CaseUtils";

export class ChargebeeApi extends BaseApi {
  getSubscriptions() {
    return this.get("chargebee/subscriptions/get");
  }

  createSubscription(data) {
    return this.post("chargebee/subscriptions/create", { json: data });
  }

  getPlanList() {
    return this.get("chargebee/plans/list");
  }

  getCustomer() {
    return this.get("chargebee/customers/get");
  }

  getProfile() {
    return this.get("chargebee/profile");
  }

  updateCustomer() {
    return this.put("chargebee/customers/update");
  }

  reactivateSubscription() {
    return this.post("chargebee/subscriptions/reactivate");
  }

  updateCard(body) {
    return this.put("chargebee/customers/update-card", {
      json: toSnakeCase(body),
    });
  }

  getInvoiceList() {
    return this.get("chargebee/invoices/list").then(({ list }) => list);
  }

  getBraintreeToken() {
    return this.get("chargebee/braintree/token");
  }

  updateBillingAddress(body) {
    return this.put("chargebee/customers/update-bill-addr", {
      json: toSnakeCase(body),
    });
  }
  getPlanDescription(planId) {
    return this.get("chargebee/plans/get/:planId", { params: { planId } }).then(
      ({ data = [] }) => data,
    );
  }
}
