import { useMemo } from "react";

import { TeamApi } from "./TeamApi";
import { useApiBase } from "../ApiContext";

export function useTeamContext() {
  const data = useApiBase();

  const api = useMemo(() => new TeamApi(data), [data]);

  return {
    TeamApi: api,
  };
}
