import { IS_DEV, IS_PROD } from "./AppConstants";

export const PRODUCTION_HOST = "https://app-api.vsbl.biz";
export const STAGING_HOST = "https://staging-api.vsbl.biz";
export const DEVELOPMENT_HOST = "https://dev-api.vsbl.biz";

export const BASE_HOST = IS_DEV ? DEVELOPMENT_HOST : IS_PROD ? PRODUCTION_HOST : STAGING_HOST;

export const HOST = `${BASE_HOST}/api/v1`;

export const PIXABAY_HOST = "https://pixabay.com";
