import { reducePromiseState, usePromise } from "@victorzimnikov/utility-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

import { toCamelCase } from "../helpers/CaseUtils";

function reducer(state, action) {
  if (action.type === "pending") {
    return { status: "pending", value: state.value };
  }

  return reducePromiseState(state, action);
}

export function useResource(factory, deps, options) {
  const [key, setKey] = useState(Math.random);
  const [error, setError] = useState();

  const state = usePromise(factory, deps, {
    reducer,
    ...options,
    key: `${key}@${options?.key}`,
  });

  const refetch = useCallback(() => setKey(Math.random), []);
  const resetError = useCallback(() => setError(undefined), []);

  useEffect(() => {
    if (state.error) {
      setError(state.error);
    }
  }, [state.error]);

  return useMemo(
    () => ({
      error,
      refetch,
      resetError,
      data: toCamelCase(state.value),
      loading: state.status === "pending" && !options?.skip,
    }),
    [state.value, error, state.status, refetch, options, resetError],
  );
}
