import React from "react";

import PropTypes from "prop-types";
import { Form, SubmitButton } from "formik-antd";
import { InputField } from "../form/InputField";

// eslint-disable-next-line no-use-before-define
AcceptTeamVerificationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export function AcceptTeamVerificationForm({ handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item name="password" label="Password">
        <InputField
          type="password"
          name="password"
          className="mb-4"
          transparent={false}
          placeholder="Your Password"
          showError={false}
        />
      </Form.Item>

      <Form.Item name="confirmationPassword" label="Confirm Password">
        <InputField
          type="password"
          name="confirmationPassword"
          className="mb-4"
          transparent={false}
          placeholder="Your password confirmation"
          showError={false}
        />
      </Form.Item>

      <SubmitButton block={true} type="primary">
        Save
      </SubmitButton>
    </Form>
  );
}
