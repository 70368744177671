import qs from "qs";
import ky from "ky";

import { AppError } from "../helpers/AppError";
import { apiLogger } from "../helpers/ApiUtils";
import { replaceParams } from "../helpers/UrlUtils";

export interface BaseApiProps {
  readonly host?: string;
  readonly appId?: string;
  readonly token?: string;
  readonly logout?: () => void;
  readonly administrator?: boolean;
}

export class BaseApi {
  private readonly host?: string;
  private readonly appId?: string;
  private readonly token?: string;
  private readonly logout?: () => void;
  private readonly administrator?: boolean;

  constructor({ token, host, appId, administrator, logout }: BaseApiProps = {}) {
    this.host = host;
    this.appId = appId;
    this.token = token;
    this.logout = logout;
    this.administrator = administrator;
  }

  queryToString(query) {
    return qs.stringify(query);
  }

  createRequestUrl(url, query, params) {
    const formattedUrl = replaceParams(url, params);

    return [formattedUrl, this.queryToString(query)].filter(Boolean).join("?");
  }

  createRequestOptions(options) {
    const { hooks = {}, headers: optionHeaders = {} } = options;

    const headers = new Headers(optionHeaders);

    if (this.appId) {
      headers.set("vsbl-app-id", this.appId);
    }

    if (this.administrator && this.token) {
      headers.set("vsbl-user-session-token", this.token);
    }

    if (!this.administrator && this.token) {
      headers.set("vsbl-member-session-token", this.token);
    }

    return {
      prefixUrl: this.host,
      timeout: 60000,
      ...options,
      headers,
      hooks: {
        ...hooks,
        beforeRequest: [...(hooks?.beforeRequest || []), apiLogger],
        afterResponse: [
          ...(hooks?.afterResponse || []),
          async (request, options, response) => {
            if (response.status === 403 && this.logout) {
              this.logout();

              return null;
            }
          },
        ],
      },
    };
  }

  request(url, options) {
    const { query, params, ...kyOptions } = options;

    const formattedOptions = this.createRequestOptions(kyOptions);
    const formattedUrl = this.createRequestUrl(url, query, params);

    return ky(formattedUrl, formattedOptions);
  }

  jsonRequest<TData>(url, options): Promise<TData> {
    return new Promise((resolve, reject) => {
      this.request(url, options)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          return response
            .json()
            .then((data) => this.parseError(data))
            .then((error) => {
              throw error;
            });
        })
        .then(resolve)
        .catch(({ response }) => {
          if (response) {
            response
              .json()
              .then(({ errors }) => {
                const error = this.parseError(response, errors);

                reject(error);
              })
              .catch(reject);
          } else {
            reject(new Error("Unknown"));
          }
        });
    });
  }

  parseError(response: Response, data?: any) {
    const error = new Error(response.statusText) as AppError;

    error.data = data || response;
    error.status = response?.status;

    return new AppError(error);
  }

  get<TData = any>(url, options): Promise<TData> {
    return this.jsonRequest<TData>(url, { ...options, method: "get" });
  }

  post<TData = any>(url, options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "post" });
  }

  put<TData = any>(url, options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "put" });
  }

  patch<TData = any>(url, options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "patch" });
  }

  delete<TData = any>(url, options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "delete" });
  }
}
