import { BaseApi } from "../BaseApi";
import { toKebabCase } from "../../helpers/CaseUtils";

export class UsersApi extends BaseApi {
  importUsers(body) {
    return this.post("users/import", { body }).then(({ data }) => data);
  }

  getUsersList(clientId) {
    return this.get("users/:clientId/list", { params: { clientId } }).then(({ data }) => data);
  }

  changeUserStatus(id, query) {
    return this.patch("users/:id/activate", { query, params: { id } });
  }

  updateUser(body) {
    return this.post("users/update", { json: toKebabCase(body) });
  }

  deleteUser(id) {
    return this.delete("users/:id", { params: { id } });
  }

  resendInvite(email) {
    return this.patch("users/resend-activation", { query: { email } });
  }

  inviteUser(body) {
    return this.post("users/invite", { json: toKebabCase(body) });
  }

  changePassword(body) {
    return this.post("users/change-password", { json: body });
  }
}
