import React from "react";

import { Form } from "antd";

import { VSBLCol } from "../grid";

export function VSBLFormItem(props) {
  return (
    <VSBLCol span={props.span} offset={props.offset}>
      <Form.Item colon={props.colon} label={props.label} className={props.className}>
        {props.children}
      </Form.Item>
    </VSBLCol>
  );
}
