import React from "react";

import { Helmet } from "react-helmet";

export function HTMLHead(props) {
  return (
    <Helmet>
      <title>{makeTitle(props.title)}</title>
    </Helmet>
  );
}

function makeTitle(title) {
  if (!title) return "Vsbl - Light up your team's social networks";

  return `Vsbl - Light up your team's social networks - ${title}`;
}
