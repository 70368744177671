import "./assets/user-status-switch-wrapper.scss";

import PropTypes from "prop-types";
import { notification, Switch } from "antd";
import React, { useCallback, useState } from "react";

import { UserStatusPending } from "./UserStatusPending";
import { useUsersContext } from "../../api/users/UsersContext";

UserStatusSwitchWrapper.propTypes = {
  user: PropTypes.object,
  onComplete: PropTypes.func,
};

export function UserStatusSwitchWrapper({ user, onComplete }) {
  const [loading, setLoading] = useState(false);

  const { UsersApi } = useUsersContext();

  const updateHandle = useCallback(async () => {
    setLoading(true);

    try {
      await UsersApi.changeUserStatus(user.id, { activate: !user.active });

      setLoading(false);

      onComplete();
    } catch (e) {
      setLoading(false);

      notification.open({
        type: "error",
        description: e.message,
        message: "Change Status",
        duration: null,
      });
    }
  }, []);

  return (
    <div className="mt-1 user-status-switch-wrapper">
      {user.verified && <Switch checked={user.active} onClick={updateHandle} loading={loading} />}
      {!user.verified && <UserStatusPending />}
    </div>
  );
}
