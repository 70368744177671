import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { Field } from "../form/Field";
import { InputWithCount } from "../ui/InputWithCount";

PromotionTextField.propTypes = {
  name: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    required: PropTypes.bool,
    options: PropTypes.shape({
      settings: PropTypes.shape({
        regex: PropTypes.string,
        charLimit: PropTypes.bool,
        rowCount: PropTypes.number,
        charCount: PropTypes.number,
      }),
    }),
  }),
};

export function PromotionTextField({ name, field }) {
  const inputType = useMemo(() => {
    if (field.options && field.options.settings && field.options.settings.rowCount > 1) {
      return "textarea";
    }
  }, [field.options]);

  const charCount = useMemo(() => {
    if (field.options && field.options.settings && field.options.settings.charLimit) {
      return field.options.settings.charCount;
    }
  }, [field.options]);

  return (
    <Field label={field.name} name={`${name}.value`} withError={field.required}>
      {({ inputProps, field: inputField }) => (
        <InputWithCount {...inputField} {...inputProps} maxLength={charCount} type={inputType} />
      )}
    </Field>
  );
}
