import { Button } from "antd";
import { update } from "immupdate";
import React, { useCallback, useState } from "react";

import { useNotification } from "../../hooks/useNotification";
import { ReminderProps } from "../../api/reminders/RemindersDTO";
import { useRemindersContext } from "../../api/reminders/RemindersContext";
import { useUser } from "../../context/auth/hooks";
import { getErrorMessage } from "../../utils/utils";
import { useNavigate } from "react-router";

interface Props {
  readonly className?: string;
  readonly item: ReminderProps;
}

export function CopyReminderButtonWrapper({ item, className }: Props) {
  const { clientId } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { showError } = useNotification();

  const { RemindersApi } = useRemindersContext();

  const copyHandler = useCallback(
    async (item: ReminderProps) => {
      setLoading(true);

      try {
        await RemindersApi.createReminder(
          update(item, {
            id: 0,
            name: `${item.name}_copy`,
          }),
        );
        navigate(0);
        setLoading(false);
      } catch (e) {
        setLoading(false);

        showError(getErrorMessage(e));
      }
    },
    [showError, clientId, RemindersApi],
  );

  return (
    <Button
      size="small"
      type="primary"
      loading={loading}
      disabled={loading}
      className={className}
      onClick={() => copyHandler(item)}
    >
      Copy
    </Button>
  );
}
