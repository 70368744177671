export const months = [
  {
    abbreviation: "Jan",
    text: "January",
    value: 0,
  },
  {
    abbreviation: "Feb",
    text: "February",
    value: 1,
  },
  {
    abbreviation: "Mar",
    text: "March",
    value: 2,
  },
  {
    abbreviation: "Apr",
    text: "April",
    value: 3,
  },
  {
    abbreviation: "May",
    text: "May",
    value: 4,
  },
  {
    abbreviation: "Jun",
    text: "June",
    value: 5,
  },
  {
    abbreviation: "Jul",
    text: "July",
    value: 6,
  },
  {
    abbreviation: "Aug",
    text: "August",
    value: 7,
  },
  {
    abbreviation: "Sep",
    text: "September",
    value: 8,
  },
  {
    abbreviation: "Oct",
    text: "October",
    value: 9,
  },
  {
    abbreviation: "Nov",
    text: "November",
    value: 10,
  },
  {
    abbreviation: "Dec",
    text: "December",
    value: 11,
  },
];

const yearOptionCount = 10;

/* eslint-disable prefer-spread */
const filledArray = Array.apply(null, Array(yearOptionCount));
/* eslint-enable prefer-spread */

export const years = filledArray.map((value, index) => {
  const year = new Date().getFullYear() + index;

  return {
    text: year,
    value: year,
  };
});
