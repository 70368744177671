import React, { SVGProps, useMemo } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  readonly size?: number;
  readonly active?: boolean;
}

export function CheckCircleIcon({ size = 24, active, color = "#2f9947", ...svgProps }: Props) {
  const fill = useMemo(() => {
    if (active) {
      return color;
    }

    return "#cccccc";
  }, [color, active]);

  return (
    <svg {...svgProps} width={size} height={size} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
      />
    </svg>
  );
}
