import React from "react";
import * as yup from "yup";
import { Button } from "antd";
import { Formik } from "formik";
import valid from "card-validator";
import PropTypes from "prop-types";
import { update } from "immupdate";

import { Dialog } from "../ui/Dialog";
import { CardDetailsForm } from "../payment/CardDetailsForm";
import { toFinite } from "lodash";

const validationSchema = yup.object().shape({
  cardNumber: yup
    .string()
    .test("cardNumber", "Credit Card number is invalid", (number) => valid.number(number).isValid)
    .required("Card Number is required"),
  expirationMonth: yup
    .string()
    .test(
      "expirationMonth",
      "Expiration Month is invalid",
      (month) => valid.expirationMonth(String(Number(month) + 1)).isValid,
    )
    .required("Expiration Month is required"),
  expirationYear: yup
    .string()
    .test(
      "expirationYear",
      "ExpirationYear is invalid",
      (year) => valid.expirationYear(year).isValid,
    )
    .required("Expiration Year is required"),
  cvv: yup
    .string()
    .test("cvv", "CVV is invalid", (cvv) => valid.cvv(cvv).isValid)
    .required("CVV is required"),
  holderName: yup.string().required("Card Holder Name is required"),
  country: yup.string().required("Country is required"),
  state: yup
    .string()
    .when("country", (field, schema) =>
      field === "US" ? schema.required("State is required") : schema,
    ),
  postalCode: yup
    .string()
    .test(
      "postalCode",
      "Postal Code is invalid",
      (postalCode) => valid.postalCode(postalCode).isValid,
    )
    .required("Zip Code is required"),
});

SettingsEditCardDialog.propTypes = {
  show: PropTypes.bool,
  card: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  stateList: PropTypes.array,
};

export function SettingsEditCardDialog({ show, card = {}, onSubmit, stateList = [], onCancel }) {
  return (
    <Dialog footer={null} width={768} show={show} onCancel={onCancel} title="Update Payment Method">
      <Formik
        onSubmit={onSubmit}
        validateOnMount={true}
        validationSchema={validationSchema}
        initialValues={update(card, {
          state: "",
          cardNumber: card.maskedNumber,
          expirationYear: card.expiryYear,
          country: card.issuingCountry || "US",
          holderName: [card.firstName, card.lastName].filter(Boolean).join(" "),
          expirationMonth: card.expiryMonth
            ? Math.max(0, toFinite(card.expiryMonth) - 1)
            : undefined,
        })}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form className="d-flex flex-column p-2" onSubmit={handleSubmit}>
            <CardDetailsForm stateList={stateList} />

            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              className="m-2 align-self-sm-center"
            >
              update payment method
            </Button>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
