import { toString } from "lodash";

export function replaceParams(str, params = {}): string {
  return str.replace(/:[a-zA-Z]+\??/g, (value) => {
    const key = value.slice(1);

    const param = params[key];

    if (!param && param !== 0) {
      return "";
    }

    return toString(param);
  });
}
