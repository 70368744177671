import React from "react";

interface Props {
  readonly size?: number;
  readonly className?: string;
}

export function ArrowTopIcon({ size = 24, className }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" className={className}>
      <path
        fill="currentColor"
        d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z"
      />
    </svg>
  );
}
