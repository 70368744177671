import React from "react";

import { ColorPickerField } from "../form/ColorPickerField";

export function SettingsCompanyColors() {
  return (
    <div className="d-flex flex-column mb-6 mb-md-0">
      <div className="d-flex">
        <div className="d-flex flex-grow-1 flex-shrink-1 flex-column mr-2">
          <span className="fl-3 font-weight-light text-primary mb-2">Primary Color</span>
          <ColorPickerField name="primaryColor" />
        </div>
        <div className="d-flex flex-grow-1 flex-shrink-1 flex-column ml-2">
          <span className="fl-3 font-weight-light text-primary mb-2">Accent Color</span>
          <ColorPickerField
            name="secondaryColor"
            anchor={{ horizontal: "right", vertical: "top" }}
          />
        </div>
      </div>
    </div>
  );
}
