export function sleep(ms = 250) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function createWindow(link, options = {}) {
  const { width = 560, height = 650 } = options;

  const x = screen.width / 2 - width / 2;
  const y = screen.height / 2 - height / 2;

  window.open(link, undefined, `height=${height}, width=${width}, top=${y}, left=${x}`);
}

export const onCopyToClipboard = async (message, onCopySuccess) => {
  try {
    await navigator.clipboard.writeText(message);
    onCopySuccess();
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
