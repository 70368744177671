import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

SettingsTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(["secondary", "primary"]),
};

export function SettingsTitle({ color = "secondary", children, className }) {
  return (
    <h6
      className={cx(
        "font-family-museo text-primary m-0 text-uppercase font-weight-light",
        className,
        {
          "text-primary": color === "primary",
          "text-secondary": color === "secondary",
        },
      )}
    >
      {children}
    </h6>
  );
}
