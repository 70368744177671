import React from "react";

import { Dialog } from "../ui/Dialog";

interface Props {
  readonly show: boolean;
  readonly onCancel: () => void;
}

export function DeleteInfoDialog({ show, onCancel }: Props) {
  return (
    <Dialog footer={null} show={show} onCancel={onCancel} title="Delete Account">
      <div className="py-5">
        <h2 className="text-center">Account has been deleted</h2>
      </div>
    </Dialog>
  );
}
