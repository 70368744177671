import React from "react";
import cx from "classnames";
import { Spin } from "antd";
import PropTypes from "prop-types";

Loader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export function Loader(props) {
  return (
    <div
      className={cx(
        "d-flex align-items-center justify-content-center flex-1 mt-1",
        props.className,
      )}
    >
      <Spin
        size="large"
        indicator={
          <span className="ant-spin-dot ant-spin-dot-spin">
            <i className="ant-spin-dot-item" style={{ backgroundColor: props.color }} />
            <i className="ant-spin-dot-item" style={{ backgroundColor: props.color }} />
            <i className="ant-spin-dot-item" style={{ backgroundColor: props.color }} />
            <i className="ant-spin-dot-item" style={{ backgroundColor: props.color }} />
          </span>
        }
      />
    </div>
  );
}
