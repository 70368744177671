import { useMemo } from "react";

import { useApiBase } from "../ApiContext";
import { FilesApi } from "./FilesApi";

export function useFilesContext() {
  const data = useApiBase();

  const api = useMemo(() => new FilesApi(data), [data]);

  return {
    FilesApi: api,
  };
}
