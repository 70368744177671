import { DateTime } from "luxon";
import { isUndefined } from "lodash";
import React, { useMemo } from "react";

import { LinkButton } from "../ui/LinkButton";
import { SettingsTitle } from "./SettingsTitle";
import { formatCardType } from "../../helpers/CardHelpers";

interface Props {
  readonly card?: any;
  readonly subscription: any;
  readonly onEditCardClick?: () => void;
  readonly onViewBillingClick?: () => void;
}

export function SettingsPaymentMethod({
  card = {},
  subscription = {},
  onEditCardClick,
  onViewBillingClick,
}: Props) {
  const status = useMemo(() => subscription?.status, [subscription]);
  const trialPassDays = useMemo(() => {
    if (!subscription?.trialStart) {
      return;
    }

    const { days } = DateTime.fromSeconds(subscription?.trialStart).diffNow("days");

    return Math.floor(Math.abs(days));
  }, [subscription]);

  const expiryTitle = useMemo(() => {
    if (status === "extra_days_expired" || status === "extra_days_notstarted") {
      return "if you wish to keep using Vsbl, please enter a payment method to start your subscription. If you have questions, please contact us at support@vsbl.biz";
    }

    if (status === "in_trial" && !isUndefined(trialPassDays)) {
      const days = 30 - trialPassDays;

      return `${Math.max(0, days)} Days remain`;
    }

    if (status === "extra_days_inprogress" && !isUndefined(trialPassDays)) {
      const days = 15 - trialPassDays;

      return `${Math.max(0, days)} Days remain`;
    }

    if (Object.keys(card).length > 0 && card.expiryMonth && card.expiryYear)
      return `Expires ${card.expiryMonth}/${card.expiryYear}`;

    return "00/0000";
  }, [card, status, trialPassDays]);

  const cardInfoTitle = useMemo(() => {
    if (status === "extra_days_expired" || status === "extra_days_notstarted") {
      return "Your trial is over";
    }
    if (status === "in_trial" || status === "extra_days_inprogress") {
      return "We hope you are enjoying your trial";
    }

    if (Object.keys(card).length > 0 && card.last4 && card.cardType)
      return `${formatCardType(card.cardType)} ending in ${card.last4}`;

    return "No card on file";
  }, [card, status]);

  const buttonTitle = useMemo(() => {
    if (Object.keys(card).length > 0 && onEditCardClick) return "Edit card";

    if (Object.keys(card).length > 0 && onViewBillingClick) return "View billing";

    if (Object.keys(card).length === 0 && onEditCardClick) return "Add a card";

    if (Object.keys(card).length === 0) return "Add a payment method";
  }, [card, onEditCardClick, onViewBillingClick]);

  return (
    <div className="d-flex flex-column flex-1 mb-6 mb-mb-0 justify-content-between">
      <SettingsTitle className="mb-3">Payment method:</SettingsTitle>
      <div className="d-flex flex-column">
        <span className="fl-5 font-weight-light text-primary mb-1">{cardInfoTitle}</span>
        <span className="text-primary font-weight-light mb-3">{expiryTitle}</span>
        {Boolean(onEditCardClick || onViewBillingClick) && (
          <div className="d-flex">
            <LinkButton className="mr-3" onClick={onEditCardClick! || onViewBillingClick!}>
              {buttonTitle}
            </LinkButton>
          </div>
        )}
      </div>
    </div>
  );
}
