import React from "react";
import PropTypes from "prop-types";

import { VSBLAlert } from "../../widgets/VSBLAlert";

PublicAlert.propTypes = {
  error: PropTypes.any,
  children: PropTypes.node,
  isSuccess: PropTypes.bool,
  onResendVerificationLink: PropTypes.func,
};

export function PublicAlert({ isSuccess, error, children, onResendVerificationLink }) {
  if (!isSuccess && !error) return null;

  const alertProps = {
    type: "warning",
    text: children,
  };

  if (error) {
    alertProps.type = "error";

    if (error.indexOf("has not been verified") >= 0) {
      alertProps.text = (
        <span>
          {error}{" "}
          <span
            role="button"
            onClick={onResendVerificationLink}
            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
          >
            Resend verification link
          </span>
        </span>
      );
    } else {
      alertProps.text = error;
    }
  }

  return <VSBLAlert span={24} {...alertProps} />;
}
