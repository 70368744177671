import { useCallback, useEffect, useRef, useState } from "react";

import { useLocation } from "react-router";
import { shallowEqual, useSelector } from "react-redux";
import qs from "query-string";

import { useAsync, useDispatchify, useOnce } from "./decorators";

export function useEffectOnce(callback) {
  return useOnce(useEffect)(callback);
}

export function useStateRef(defaultValue) {
  const refContainer = useRef(defaultValue);

  function setRef(newValue) {
    refContainer.current = newValue;
  }

  return [refContainer, setRef];
}

export function useAsyncEffectOnce(asyncCallback) {
  return useOnce(useAsync(useEffect))(asyncCallback);
}

export function useDispatchAsyncCallback(action, deps) {
  return useDispatchify(useAsync(useCallback))(action, deps);
}

export function useDispatchCallbackOnce(action) {
  return useDispatchify(useOnce(useCallback))(action);
}

/**
 * todo callback
 * @param selector
 * @param action
 * @return {{state: *}}
 */
export function useConnect(selector, action, deps) {
  const state = useReduxState(selector);

  const hook = useDispatchify(useOnce(useAsync(useEffect)));

  const result = hook(action, deps);
  return { ...result, state };
}

// to store a function we need to wrap it as useState consider's it as callback
export function useFunctionState(defaultValue) {
  const [state, setState] = useState(() => defaultValue);

  function setFunction(newValue) {
    setState(() => newValue);
  }

  return [state, setFunction];
}

export function useReduxState(selector) {
  const state = useSelector(selector, shallowEqual);

  return state;
}

export function useQuery(options) {
  const { search } = useLocation();

  return qs.parse(search, options);
}

export function useForceUpdate() {
  const [, dispatch] = useState(Object.create(null));

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = useCallback(() => {
    dispatch(Object.create(null));
  }, [dispatch]);

  return memoizedDispatch;
}
