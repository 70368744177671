import { useDrop } from "react-dnd";
import { useField, useFormikContext } from "formik";
import { makeStyles } from "@material-ui/styles";
import React, { ReactNode, useCallback, useEffect, useMemo } from "react";

import { TemplateDndType } from "./TemplateDnd";
import { useTemplateDnd } from "./TemplateDndContext";

const useStyles = makeStyles({
  root: { position: "relative", overflow: "hidden", background: "#b1abab" },
  container: { position: "absolute", top: 0, left: 0, right: 0, bottom: 0 },
});

interface Props {
  readonly id: string;
  readonly width: number;
  readonly height: number;
  readonly image: string;
  readonly children: ReactNode;
  readonly background?: any;
}

export function TemplateDndTarget({
  id,
  width,
  image,
  height,
  children,
  background = { blur: 0 },
}: Props) {
  const classes = useStyles();
  const formik = useFormikContext<any>();
  const { setRealSize, rootSize, aspectRatio, getSizes } = useTemplateDnd();
  const overlayZone = useField("zones.overlayZone");
  const overlayZoneValue = overlayZone[1].value;
  const isBig = width === 1200;

  const moveHandler = useCallback(
    (type: TemplateDndType, left: number, top: number) => {
      if (type === TemplateDndType.Headline) {
        formik.setFieldValue("zones.headlineZone.foaty", top / aspectRatio);
        formik.setFieldValue("zones.headlineZone.foatx", left / aspectRatio);
      } else if (type === TemplateDndType.Logo) {
        formik.setFieldValue("zones.logoZone.foaty", top / aspectRatio);
        formik.setFieldValue("zones.logoZone.foatx", left / aspectRatio);
      }
    },
    [formik, aspectRatio],
  );

  useEffect(() => {
    getSizes();
  }, [getSizes, formik.values]);

  const [, drop] = useDrop(
    () => ({
      accept: [TemplateDndType.Logo, TemplateDndType.Headline],
      drop(item: { top: number; left: number; width: number; height: number }, monitor) {
        const type = monitor.getItemType() as TemplateDndType;
        const delta = monitor.getDifferenceFromInitialOffset() as { x: number; y: number };

        const translateY = item.top + delta.y;
        const translateX = item.left + delta.x;

        const translateRight = rootSize.x + rootSize.width - item.width;
        const translateBottom = rootSize.y + rootSize.height - item.height;

        const left = Math.min(translateX, translateRight);
        const top = Math.min(translateY, translateBottom);

        moveHandler(type, left, top);

        return undefined;
      },
    }),
    [moveHandler, rootSize],
  );

  const rootStyles = useMemo(() => ({ width }), [width]);
  const imageStyles = useMemo(
    () => ({
      maxWidth: width,
      width: "100%",
      height: height,
      filter: `blur(${background.blur.toString()}px) brightness(${background.brightness.toString()}%) saturate(${background.saturation.toString()}%)`,
    }),
    [width, background, height],
  );

  useEffect(() => {
    setRealSize({ width, height });
  }, [width, height, setRealSize, rootSize]);

  return (
    <div ref={drop} style={rootStyles} id={id} className={classes.root}>
      <img alt="bg" src={image} style={imageStyles} />
      <img
        src={overlayZone[1]?.value?.image}
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: isBig ? 1200 : 600,
          height: isBig ? overlayZoneValue?.height * 2 : overlayZoneValue?.height,
        }}
        alt="overlay"
      />

      <div className={classes.container}>{children}</div>
    </div>
  );
}
