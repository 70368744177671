import React from "react";

export function ReadyTitle() {
  return (
    <>
      <div>Congratulations Your VSBL Dashboard is Ready!</div>
      <strong>{window.location.hostname}</strong>
    </>
  );
}
