import "./assets/promotion-messaging-pinterest.scss";

import React from "react";

import { SwitchField } from "../form/SwitchField";
import { InputWithCountField } from "../form/InputWithCountField";
import { PromotionMessagingPinterestPost } from "./PromotionMessagingPinterestPost";
import InputUrlField from "../form/InputUrlField";
import { useFormikContext } from "formik";

export function PromotionMessagingPinterest() {
  const { values } = useFormikContext() as any;
  const enabled = values.promotionSocial.pinterestEnabled;

  return (
    <div className="messaging-tab-body-layout">
      <div className="d-flex flex-column flex-md-row">
        <div className="flex-1 mr-lg-3 client-promotion-edit-messaging-pinterest-controls">
          <SwitchField name="promotionSocial.pinterestEnabled" text="Pinterest" />

          <InputWithCountField
            type="textarea"
            maxLength={256}
            label="Description"
            disabled={!enabled}
            withErrors={false}
            className="messaging-form-item"
            name="promotionSocial.pinterestDescription"
            footerTextClassName="messaging-form-item-characters"
            footerContainerClassName="messaging-form-item-footer"
            placeholder="Pinterest post description goes here"
          />

          <InputUrlField active={enabled} values={values} valueName="pinterestOverrideUrl" />
        </div>
        <PromotionMessagingPinterestPost />
      </div>
    </div>
  );
}
