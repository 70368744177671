import "../promotion/assets/promotion-choose-button.scss";

import React from "react";
import { Button } from "antd";
import { CheckCircleIcon } from "../icons/CheckCircleIcon";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import moment from "moment";
import { prop, propOr } from "ramda";

interface Props {
  readonly disabled?: boolean;
  readonly onClick: (value) => void;
  readonly headline: any;
  readonly settings: any;
}

export function PromotionChooseButton({ disabled, onClick, headline, settings }: Props) {
  const { isMobile } = useMediaQuery();

  const color = propOr("#025692", "primaryColor", settings);

  const submittedDate = propOr(0, "submittedDate", headline);
  const used = prop("used", headline);
  const value = prop("value", headline);

  const date = moment(submittedDate).format("MM/DD/yyyy");
  return (
    <Button
      onClick={() => onClick(headline)}
      disabled={disabled}
      style={{
        borderColor: color,
        backgroundColor: color,
      }}
      type="primary"
      className="mb-2 promotion-choose-button"
    >
      {used && <CheckCircleIcon size={isMobile ? 42 : 48} color="white" active={true} />}
      <div className="choose-button-text">
        <span className="label">{value}</span>
        {used && <span className="time">LAST POSTED {date}</span>}
      </div>
    </Button>
  );
}
