import * as yup from "yup";
import { Formik } from "formik";
import { useLocation, useParams } from "react-router";
import React, { useCallback, useMemo, useState } from "react";

import { AppRoutes } from "../../constants/Routes";
import { useAuthContext } from "../../api/auth/AuthContext";
import { PublicLayout } from "../../core/layout/public/PublicLayout";
import { ResetPasswordForm } from "../../components/public/ResetPasswordForm";
import useRouter from "../../hooks/useRouter";
import { toCamelCase } from "../../helpers/CaseUtils";
import { useSettingsContext } from "../../api/settings/SettingsContext";
import { useSettings, useToken, useUser } from "../../context/auth/hooks";
import { prop } from "ramda";
import { useNotification } from "../../hooks/useNotification";

const validationSchema = yup.object().shape({
  password: yup.string().min(8).required("Password is required"),
  confirmationPassword: yup.string().oneOf([yup.ref("password")], "Passwords must match"),
});

const initialValues = {
  password: "",
  confirmationPassword: "",
};

export function ResetPasswordContainer() {
  const router = useRouter();
  const location = useLocation();
  const { key } = useParams<any>();
  const { setUser } = useUser();
  const { setToken } = useToken();
  const { setSettings } = useSettings();
  const { showError } = useNotification();

  const { AuthApi } = useAuthContext();
  const { SettingsApi } = useSettingsContext();

  const [loading, setLoading] = useState(false);

  const isMember = useMemo(() => location.pathname.indexOf("/auth/team") === 0, [
    location.pathname,
  ]);

  const submitHandler = useCallback(
    ({ password }) => {
      setLoading(true);
      if (isMember) {
        AuthApi.resetTeamPassword({ password, key })
          .then((resetResponse) => {
            const sessionToken = prop("session-token", resetResponse);
            SettingsApi.getCompany({
              headers: [["vsbl-member-session-token", sessionToken]],
            })
              .then((response) => {
                setSettings(toCamelCase(response));
                setUser(toCamelCase({ ...resetResponse, isAdministrator: false }));
                setToken(sessionToken);
                router.push(AppRoutes.TeamPortalPromotions);
                setLoading(false);
              })
              .catch((e) => {
                showError(e);
                setLoading(false);
              });
          })
          .catch((e) => {
            showError(e);
            setLoading(false);
          });
      } else {
        AuthApi.resetPassword({ password, key })
          .then((response) => {
            const res = toCamelCase(response);
            setUser({ ...res, isAdministrator: true });
            setToken(res.sessionToken);

            return SettingsApi.getCompany({
              headers: [["vsbl-user-session-token", res.sessionToken]],
            }).then((companyRes) => {
              setSettings(toCamelCase(companyRes));
              if (
                res.companyStatus !== "extra_days_expired" &&
                res.companyStatus !== "extra_days_notstarted"
              ) {
                router.push(AppRoutes.AdminPromotions);
              }
              setLoading(false);
            });
          })
          .catch((e) => {
            showError(e);
            setLoading(false);
          });
      }
    },
    [showError, SettingsApi, setToken, setSettings, setUser, AuthApi, router, key, isMember],
  );

  return (
    <PublicLayout title="Reset password" HTMLHeadTitle="Sign In">
      <Formik
        onSubmit={submitHandler}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ handleSubmit }) => <ResetPasswordForm handleSubmit={handleSubmit} loading={loading} />}
      </Formik>
    </PublicLayout>
  );
}
