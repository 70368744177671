import "./assets/promotion-search-background-item.scss";

import React from "react";

import { PixabayImageResponseHitProps } from "../../api/other/PixabayDTO";

interface Props {
  readonly onClick: (link: string) => void;
  readonly item: PixabayImageResponseHitProps;
}

export function PromotionSearchBackgroundItem({ item, onClick }: Props) {
  return (
    <div
      role="button"
      onClick={() => onClick(item.largeImageUrl)}
      className="d-flex align-items-center justify-content-center mr-md-2 mb-2 cursor-pointer promotion-search-background-item"
    >
      <img src={item.webformatUrl} className="mw-100 mh-100" alt="img" />
    </div>
  );
}
