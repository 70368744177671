import "./assets/top-menu-item.scss";

import H from "history";
import React from "react";
import cx from "classnames";
import { Link, LinkProps } from "react-router-dom";

interface Props<S = H.LocationState> extends Omit<LinkProps, "to"> {
  readonly onClick?: () => void;
  readonly to?: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
}

export function TopMenuItem({ to, className, onClick, children, ...props }: Props) {
  if (onClick && !to) {
    return (
      <div
        role="button"
        onClick={onClick}
        className={cx(
          "text-gray font-weight-light px-3 text-uppercase cursor-pointer top-menu-item",
          className,
        )}
      >
        {children}
      </div>
    );
  }

  return (
    <Link
      {...props}
      to={to! as any}
      className={cx("text-gray font-weight-light px-3 text-uppercase top-menu-item", className)}
    >
      {children}
    </Link>
  );
}
